import CancellablePromise, { delay } from '../utils/CancelablePromiseWithDelay'
import useCancellablePromises from './use-cancellable-promises'

const useClickPreventionOnDoubleClick = (onClick, onDoubleClick) => {
  const api = useCancellablePromises()

  const handleClick = () => {
    api.clearPendingPromises()
    const waitForClick = CancellablePromise(delay(300))
    api.appendPendingPromise(waitForClick)

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick)
        onClick()
      })
      .catch((errorInfo) => {
        api.removePendingPromise(waitForClick)
        if (!errorInfo.isCanceled) {
          console.error('Promise rejection error:', errorInfo)
          throw errorInfo.error || new Error('Unknown error')
        }
      })
  }

  const handleDoubleClick = () => {
    api.clearPendingPromises()
    onDoubleClick()
  }

  return [handleClick, handleDoubleClick]
}

export default useClickPreventionOnDoubleClick
