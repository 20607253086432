/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { useQueryClient } from '@tanstack/react-query'
import { endOfWeek, isAfter, isBefore, startOfWeek } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../../auth/hooks/useUser'
import { ERROR_CODES_SHOW_MESSAGE, SUCCESS_ON_SAVE, UNABLE_TO_SAVE } from '../../../constants/errorConstants'
import {
  clearPreviousState,
  postAvailabilityRequestsData,
  setAvailabilityWeeklySelectedDate,
} from '../../../store/availability/actionCreator'
import { showNotificationError, showNotificationSuccess } from '../../../store/notification/actionCreator'
import { checkIfEndTimeSmaller } from '../../../utils/AvailabilityUtils'
import { getNowDateInTimezone, toIsoStringWithoutTime } from '../../../utils/DateUtil'
import { formatErrorCode } from '../../../utils/ErrorHandling'
import HeaderTitle from '../../Header/HeaderTitle'
import AvailabilitySubmitCancel from './AvailabilitySubmitCancel'
import RequestAvailabilityDetailByDate from './RequestAvailabilityDetailByDate'

const AVAILABILITY_REQUEST_ERROR_CODE = 'wfm-18-0'

const getStyles = (theme) => ({
  mainScrollContainer: {
    ...theme.mainScrollContainer,
    overflowY: 'auto',
    height: '100%',
  },
  mainContainerPosition: theme.mainContainerPosition,
  datesMarginTopMax: {
    marginTop: '40px',
  },
  clickableContainer: {
    cursor: 'pointer',
  },
  headerStyles: {
    backgroundColor: '#eeeeee',
  },
  datesMarginMin: {
    marginTop: '20px',
  },
  sectionHeader1: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(15),
  },
  bottomSectionContainer: {
    textAlign: 'right',
    borderTop: `1px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
  },
})

const RequestAvailabilityDetail = () => {
  const PAGE_TITLE = 'Update Availability Details'
  const dispatch = useDispatch()

  const [data, setData] = useState({})
  const [hasChanges, setHasChanges] = useState(false)

  const { userData, locationData } = useUser()
  const availabilitySelectedDate = useSelector((state) => state.availability.weeklySelectedDate)

  const userAvailabilityPostResponse = useSelector((state) => state.availability.userAvailabilityPostResponse)
  const userAvailabilityPostErrResponse = useSelector((state) => state.availability.userAvailabilityPostErrResponse)

  const availabilityReqStartDate = useSelector((state) => state.availability.availabilityReqStartDate)
  const availabilityReqEndDate = useSelector((state) => state.availability.availabilityReqEndDate)
  const availabilityPatternId = useSelector((state) => state.availability.availabilityPatternId)
  // const [comment, setComment] = useState('')
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false)
  const [alertMsg, setAlertMsg] = useState('FormNotStartedErrMsg')
  const [week2Enabled, setWeek2Enabled] = useState(false)

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const styles = getStyles(theme)
  useEffect(() => {
    if (!availabilityReqStartDate || !availabilityReqEndDate) {
      navigate('/team-member/availability/create-new-availability')
    }

    const { iso_time_zone_code } = locationData
    let currentSelectedDate

    if (iso_time_zone_code) {
      currentSelectedDate = getNowDateInTimezone(iso_time_zone_code)
    } else {
      handleDateChange(currentSelectedDate)
    }
  }, [])

  /* Handle Post Sucess and failure case */
  useEffect(() => {
    if (userAvailabilityPostResponse) {
      setSubmitBtnDisabled(false)
      dispatch(showNotificationSuccess(true, SUCCESS_ON_SAVE))
      dispatch(clearPreviousState())
      navigate('/team-member/availability')
    } else if (userAvailabilityPostErrResponse) {
      setSubmitBtnDisabled(false)
      if (
        userAvailabilityPostErrResponse.code &&
        userAvailabilityPostErrResponse.message &&
        ERROR_CODES_SHOW_MESSAGE.includes(userAvailabilityPostErrResponse.code)
      ) {
        dispatch(showNotificationError(true, userAvailabilityPostErrResponse.message))
      } else {
        dispatch(
          showNotificationError(
            true,
            UNABLE_TO_SAVE + ' ' + formatErrorCode(userAvailabilityPostErrResponse, AVAILABILITY_REQUEST_ERROR_CODE),
          ),
        )
      }
      // dispatch(clearPreviousState())
    }
  }, [userAvailabilityPostResponse, userAvailabilityPostErrResponse, dispatch, queryClient, navigate])

  const handleDateChange = (date) => {
    let inCurrentWeek = isSelectedDateInWeekOfPrevSelectedDate(date)

    if (!inCurrentWeek) {
      handleDifferentWeekDateChange(date)
    } else {
      dispatch(setAvailabilityWeeklySelectedDate(date))
    }
  }

  const handleDifferentWeekDateChange = (date) => {
    dispatch(setAvailabilityWeeklySelectedDate(date))
  }

  const isSelectedDateInWeekOfPrevSelectedDate = (date) => {
    return !(
      isBefore(date, startOfWeek(availabilitySelectedDate)) || isAfter(date, endOfWeek(availabilitySelectedDate))
    )
  }

  const dataOnSubmit = (data, isWeek2Enabled) => {
    findIncorrectCards(data)
    setData(data)
    setWeek2Enabled(isWeek2Enabled)
  }

  const submitClicked = () => {
    let weekWiseAvailabilities = formatDataBeforeSubmit()
    let finalData = {
      worker_id: userData.worker_id,
      location_id: locationData.location_id,
      availabilities: [
        {
          worker_id: userData.worker_id,
          location_id: locationData.location_id,
          pattern_id: availabilityPatternId,
          effective_date: toIsoStringWithoutTime(availabilityReqStartDate),
          expiration_date: toIsoStringWithoutTime(availabilityReqEndDate),
          week_wise_availabilities: weekWiseAvailabilities,
        },
      ],
    }
    setSubmitBtnDisabled(true)
    dispatch(postAvailabilityRequestsData(finalData))
  }

  const formatDataBeforeSubmit = () => {
    let allWeekData = { ...data }
    let week1Data = allWeekData['week_1']
    let week2Data = allWeekData['week_2']

    adjustEndTime(week1Data)
    adjustEndTime(week2Data)

    if (!week2Enabled) {
      delete allWeekData['week_2']
    }
    return allWeekData
  }

  const adjustEndTime = (weekArr) => {
    for (let dayObj of weekArr) {
      for (let timeInterval of dayObj.time_intervals) {
        if (timeInterval.end_time === '24:00') {
          timeInterval.end_time = '23:59'
        }
      }
    }
  }

  const findIncorrectCards = (weeklyAvailabilitiesObj) => {
    let week1Data = [...weeklyAvailabilitiesObj['week_1']]

    let noDataFoundInForm
    let countOfIncompleteCards = 0
    let countOfEndTimeGreater = 0
    let isModified
    // let isReallyWeek2Filled = false

    if (week1Data.every((elem) => elem.time_intervals.length === 0)) {
      noDataFoundInForm = true
      isModified = false
    } else {
      noDataFoundInForm = false
      isModified = true
    }

    for (let dayObj of week1Data) {
      for (let timeInterval of dayObj.time_intervals) {
        if (timeInterval.start_time === '' || timeInterval.end_time === '') {
          countOfIncompleteCards++
        } else if (checkIfEndTimeSmaller(timeInterval.start_time, timeInterval.end_time)) {
          countOfEndTimeGreater++
        }
      }
    }

    if (Object.keys(weeklyAvailabilitiesObj).length > 0 && Object.keys(weeklyAvailabilitiesObj).includes('week_2')) {
      let week2Data = [...weeklyAvailabilitiesObj['week_2']]
      // isReallyWeek2Filled = week2Data.some((item) => item.time_intervals.length > 0)
      for (let dayObj of week2Data) {
        if (dayObj.time_intervals.length > 0) {
          for (let timeInterval of dayObj.time_intervals) {
            if (timeInterval.start_time === '' || timeInterval.end_time === '') {
              countOfIncompleteCards++
            }
            if (checkIfEndTimeSmaller(timeInterval.start_time, timeInterval.end_time)) {
              countOfEndTimeGreater++
            }
          }
        }
      }
    }

    if (noDataFoundInForm) {
      setReadyToSubmit(false)
      setAlertMsg('FormNotStartedErrMsg')
    } else if (countOfIncompleteCards > 0 || countOfEndTimeGreater > 0) {
      setReadyToSubmit(false)
      setAlertMsg('FormIncompleteErrMsg')
    } /* else if (!isReallyWeek2Filled) {
      setReadyToSubmit(false)
      setAlertMsg('Week2DataNotFilledErrMsg')
    }  */ else if (!noDataFoundInForm && countOfIncompleteCards === 0 && countOfEndTimeGreater === 0) {
      setReadyToSubmit(true)
      setAlertMsg('')
    }

    if (isModified) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }
  }

  const renderComponent = () => {
    return (
      <React.Fragment>
        <div style={styles.mainContainerPosition}>
          <Paper elevation={1} square sx={styles.mainScrollContainer}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <RequestAvailabilityDetailByDate
                  dataOnSubmit={(data, isWeek2Enabled) => dataOnSubmit(data, isWeek2Enabled)}
                />
              </Grid>
              {/* <Grid item xs={12} md={12}>
                {renderCommentForm()}
              </Grid> */}
            </Grid>
          </Paper>
        </div>
      </React.Fragment>
    )
  }

  /* const renderCommentForm = () => {
    return <RequestCommentForm setComment={setComment} label={COMMENT} maxLength={COMMENT_MAX_LENGTH} value={comment} />
  } */

  const renderActionControls = () => {
    return (
      <React.Fragment>
        <Paper sx={styles.bottomSectionContainer} elevation={1} square>
          <AvailabilitySubmitCancel
            showCancelDialog={hasChanges}
            readyToSubmit={readyToSubmit}
            submitClicked={submitClicked}
            submitBtnDisabled={submitBtnDisabled}
            alertMsg={alertMsg}
          />
        </Paper>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <HeaderTitle title={PAGE_TITLE} />
      {/* {viewCalendar()} */}
      {renderComponent()}
      {renderActionControls()}
    </React.Fragment>
  )
}

export default RequestAvailabilityDetail
