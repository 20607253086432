import { addDays, addMonths, startOfWeek, startOfYear, subDays, subWeeks, subYears } from 'date-fns'
import { IFeature } from '../api/userAccess/responses/userAccess'
import { FeatureName } from '../auth/User'
import { getDateOfTodayWithNoTimestamp } from '../utils/DateUtil'
import {
  AVAILABILITY,
  CALL_IN_LATE,
  CALL_OFF_ABSENT,
  FAILED,
  FAILED_CANCELLED,
  PENDING,
  PUNCH_CORRECTION,
  TIME_OFF,
} from './RequestConstants'

type SortOrder = 'ASC' | 'DESC'

type SortableColumn = {
  columnName: string
  displayName: string
}

type StatusFilter = {
  statuses: {
    selectedValues: string[] // Replace with enum if you have a defined set of statuses
  }
}

export type TableSettings = {
  sortableColumns: SortableColumn[]
  sortOrder: SortOrder
  selectedSortByIndex: number
  minDate: Date
  maxDate: Date
  defaultStartDate: Date
  defaultEndDate: Date
  maxDaysInRange: number
  searchText: string
  page: number
  rowsPerPage: number
  totalCount: number
  filters?: StatusFilter
  configFeatureName?: FeatureName
}

const DEFAULT_FEATURE_SPECIFIC_CONFIG: Record<string, TableSettings> = {
  [TIME_OFF]: {
    sortableColumns: [
      { columnName: 'name', displayName: 'Name' },
      { columnName: 'created_timestamp', displayName: 'Created Timestamp' },
    ],
    sortOrder: 'ASC',
    selectedSortByIndex: 1,
    minDate: startOfYear(subYears(getDateOfTodayWithNoTimestamp(), 1)),
    maxDate: addMonths(getDateOfTodayWithNoTimestamp(), 6),
    defaultStartDate: subDays(getDateOfTodayWithNoTimestamp(), 30),
    defaultEndDate: addMonths(getDateOfTodayWithNoTimestamp(), 6),
    maxDaysInRange: 365,
    searchText: '',
    page: 1,
    rowsPerPage: 25,
    totalCount: 0,
    filters: { statuses: { selectedValues: [PENDING, FAILED, FAILED_CANCELLED] } },
  },
  [CALL_IN_LATE]: {
    sortableColumns: [
      { columnName: 'name', displayName: 'Name' },
      { columnName: 'created_timestamp', displayName: 'Created Timestamp' },
    ],
    sortOrder: 'DESC',
    selectedSortByIndex: 1,
    minDate: startOfYear(subYears(getDateOfTodayWithNoTimestamp(), 1)),
    maxDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    defaultStartDate: getDateOfTodayWithNoTimestamp(),
    defaultEndDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    maxDaysInRange: 365,
    searchText: '',
    page: 1,
    rowsPerPage: 25,
    totalCount: 0,
  },
  [CALL_OFF_ABSENT]: {
    sortableColumns: [
      { columnName: 'name', displayName: 'Name' },
      { columnName: 'created_timestamp', displayName: 'Created Timestamp' },
    ],
    sortOrder: 'DESC',
    selectedSortByIndex: 1,
    minDate: startOfYear(subYears(getDateOfTodayWithNoTimestamp(), 1)),
    maxDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    defaultStartDate: getDateOfTodayWithNoTimestamp(),
    defaultEndDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    maxDaysInRange: 365,
    searchText: '',
    page: 1,
    rowsPerPage: 25,
    totalCount: 0,
  },
  [AVAILABILITY]: {
    sortableColumns: [
      { columnName: 'name', displayName: 'Name' },
      { columnName: 'created_timestamp', displayName: 'Created Timestamp' },
    ],
    sortOrder: 'ASC',
    selectedSortByIndex: 1,
    minDate: startOfYear(subYears(getDateOfTodayWithNoTimestamp(), 1)),
    maxDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    defaultStartDate: startOfWeek(subWeeks(getDateOfTodayWithNoTimestamp(), 13)),
    defaultEndDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    maxDaysInRange: 365,
    searchText: '',
    page: 1,
    rowsPerPage: 25,
    totalCount: 0,
  },
  [PUNCH_CORRECTION]: {
    sortableColumns: [
      { columnName: 'name', displayName: 'Name' },
      { columnName: 'created_timestamp', displayName: 'Created Timestamp' },
    ],
    sortOrder: 'ASC',
    selectedSortByIndex: 1,
    minDate: startOfYear(subYears(getDateOfTodayWithNoTimestamp(), 1)),
    maxDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    defaultStartDate: startOfWeek(subWeeks(getDateOfTodayWithNoTimestamp(), 13)),
    defaultEndDate: addDays(getDateOfTodayWithNoTimestamp(), 1),
    maxDaysInRange: 365,
    searchText: '',
    page: 1,
    rowsPerPage: 25,
    totalCount: 0,
  },
}

export const getLeaderConfiguration = (leaderFeatures: IFeature[]) => {
  const configMap: Record<string, TableSettings> = {}

  for (const feature of leaderFeatures) {
    if (feature?.display_name && DEFAULT_FEATURE_SPECIFIC_CONFIG[feature.display_name]) {
      configMap[feature.display_name] = DEFAULT_FEATURE_SPECIFIC_CONFIG[feature.display_name]
      configMap[feature.display_name].configFeatureName = feature.name as FeatureName
    }
  }
  const selectableCategories = Object.keys(configMap)
  return { configMap, selectableCategories }
}
