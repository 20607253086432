import { Grid, Paper, styled } from '@mui/material'

import { Outlet } from 'react-router-dom'
import { useLeaderFilterConfiguration } from '../../../api/configuration/useConfiguration'
import HeaderTitle from '../../Header/HeaderTitle'
import BottomNavigationBar from '../../HomePage/BottomNavigationBar'
import LeaderNavigationBar from '../../Leader/LeaderNavigationBar'
import Admindropdown from '../../admin/AdminSelect'
import { QueryBoundaries } from '../wrappers/QueryBoundaries'

export const MainScrollPaper = styled(Paper)(({ theme }) => ({
  ...theme.mainScrollContainer,
  height: '100%',
}))

export const MainContentDiv = styled(Grid)(({ theme }) => ({
  ...theme.mainScrollContainer,
}))

export const MainContainerDiv = styled(Grid)(({ theme }) => ({
  ...theme.mainContainerPosition,
}))

export const TeamMemberContainer = styled(Grid)(({ theme }) => ({
  ...theme.mainContainerPosition,
  ...theme.mainScrollContainer,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const LeaderContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  maxWidth: '100%',
  ...theme.mainContainerPosition,
}))

export const AdminContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  height: 'calc(100% - 64px)',
  display: 'flex',
  flexFlow: 'column',
  maxWidth: '100%',
}))

// could add some type of metrics wrapper here too or at a higher level
export function TeamMemberLayout() {
  return (
    <>
      <TeamMemberContainer>
        <Outlet />
      </TeamMemberContainer>
      <BottomNavigationBar />
    </>
  )
}

export function LeaderLayout() {
  // will fetch and set filter data.
  useLeaderFilterConfiguration()

  return (
    <>
      <LeaderContainer>
        <Outlet />
      </LeaderContainer>
      <LeaderNavigationBar />
    </>
  )
}

export function AdminLayout() {
  return (
    <AdminContainer>
      <HeaderTitle title={'Admin'} />
      <Admindropdown />
      <QueryBoundaries>
        <Outlet />
      </QueryBoundaries>
    </AdminContainer>
  )
}

export default function MainLayout({ children }) {
  return (
    <MainContainerDiv>
      <MainScrollPaper square>{children}</MainScrollPaper>
    </MainContainerDiv>
  )
}
