import { Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { formatHours } from '../../utils/formatHours'
import { hrsToHours } from '../../utils/strings'

const OtherDaily = (props) => {
  const { payCodes, styles, isPunchedIn } = props

  return (
    <React.Fragment>
      <Divider role="presentation" />
      <div
        style={{ ...styles.paddedContainer, ...styles.headerStyles }}
        tabIndex={0}
        aria-label="other details - table"
      >
        <Grid item xs={12} md={12} container>
          <Grid container>
            <Grid item xs={10} md={11}>
              <Typography variant="body2" sx={styles.sectionHeader1}>
                Other
              </Typography>
            </Grid>
            <Grid item xs={2} md={1}>
              <Typography variant="body2" sx={styles.sectionHeader2}>
                Hrs
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Divider role="presentation" />
      <div style={styles.paddedContainer}>
        {payCodes &&
          payCodes.map((payCode, index) => (
            <PayCode
              payCodeName={payCode.pay_code_name}
              payCodeHour={payCode.pay_code_hour}
              styles={styles}
              key={index}
            />
          ))}
      </div>
      <Divider role="presentation" />
      {isPunchedIn ? <div style={styles.emptyDiv} /> : null}
    </React.Fragment>
  )
}

const PayCode = (props) => {
  const { payCodeName, payCodeHour, styles } = props

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        md={12}
        tabIndex="0"
        role="heading"
        aria-label={hrsToHours(payCodeName) + ': ' + payCodeHour + ' hours'}
      >
        <Grid container>
          <Grid item xs={10} md={11} sx={styles.gridContainer}>
            <Typography variant="body2" sx={styles.segmentStyles1}>
              {payCodeName}
            </Typography>
          </Grid>
          <Grid item xs={2} md={1} sx={styles.gridContainer}>
            <Typography variant="body2" sx={styles.segmentStyles2}>
              {`${formatHours?.format(payCodeHour)}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default OtherDaily
