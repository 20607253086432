import { object, shape } from 'prop-types'
import React from 'react'

import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { withAuth } from '@praxis/component-auth'
import { addWeeks, endOfWeek, format, isBefore, isValid, startOfWeek, subDays, subWeeks } from 'date-fns'
import MUIDataTable from 'mui-datatables'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getLocationDetails,
  loadGetLocationsByWorkerIdRequest,
  setAreUnsavedChanges,
  setDesiredLocationID,
  setDesiredScreenName,
  setDesiredSelectedDate,
  setDialogChangedPage,
  setDisplayLoadingIcon,
  setExitLeaderDialogOpen,
  setLocationLoading,
  setNavigateToNextLeaderLocation,
  setNextLeaderLocation,
  setPopUpTextToChangeDate,
  setPopUpTextToChangeLocation,
  setPopUpTextToChangeRadioView,
  setPopUpTextToExit,
  setRefreshTable,
  setScreenName,
  setSelectedDate,
  setSelectedEndDate,
  setSelectedLocationId,
  setUpDownState,
} from '../../store/leaderViewAutomation/actionCreator'
import {
  addDirectorySessionData,
  addSelectedDirectoryIndex,
  loadGetLeaderDirectoryFilters,
  loadGetWorkersDirectoryRequest,
  removeSelectedDirectoryIndex,
  setDirectoryColumnView,
  setDirectoryDataLoading,
  setDirectoryDataPage,
  setDirectoryDataPerPage,
  setDirectoryDataSearch,
  setDirectoryDataSelectedFilters,
  setDirectoryDataSort,
  setDirectoryDataTableColumnsToDefault,
  setDirectoryDataTableFiltersToDefault,
  setDirectoryDataTableStateToDefault,
  setDirectoryPostError,
  setDirectoryPostResponse,
  setLeaderScreenUneditable,
} from '../../store/leaderViewDirectoryAutomation/actionCreator'
import {
  handleUpdateMandatoryLeaderDecisionsError,
  handleUpdateMandatoryLeaderDecisionsSuccess,
  loadGetLeaderMandatoryAvailabilityFiltersRequest,
  loadGetWorkersMandatoryAvailabilityPreferencesRequest,
  setMandatoryColumnView,
  setMandatoryDataLoading,
  setMandatoryDataPage,
  setMandatoryDataPerPage,
  setMandatoryDataSearch,
  setMandatoryDataSelectedFilters,
  setMandatoryDataSort,
  setMandatoryDataTableColumnsToDefault,
  setMandatoryDataTableFiltersToDefault,
  setMandatoryDataTableStateToDefault,
  setMandatoryPostResponse,
  setMandatoryPostResponseIneligibilities,
  setSaveMandatoryDialogOpen,
  setShouldLoadMandatoryData,
} from '../../store/leaderViewMandatoryAutomation/actionCreator'
import {
  loadGetLeaderAvailabilityFiltersRequest,
  loadGetWorkersAvailabilityPreferencesRequest,
  setSaveVoluntaryDialogOpen,
  setShouldLoadVoluntaryData,
  setVoluntaryColumnView,
  setVoluntaryDataLoading,
  setVoluntaryDataPage,
  setVoluntaryDataPerPage,
  setVoluntaryDataSearch,
  setVoluntaryDataSelectedFilters,
  setVoluntaryDataSort,
  setVoluntaryDataTableColumnsToDefault,
  setVoluntaryDataTableFiltersToDefault,
  setVoluntaryDataTableStateToDefault,
  setVoluntaryPostError,
  setVoluntaryPostResponse,
  setVoluntaryPostResponseIneligibilities,
} from '../../store/leaderViewVoluntaryAutomation/actionCreator'
import HeaderTitle from '../Header/HeaderTitle'
import CustomFooter from './CustomFooterDirectoryAutomation'
import CustomToolBar from './CustomToolBarAutomation'
import MandatoryCustomToolbarSelect from './MandatoryCustomToolbarAutomation'
import TimeOffRequestLeaderDialog from './TimeOffRequestLeaderDialog'
import TmDirectoryCustomToolbarSelect from './TmDirectoryCustomToolbarAutomation'
import VoluntaryCustomToolbarSelect from './VoluntaryCustomToolbarAutomation'

import apiConfig from '../../config/apiConfig'
import {
  loadGetLeaderPTORequestFilters,
  loadGetWorkersPTORequestsRequest,
  openTimeOffRequestDialog,
  setPTORequestsColumnView,
  setPTORequestsDataLoading,
  setPTORequestsDataPage,
  setPTORequestsDataPerPage,
  setPTORequestsDataSearch,
  setPTORequestsDataSelectedFilters,
  setPTORequestsDataSort,
  setPTORequestsDataTableColumnsToDefault,
  setPTORequestsDataTableFiltersToDefault,
  setPTORequestsDataTableStateToDefault,
  setTimeOffRequestDataError,
  setTimeOffRequestDataResponse,
} from '../../store/leaderViewPTORequestsAutomation/actionCreator'
import { showNotificationError, showNotificationSuccess } from '../../store/notification/actionCreator'
import { formatErrorCode } from '../../utils/ErrorHandling'

import { Navigate } from 'react-router-dom'
import UserContext from '../../auth/UserContext'
import praxisTheme from '../../config/themeConfig'
import { PERSONAS } from '../../constants/userConstants'
import { getDateOfTodayWithNoTimestamp, getFiscalYearStartEnd, getNowDateInTimezone } from '../../utils/DateUtil'
import {
  SCREEN_NAME_MANDATORY,
  SCREEN_NAME_PTO_REQUEST,
  SCREEN_NAME_TM_DIRECTORY,
  SCREEN_NAME_VOLUNTARY,
} from '../../utils/ScreenName'
import InnerLoaderWithText from '../Loader/InnerLoaderWithText'
import DatePicker from '../common/calendar/DatePicker'
import DateRangePicker from '../common/calendar/DateRangePicker'
import LeaderViewPromptContainer from './LeaderViewPromptContainer'
import { RedirectNavigation } from './RedirectNavigation'

const styles = {
  infoMessage: praxisTheme.infoMessages,
  infoMessageDetail: praxisTheme.infoMessageDetail,
  errorCodeMessage: praxisTheme.errorCodeMessages,
  errorMessage: praxisTheme.errorMessages,
  message: {
    paddingTop: '2em',
    paddingLeft: '2em',
    color: praxisTheme.palette.tertiary.contrastText,
    fontSize: 'medium',
    fontWeight: 'bold',
  },
  loadingText: {
    color: praxisTheme.palette.tertiary.contrastText,
    paddingTop: '12px',
    fontSize: '14px',
  },
  loadingIconContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  textFieldDropDown: {
    width: 150,
    marginRight: '2em',
  },
  dateField: {
    width: 150,
    paddingTop: '1em',
    marginRight: '2em',
  },
  timeOffRequestDateField: {
    width: '360px',
    paddingTop: '6px',
    marginRight: '2em',
  },
  button: {
    paddingTop: '2em',
  },
  formControl: {
    width: 250,
    height: 30,
    top: '200px',
  },
  mainContainer: {
    width: '100%',
    paddingTop: '1em',
    paddingLeft: '2em',
  },
  pageContainer: {
    width: '100%',
    paddingTop: '1em',
    flex: '1 1 auto',
  },
  errorMessageContainerPosition: {
    paddingTop: '2em',
    paddingBottom: '2em',
    paddingLeft: '.5em',
    paddingRight: '.5em',
    width: '100%',
  },
}

const AVAILABILITY_SAVED_MESSAGE = 'Scheduling decision actions saved successfully'
const MANDATORY_SAVED_MESSAGE = 'Scheduling decision actions saved successfully'
const DIRECTORY_SAVED_MESSAGE = 'Team member directory edits saved successfully'
const TIME_OFF_REQUEST_SAVED_MESSAGE = 'Time off request edits saved successfully'
const VOLUNTARY_PARTIALLY_SAVED_MESSAGE = 'Scheduling decision actions did not save for the following team members: '
const MANDATORY_PARTIALLY_SAVED_MESSAGE = 'Scheduling decision actions did not save for the following team members: '
const DIRECTORY_PARTIALLY_SAVED_MESSAGE = 'Team member directory edits did not save for the following team members: '

const LEADER_ROWS_PER_PAGE_OPTIONS = [25, 50, 100]
const CONTACT_CSC_MESSAGE = ' Please try again later and if the issue persists, contact the CSC.'
const LOAD_ERROR_MESSAGE_LOCATION = 'Unable to load your locations at this time.' + CONTACT_CSC_MESSAGE
const LOAD_ERROR_MESSAGE_VOLUNTARY = 'Unable to load voluntary availability data at this time.' + CONTACT_CSC_MESSAGE
const LOAD_ERROR_MESSAGE_MANDATORY = 'Unable to load mandatory availability data at this time.' + CONTACT_CSC_MESSAGE
const LOAD_ERROR_MESSAGE_DIRECTORY = 'Unable to load team member directory data at this time.' + CONTACT_CSC_MESSAGE
const LOAD_ERROR_MESSAGE_PTO_REQUESTS = 'Unable to load time off requests data at this time.' + CONTACT_CSC_MESSAGE
const SAVE_ERROR_MESSAGE_VOLUNTARY = 'Unable to save voluntary availability data at this time.' + CONTACT_CSC_MESSAGE
const SAVE_ERROR_MESSAGE_MANDATORY = 'Unable to save mandatory availability data at this time.' + CONTACT_CSC_MESSAGE
const SAVE_ERROR_MESSAGE_TIME_OFF_REQUEST = 'Unable to save time off request data at this time.' + CONTACT_CSC_MESSAGE
const SAVE_ERROR_MESSAGE_DIRECTORY = 'Unable to save team member directory data at this time.' + CONTACT_CSC_MESSAGE
const ERROR_CODES_SHOW_MESSAGE_LOCATION = ['wfm-4-11', 'wfm-4-5', 'wfm-5-5']
const ERROR_CODES_SHOW_MESSAGE_VOLUNTARY = ['wfm-3-11', 'wfm-3-5', 'wfm-6-5', 'wfm-8-11', 'wfm-8-5']
const ERROR_CODES_SHOW_MESSAGE_MANDATORY = ['wfm-9-11', 'wfm-9-5', 'wfm-6-5', 'wfm-8-11', 'wfm-8-5']
const ERROR_CODES_SHOW_MESSAGE_DIRECTORY = ['wfm-6-5', 'wfm-8-11', 'wfm-8-5']
const ERROR_CODES_SHOW_MESSAGE_TIME_OFF = ['wfm-15-5', 'wfm-15-11', 'wfm-15-1', 'wfm-8-11', 'wfm-8-5']
const ERROR_CODES_SHOW_MESSAGE_SAVE_VOLUNTARY = ['wfm-3-5', 'wfm-3-11']
const ERROR_CODES_SHOW_MESSAGE_SAVE_MANDATORY = ['wfm-9-5', 'wfm-9-11']
const ERROR_CODES_SHOW_MESSAGE_SAVE_DIRECTORY = ['wfm-6-5', 'wfm-6-11']
const AVAILABILITY_ID = 'wfm-3'
const USER_ACCESS_ID = 'wfm-5'
const WFM_WORKER_ID = 'wfm-6'
// const ORG_BLOCKS_ID = 'wfm-7'
const WFM_CONFIGS_ID = 'wfm-8'
const MANDATORY_ID = 'wfm-9'
const TIME_OFF_REQUEST_ID = 'wfm-15'
const ERROR_CODE_CANNOT_CONNECT_TO_SERVER = '-0'
const ERROR_CODE_CANNOT_CONNECT_TO_LOCATION = ' (error code: wfm-10-0)'
const TIME_OFF_MIN_DATE = new Date(2021, 0, 1)
const TIME_OFF_MAX_DAYS = 400

/**
 * The enumeration of the all of the column keys/names for the voluntary screen.
 * @type {{full_name: string, worker_id: string, scheduling_key: string, department: string, job: string,
 * proficient: string, voluntary_preference: string, last_updated_timestamp: string, status: string,
 * status_timestamp: string, schedule: string, attendance_codes: string, total_hours_worked: string}}
 */
export const VOLUNTARY_COLUMNS_ENUM = {
  full_name: 'full_name',
  worker_id: 'worker_id',
  scheduling_key: 'scheduling_key',
  department: 'department',
  job: 'job_classification',
  proficient: 'proficient',
  voluntary_preference: 'voluntary_availability_preference',
  last_updated_timestamp: 'last_updated_timestamp',
  status: 'status',
  status_timestamp: 'status_timestamp',
  schedule: 'schedule',
  attendance_codes: 'attendance_codes',
  total_hours_worked: 'total_hours_worked',
  ineligibilities: 'ineligibilities',
}

/**
 * Defines the column order for the voluntary screen. If add/removing or changing order of a column(s) here, also update
 * voluntaryColumns in the reducer.
 * @type {string[]}
 */
export const VOLUNTARY_COLUMNS_ORDER = [
  VOLUNTARY_COLUMNS_ENUM.full_name,
  VOLUNTARY_COLUMNS_ENUM.worker_id,
  VOLUNTARY_COLUMNS_ENUM.scheduling_key,
  VOLUNTARY_COLUMNS_ENUM.department,
  VOLUNTARY_COLUMNS_ENUM.job,
  VOLUNTARY_COLUMNS_ENUM.proficient,
  VOLUNTARY_COLUMNS_ENUM.voluntary_preference,
  VOLUNTARY_COLUMNS_ENUM.last_updated_timestamp,
  VOLUNTARY_COLUMNS_ENUM.status,
  VOLUNTARY_COLUMNS_ENUM.status_timestamp,
  VOLUNTARY_COLUMNS_ENUM.schedule,
  VOLUNTARY_COLUMNS_ENUM.attendance_codes,
  VOLUNTARY_COLUMNS_ENUM.total_hours_worked,
  VOLUNTARY_COLUMNS_ENUM.ineligibilities,
]

/**
 * The enumeration of the all of the column keys/names for the mandatory screen.
 * @type {{full_name: string, worker_id: string, scheduling_key: string, department: string, job_classification: string,
 * proficiency: string, schedule_up_group: string, schedule_down_group: string, last_up_date: string,
 * last_down_date: string, mandatory_shift: string, status: string, status_timestamp: string, schedule: string,
 * attendance_codes: string, total_hours_worked: string}}
 */
export const MANDATORY_COLUMNS_ENUM = {
  full_name: 'full_name',
  worker_id: 'worker_id',
  scheduling_key: 'scheduling_key',
  department: 'department',
  job_classification: 'job_classification',
  proficiency: 'proficiency',
  schedule_up_group: 'schedule_up_group',
  schedule_down_group: 'schedule_down_group',
  last_up_date: 'last_up_date',
  last_down_date: 'last_down_date',
  mandatory_shift: 'mandatory_shift',
  status: 'status',
  status_timestamp: 'status_timestamp',
  schedule: 'schedule',
  attendance_codes: 'attendance_codes',
  total_hours_worked: 'total_hours_worked',
  ineligibilities: 'ineligibilities',
}

/**
 * Defines the column order for the mandatory screen. If add/removing or changing order of a column(s) here, also update
 * mandatoryColumns in the reducer.
 * @type {string[]}
 */
export const MANDATORY_COLUMNS_ORDER = [
  MANDATORY_COLUMNS_ENUM.full_name,
  MANDATORY_COLUMNS_ENUM.worker_id,
  MANDATORY_COLUMNS_ENUM.scheduling_key,
  MANDATORY_COLUMNS_ENUM.department,
  MANDATORY_COLUMNS_ENUM.job_classification,
  MANDATORY_COLUMNS_ENUM.proficiency,
  MANDATORY_COLUMNS_ENUM.schedule_up_group,
  MANDATORY_COLUMNS_ENUM.schedule_down_group,
  MANDATORY_COLUMNS_ENUM.last_up_date,
  MANDATORY_COLUMNS_ENUM.last_down_date,
  MANDATORY_COLUMNS_ENUM.mandatory_shift,
  MANDATORY_COLUMNS_ENUM.status,
  MANDATORY_COLUMNS_ENUM.status_timestamp,
  MANDATORY_COLUMNS_ENUM.schedule,
  MANDATORY_COLUMNS_ENUM.attendance_codes,
  MANDATORY_COLUMNS_ENUM.total_hours_worked,
  MANDATORY_COLUMNS_ENUM.ineligibilities,
]

/**
 * The enumeration of the all of the column keys/names for the directory screen.
 * @type {{name: string, worker_id: string, scheduling_key: string, department: string, job_classification: string,
 * proficient: string, schedule_up_group: string, schedule_down_group: string}}
 */
export const DIRECTORY_COLUMNS_ENUM = {
  name: 'name',
  worker_id: 'worker_id',
  scheduling_key: 'scheduling_key',
  department: 'department',
  job_classification: 'job_classification',
  proficient: 'proficient',
  schedule_up_group: 'schedule_up_group',
  schedule_down_group: 'schedule_down_group',
}
/**
 * The enumeration of the all of the column keys/names for the paid time off requests screen.
 * @type {{name: string, worker_id: string, scheduling_key: string, department: string, job_classification: string,
 * type: array, created_timestamp: string, requested_timestamp: array, total_hours: int, comments: string, status: string}}
 */
export const PTO_REQUESTS_COLUMNS_ENUM = {
  name: 'name',
  worker_id: 'worker_id',
  scheduling_key: 'scheduling_key',
  department: 'department',
  job_classification: 'job_classification',
  type: 'type',
  created_timestamp: 'created_timestamp',
  start_time: 'start_time',
  end_time: 'end_time',
  hours: 'hours',
  priority: 'priority',
  request_type: 'request_type',
  comments: 'comments',
  status: 'status',
  updated_timestamp: 'updated_timestamp',
  updated_by: 'updated_by',
}

/**
 * Defines the column order for the directory screen. If add/removing or changing order of a column(s) here, also update
 * directoryColumns in the reducer.
 * @type {string[]}
 */
export const DIRECTORY_COLUMNS_ORDER = [
  DIRECTORY_COLUMNS_ENUM.name,
  DIRECTORY_COLUMNS_ENUM.worker_id,
  DIRECTORY_COLUMNS_ENUM.scheduling_key,
  DIRECTORY_COLUMNS_ENUM.department,
  DIRECTORY_COLUMNS_ENUM.job_classification,
  DIRECTORY_COLUMNS_ENUM.proficient,
  DIRECTORY_COLUMNS_ENUM.schedule_up_group,
  DIRECTORY_COLUMNS_ENUM.schedule_down_group,
]
/**
 * Defines the column order for the directory screen. If add/removing or changing order of a column(s) here, also update
 * directoryColumns in the reducer.
 * @type {string[]}
 */
export const PTO_REQUESTS_COLUMNS_ORDER = [
  PTO_REQUESTS_COLUMNS_ENUM.name,
  PTO_REQUESTS_COLUMNS_ENUM.worker_id,
  PTO_REQUESTS_COLUMNS_ENUM.scheduling_key,
  PTO_REQUESTS_COLUMNS_ENUM.department,
  PTO_REQUESTS_COLUMNS_ENUM.job_classification,
  PTO_REQUESTS_COLUMNS_ENUM.type,
  PTO_REQUESTS_COLUMNS_ENUM.start_time,
  PTO_REQUESTS_COLUMNS_ENUM.end_time,
  PTO_REQUESTS_COLUMNS_ENUM.hours,
  PTO_REQUESTS_COLUMNS_ENUM.priority,
  PTO_REQUESTS_COLUMNS_ENUM.request_type,
  PTO_REQUESTS_COLUMNS_ENUM.comments,
  PTO_REQUESTS_COLUMNS_ENUM.status,
  PTO_REQUESTS_COLUMNS_ENUM.updated_by,
  PTO_REQUESTS_COLUMNS_ENUM.updated_timestamp,
  PTO_REQUESTS_COLUMNS_ENUM.created_timestamp,
]

const voluntaryDataQueryParamKeys = [
  'full_name',
  'worker_id',
  'scheduling_keys',
  'departments',
  'job_classifications',
  'proficiencies',
  'voluntary_preferences',
  'last_updated_timestamp',
  'statuses',
  'status_timestamp',
]
const directoryDataQueryParamKeys = [
  'name',
  'worker_id',
  'scheduling_keys',
  'departments',
  'job_classifications',
  'proficiencies',
  'schedule_up_groups',
  'schedule_down_groups',
]
const mandatoryDataQueryParamKeys = [
  'full_name',
  'worker_id',
  'scheduling_keys',
  'departments',
  'job_classifications',
  'proficiencies',
  'schedule_up_groups',
  'schedule_down_groups',
  'last_up_date',
  'last_down_date',
  'mandatory_shifts',
  'statuses',
]

const timeOffRequestQueryParamKeys = new Map([
  [PTO_REQUESTS_COLUMNS_ENUM.name, 'name'],
  [PTO_REQUESTS_COLUMNS_ENUM.worker_id, 'worker_id'],
  [PTO_REQUESTS_COLUMNS_ENUM.scheduling_key, 'scheduling_keys'],
  [PTO_REQUESTS_COLUMNS_ENUM.department, 'departments'],
  [PTO_REQUESTS_COLUMNS_ENUM.job_classification, 'job_classifications'],
  [PTO_REQUESTS_COLUMNS_ENUM.status, 'statuses'],
  [PTO_REQUESTS_COLUMNS_ENUM.type, 'types'],
  [PTO_REQUESTS_COLUMNS_ENUM.updated_by, 'updated_by'],
  [PTO_REQUESTS_COLUMNS_ENUM.priority, 'priorities'],
  [PTO_REQUESTS_COLUMNS_ENUM.request_type, 'request_types'],
])

const refreshTableEnum = {
  PREWORK: 'prework',
  REFRESH: 'refresh',
  POSTWORK: 'postwork',
}

export const DEFAULT_PAGE_NUM = 1
export const DEFAULT_PER_PAGE = 25
export const DEFAULT_VOLUNTARY_DATA_UP_DOWN = 'up'
export const DEFAULT_VOLUNTARY_DATA_SORT = 'ASC'
export const DEFAULT_MANDATORY_DATA_SORT = 'ASC'
export const DEFAULT_MANDATORY_COLUMN_SORT_BY = MANDATORY_COLUMNS_ENUM.full_name
export const DEFAULT_VOLUNTARY_DATA_TOTAL_RECORDS = 0
export const DEFAULT_MANDATORY_DATA_TOTAL_RECORDS = 0
export const DEFAULT_VOLUNTARY_DATA = []
export const DEFAULT_MANDATORY_DATA = []
export const DEFAULT_DIRECTORY_DATA = []
export const DEFAULT_PTO_REQUESTS_DATA = [{}]
export const DEFAULT_DIRECTORY_DATA_TOTAL_RECORDS = 0
export const DEFAULT_PTO_REQUESTS_DATA_TOTAL_RECORDS = 0
export const DEFAULT_DIRECTORY_DATA_SORT = 'ASC'
export const DEFAULT_PTO_REQUESTS_DATA_SORT = 'ASC'
export const DEFAULT_PTO_REQUESTS_SORT_BY = PTO_REQUESTS_COLUMNS_ENUM.created_timestamp

class LeaderViewAutomation extends React.Component {
  static propTypes = {
    classes: object,
    layoutActions: shape({
      setScreenName: '',
    }),
  }

  static contextType = UserContext

  constructor(props) {
    super(props)
    this.state = {
      redirectOn: false,
      screenName: '/leaderView',
      isDisabled: true,
    }
  }

  componentDidMount() {
    this.props.loadGetLocationsByWorkerIdRequest(this.context.user.userData.worker_id)

    if (
      this.context.user.is('punch_correction', 'leader', 'read', true) ||
      this.context.user.is('availability', 'leader', 'read', true)
    ) {
      this.props.setLeaderScreenUneditable(true)
    } else {
      this.props.setLeaderScreenUneditable(false)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      auth,
      voluntaryData,
      voluntaryPostResponse,
      voluntaryPostError,
      directoryData,
      directoryPostResponse,
      directoryPostError,
      locations,
      mandatoryData,
      mandatoryPostResponse,
      mandatoryPostError,
      showNotificationError,
      voluntaryDataLoading,
      voluntaryDataSaving,
      mandatoryDataLoading,
      mandatoryDataSaving,
      directoryDataLoading,
      directoryDataSaving,
      ptoRequestsDataLoading,
      ptoRequestsDataSaving,
      timeOffRequestPostResponse,
      timeOffRequestPostError,
      selectedDate,
      selectedLocationDetails,
    } = this.props

    if (!auth.isAuthenticated()) {
      auth.login({ redirect: window.location.href })
    }

    if (this.context.user && !locations) {
      this.props.loadGetLocationsByWorkerIdRequest(this.context.user.userData.worker_id)
    }

    this.checkAndGetLeaderViewData(prevProps)

    if (voluntaryPostResponse) {
      this.showNotificationBasedOnVoluntaryResponse(voluntaryPostResponse, voluntaryData)
    }

    if (voluntaryPostError) {
      this.props.setVoluntaryPostError(null)
      if (
        voluntaryPostError.message &&
        voluntaryPostError.code &&
        ERROR_CODES_SHOW_MESSAGE_SAVE_VOLUNTARY.includes(voluntaryPostError.code)
      ) {
        showNotificationError(true, voluntaryPostError.message)
      } else {
        showNotificationError(
          true,
          SAVE_ERROR_MESSAGE_VOLUNTARY +
            ' ' +
            formatErrorCode(voluntaryPostError, AVAILABILITY_ID + ERROR_CODE_CANNOT_CONNECT_TO_SERVER),
        )
      }
    }

    if (directoryPostResponse) {
      this.showNotificationBasedOnDirectoryResponse(directoryPostResponse, directoryData)
      this.props.setDirectoryPostResponse(null)
      this.loadGetWorkersDirectoryPreferencesRequest(null)
    }

    if (directoryPostError) {
      this.props.setDirectoryPostError(null)
      if (
        directoryPostError.message &&
        directoryPostError.code &&
        ERROR_CODES_SHOW_MESSAGE_SAVE_DIRECTORY.includes(directoryPostError.code)
      ) {
        showNotificationError(true, directoryPostError.message)
      } else {
        showNotificationError(
          true,
          SAVE_ERROR_MESSAGE_DIRECTORY +
            ' ' +
            formatErrorCode(directoryPostError, WFM_WORKER_ID + ERROR_CODE_CANNOT_CONNECT_TO_SERVER),
        )
      }
    }

    if (mandatoryPostResponse) {
      this.showNotificationBasedOnMandatoryResponse(mandatoryPostResponse, mandatoryData)
    }

    if (mandatoryPostError) {
      this.props.handleUpdateMandatoryLeaderDecisionsError(null)
      if (
        mandatoryPostError.message &&
        mandatoryPostError.code &&
        ERROR_CODES_SHOW_MESSAGE_SAVE_MANDATORY.includes(mandatoryPostError.code)
      ) {
        showNotificationError(true, mandatoryPostError.message)
      } else {
        showNotificationError(
          true,
          SAVE_ERROR_MESSAGE_MANDATORY +
            ' ' +
            formatErrorCode(mandatoryPostError, MANDATORY_ID + ERROR_CODE_CANNOT_CONNECT_TO_SERVER),
        )
      }
    }
    if (timeOffRequestPostResponse) {
      this.props.showNotificationSuccess(true, TIME_OFF_REQUEST_SAVED_MESSAGE)
      this.props.setTimeOffRequestDataResponse(null)
      this.loadGetWorkersPTORequestsPreferencesRequest(null)
    }

    if (timeOffRequestPostError) {
      if (
        timeOffRequestPostError.message &&
        timeOffRequestPostError.code &&
        ERROR_CODES_SHOW_MESSAGE_TIME_OFF.includes(timeOffRequestPostError.code)
      ) {
        showNotificationError(true, timeOffRequestPostError.message)
      } else {
        showNotificationError(
          true,
          SAVE_ERROR_MESSAGE_TIME_OFF_REQUEST +
            ' ' +
            formatErrorCode(timeOffRequestPostError, TIME_OFF_REQUEST_ID + ERROR_CODE_CANNOT_CONNECT_TO_SERVER),
        )
      }
      this.props.setTimeOffRequestDataError(null)
    }

    // Wait to set the default location until all locations available to user have been loaded.
    // If the user's main location is not in this list of locations, set default location
    // to the first location in the list. Then, the default date will use the tz from this
    // location to be set.
    if (prevProps.locations !== locations) {
      if (!locations.includes(this.context.user.locationData.location_id)) {
        this.setSelectedLocationId(locations[0])
      } else {
        this.setSelectedLocationId(this.context.user.locationData.location_id)
      }
      this.setState({ isDisabled: false }, () => {})
    }

    // This is only for setting the default date in the location's timezone of today when doing initial load of
    // Leader View screen. Not for when changing locations.
    if (
      selectedLocationDetails.iso_time_zone_code &&
      prevProps.selectedLocationDetails.iso_time_zone_code !== selectedLocationDetails.iso_time_zone_code &&
      !selectedDate
    ) {
      this.setDateToToday()
      this.setState({ isDisabled: false }, () => {})
    }

    this.handleDisplayLoadingIcon(prevProps.voluntaryDataLoading, voluntaryDataLoading)
    this.handleDisplayLoadingIcon(prevProps.voluntaryDataSaving, voluntaryDataSaving)
    this.handleDisplayLoadingIcon(prevProps.mandatoryDataLoading, mandatoryDataLoading)
    this.handleDisplayLoadingIcon(prevProps.mandatoryDataSaving, mandatoryDataSaving)
    this.handleDisplayLoadingIcon(prevProps.directoryDataLoading, directoryDataLoading)
    this.handleDisplayLoadingIcon(prevProps.directoryDataSaving, directoryDataSaving)
    this.handleDisplayLoadingIcon(prevProps.ptoRequestsDataLoading, ptoRequestsDataLoading)
    this.handleDisplayLoadingIcon(prevProps.ptoRequestsDataSaving, ptoRequestsDataSaving)

    this.handleRefreshTable(prevProps.voluntaryDataLoading, voluntaryDataLoading)
    this.handleRefreshTable(prevProps.mandatoryDataLoading, mandatoryDataLoading)
    this.handleRefreshTable(prevProps.directoryDataLoading, directoryDataLoading)
    this.handleRefreshTable(prevProps.ptoRequestsDataLoading, ptoRequestsDataLoading)
  }

  componentWillUnmount() {
    this.props.setScreenName('')
    this.setState({ isDisabled: false }, () => {})

    this.props.setNavigateToNextLeaderLocation(false)
    this.props.setNextLeaderLocation(null)
  }

  setDateToToday = () => {
    const { selectedLocationDetails } = this.props
    this.setSelectedDate(getNowDateInTimezone(selectedLocationDetails.iso_time_zone_code))
  }

  setSelectedDate = (date) => {
    this.props.setSelectedDate(date)
    this.props.setDisplayLoadingIcon(true)

    if (this.props.screenName === SCREEN_NAME_VOLUNTARY) {
      this.setVoluntaryDataTableStateToDefault()
      this.props.setVoluntaryDataPage(DEFAULT_PAGE_NUM)
    } else if (this.props.screenName === SCREEN_NAME_TM_DIRECTORY) {
      this.setDirectoryDataTableStateToDefault()
      this.props.setDirectoryDataPage(DEFAULT_PAGE_NUM)
    } else if (this.props.screenName === SCREEN_NAME_MANDATORY) {
      this.setMandatoryDataTableStateToDefault()
      this.props.setMandatoryDataPage(DEFAULT_PAGE_NUM)
    } else if (this.props.screenName === SCREEN_NAME_PTO_REQUEST) {
      this.setPTORequestsDataTableStateToDefault()
      this.props.setPTORequestsDataPage(DEFAULT_PAGE_NUM)
    }
  }

  setSelectedEndDate = (date) => {
    this.props.setSelectedEndDate(date)
    this.props.setDisplayLoadingIcon(true)
    this.setPTORequestsDataTableStateToDefault()
    this.props.setPTORequestsDataPage(DEFAULT_PAGE_NUM)
  }

  setSelectedLocationId = (selectedLocationId) => {
    const {
      upDownState,
      voluntaryDataSort,
      mandatoryDataSort,
      mandatoryColumnSortBy,
      ptoRequestsDataSort,
      ptoRequestsColumnSortBy,
    } = this.props

    this.props.setDisplayLoadingIcon(true)
    this.props.setSelectedLocationId(selectedLocationId)
    this.props.getLocationDetails(selectedLocationId)

    if (this.props.screenName === SCREEN_NAME_VOLUNTARY) {
      this.setVoluntaryDataTableFiltersToDefault()
      this.setVoluntaryDataTableColumnsToDefault()
      this.setVoluntaryDataTableStateToDefault()

      this.loadGetLeaderAvailabilityFiltersRequest(selectedLocationId, upDownState)
      this.props.setVoluntaryDataSort(voluntaryDataSort)
      this.props.setVoluntaryDataPage(DEFAULT_PAGE_NUM)
      this.props.setVoluntaryDataSearch(null)
    } else if (this.props.screenName === SCREEN_NAME_TM_DIRECTORY) {
      this.setDirectoryDataTableFiltersToDefault()
      this.setDirectoryDataTableColumnsToDefault()
      this.setDirectoryDataTableStateToDefault()
      this.props.loadGetLeaderDirectoryFilters(selectedLocationId)
      this.props.setDirectoryDataPage(DEFAULT_PAGE_NUM)
      this.props.setDirectoryDataSearch(null)
    } else if (this.props.screenName === SCREEN_NAME_MANDATORY) {
      this.setMandatoryDataTableFiltersToDefault()
      this.setMandatoryDataTableColumnsToDefault()
      this.setMandatoryDataTableStateToDefault()
      this.loadGetLeaderMandatoryAvailabilityFiltersRequest(selectedLocationId, upDownState)
      this.props.setMandatoryDataSort(mandatoryDataSort, mandatoryColumnSortBy)
      this.props.setMandatoryDataPage(DEFAULT_PAGE_NUM)
      this.props.setMandatoryDataSearch(null)
    } else if (this.props.screenName === SCREEN_NAME_PTO_REQUEST) {
      this.setPTORequestsDataTableColumnsToDefault()
      this.setPTORequestsDataTableFiltersToDefault()
      this.setPTORequestsDataTableStateToDefault()
      this.props.loadGetLeaderPTORequestFilters(selectedLocationId)
      this.props.setPTORequestsDataPage(DEFAULT_PAGE_NUM)
      this.props.setPTORequestsDataSearch(null)
      this.props.setPTORequestsDataSort(ptoRequestsDataSort, ptoRequestsColumnSortBy)
    }
  }

  setScreenName = (name) => {
    const { selectedLocationId } = this.props
    this.props.setScreenName(name)
    this.props.setUpDownState(DEFAULT_VOLUNTARY_DATA_UP_DOWN)

    this.props.setDisplayLoadingIcon(true)

    if (name === SCREEN_NAME_VOLUNTARY) {
      this.setDateToToday()
      this.setVoluntaryDataTableFiltersToDefault()
      this.setVoluntaryDataTableColumnsToDefault()
      this.setVoluntaryDataTableStateToDefault()
      this.props.setVoluntaryDataPerPage(DEFAULT_PER_PAGE)
      this.props.setVoluntaryDataPage(DEFAULT_PAGE_NUM)
      this.props.setVoluntaryDataSearch(null)
      this.props.setVoluntaryDataSort(DEFAULT_VOLUNTARY_DATA_SORT)

      this.loadGetLeaderAvailabilityFiltersRequest(selectedLocationId, DEFAULT_VOLUNTARY_DATA_UP_DOWN)
    } else if (name === SCREEN_NAME_TM_DIRECTORY) {
      this.setDirectoryDataTableFiltersToDefault()
      this.setDirectoryDataTableColumnsToDefault()
      this.setDirectoryDataTableStateToDefault()
      this.props.setDirectoryDataPerPage(DEFAULT_PER_PAGE)
      this.props.setDirectoryDataPage(DEFAULT_PAGE_NUM)
      this.props.setDirectoryDataSearch(null)
      this.loadGetLeaderDirectoryFiltersRequest(selectedLocationId)
    } else if (name === SCREEN_NAME_MANDATORY) {
      this.setDateToToday()
      this.setMandatoryDataTableFiltersToDefault()
      this.setMandatoryDataTableColumnsToDefault()
      this.setMandatoryDataTableStateToDefault()
      this.props.setMandatoryDataPerPage(DEFAULT_PER_PAGE)
      this.props.setMandatoryDataPage(DEFAULT_PAGE_NUM)
      this.props.setMandatoryDataSearch(null)
      this.props.setMandatoryDataSort(DEFAULT_MANDATORY_DATA_SORT, DEFAULT_MANDATORY_COLUMN_SORT_BY)
      this.loadGetLeaderMandatoryAvailabilityFiltersRequest(selectedLocationId, DEFAULT_VOLUNTARY_DATA_UP_DOWN)
    } else if (name === SCREEN_NAME_PTO_REQUEST) {
      this.setSelectedDate(null)
      this.setSelectedEndDate(null)
      this.setPTORequestsDataTableColumnsToDefault()
      this.setPTORequestsDataTableFiltersToDefault()
      this.setPTORequestsDataTableStateToDefault()
      this.props.setPTORequestsDataPerPage(DEFAULT_PER_PAGE)
      this.props.setPTORequestsDataPage(DEFAULT_PAGE_NUM)
      this.props.setPTORequestsDataSearch(null)
      this.loadGetLeaderPTORequestsFiltersRequest(selectedLocationId)
      this.props.setPTORequestsDataSort(DEFAULT_PTO_REQUESTS_DATA_SORT, DEFAULT_PTO_REQUESTS_SORT_BY)
    }
  }

  loadGetLeaderAvailabilityFiltersRequest = (selectedLocationId, upDownState) => {
    if (selectedLocationId && upDownState) {
      this.props.loadGetLeaderAvailabilityFiltersRequest(upDownState, selectedLocationId)
    }
  }

  loadGetLeaderMandatoryAvailabilityFiltersRequest = (selectedLocationId, upDownState) => {
    if (selectedLocationId && upDownState) {
      this.props.loadGetLeaderMandatoryAvailabilityFiltersRequest(upDownState, selectedLocationId)
    }
  }

  loadGetLeaderDirectoryFiltersRequest = (selectedLocationId) => {
    if (selectedLocationId) {
      this.props.loadGetLeaderDirectoryFilters(selectedLocationId)
    }
  }

  loadGetLeaderPTORequestsFiltersRequest = (selectedLocationId) => {
    if (selectedLocationId) {
      this.props.loadGetLeaderPTORequestFilters(selectedLocationId)
    }
  }

  setVoluntaryDataTableStateToDefault = () => {
    this.props.setVoluntaryDataTableStateToDefault()
  }

  setDirectoryDataSelectedFilters = (filters) => {
    this.props.setDirectoryDataSelectedFilters(filters)
  }

  setDirectoryDataTableStateToDefault = () => {
    this.props.setDirectoryDataTableStateToDefault()
  }

  setMandatoryDataTableStateToDefault = () => {
    this.props.setMandatoryDataTableStateToDefault()
  }

  setVoluntaryDataTableFiltersToDefault = () => {
    this.props.setVoluntaryDataTableFiltersToDefault()
  }

  setDirectoryDataTableFiltersToDefault = () => {
    this.props.setDirectoryDataTableFiltersToDefault()
  }

  setMandatoryDataTableFiltersToDefault = () => {
    this.props.setMandatoryDataTableFiltersToDefault()
  }

  setVoluntaryDataTableColumnsToDefault = () => {
    this.props.setVoluntaryDataTableColumnsToDefault()
  }

  setDirectoryDataTableColumnsToDefault = () => {
    this.props.setDirectoryDataTableColumnsToDefault()
  }

  setMandatoryDataTableColumnsToDefault = () => {
    this.props.setMandatoryDataTableColumnsToDefault()
  }

  setPTORequestsDataTableFiltersToDefault = () => {
    this.props.setPTORequestsDataTableFiltersToDefault(this.context.user?.userData?.persona)
  }

  setPTORequestsDataTableColumnsToDefault = () => {
    this.props.setPTORequestsDataTableColumnsToDefault()
  }

  setPTORequestsDataTableStateToDefault = () => {
    this.props.setPTORequestsDataTableStateToDefault()
  }

  setPTORequestsDataSelectedFilters = (filters) => {
    this.props.setPTORequestsDataSelectedFilters(filters)
  }

  handleDisplayLoadingIcon = (previousLoading, currentLoading) => {
    if (this.props.displayLoadingIcon && previousLoading && !currentLoading) {
      this.props.setDisplayLoadingIcon(false)
    }
  }

  /**
   * Don't refresh the table until loading is complete. Loading in this sense is prework.
   * @param previousLoading
   * @param currentLoading
   */
  handleRefreshTable = (previousLoading, currentLoading) => {
    if (this.props.refreshTable === refreshTableEnum.PREWORK && previousLoading && !currentLoading) {
      this.props.setRefreshTable(refreshTableEnum.REFRESH)
    }
  }

  checkAndGetLeaderViewData = (prevProps) => {
    const { selectedLocationId, screenName, selectedDate, selectedEndDate } = this.props

    if (selectedLocationId && selectedDate) {
      if (screenName === SCREEN_NAME_VOLUNTARY) {
        this.loadGetWorkersAvailabilityPreferencesRequest(prevProps)
      } else if (screenName === SCREEN_NAME_MANDATORY) {
        this.loadGetWorkersMandatoryPreferencesRequest(prevProps)
      } else if (screenName === SCREEN_NAME_TM_DIRECTORY) {
        this.loadGetWorkersDirectoryPreferencesRequest(prevProps)
      } else if (screenName === SCREEN_NAME_PTO_REQUEST && selectedEndDate) {
        this.loadGetWorkersPTORequestsPreferencesRequest(prevProps)
      }
    }
  }

  shouldGetData = (shouldLoad, setShouldLoad) => {
    if (shouldLoad) {
      setShouldLoad(false)
      this.props.setDisplayLoadingIcon(true)
    }

    return shouldLoad
  }

  /**
   * Only trigger the api request if current change has a value, the api request is not in a loading state, and the
   * current change doesn't match the previous change used in an api request.
   *
   * A change could be filters or search
   */
  shouldGetDataFromChange = (prevChange, currentChange, currentLoading) => {
    return currentChange !== null && !currentLoading && prevChange !== currentChange
  }

  loadGetWorkersAvailabilityPreferencesRequest = (prevProps) => {
    const {
      screenName,
      selectedLocationId,
      selectedDate,
      voluntaryDataSelectedFilters,
      voluntaryDataSearch,
      upDownState,
      voluntaryDataSort,
      voluntaryDataPage,
      voluntaryDataPerPage,
      shouldLoadVoluntaryData,
      voluntaryPrevRequestedSearch,
      voluntaryDataLoading,
      voluntaryPrevRequestedSelectedFilters,
    } = this.props

    if (
      !this.props.selectedLocationDetailsGetError &&
      (prevProps === null ||
        prevProps.selectedLocationId !== selectedLocationId ||
        prevProps.screenName !== screenName ||
        prevProps.selectedDate !== selectedDate ||
        prevProps.upDownState !== upDownState ||
        prevProps.voluntaryDataSort !== voluntaryDataSort ||
        prevProps.voluntaryDataPage !== voluntaryDataPage ||
        prevProps.voluntaryDataPerPage !== voluntaryDataPerPage ||
        this.shouldGetData(shouldLoadVoluntaryData, this.props.setShouldLoadVoluntaryData) ||
        this.shouldGetDataFromChange(voluntaryPrevRequestedSearch, voluntaryDataSearch, voluntaryDataLoading) ||
        this.shouldGetDataFromChange(
          voluntaryPrevRequestedSelectedFilters,
          voluntaryDataSelectedFilters,
          voluntaryDataLoading,
        ))
    ) {
      // Temporarily change selected filters to all in up or down group if none selected - until API is updated
      let mockSelectedFilters = ''
      if (voluntaryDataSelectedFilters[6].length === 0 && upDownState === 'up') {
        mockSelectedFilters =
          '&voluntary_preferences=Up%20-%20Full&voluntary_preferences=Up%20-%20Half%20Early&voluntary_preferences=Up%20-%20Any&voluntary_preferences=Up%20-%20Extend%20Early&voluntary_preferences=Up%20-%20Extend%20Late&voluntary_preferences=Up%20-%20Half%20Late'
      } else if (voluntaryDataSelectedFilters[6].length === 0 && upDownState === 'down') {
        mockSelectedFilters =
          '&voluntary_preferences=Down%20-%20Any&voluntary_preferences=Down%20-%20Full&voluntary_preferences=Down%20-%20Half%20Early&voluntary_preferences=Down%20-%20Half%20Late'
      }
      let filterParams = this.buildEncodedQueryParams(voluntaryDataSelectedFilters)
      filterParams += mockSelectedFilters

      if (selectedDate === null || selectedDate === 'Invalid Date') {
        this.setDateToToday()
      }

      let queryParams = this.buildQueryParams(
        `${apiConfig.availability.key}`,
        selectedLocationId,
        selectedDate || new Date(),
        voluntaryDataPerPage,
        voluntaryDataPage,
        voluntaryDataSort,
        voluntaryDataSearch,
        filterParams,
        null,
      )

      this.props.setVoluntaryDataLoading(true, voluntaryDataSearch, voluntaryDataSelectedFilters)
      this.props.loadGetWorkersAvailabilityPreferencesRequest(queryParams)
    }
  }
  loadGetWorkersPTORequestsPreferencesRequest = (prevProps) => {
    const {
      screenName,
      selectedLocationId,
      selectedDate,
      selectedEndDate,
      ptoRequestsDataSelectedFilters,
      ptoRequestsDataSearch,
      ptoRequestsPrevRequestedSearch,
      ptoRequestsDataPage,
      ptoRequestsDataPerPage,
      ptoRequestsDataLoading,
      ptoRequestsDataSort,
      ptoRequestsColumnSortBy,
      ptoRequestsPrevRequestedSelectedFilters,
    } = this.props
    if (
      prevProps === null ||
      prevProps.selectedLocationId !== selectedLocationId ||
      prevProps.screenName !== screenName ||
      prevProps.ptoRequestsDataPage !== ptoRequestsDataPage ||
      prevProps.ptoRequestsDataPerPage !== ptoRequestsDataPerPage ||
      prevProps.ptoRequestsDataSort !== ptoRequestsDataSort ||
      prevProps.ptoRequestsColumnSortBy !== ptoRequestsColumnSortBy ||
      prevProps.selectedDate !== selectedDate ||
      this.shouldGetDataFromChange(ptoRequestsPrevRequestedSearch, ptoRequestsDataSearch, ptoRequestsDataLoading) ||
      this.shouldGetDataFromChange(
        ptoRequestsPrevRequestedSelectedFilters,
        ptoRequestsDataSelectedFilters,
        ptoRequestsDataLoading,
      ) ||
      (prevProps.selectedEndDate !== selectedEndDate && selectedDate != null && selectedEndDate != null)
    ) {
      let filterParams = this.buildEncodedQueryParams(ptoRequestsDataSelectedFilters)

      let queryParams = this.buildQueryParams(
        `${apiConfig.timeOff.key}`,
        selectedLocationId,
        selectedDate,
        ptoRequestsDataPerPage,
        ptoRequestsDataPage,
        ptoRequestsDataSort,
        ptoRequestsDataSearch,
        filterParams,
        selectedEndDate,
      )
      this.props.setPTORequestsDataLoading(true, ptoRequestsDataSearch, ptoRequestsDataSelectedFilters)
      this.props.loadGetWorkersPTORequestsRequest(queryParams)
    }
  }

  loadGetWorkersDirectoryPreferencesRequest = (prevProps) => {
    const {
      screenName,
      selectedDate,
      selectedLocationId,
      directoryDataSort,
      directoryDataSelectedFilters,
      directoryDataPage,
      directoryDataPerPage,
      directoryDataSearch,
      directoryDataLoading,
      directoryPrevRequestedSearch,
      directoryPrevRequestedSelectedFilters,
    } = this.props

    if (
      prevProps === null ||
      prevProps.selectedLocationId !== selectedLocationId ||
      prevProps.screenName !== screenName ||
      prevProps.selectedDate !== selectedDate ||
      prevProps.directoryDataSort !== directoryDataSort ||
      prevProps.directoryDataPage !== directoryDataPage ||
      prevProps.directoryDataPerPage !== directoryDataPerPage ||
      this.shouldGetDataFromChange(directoryPrevRequestedSearch, directoryDataSearch, directoryDataLoading) ||
      this.shouldGetDataFromChange(
        directoryPrevRequestedSelectedFilters,
        directoryDataSelectedFilters,
        directoryDataLoading,
      )
    ) {
      let filterParams = this.buildEncodedQueryParams(directoryDataSelectedFilters)

      let queryParams = this.buildQueryParams(
        `${apiConfig.worker.key}`,
        selectedLocationId,
        null,
        directoryDataPerPage,
        directoryDataPage,
        null,
        directoryDataSearch,
        filterParams,
        null,
      )

      this.props.setDirectoryDataLoading(true, directoryDataSearch, directoryDataSelectedFilters)
      this.props.loadGetWorkersDirectoryRequest(queryParams)
    }
  }

  loadGetWorkersMandatoryPreferencesRequest = (prevProps) => {
    const {
      screenName,
      selectedLocationId,
      selectedDate,
      mandatoryColumnSortBy,
      mandatoryDataSelectedFilters,
      upDownState,
      mandatoryDataSearch,
      mandatoryDataSort,
      mandatoryDataPage,
      mandatoryDataPerPage,
      shouldLoadMandatoryData,
      mandatoryPrevRequestedSearch,
      mandatoryDataLoading,
      mandatoryPrevRequestedSelectedFilters,
    } = this.props

    if (
      !this.props.selectedLocationDetailsGetError &&
      (prevProps === null ||
        prevProps.selectedLocationId !== selectedLocationId ||
        prevProps.screenName !== screenName ||
        prevProps.selectedDate !== selectedDate ||
        prevProps.mandatoryDataSort !== mandatoryDataSort ||
        prevProps.mandatoryColumnSortBy !== mandatoryColumnSortBy ||
        prevProps.mandatoryDataPage !== mandatoryDataPage ||
        prevProps.mandatoryDataPerPage !== mandatoryDataPerPage ||
        this.shouldGetData(shouldLoadMandatoryData, this.props.setShouldLoadMandatoryData) ||
        this.shouldGetDataFromChange(mandatoryPrevRequestedSearch, mandatoryDataSearch, mandatoryDataLoading) ||
        this.shouldGetDataFromChange(
          mandatoryPrevRequestedSelectedFilters,
          mandatoryDataSelectedFilters,
          mandatoryDataLoading,
        ))
    ) {
      if (selectedDate === null || selectedDate === 'Invalid Date') {
        this.setDateToToday()
      }
      let filterParams = this.buildEncodedQueryParams(mandatoryDataSelectedFilters)
      let queryParams = this.buildQueryParams(
        `${apiConfig.mandatory.key}`,
        selectedLocationId,
        selectedDate || new Date(),
        mandatoryDataPerPage,
        mandatoryDataPage,
        mandatoryDataSort,
        mandatoryDataSearch,
        filterParams,
        null,
      )

      queryParams += '&mandatory_type=' + upDownState

      this.props.setMandatoryDataLoading(true, mandatoryDataSearch, mandatoryDataSelectedFilters)
      this.props.loadGetWorkersMandatoryAvailabilityPreferencesRequest(queryParams)
    }
  }

  buildEncodedQueryParams = (multiValueArray) => {
    const { screenName } = this.props
    const queryParams = []
    if (screenName === SCREEN_NAME_TM_DIRECTORY) {
      multiValueArray.forEach(function (arrayElement, i) {
        arrayElement.forEach(function (element) {
          queryParams.push(directoryDataQueryParamKeys[i] + '=' + encodeURIComponent(element))
        })
      })
    } else if (screenName === SCREEN_NAME_VOLUNTARY) {
      multiValueArray.forEach(function (arrayElement, i) {
        arrayElement.forEach(function (element) {
          queryParams.push(voluntaryDataQueryParamKeys[i] + '=' + encodeURIComponent(element))
        })
      })
    } else if (screenName === SCREEN_NAME_MANDATORY) {
      multiValueArray.forEach(function (arrayElement, i) {
        arrayElement.forEach(function (element) {
          queryParams.push(mandatoryDataQueryParamKeys[i] + '=' + encodeURIComponent(element))
        })
      })
    } else if (screenName === SCREEN_NAME_PTO_REQUEST) {
      multiValueArray.forEach(function (arrayElement, i) {
        arrayElement.forEach(function (element) {
          queryParams.push(
            timeOffRequestQueryParamKeys.get(PTO_REQUESTS_COLUMNS_ORDER[i]) + '=' + encodeURIComponent(element),
          )
        })
      })
    }

    return queryParams.join('&')
  }

  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d)
  }

  buildQueryParams = (
    key,
    selectedLocationId,
    date,
    perPage,
    pageNum,
    sortOrder,
    searchableName,
    filterParams,
    endDate,
  ) => {
    const { mandatoryColumnSortBy, ptoRequestsColumnSortBy, screenName } = this.props
    let filterRequired = this.context.user?.personaIncludes([PERSONAS.DC_LEADER_EX])
    let queryParams = '?key=' + key + '&location_id=' + selectedLocationId

    var validDate = this.isValidDate(date)

    if (!validDate && screenName !== 'tmDirectory') {
      date = new Date()
      this.setSelectedDate(date)
    }

    if (isValid(date)) {
      if (screenName === SCREEN_NAME_PTO_REQUEST) {
        queryParams = queryParams.concat('&start_date=' + format(date, 'yyyy-MM-dd'))
      } else {
        queryParams = queryParams.concat('&schedule_date=' + format(date, 'yyyy-MM-dd'))
      }
    }
    if (isValid(endDate) && screenName === SCREEN_NAME_PTO_REQUEST) {
      queryParams = queryParams.concat('&end_date=' + format(endDate, 'yyyy-MM-dd'))
    }

    if (SCREEN_NAME_PTO_REQUEST) {
      queryParams = queryParams.concat('&filter_required=' + filterRequired)
    }

    if (pageNum) {
      queryParams = queryParams.concat('&page=' + pageNum)
    }

    if (perPage) {
      queryParams = queryParams.concat('&per_page=' + perPage)
    }

    if (searchableName) {
      queryParams = queryParams.concat('&searchable_name=' + searchableName)
    }

    if (screenName === SCREEN_NAME_MANDATORY && mandatoryColumnSortBy) {
      queryParams = queryParams.concat('&sort=' + mandatoryColumnSortBy + '(' + sortOrder + ')')
    } else {
      if (screenName === SCREEN_NAME_PTO_REQUEST && ptoRequestsColumnSortBy) {
        queryParams = queryParams.concat('&sort_by=' + ptoRequestsColumnSortBy)
      }
      if (sortOrder) {
        queryParams = queryParams.concat('&sort_order=' + sortOrder)
      }
    }

    if (filterParams) {
      queryParams = queryParams.concat('&' + filterParams)
    }

    return queryParams
  }

  showNotificationPartialSave = (message, workerNameList) => {
    workerNameList = workerNameList.map((i) => ' [' + i + ']')
    this.props.showNotificationError(true, message + workerNameList.join(','))
  }

  showNotificationBasedOnVoluntaryResponse = (postResponse, voluntaryData) => {
    let workerNameErrorList = []
    let ineligibilitiesMap = new Map()

    if (postResponse && voluntaryData) {
      postResponse.voluntary_availability_responses.forEach((postResponseElement) => {
        let hasIneligibilities = postResponseElement.total_ineligibilities > 0
        if (postResponseElement.status_change.toLowerCase() === 'failed' && !hasIneligibilities) {
          voluntaryData.forEach((voluntaryDataElement) => {
            if (
              postResponseElement.voluntary_availability_request_id.toString() ===
              voluntaryDataElement.voluntary_availability_request_id
            ) {
              // used to display in snack bar for unknown error
              workerNameErrorList.push(voluntaryDataElement.full_name)
            }
          })
        }
        if (hasIneligibilities) {
          ineligibilitiesMap.set(postResponseElement.worker_id, postResponseElement.ineligibilities)
        }
      })
    }

    if (ineligibilitiesMap.size) {
      // Scheduling rules were checked during save attempt and violations were found. Open save dialog so user has
      // ability to save tm's with violations.
      this.props.setVoluntaryPostResponseIneligibilities(ineligibilitiesMap)
      this.props.setSaveVoluntaryDialogOpen('schedule')
      if (workerNameErrorList.length) {
        //unknown errors occurred for some tms. Open notification snack bar with message and list of TMs.
        this.showNotificationPartialSave(VOLUNTARY_PARTIALLY_SAVED_MESSAGE, workerNameErrorList)
      }
    } else {
      if (workerNameErrorList.length) {
        //unknown errors occurred for some tms. Open notification snack bar with message and list of TMs.
        this.showNotificationPartialSave(VOLUNTARY_PARTIALLY_SAVED_MESSAGE, workerNameErrorList)
      } else {
        this.props.showNotificationSuccess(true, AVAILABILITY_SAVED_MESSAGE)
      }

      this.props.setVoluntaryPostResponse(null)
      this.props.setDisplayLoadingIcon(true)
      this.loadGetWorkersAvailabilityPreferencesRequest(null)
    }
  }

  showNotificationBasedOnDirectoryResponse = (postResponse, directoryData) => {
    let errorList = []

    if (postResponse && directoryData) {
      postResponse.worker_details.forEach((postResponseElement) => {
        if (postResponseElement.status.toLowerCase() === 'failed') {
          directoryData.forEach((directoryDataElement) => {
            if (postResponseElement.worker_id.toString() === directoryDataElement.worker_id) {
              errorList.push(' [' + directoryDataElement.name + ']')
            }
          })
        }
      })
    }

    if (errorList.length) {
      this.props.showNotificationError(true, DIRECTORY_PARTIALLY_SAVED_MESSAGE + errorList.join(','))
    } else {
      this.props.showNotificationSuccess(true, DIRECTORY_SAVED_MESSAGE)
    }
  }

  showNotificationBasedOnMandatoryResponse = (postResponse, mandatoryData) => {
    let workerNameErrorList = []
    let ineligibilitiesMap = new Map()

    if (postResponse && mandatoryData) {
      postResponse.mandatory_scheduling_decisions.forEach((postResponseElement) => {
        let hasIneligibilities = postResponseElement.total_ineligibilities > 0
        if (postResponseElement.status_change.toLowerCase() === 'failed' && !hasIneligibilities) {
          mandatoryData.forEach((mandatoryDataElement) => {
            if (postResponseElement.worker_id.toString() === mandatoryDataElement.worker_id) {
              // used to display in snack bar for unknown error
              workerNameErrorList.push(mandatoryDataElement.full_name)
            }
          })
        }
        if (hasIneligibilities) {
          ineligibilitiesMap.set(postResponseElement.worker_id, postResponseElement.ineligibilities)
        }
      })
    }

    if (ineligibilitiesMap.size) {
      // Scheduling rules were checked during save attempt and violations were found. Open save dialog so user has
      // ability to save tm's with violations.
      this.props.setMandatoryPostResponseIneligibilities(ineligibilitiesMap)
      this.props.setSaveMandatoryDialogOpen('schedule')
      if (workerNameErrorList.length) {
        //unknown errors occurred for some tms. Open notification snack bar with message and list of TMs.
        this.showNotificationPartialSave(MANDATORY_PARTIALLY_SAVED_MESSAGE, workerNameErrorList)
      }
    } else {
      if (workerNameErrorList.length) {
        //unknown errors occurred for some tms. Open notification snack bar with message and list of TMs.
        this.showNotificationPartialSave(MANDATORY_PARTIALLY_SAVED_MESSAGE, workerNameErrorList)
      } else {
        this.props.showNotificationSuccess(true, MANDATORY_SAVED_MESSAGE)
      }

      this.props.setMandatoryPostResponse(null)
      this.props.setDisplayLoadingIcon(true)
      this.loadGetWorkersMandatoryPreferencesRequest(null)
    }
  }

  handleLocationId = (newLocationId) => {
    const isChanges = this.checkForChanges()
    if (isChanges) {
      this.props.setDesiredLocationID(newLocationId)
      this.props.setPopUpTextToChangeLocation(true)
      this.props.setExitLeaderDialogOpen(true)
    } else {
      this.setSelectedLocationId(newLocationId)
    }
  }

  handleDateOnChange = (name, date) => {
    name === 'startDate' ? this.handleStartDateClick(date) : this.handleEndDateClick(date)
  }

  handleStartDateClick = (day) => {
    const isChanges = this.checkForChanges()
    if (isChanges) {
      this.props.setDesiredSelectedDate(day)
      this.props.setPopUpTextToChangeDate(true)
      this.props.setExitLeaderDialogOpen(true)
    } else {
      this.setSelectedDate(day)
      this.setSelectedEndDate(null)
    }
  }

  handleEndDateClick = (day) => {
    this.setSelectedEndDate(day)
  }

  /**
   *
   * Getting the screenName from .props
   * Getting the selected screen that the user picked.
   * If the current screen Name is not the same as the requested screen, then run into this function.
   * See checkForChanges down below for this logic.
   * this is opening the dialog box. from the box the user has the option to keep the state or move one to the next screen.
   * If there is no changes then move on to the new screen.
   * If there is no changes then move on to the new screen.
   *
   */
  maybeWantToChangeScreens = (newScreenName) => {
    const { screenName } = this.props

    if (screenName !== newScreenName && screenName === SCREEN_NAME_TM_DIRECTORY) {
      let isChanges = this.checkForChanges()
      if (isChanges) {
        this.props.setDesiredScreenName(newScreenName)
        this.props.setPopUpTextToChangeRadioView(true)
        this.props.setExitLeaderDialogOpen(true)
      } else {
        this.setScreenName(newScreenName)
      }
    } else {
      this.setScreenName(newScreenName)
    }

    this.setState({ redirectOn: true, screenName: newScreenName }, () => {})
  }

  checkForChanges = () => {
    //If there is changes on the Directory page, the length will not be 0. thus returning true.
    return this.getIndexesOfUpdatedWorkers().length !== 0
  }

  // returns row indices of workers whose directorySessionData does not match directoryData
  getIndexesOfUpdatedWorkers = () => {
    const { directoryData, directorySessionData } = this.props
    let updatedWorkerIndexes = []
    for (let i = 0; i < directoryData.length; i++) {
      if (!this.checkWorkerRowsEqual(directoryData[i], directorySessionData[i])) {
        updatedWorkerIndexes.push(i)
      }
    }
    return updatedWorkerIndexes
  }

  checkWorkerRowsEqual = (rowOne, rowTwo) => {
    return (
      rowOne.proficient === rowTwo.proficient &&
      rowOne.schedule_up_group === rowTwo.schedule_up_group &&
      rowOne.schedule_down_group === rowTwo.schedule_down_group
    )
  }

  handleNavigation = (nextLocation) => {
    if (this.getIndexesOfUpdatedWorkers().length !== 0) {
      this.handleLeaderOpeningExitDialog(nextLocation)
      return false
    }
    return true
  }

  handleLeaderOpeningExitDialog = (nextLocation) => {
    this.props.setNextLeaderLocation(nextLocation.pathname)
    this.props.setPopUpTextToExit(true)
    this.props.setExitLeaderDialogOpen(true)
  }

  handleExitDialogYesButton = () => {
    const {
      setExitLeaderDialogOpen,
      nextLeaderLocation,
      setNavigateToNextLeaderLocation,
      desiredScreenName,
      desiredSelectedDate,
      desiredLocationID,
    } = this.props
    setExitLeaderDialogOpen(false)

    this.props.setDirectoryDataTableStateToDefault()

    if (nextLeaderLocation) {
      setNavigateToNextLeaderLocation(true)
    } else if (desiredScreenName) {
      this.setScreenName(desiredScreenName)
      this.props.setDesiredScreenName(null)
      this.props.setPopUpTextToExit(false)
    } else if (desiredSelectedDate) {
      this.setSelectedDate(desiredSelectedDate)
      this.props.setDesiredSelectedDate(null)
      this.props.setPopUpTextToChangeDate(false)
    } else if (desiredLocationID) {
      this.setSelectedLocationId(desiredLocationID)
      this.props.setDesiredLocationID(null)
      this.props.setPopUpTextToChangeLocation(false)
    }
  }

  handleExitDialogNoButtonOrClose = () => {
    this.props.setExitLeaderDialogOpen(false)
    this.props.setPopUpTextToChangeLocation(false)
    this.props.setPopUpTextToExit(false)
    this.props.setPopUpTextToChangeRadioView(false)
    this.props.setPopUpTextToChangeDate(false)
    this.props.setNextLeaderLocation(null)
  }

  handleMandatoryFilterChange = (newFilterList) => {
    this.props.setMandatoryDataSelectedFilters(newFilterList)
    this.props.setMandatoryDataPage(DEFAULT_PAGE_NUM)
  }

  handleDirectoryFilterChange = (newFilterList) => {
    this.setDirectoryDataSelectedFilters(newFilterList)
    this.props.setDirectoryDataPage(DEFAULT_PAGE_NUM)
  }

  handlePTORequestsFilterChange = (newFilterList) => {
    this.props.setPTORequestsDataSelectedFilters(newFilterList)
    this.props.setPTORequestsDataPage(DEFAULT_PAGE_NUM)
  }

  handleVoluntarySearchChange = (searchText) => {
    this.props.setVoluntaryDataSearch(searchText)
    this.props.setVoluntaryDataPage(DEFAULT_PAGE_NUM)
    if (this.props.voluntaryDataPage !== DEFAULT_PAGE_NUM) {
      this.props.setRefreshTable(refreshTableEnum.PREWORK)
    }
  }

  handleMandatorySearchChange = (searchText) => {
    this.props.setMandatoryDataSearch(searchText)
    this.props.setMandatoryDataPage(DEFAULT_PAGE_NUM)
    if (this.props.mandatoryDataPage !== DEFAULT_PAGE_NUM) {
      this.props.setRefreshTable(refreshTableEnum.PREWORK)
    }
  }

  handleDirectorySearchChange = (searchText) => {
    this.props.setDirectoryDataSearch(searchText)
    this.props.setDirectoryDataPage(DEFAULT_PAGE_NUM)
    if (this.props.directoryDataPage !== DEFAULT_PAGE_NUM) {
      this.props.setRefreshTable(refreshTableEnum.PREWORK)
    }
  }

  handlePTOSearchChange = (searchText) => {
    this.props.setPTORequestsDataSearch(searchText)
    this.props.setPTORequestsDataPage(DEFAULT_PAGE_NUM)
    if (this.props.ptoRequestsDataPage !== DEFAULT_PAGE_NUM) {
      this.props.setRefreshTable(refreshTableEnum.PREWORK)
    }
  }

  handleUpDownToggle = (toggleState) => {
    const { screenName, selectedLocationId } = this.props

    this.props.setUpDownState(toggleState)
    this.props.setDisplayLoadingIcon(true)

    if (screenName === SCREEN_NAME_VOLUNTARY) {
      this.setVoluntaryDataTableFiltersToDefault()
      this.setVoluntaryDataTableColumnsToDefault()
      this.setVoluntaryDataTableStateToDefault()
      this.props.setVoluntaryDataSort(DEFAULT_VOLUNTARY_DATA_SORT)
      this.props.setVoluntaryDataPage(DEFAULT_PAGE_NUM)
      this.props.setVoluntaryDataSearch(null)
      this.loadGetLeaderAvailabilityFiltersRequest(selectedLocationId, toggleState)
    } else if (screenName === SCREEN_NAME_TM_DIRECTORY) {
      this.setDirectoryDataTableFiltersToDefault()
      this.setDirectoryDataTableColumnsToDefault()
      this.setDirectoryDataTableStateToDefault()
      this.props.setDirectoryDataPage(DEFAULT_PAGE_NUM)
      this.props.setDirectoryDataSearch(null)
      this.loadGetLeaderDirectoryFiltersRequest(selectedLocationId)
    } else if (screenName === SCREEN_NAME_MANDATORY) {
      this.setMandatoryDataTableFiltersToDefault()
      this.setMandatoryDataTableColumnsToDefault()
      this.setMandatoryDataTableStateToDefault()
      this.props.setMandatoryDataSort(DEFAULT_MANDATORY_DATA_SORT, DEFAULT_MANDATORY_COLUMN_SORT_BY)
      this.props.setMandatoryDataPage(DEFAULT_PAGE_NUM)
      this.props.setMandatoryDataSearch(null)
      this.loadGetLeaderMandatoryAvailabilityFiltersRequest(selectedLocationId, toggleState)
    }
  }

  handleVoluntaryColumnViewChange = (changedColumn, action) => {
    this.props.setVoluntaryColumnView(changedColumn, action)
  }

  handleDirectoryColumnViewChange = (changedColumn, action) => {
    this.props.setDirectoryColumnView(changedColumn, action)
  }

  handleMandatoryColumnViewChange = (changedColumn, action) => {
    this.props.setMandatoryColumnView(changedColumn, action)
  }

  handlePTORequestsColumnViewChange = (changedColumn, action) => {
    this.props.setPTORequestsColumnView(changedColumn, action)
  }

  /**
   * We're defining ascending as: For dates, the sort will be oldest/earliest dates to most recent.
   * The oldest dates will be at the top of the list. This is when the arrow is pointing downwards the UI.
   *
   * @param changedColumn
   * @param direction
   */
  handleVoluntarySortChange = (changedColumn, direction) => {
    this.props.setVoluntaryDataSort(direction.toUpperCase())
    this.props.setVoluntaryDataPage(DEFAULT_PAGE_NUM)
  }

  handleMandatorySortChange = (changedColumn, direction) => {
    this.props.setMandatoryDataSort(direction.toUpperCase(), changedColumn)
    this.props.setMandatoryDataPage(DEFAULT_PAGE_NUM)
  }

  handleDirectorySortChange = (changedColumn, direction) => {
    this.props.setDirectoryDataSort(direction.toUpperCase())
    this.props.setDirectoryDataPage(DEFAULT_PAGE_NUM)
  }

  handlePTORequestsSortChange = (changedColumn, direction) => {
    this.props.setPTORequestsDataSort(direction.toUpperCase(), changedColumn)
    this.props.setPTORequestsDataPage(DEFAULT_PAGE_NUM)
  }

  handleVoluntaryPageChange = (currentPage) => {
    this.props.setDisplayLoadingIcon(true)
    this.props.setVoluntaryDataPage(currentPage)
  }

  handleDirectoryPageChange = (currentPage) => {
    this.props.setDisplayLoadingIcon(true)
    this.props.setDirectoryDataPage(currentPage)
  }

  handleMandatoryPageChange = (currentPage) => {
    this.props.setDisplayLoadingIcon(true)
    this.props.setMandatoryDataPage(currentPage)
  }

  handlePTORequestsPageChange = (currentPage) => {
    this.props.setDisplayLoadingIcon(true)
    this.props.setPTORequestsDataPage(currentPage)
  }

  handleVoluntaryPerPageChange = (numberOfRows) => {
    this.props.setDisplayLoadingIcon(true)

    this.setVoluntaryDataTableStateToDefault()
    this.props.setVoluntaryDataPage(DEFAULT_PAGE_NUM)
    this.props.setVoluntaryDataPerPage(numberOfRows)
  }

  handleDirectoryPerPageChange = (numberOfRows) => {
    this.props.setDisplayLoadingIcon(true)

    this.setDirectoryDataTableStateToDefault()
    this.props.setDirectoryDataPage(DEFAULT_PAGE_NUM)
    this.props.setDirectoryDataPerPage(numberOfRows)
  }

  handleMandatoryPerPageChange = (numberOfRows) => {
    this.props.setDisplayLoadingIcon(true)

    this.setMandatoryDataTableStateToDefault()
    this.props.setMandatoryDataPage(DEFAULT_PAGE_NUM)
    this.props.setMandatoryDataPerPage(numberOfRows)
  }

  handlePTORequestsPerPageChange = (numberOfRows) => {
    this.props.setDisplayLoadingIcon(true)
    this.setPTORequestsDataTableStateToDefault()
    this.props.setPTORequestsDataPage(DEFAULT_PAGE_NUM)
    this.props.setPTORequestsDataPerPage(numberOfRows)
  }

  handleVoluntaryFilterChange = (newFilterList) => {
    this.setVoluntaryDataSelectedFilters(newFilterList)
    this.props.setVoluntaryDataPage(DEFAULT_PAGE_NUM)
  }

  setVoluntaryDataSelectedFilters = (filters) => {
    this.props.setVoluntaryDataSelectedFilters(filters)
  }

  // returns true if row doesn't contain NA and is different from directoryData
  canSelectRow = (rowIndex, colIndex, newValue) => {
    const { directorySessionData, directoryData } = this.props

    const targetRow = directorySessionData[rowIndex]

    const origRow = directoryData[rowIndex]

    // if changed by click, use the new value. otherwise use session data
    const currProficientVal =
      colIndex === DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.proficient) ? newValue : targetRow.proficient
    const currUpGroupVal =
      colIndex === DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.schedule_up_group)
        ? newValue
        : targetRow.schedule_up_group
    const currDownGroupVal =
      colIndex === DIRECTORY_COLUMNS_ORDER.indexOf(DIRECTORY_COLUMNS_ENUM.schedule_down_group)
        ? newValue
        : targetRow.schedule_down_group

    const rowContainsNA = currProficientVal === 'NA' || currUpGroupVal === 'NA' || currDownGroupVal === 'NA'

    const rowDiffersFromOrig =
      currProficientVal !== origRow.proficient ||
      currUpGroupVal !== origRow.schedule_up_group ||
      currDownGroupVal !== origRow.schedule_down_group

    return !rowContainsNA && rowDiffersFromOrig
  }

  renderErrorMessageWithCode = (message, error, defaultErrorCode) => {
    return (
      <div style={styles.errorMessageContainerPosition}>
        <p style={styles.errorMessage}>
          {message}
          <span style={styles.errorCodeMessage}>{formatErrorCode(error, defaultErrorCode)}</span>
        </p>
      </div>
    )
  }

  renderErrorMessageWithoutCode = (message) => {
    return (
      <div style={styles.errorMessageContainerPosition}>
        <p style={styles.errorMessage}>{message}</p>
      </div>
    )
  }

  renderErrorMessage = () => {
    const {
      voluntaryGetError,
      voluntaryFiltersGetError,
      directoryGetError,
      directoryFiltersGetError,
      ptoRequestFiltersGetError,
      ptoRequestsGetError,
      mandatoryGetPreferencesError,
      mandatoryGetFiltersError,
      screenName,
      selectedLocationDetailsGetError,
    } = this.props
    let ViewableContent = null

    if (screenName === SCREEN_NAME_VOLUNTARY && voluntaryGetError) {
      if (
        voluntaryGetError.message &&
        voluntaryGetError.code &&
        ERROR_CODES_SHOW_MESSAGE_VOLUNTARY.includes(voluntaryGetError.code)
      ) {
        ViewableContent = this.renderErrorMessageWithoutCode(voluntaryGetError.message)
      } else {
        ViewableContent = this.renderErrorMessageWithCode(
          LOAD_ERROR_MESSAGE_VOLUNTARY,
          voluntaryGetError,
          AVAILABILITY_ID + ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
        )
      }
    } else if (screenName === SCREEN_NAME_VOLUNTARY && voluntaryFiltersGetError) {
      if (
        voluntaryFiltersGetError.message &&
        voluntaryFiltersGetError.code &&
        ERROR_CODES_SHOW_MESSAGE_VOLUNTARY.includes(voluntaryFiltersGetError.code)
      ) {
        ViewableContent = this.renderErrorMessageWithoutCode(voluntaryFiltersGetError.message)
      } else {
        ViewableContent = this.renderErrorMessageWithCode(
          LOAD_ERROR_MESSAGE_VOLUNTARY,
          voluntaryFiltersGetError,
          WFM_CONFIGS_ID + ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
        )
      }
    } else if (screenName === SCREEN_NAME_VOLUNTARY && selectedLocationDetailsGetError) {
      ViewableContent = (
        <div style={styles.mainContainerPosition}>
          <p style={styles.errorMessage}>
            {LOAD_ERROR_MESSAGE_VOLUNTARY}
            <span style={styles.errorCodeMessage}>{ERROR_CODE_CANNOT_CONNECT_TO_LOCATION}</span>
          </p>
        </div>
      )
    } else if (screenName === SCREEN_NAME_TM_DIRECTORY && directoryFiltersGetError) {
      if (
        directoryFiltersGetError.message &&
        directoryFiltersGetError.code &&
        ERROR_CODES_SHOW_MESSAGE_DIRECTORY.includes(directoryFiltersGetError.code)
      ) {
        ViewableContent = this.renderErrorMessageWithoutCode(directoryFiltersGetError.message)
      } else {
        ViewableContent = this.renderErrorMessageWithCode(
          LOAD_ERROR_MESSAGE_DIRECTORY,
          directoryFiltersGetError,
          WFM_CONFIGS_ID + ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
        )
      }
    } else if (screenName === SCREEN_NAME_TM_DIRECTORY && directoryGetError) {
      if (
        directoryGetError.message &&
        directoryGetError.code &&
        ERROR_CODES_SHOW_MESSAGE_DIRECTORY.includes(directoryGetError.code)
      ) {
        ViewableContent = this.renderErrorMessageWithoutCode(directoryGetError.message)
      } else {
        ViewableContent = this.renderErrorMessageWithCode(
          LOAD_ERROR_MESSAGE_DIRECTORY,
          directoryGetError,
          WFM_WORKER_ID + ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
        )
      }
    } else if (screenName === SCREEN_NAME_PTO_REQUEST && ptoRequestsGetError) {
      if (
        ptoRequestsGetError.message &&
        ptoRequestsGetError.code &&
        ERROR_CODES_SHOW_MESSAGE_TIME_OFF.includes(ptoRequestsGetError.code)
      ) {
        ViewableContent = this.renderErrorMessageWithoutCode(ptoRequestsGetError.message)
      } else {
        ViewableContent = this.renderErrorMessageWithCode(
          LOAD_ERROR_MESSAGE_PTO_REQUESTS,
          ptoRequestsGetError,
          WFM_WORKER_ID + ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
        )
      }
    } else if (screenName === SCREEN_NAME_PTO_REQUEST && ptoRequestFiltersGetError) {
      if (
        ptoRequestFiltersGetError.message &&
        ptoRequestFiltersGetError.code &&
        ERROR_CODES_SHOW_MESSAGE_TIME_OFF.includes(ptoRequestFiltersGetError.code)
      ) {
        ViewableContent = this.renderErrorMessageWithoutCode(ptoRequestFiltersGetError.message)
      } else {
        ViewableContent = this.renderErrorMessageWithCode(
          LOAD_ERROR_MESSAGE_PTO_REQUESTS,
          ptoRequestsGetError,
          WFM_CONFIGS_ID + ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
        )
      }
    } else if (screenName === SCREEN_NAME_MANDATORY && mandatoryGetFiltersError) {
      if (
        mandatoryGetFiltersError.message &&
        mandatoryGetFiltersError.code &&
        ERROR_CODES_SHOW_MESSAGE_MANDATORY.includes(mandatoryGetFiltersError.code)
      ) {
        ViewableContent = this.renderErrorMessageWithoutCode(mandatoryGetFiltersError.message)
      } else {
        ViewableContent = this.renderErrorMessageWithCode(
          LOAD_ERROR_MESSAGE_MANDATORY,
          mandatoryGetFiltersError,
          WFM_CONFIGS_ID + ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
        )
      }
    } else if (screenName === SCREEN_NAME_MANDATORY && mandatoryGetPreferencesError) {
      if (
        mandatoryGetPreferencesError.message &&
        mandatoryGetPreferencesError.code &&
        ERROR_CODES_SHOW_MESSAGE_MANDATORY.includes(mandatoryGetPreferencesError.code)
      ) {
        ViewableContent = this.renderErrorMessageWithoutCode(mandatoryGetPreferencesError.message)
      } else {
        ViewableContent = this.renderErrorMessageWithCode(
          LOAD_ERROR_MESSAGE_MANDATORY,
          mandatoryGetPreferencesError,
          MANDATORY_ID + ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
        )
      }
    } else if (screenName === SCREEN_NAME_MANDATORY && selectedLocationDetailsGetError) {
      ViewableContent = (
        <div style={styles.mainContainerPosition}>
          <p style={styles.errorMessage}>
            {LOAD_ERROR_MESSAGE_MANDATORY}
            <span style={styles.errorCodeMessage}>{ERROR_CODE_CANNOT_CONNECT_TO_LOCATION}</span>
          </p>
        </div>
      )
    }
    return ViewableContent
  }

  renderLocationErrorMessage = () => {
    const { locationGetError } = this.props
    let ViewableContent = null

    if (locationGetError) {
      if (
        locationGetError.message &&
        locationGetError.code &&
        ERROR_CODES_SHOW_MESSAGE_LOCATION.includes(locationGetError.code)
      ) {
        ViewableContent = this.renderErrorMessageWithoutCode(locationGetError.message)
      } else {
        ViewableContent = this.renderErrorMessageWithCode(
          LOAD_ERROR_MESSAGE_LOCATION,
          locationGetError,
          USER_ACCESS_ID + ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
        )
      }
    }

    return ViewableContent
  }

  customFooterFunction = () => {
    return <CustomFooter />
  }

  getSelectableRowsValueBasedOnDate = () => {
    const { selectedDate, selectedLocationDetails } = this.props

    let selectableRows = 'multiple'
    if (selectedLocationDetails.iso_time_zone_code) {
      let currentDateAtLocation = getNowDateInTimezone(selectedLocationDetails.iso_time_zone_code)
      if (isBefore(selectedDate, currentDateAtLocation)) {
        selectableRows = 'none'
      }
    }

    return selectableRows
  }

  getVoluntaryAvailabilityOptions = () => {
    const {
      voluntaryDataTotalRecords,
      voluntaryDataPage,
      voluntaryDataPerPage,
      voluntaryDataSearch,
      voluntaryDataSelectedFilters,
      selectedDate,
      upDownState,
      selectedLocationId,
      refreshTable,
    } = this.props

    let searchAutoFocus = this.shouldSearchAutoFocus(refreshTable)

    return {
      search: false,
      filter: true,
      filterType: 'multiselect',
      serverSide: true,
      serverSideFilterList: voluntaryDataSelectedFilters, // Required for filter "chips"
      responsive: 'standard',
      rowsPerPageOptions: LEADER_ROWS_PER_PAGE_OPTIONS,
      page: voluntaryDataPage - 1,
      rowsPerPage: voluntaryDataPerPage,
      count: parseInt(voluntaryDataTotalRecords),
      print: false,
      fixedHeader: true,
      download: false,
      elevation: 1,
      selectableRows: this.getSelectableRowsValueBasedOnDate(),
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <VoluntaryCustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          selectedDate={selectedDate}
          selectedLocationId={selectedLocationId}
          upDownState={upDownState}
        />
      ),
      customToolbar: () => {
        return (
          <CustomToolBar
            locationId={selectedLocationId}
            date={selectedDate}
            filters={voluntaryDataSelectedFilters}
            screen={SCREEN_NAME_VOLUNTARY}
            onSearch={this.handleVoluntarySearchChange}
            searchText={voluntaryDataSearch}
            searchAutoFocus={searchAutoFocus}
          />
        )
      },
      onFilterChange: (changedColumn, filterList) => {
        this.handleVoluntaryFilterChange(filterList)
      },
      onColumnSortChange: (changedColumn, direction) => {
        this.handleVoluntarySortChange(changedColumn, direction)
      },
      onChangePage: (currentPage) => {
        // MUIDataTable stores page numbers starting at 0, so add 1
        this.handleVoluntaryPageChange(currentPage + 1)
      },
      onChangeRowsPerPage: (numberOfRows) => {
        this.handleVoluntaryPerPageChange(numberOfRows)
      },
      onColumnViewChange: (changedColumn, action) => {
        this.handleVoluntaryColumnViewChange(changedColumn, action)
      },
    }
  }

  getTmDirectoryOptions = () => {
    const {
      directoryDataSearch,
      directoryDataPage,
      directoryDataPerPage,
      directoryDataTotalRecords,
      directoryDataSelectedFilters,
      selectedDate,
      refreshTable,
    } = this.props

    let customFooterProperty = this.checkForChanges() ? this.customFooterFunction : null

    let searchAutoFocus = this.shouldSearchAutoFocus(refreshTable)

    return {
      search: false,
      filter: true,
      filterType: 'multiselect',
      serverSide: true,
      serverSideFilterList: directoryDataSelectedFilters, // Required for filter "chips"
      rowsSelected: this.props.selectedDirectoryIndexes,
      isRowSelectable: () => false, //so users can't select rows
      rowsPerPageOptions: LEADER_ROWS_PER_PAGE_OPTIONS,
      page: directoryDataPage - 1,
      rowsPerPage: directoryDataPerPage,
      count: parseInt(directoryDataTotalRecords),
      responsive: 'standard',
      print: false,
      fixedHeader: true,
      download: false,
      elevation: 1,
      customFooter: customFooterProperty,
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <TmDirectoryCustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          setDisplayLoadingIcon={this.props.setDisplayLoadingIcon}
        />
      ),
      customToolbar: () => {
        return (
          <CustomToolBar
            locationId={this.props.selectedLocationId}
            date={selectedDate}
            filters={directoryDataSelectedFilters}
            screen={SCREEN_NAME_TM_DIRECTORY}
            onSearch={this.handleDirectorySearchChange}
            searchText={directoryDataSearch}
            searchAutoFocus={searchAutoFocus}
          />
        )
      },
      onFilterChange: (changedColumn, filterList) => {
        this.handleDirectoryFilterChange(filterList)
      },
      onColumnSortChange: (changedColumn, direction) => {
        this.handleDirectorySortChange(changedColumn, direction)
      },
      onChangePage: (currentPage) => {
        this.handleDirectoryPageChange(currentPage + 1)
      },
      onChangeRowsPerPage: (numberOfRows) => {
        this.handleDirectoryPerPageChange(numberOfRows)
      },
      onColumnViewChange: (changedColumn, action) => {
        this.handleDirectoryColumnViewChange(changedColumn, action)
      },
      onCellClick: (colData, cellMeta) => {
        const { selectedDirectoryIndexes } = this.props
        let value = cellMeta.event.target.dataset.value
        //filters out first action
        if (value) {
          const shouldSelectRow = this.canSelectRow(cellMeta.rowIndex, cellMeta.colIndex, value)
          if (!shouldSelectRow && selectedDirectoryIndexes.includes(cellMeta.rowIndex)) {
            //unselect row
            this.props.removeSelectedDirectoryIndex(cellMeta.rowIndex)
          } else if (shouldSelectRow && !selectedDirectoryIndexes.includes(cellMeta.rowIndex)) {
            //select row
            this.props.addSelectedDirectoryIndex(cellMeta.rowIndex)
          }
          this.props.addDirectorySessionData(cellMeta.rowIndex, cellMeta.colIndex, value)
        }
      },
    }
  }

  getMandatoryAvailabilityOptions = () => {
    const {
      mandatoryColumns,
      mandatoryData,
      mandatoryDataTotalRecords,
      mandatoryDataPage,
      mandatoryDataPerPage,
      mandatoryDataSearch,
      mandatoryDataSelectedFilters,
      selectedDate,
      upDownState,
      refreshTable,
    } = this.props

    let mandatoryShifts =
      mandatoryColumns[MANDATORY_COLUMNS_ORDER.indexOf(MANDATORY_COLUMNS_ENUM.mandatory_shift)].options.filterOptions
        .names

    let searchAutoFocus = this.shouldSearchAutoFocus(refreshTable)

    return {
      search: false,
      filter: true,
      filterType: 'multiselect',
      isRowSelectable: () => (this.context.user.is('punch_correction', 'leader', 'read', true) ? false : true), //so users can't select rows
      serverSide: true,
      serverSideFilterList: mandatoryDataSelectedFilters, // Required for filter "chips"
      responsive: 'standard',
      rowsPerPageOptions: LEADER_ROWS_PER_PAGE_OPTIONS,
      page: mandatoryDataPage - 1,
      rowsPerPage: mandatoryDataPerPage,
      count: parseInt(mandatoryDataTotalRecords),
      print: false,
      fixedHeader: true,
      download: false,
      elevation: 1,
      selectableRows: this.getSelectableRowsValueBasedOnDate(),
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <MandatoryCustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          tableData={mandatoryData}
          selectedDate={selectedDate}
          mandatoryShifts={mandatoryShifts}
          selectedLocationId={this.props.selectedLocationId}
          upDownState={upDownState}
        />
      ),
      customToolbar: () => {
        return (
          <CustomToolBar
            locationId={this.props.selectedLocationId}
            date={selectedDate}
            filters={this.props.mandatoryDataSelectedFilters}
            screen={SCREEN_NAME_MANDATORY}
            onSearch={this.handleMandatorySearchChange}
            searchText={mandatoryDataSearch}
            searchAutoFocus={searchAutoFocus}
          />
        )
      },
      onFilterChange: (changedColumn, filterList) => {
        this.handleMandatoryFilterChange(filterList)
      },
      onColumnSortChange: (changedColumn, direction) => {
        this.handleMandatorySortChange(changedColumn, direction)
      },
      onChangePage: (currentPage) => {
        // MUIDataTable stores page numbers starting at 0, so add 1
        this.handleMandatoryPageChange(currentPage + 1)
      },
      onChangeRowsPerPage: (numberOfRows) => {
        this.handleMandatoryPerPageChange(numberOfRows)
      },
      onColumnViewChange: (changedColumn, action) => {
        this.handleMandatoryColumnViewChange(changedColumn, action)
      },
    }
  }

  getPTORequestOptions = () => {
    const {
      ptoRequestsDataPage,
      ptoRequestsDataPerPage,
      ptoRequestsDataTotalRecords,
      ptoRequestsDataSelectedFilters,
      ptoRequestsDataSearch,
      workerTimeOffRequests,
      refreshTable,
    } = this.props

    let searchAutoFocus = this.shouldSearchAutoFocus(refreshTable)

    return {
      search: false,
      filter: true,
      filterType: 'multiselect',
      serverSide: true,
      serverSideFilterList: ptoRequestsDataSelectedFilters, // Required for filter "chips"
      selectableRows: false,
      selectableRowsHideCheckboxes: true,
      isRowSelectable: () => false,
      rowsPerPageOptions: LEADER_ROWS_PER_PAGE_OPTIONS,
      page: ptoRequestsDataPage - 1,
      rowsPerPage: ptoRequestsDataPerPage,
      count: parseInt(ptoRequestsDataTotalRecords),
      responsive: 'standard',
      print: false,
      fixedHeader: true,
      download: false,
      elevation: 1,
      onRowClick: (rowData, rowMeta) => {
        this.props.openTimeOffRequestDialog(workerTimeOffRequests[rowMeta.rowIndex])
      },
      onChangePage: (currentPage) => {
        this.handlePTORequestsPageChange(currentPage + 1)
      },
      customToolbar: () => {
        return (
          <CustomToolBar
            locationId={this.props.selectedLocationId}
            filters={this.props.ptoRequestsDataSelectedFilters}
            screen={SCREEN_NAME_PTO_REQUEST}
            onSearch={this.handlePTOSearchChange}
            searchText={ptoRequestsDataSearch}
            searchAutoFocus={searchAutoFocus}
          />
        )
      },
      onChangeRowsPerPage: (numberOfRows) => {
        this.handlePTORequestsPerPageChange(numberOfRows)
      },
      onColumnSortChange: (changedColumn, direction) => {
        this.handlePTORequestsSortChange(changedColumn, direction)
      },
      onFilterChange: (changedColumn, filterList) => {
        this.handlePTORequestsFilterChange(filterList)
      },
      onColumnViewChange: (changedColumn, action) => {
        this.handlePTORequestsColumnViewChange(changedColumn, action)
      },
      setRowProps: () => {
        return {
          style: {
            cursor: 'pointer',
          },
        }
      },
    }
  }

  /**
   * The refreshTable postwork is to set the search autofocus to true only after the table has
   * refreshed (search has unmounted/mounted)
   * @param refreshTable
   * @returns {boolean}
   */
  shouldSearchAutoFocus = (refreshTable) => {
    let searchAutoFocus = false
    if (refreshTable === refreshTableEnum.POSTWORK) {
      searchAutoFocus = true
      this.props.setRefreshTable(null)
    }
    return searchAutoFocus
  }

  renderLoadingSection = (screenName, selectedDate, selectedEndDate) => {
    if (screenName === SCREEN_NAME_PTO_REQUEST && (selectedDate === null || selectedEndDate === null)) {
      return (
        <div>
          <p style={styles.message}>Please select a start and end date</p>
        </div>
      )
    }
    if ([SCREEN_NAME_VOLUNTARY, SCREEN_NAME_MANDATORY].includes(screenName) && selectedDate === null) {
      return (
        <div>
          <p style={styles.message}>Please select a date</p>
        </div>
      )
    }
    return <InnerLoaderWithText size={48} loadingText={'LOADING'} />
  }

  renderTableInfo = () => {
    const {
      voluntaryData,
      voluntaryColumns,
      mandatoryColumns,
      mandatoryData,
      directorySessionData,
      directoryColumns,
      ptoRequestsColumns,
      ptoRequestsData,
      refreshTable,
      screenName,
      selectedDate,
      selectedEndDate,
      displayLoadingIcon,
    } = this.props

    let title
    let data
    let columns
    let options

    if (screenName === SCREEN_NAME_VOLUNTARY) {
      title = 'Voluntary Availability List'
      data = voluntaryData
      columns = voluntaryColumns
      options = this.getVoluntaryAvailabilityOptions()
    } else if (screenName === SCREEN_NAME_MANDATORY) {
      title = 'Mandatory Availability List'
      data = mandatoryData
      columns = mandatoryColumns
      options = this.getMandatoryAvailabilityOptions()
    } else if (screenName === SCREEN_NAME_TM_DIRECTORY) {
      title = 'Team Member Directory'
      data = directorySessionData
      columns = directoryColumns
      options = this.getTmDirectoryOptions()
    } else if (screenName === SCREEN_NAME_PTO_REQUEST) {
      title = 'Call In / Paid Time Off'
      data = ptoRequestsData
      columns = ptoRequestsColumns
      options = this.getPTORequestOptions()
    } else {
      return (
        <div>
          {!this.state.isDisabled && <p style={styles.message}>Please ensure location, date, and view are selected.</p>}
        </div>
      )
    }

    let viewableTable
    if (displayLoadingIcon) {
      viewableTable = this.renderLoadingSection(screenName, selectedDate, selectedEndDate)
    } else {
      if (refreshTable === refreshTableEnum.REFRESH) {
        this.props.setRefreshTable(refreshTableEnum.POSTWORK)
      } else {
        viewableTable = <MUIDataTable title={title} data={data} columns={columns} options={options} />
      }
    }
    return viewableTable
  }

  getDefaultSelectedDates = (today, selectedStartDate, selectedEndDate, fiscalDateRange) => {
    let selectedDateRange = {
      start: selectedStartDate,
      end: selectedEndDate,
    }
    if (!selectedStartDate) {
      if (this.context.user?.personaIncludes([PERSONAS.DC_HR_NE])) {
        selectedDateRange.start = startOfWeek(subWeeks(today, 2))
        selectedDateRange.end = endOfWeek(addWeeks(today, 1))
      } else if (this.context.user?.personaIncludes([PERSONAS.DC_LEADER_EX])) {
        selectedDateRange.start = subDays(today, 30)
        selectedDateRange.end = fiscalDateRange.end
      }
      this.handleStartDateClick(selectedDateRange.start)
      this.handleEndDateClick(selectedDateRange.end)
    }
    return selectedDateRange
  }

  renderDatePicker = () => {
    const { selectedDate, screenName, selectedLocationDetails, selectedEndDate } = this.props

    if (screenName && screenName !== SCREEN_NAME_TM_DIRECTORY) {
      let today = selectedLocationDetails.iso_time_zone_code
        ? getNowDateInTimezone(selectedLocationDetails.iso_time_zone_code)
        : getDateOfTodayWithNoTimestamp()

      if (screenName === SCREEN_NAME_PTO_REQUEST) {
        let fiscalDates = getFiscalYearStartEnd('leader')
        let selectedDates = this.getDefaultSelectedDates(today, selectedDate, selectedEndDate, fiscalDates)

        return (
          <React.Fragment>
            <Grid item sx={styles.timeOffRequestDateField}>
              <DateRangePicker
                minDate={TIME_OFF_MIN_DATE}
                maxDate={fiscalDates.end}
                defaultStartDate={selectedDates.start}
                defaultEndDate={selectedDates.end}
                maxDaysInRange={this.context.user.personaIncludes([PERSONAS.DC_HR_NE]) ? 28 : TIME_OFF_MAX_DAYS}
                handleOnChange={this.handleDateOnChange}
                onBlurMessage={true}
                containerSx={{ padding: '10px', justifyContent: 'space-evenly', alignItems: 'flex-start' }}
                textFieldSx={{ maxWidth: '150px' }}
                pickerJoinComponent={<Typography sx={{ fontSize: '16px', padding: '16px 8px 0px 8px' }}>to</Typography>}
              />
            </Grid>
          </React.Fragment>
        )
      } else {
        // Valid range for Voluntary & Mandatory is minus 13 to plus 4 weeks
        let minDate = subWeeks(startOfWeek(today), 13)
        let maxDate = addWeeks(endOfWeek(today), 4)

        return (
          <Grid item sx={styles.dateField}>
            <DatePicker
              name="Leader View Date"
              label="Date"
              minDate={minDate}
              maxDate={maxDate}
              defaultDate={selectedDate}
              value={selectedDate}
              handleDateChange={this.handleStartDateClick}
              textFieldSx={{ maxWidth: '150px' }}
            />
          </Grid>
        )
      }
    }
  }

  onKeyDown = (e) => {
    e.preventDefault()
  }

  renderLocationDropDown = () => {
    const { selectedLocationId, locations, screenName } = this.props

    if (screenName) {
      return (
        <Grid item>
          <TextField
            id="location"
            select
            label="Location"
            sx={styles.textFieldDropDown}
            value={selectedLocationId}
            onChange={(event) => this.handleLocationId(event.target.value)}
            margin="normal"
          >
            {locations.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )
    }
  }

  renderUpDownRadio = () => {
    const { upDownState, screenName } = this.props

    if (screenName && screenName !== SCREEN_NAME_TM_DIRECTORY && screenName !== SCREEN_NAME_PTO_REQUEST) {
      let labelPrefix = ''
      if (screenName === SCREEN_NAME_MANDATORY) {
        labelPrefix = 'Mandatory '
      } else if (screenName === SCREEN_NAME_VOLUNTARY) {
        labelPrefix = 'Voluntary '
      }
      return (
        <Grid item sx={styles.dateField}>
          <FormControl component="fieldset">
            <RadioGroup
              name="upDownState"
              value={upDownState}
              onChange={(event) => this.handleUpDownToggle(event.target.value)}
            >
              <FormControlLabel
                value="up"
                control={<Radio color="primary" />}
                label={labelPrefix + 'Up'}
                sx={styles.formControl}
              />
              <FormControlLabel
                value="down"
                control={<Radio color="primary" />}
                label={labelPrefix + 'Down'}
                sx={styles.formControl}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      )
    }
  }

  renderScreenRadio = () => {
    return (
      <Grid item>
        <FormControl component="fieldset">
          <RadioGroup
            name="pages"
            value={this.props.screenName}
            onChange={(event) => this.maybeWantToChangeScreens(event.target.value)}
          >
            <FormControlLabel
              value="tmDirectory"
              control={<Radio color="primary" />}
              label="Team Member Directory"
              sx={styles.formControl}
            />
            <FormControlLabel
              value="voluntary"
              disabled={this.state.isDisabled}
              control={<Radio color="primary" />}
              label="Voluntary Availability"
              sx={styles.formControl}
            />
            <FormControlLabel
              value="mandatory"
              disabled={this.state.isDisabled}
              control={<Radio color="primary" />}
              label="Mandatory Availability"
              sx={styles.formControl}
            />
            <FormControlLabel
              value={SCREEN_NAME_PTO_REQUEST}
              control={<Radio color="primary" />}
              label="Call In / Paid Time Off"
              sx={styles.formControl}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    )
  }

  renderSmallLoadingIcon = () => {
    const { screenName, selectedDate, selectedEndDate } = this.props

    if (screenName === SCREEN_NAME_PTO_REQUEST && (selectedDate === null || selectedEndDate === null)) {
      return <></>
    }
    return (
      <Grid item>
        <InnerLoaderWithText size={24} />
      </Grid>
    )
  }

  renderHeaderInfo = () => {
    let viewableHeader

    viewableHeader = (
      <Grid container>
        {this.state.isDisabled ? (
          <Grid item>
            <InnerLoaderWithText size={24} />
          </Grid>
        ) : (
          this.renderScreenRadio()
        )}
        {this.renderLocationDropDown()}
        {this.renderDatePicker()}
        {this.renderUpDownRadio()}
        {(this.props.ptoRequestsDataLoading ||
          this.props.mandatoryDataLoading ||
          this.props.voluntaryDataLoading ||
          this.props.directoryDataLoading) &&
          this.renderSmallLoadingIcon()}
      </Grid>
    )

    return viewableHeader
  }

  renderTimeOffRequestLeaderDialog = () => {
    const { selectedTimeOffRequest, timeOffRequestSaveDialogOpen } = this.props

    if (selectedTimeOffRequest && timeOffRequestSaveDialogOpen) {
      return <TimeOffRequestLeaderDialog />
    }
  }

  toggleRedirect = (bool) => {
    this.setState({ redirectOn: bool })
  }

  handleNavigationChange = () => {
    this.setScreenName(this.state.screenName)
  }

  render() {
    const {
      nextLeaderLocation,
      navigateToNextLeaderLocation,
      dialogChangedPage,
      popUpTextToChangeLocation,
      popUpTextToExit,
      popUpTextToChangeRadioView,
      popUpTextToChangeDate,
      ptoRequestsGetError,
      ptoRequestsDataLoading,
    } = this.props
    let viewableHeader = null
    let viewableTable = null
    let viewableErrorMessage = null
    let viewableExitDialog = null

    if (navigateToNextLeaderLocation) {
      return <Navigate to={nextLeaderLocation} />
    } else {
      viewableErrorMessage = this.renderLocationErrorMessage()
      if (!viewableErrorMessage) {
        viewableErrorMessage = this.renderErrorMessage()
        viewableHeader = <div style={styles.mainContainer}>{this.renderHeaderInfo()}</div>

        if (viewableErrorMessage === null) {
          viewableTable = <div style={styles.pageContainer}>{this.renderTableInfo()}</div>
        } else if (ptoRequestsGetError) {
          if (!ptoRequestsDataLoading && ERROR_CODES_SHOW_MESSAGE_TIME_OFF.includes(ptoRequestsGetError.code)) {
            viewableTable = <div style={styles.pageContainer}>{this.renderTableInfo()}</div>
          }
          if (ptoRequestsDataLoading) {
            viewableTable = <div style={styles.pageContainer}>{this.renderTableInfo()}</div>
            // don't display the informational error when user selects filter to
            // satisfy 'at least one filter selected' rule for OMs
            viewableErrorMessage = null
          }
        }
      }
    }

    viewableExitDialog = (
      <LeaderViewPromptContainer
        madeUpdates={this.checkForChanges()}
        popUpTextToChangeLocation={popUpTextToChangeLocation}
        popUpTextToExit={popUpTextToExit}
        popUpTextToChangeRadioView={popUpTextToChangeRadioView}
        popUpTextToChangeDate={popUpTextToChangeDate}
        dialogChangedPage={dialogChangedPage}
        handleNavigationChange={this.handleNavigationChange}
      />
    )

    return (
      <React.Fragment>
        <HeaderTitle title="Leader View" />
        {this.state.redirectOn && (
          <RedirectNavigation screenName={this.state.screenName} toggleRedirect={(bool) => this.toggleRedirect(bool)} />
        )}
        {viewableHeader}
        {viewableErrorMessage}
        {viewableTable}
        {viewableExitDialog}
        {this.renderTimeOffRequestLeaderDialog()}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setScreenName,
      setSelectedLocationId,
      setSelectedDate,
      setSelectedEndDate,
      setVoluntaryDataSelectedFilters,
      setVoluntaryDataLoading,
      setVoluntaryDataSearch,
      setUpDownState,
      setVoluntaryDataSort,
      setVoluntaryDataPage,
      setVoluntaryDataPerPage,
      loadGetLeaderAvailabilityFiltersRequest,
      loadGetWorkersAvailabilityPreferencesRequest,
      loadGetLocationsByWorkerIdRequest,
      setVoluntaryDataTableStateToDefault,
      setVoluntaryDataTableFiltersToDefault,
      setVoluntaryDataTableColumnsToDefault,
      setVoluntaryColumnView,
      setDirectoryDataTableStateToDefault,
      setDirectoryDataTableFiltersToDefault,
      setDirectoryDataTableColumnsToDefault,
      setDirectoryDataSelectedFilters,
      setDirectoryDataLoading,
      setLeaderScreenUneditable,
      setDirectoryDataSearch,
      setDirectoryDataSort,
      setDirectoryDataPage,
      setDirectoryDataPerPage,
      setDirectoryColumnView,
      loadGetLeaderDirectoryFilters,
      loadGetWorkersDirectoryRequest,

      addDirectorySessionData,
      addSelectedDirectoryIndex,
      removeSelectedDirectoryIndex,
      setVoluntaryPostResponse,
      setVoluntaryPostError,
      setVoluntaryPostResponseIneligibilities,
      setSaveVoluntaryDialogOpen,
      setShouldLoadVoluntaryData,
      showNotificationSuccess,
      showNotificationError,
      setDirectoryPostResponse,
      setDirectoryPostError,
      loadGetLeaderMandatoryAvailabilityFiltersRequest,
      loadGetWorkersMandatoryAvailabilityPreferencesRequest,
      setMandatoryDataSelectedFilters,
      setExitLeaderDialogOpen,
      setMandatoryDataTableStateToDefault,
      setMandatoryDataTableFiltersToDefault,
      setMandatoryDataTableColumnsToDefault,
      setMandatoryDataLoading,
      setMandatoryDataSearch,
      setMandatoryDataSort,
      setMandatoryDataPage,
      setMandatoryDataPerPage,
      setMandatoryColumnView,
      setSaveMandatoryDialogOpen,
      setMandatoryPostResponseIneligibilities,
      setMandatoryPostResponse,
      setShouldLoadMandatoryData,

      setPTORequestsDataTableFiltersToDefault,
      setPTORequestsDataTableColumnsToDefault,
      setPTORequestsDataTableStateToDefault,
      loadGetWorkersPTORequestsRequest,
      setPTORequestsDataPerPage,
      setPTORequestsDataPage,
      setPTORequestsDataSearch,

      setPTORequestsDataLoading,
      setPTORequestsDataSort,
      openTimeOffRequestDialog,
      setPTORequestsDataSelectedFilters,
      loadGetLeaderPTORequestFilters,
      setTimeOffRequestDataError,
      setTimeOffRequestDataResponse,
      setPTORequestsColumnView,

      setNextLeaderLocation,
      setNavigateToNextLeaderLocation,
      handleUpdateMandatoryLeaderDecisionsSuccess,
      handleUpdateMandatoryLeaderDecisionsError,
      getLocationDetails,
      setLocationLoading,
      setDesiredScreenName,
      setDialogChangedPage,
      setAreUnsavedChanges,
      setDesiredSelectedDate,
      setDesiredLocationID,
      setPopUpTextToChangeLocation,
      setPopUpTextToExit,
      setPopUpTextToChangeRadioView,
      setPopUpTextToChangeDate,
      setDisplayLoadingIcon,
      setRefreshTable,
    },
    dispatch,
  )

const mapStateToProps = (state) => {
  return {
    screenName: state.leaderViewAutomation.screenName,
    selectedLocationId: state.leaderViewAutomation.selectedLocationId,
    locations: state.leaderViewAutomation.locations,
    selectedDate: state.leaderViewAutomation.selectedDate,
    selectedEndDate: state.leaderViewAutomation.selectedEndDate,
    upDownState: state.leaderViewAutomation.upDownState,
    selectedLocationDetails: state.leaderViewAutomation.locationDetails,
    selectedLocationDetailsGetError: state.leaderViewAutomation.locationDetailsGetError,
    selectedLocationDetailsLoading: state.leaderViewAutomation.locationLoading,
    locationGetError: state.leaderViewAutomation.getError,
    exitLeaderDialogOpen: state.leaderViewAutomation.exitLeaderDialogOpen,
    nextLeaderLocation: state.leaderViewAutomation.nextLeaderLocation,
    navigateToNextLeaderLocation: state.leaderViewAutomation.navigateToNextLeaderLocation,
    desiredScreenName: state.leaderViewAutomation.desiredScreenName,
    dialogChangedPage: state.leaderViewAutomation.dialogChangedPage,
    thereAreUnsavedChanges: state.leaderViewAutomation.thereAreUnsavedChanges,
    desiredSelectedDate: state.leaderViewAutomation.desiredSelectedDate,
    desiredLocationID: state.leaderViewAutomation.desiredLocationID,
    popUpTextToChangeLocation: state.leaderViewAutomation.popUpTextToChangeLocation,
    popUpTextToExit: state.leaderViewAutomation.popUpTextToExit,
    popUpTextToChangeRadioView: state.leaderViewAutomation.popUpTextToChangeRadioView,
    popUpTextToChangeDate: state.leaderViewAutomation.popUpTextToChangeDate,
    displayLoadingIcon: state.leaderViewAutomation.displayLoadingIcon,
    refreshTable: state.leaderViewAutomation.refreshTable,

    voluntaryColumns: state.leaderViewVoluntaryAutomation.voluntaryColumns,
    voluntaryDownColumns: state.leaderViewVoluntaryAutomation.voluntaryDownColumns,
    voluntaryPrevRequestedSelectedFilters: state.leaderViewVoluntaryAutomation.voluntaryPrevRequestedSelectedFilters,
    voluntaryDataSelectedFilters: state.leaderViewVoluntaryAutomation.voluntaryDataSelectedFilters,
    voluntaryData: state.leaderViewVoluntaryAutomation.voluntaryData,
    voluntaryDataLoading: state.leaderViewVoluntaryAutomation.voluntaryDataLoading,
    voluntaryDataSaving: state.leaderViewVoluntaryAutomation.voluntaryDataSaving,
    voluntaryPrevRequestedSearch: state.leaderViewVoluntaryAutomation.voluntaryPrevRequestedSearch,
    voluntaryDataSearch: state.leaderViewVoluntaryAutomation.voluntaryDataSearch,
    voluntaryDataSort: state.leaderViewVoluntaryAutomation.voluntaryDataSort,
    voluntaryDataPage: state.leaderViewVoluntaryAutomation.voluntaryDataPage,
    voluntaryDataPerPage: state.leaderViewVoluntaryAutomation.voluntaryDataPerPage,
    voluntaryDataTotalRecords: state.leaderViewVoluntaryAutomation.voluntaryDataTotalRecords,
    voluntaryPostResponse: state.leaderViewVoluntaryAutomation.voluntaryPostResponse,
    voluntaryPostError: state.leaderViewVoluntaryAutomation.voluntaryPostError,
    voluntaryGetError: state.leaderViewVoluntaryAutomation.voluntaryGetError,
    voluntaryFiltersGetError: state.leaderViewVoluntaryAutomation.voluntaryFiltersGetError,
    shouldLoadVoluntaryData: state.leaderViewVoluntaryAutomation.shouldLoadVoluntaryData,

    directoryColumns: state.leaderViewDirectoryAutomation.directoryColumns,
    directoryPrevRequestedSelectedFilters: state.leaderViewDirectoryAutomation.directoryPrevRequestedSelectedFilters,
    directoryDataSelectedFilters: state.leaderViewDirectoryAutomation.directoryDataSelectedFilters,
    directoryData: state.leaderViewDirectoryAutomation.directoryData,
    directoryDataLoading: state.leaderViewDirectoryAutomation.directoryDataLoading,
    directoryDataSaving: state.leaderViewDirectoryAutomation.directoryDataSaving,
    directoryPrevRequestedSearch: state.leaderViewDirectoryAutomation.directoryPrevRequestedSearch,
    directoryDataSearch: state.leaderViewDirectoryAutomation.directoryDataSearch,
    directoryDataSort: state.leaderViewDirectoryAutomation.directoryDataSort,
    directoryDataPage: state.leaderViewDirectoryAutomation.directoryDataPage,
    directoryDataPerPage: state.leaderViewDirectoryAutomation.directoryDataPerPage,
    directoryDataTotalRecords: state.leaderViewDirectoryAutomation.directoryDataTotalRecords,
    directorySessionData: state.leaderViewDirectoryAutomation.directorySessionData,
    selectedDirectoryIndexes: state.leaderViewDirectoryAutomation.selectedDirectoryIndexes,
    directoryPostResponse: state.leaderViewDirectoryAutomation.postResponse,
    directoryFiltersGetError: state.leaderViewDirectoryAutomation.directoryFiltersGetError,
    directoryGetError: state.leaderViewDirectoryAutomation.directoryGetError,
    directoryPostError: state.leaderViewDirectoryAutomation.directoryPostError,

    mandatoryColumns: state.leaderViewMandatoryAutomation.mandatoryColumns,
    mandatoryPrevRequestedSelectedFilters: state.leaderViewMandatoryAutomation.mandatoryPrevRequestedSelectedFilters,
    mandatoryDataSelectedFilters: state.leaderViewMandatoryAutomation.mandatoryDataSelectedFilters,
    mandatoryData: state.leaderViewMandatoryAutomation.mandatoryData,
    mandatoryDataLoading: state.leaderViewMandatoryAutomation.mandatoryDataLoading,
    mandatoryDataSaving: state.leaderViewMandatoryAutomation.mandatoryDataSaving,
    mandatoryPrevRequestedSearch: state.leaderViewMandatoryAutomation.mandatoryPrevRequestedSearch,
    mandatoryDataSearch: state.leaderViewMandatoryAutomation.mandatoryDataSearch,
    mandatoryDataSort: state.leaderViewMandatoryAutomation.mandatoryDataSort,
    mandatoryColumnSortBy: state.leaderViewMandatoryAutomation.mandatoryColumnSortBy,
    mandatoryDataPage: state.leaderViewMandatoryAutomation.mandatoryDataPage,
    mandatoryDataPerPage: state.leaderViewMandatoryAutomation.mandatoryDataPerPage,
    mandatoryDataTotalRecords: state.leaderViewMandatoryAutomation.mandatoryDataTotalRecords,
    mandatoryPostResponse: state.leaderViewMandatoryAutomation.mandatoryPostResponse,
    mandatoryPostError: state.leaderViewMandatoryAutomation.mandatoryPostError,
    mandatoryGetPreferencesError: state.leaderViewMandatoryAutomation.mandatoryGetPreferencesError,
    mandatoryGetFiltersError: state.leaderViewMandatoryAutomation.mandatoryGetFiltersError,
    shouldLoadMandatoryData: state.leaderViewMandatoryAutomation.shouldLoadMandatoryData,

    ptoRequestsColumns: state.leaderViewPTORequestsAutomation.ptoRequestsColumns,
    ptoRequestsDataLoading: state.leaderViewPTORequestsAutomation.ptoRequestsDataLoading,
    ptoRequestsData: state.leaderViewPTORequestsAutomation.ptoRequestsData,
    workerTimeOffRequests: state.leaderViewPTORequestsAutomation.workerTimeOffRequests,
    ptoRequestsDataPerPage: state.leaderViewPTORequestsAutomation.ptoRequestsDataPerPage,
    ptoRequestsDataTotalRecords: state.leaderViewPTORequestsAutomation.ptoRequestsDataTotalRecords,
    ptoRequestsDataSaving: state.leaderViewPTORequestsAutomation.ptoRequestsDataSaving,
    ptoRequestsDataSelectedFilters: state.leaderViewPTORequestsAutomation.ptoRequestsDataSelectedFilters,
    ptoRequestsDataPage: state.leaderViewPTORequestsAutomation.ptoRequestsDataPage,
    ptoRequestsDataSearch: state.leaderViewPTORequestsAutomation.ptoRequestsDataSearch,
    ptoRequestsPrevRequestedSearch: state.leaderViewPTORequestsAutomation.ptoRequestsPrevRequestedSearch,
    ptoRequestFiltersGetError: state.leaderViewPTORequestsAutomation.ptoRequestFiltersGetError,
    ptoRequestsGetError: state.leaderViewPTORequestsAutomation.ptoRequestsGetError,
    ptoRequestsPrevRequestedSelectedFilters:
      state.leaderViewPTORequestsAutomation.ptoRequestsPrevRequestedSelectedFilters,
    timeOffRequestPostResponse: state.leaderViewPTORequestsAutomation.timeOffRequestPostResponse,
    timeOffRequestPostError: state.leaderViewPTORequestsAutomation.timeOffRequestPostError,
    ptoRequestsDataSort: state.leaderViewPTORequestsAutomation.ptoRequestsDataSort,
    ptoRequestsColumnSortBy: state.leaderViewPTORequestsAutomation.ptoRequestsColumnSortBy,
    selectedTimeOffRequest: state.leaderViewPTORequestsAutomation.selectedTimeOffRequest,
    timeOffRequestSaveDialogOpen: state.leaderViewPTORequestsAutomation.timeOffRequestSaveDialogOpen,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withAuth()(LeaderViewAutomation))
