import { useAuth } from '@praxis/component-auth'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useUser } from '../../auth/hooks/useUser'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

const getManagementScopes = async (workerId) => {
  let apiUrl =
    `${apiConfig.userAccess.url}` +
    '/management_scopes' +
    '?worker_id=' +
    workerId +
    '&key=' +
    `${apiConfig.userAccess.key}`

  return axios.get(apiUrl, BASIC_REQUEST_CONFIG)
}

export function useManagementScopes() {
  const { isAuthenticated, login } = useAuth()
  const authenticated = isAuthenticated()
  const user = useUser()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  return useQuery({
    queryKey: ['managementScopes'],
    queryFn: () => getManagementScopes(user.userData.worker_id),
    staleTime: 1000 * 60 * 10,
    enabled: authenticated,
    retry: 4,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
    onError: (error) => {
      const loggingInfo = {
        message: 'GET /management_scopes response has an error.',
        url: window.location.href,
        error: {
          url: error?.config?.url,
          data: error?.response?.data,
          headers: error?.response?.headers,
          status: error?.response?.status,
          statusText: error?.response?.statusText,
        },
      }
      const optionalLoggingOptions = {
        level: LogLevel.Error,
      }
      logEvent(loggingInfo, optionalLoggingOptions)
    },
  })
}
