import { useAuth } from '@praxis/component-auth'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../auth/hooks/useUser'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import {
  ERROR_CODES_SHOW_MESSAGE,
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
  SUCCESS_ON_SAVE,
  UNABLE_TO_SAVE,
} from '../../constants/errorConstants'
import { showNotificationError, showNotificationSuccess } from '../../store/notification/actionCreator'
import { clearRequest } from '../../store/requestTimeOff/actionCreator'
import { formatErrorCode } from '../../utils/ErrorHandling'

async function postRequestsData(requestDetails, comment, workerId, locationId) {
  const requestBody = {
    worker_id: workerId,
    location: locationId,
    request_details: requestDetails,
    comment: comment,
  }

  if (!/\S/.test(comment)) {
    // comment is empty or only contains whitespace
    delete requestBody.comment
  }

  let apiUrl = `${apiConfig.timeOff.url}` + '/time_off_requests' + '?key=' + `${apiConfig.timeOff.key}`

  const { data } = await axios.post(apiUrl, requestBody, BASIC_REQUEST_CONFIG)

  return data
}

export default function usePostRequest() {
  const { isAuthenticated, login } = useAuth()
  const { userData, locationData } = useUser()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  const dispatch = useDispatch()

  return useMutation(
    ({ requestDetails, comment }) =>
      postRequestsData(requestDetails, comment, userData.worker_id, locationData.location_id),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['requests'])
        dispatch(showNotificationSuccess(true, SUCCESS_ON_SAVE))
        dispatch(clearRequest())
        navigate(-1)
      },
      onError: (error) => {
        if (ERROR_CODES_SHOW_MESSAGE.includes(error?.response?.data.code)) {
          dispatch(showNotificationError(true, error?.response?.data.message))
        } else {
          dispatch(
            showNotificationError(
              true,
              UNABLE_TO_SAVE + ' ' + formatErrorCode(error?.response?.data, ERROR_CODE_CANNOT_CONNECT_TO_SERVER),
            ),
          )
        }
      },
    },
  )
}
