import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useUser } from '../../auth/hooks/useUser'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

const getWorkerDetails = async (workerId, locationId) => {
  let apiUrl =
    `${apiConfig.worker.url}` +
    '/worker_details' +
    '?key=' +
    `${apiConfig.worker.key}` +
    '&worker_ids=' +
    workerId +
    '&location_id=' +
    locationId

  const { data } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)

  return data
}

export function useWorkerDetails() {
  const { isAuthenticated, login } = useAuth()
  const user = useUser()

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  return useQuery({
    queryKey: ['worker'],
    queryFn: () => getWorkerDetails(user.userData.worker_id, user.locationData.location_id),
    staleTime: 1000 * 30,
    enabled: isAuthenticated(),
  })
}
