import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useUser } from '../../../auth/hooks/useUser'
import { CALL_OFF_TYPE_DROPDOWN, REQUEST_SCREEN_TITLES } from '../../../constants/RequestConstants'
import { LOCATION_TYPE_STORE } from '../../../constants/locationConstants'
import InnerLoader from '../../Loader/InnerLoader'
import RequestTimeOffRowInput from '../RequestTimeOffRowInput'

const RequestCallOffDetail = (props) => {
  const { rowDetail, handleRowDetail, rowDetailError } = props
  const { locationData } = useUser()

  const renderErrorMessage = () => {
    if (rowDetailError) {
      return (
        <Typography
          data-cy={`requestCallInCallOffDetail_error_message`}
          color={'primary'}
          sx={{ padding: '0px 16px 11px 16px' }}
          value={rowDetailError}
        >
          {rowDetailError}
        </Typography>
      )
    }
  }

  const renderDetail = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          data-cy={`requestCallInCallOffDetail_grid`}
          sx={{ padding: '10px' }}
          wrap="nowrap"
        >
          {!locationData.location_type ? (
            <InnerLoader />
          ) : (
            <RequestTimeOffRowInput
              rowDetail={rowDetail}
              handleRowDetail={handleRowDetail}
              requestTypes={CALL_OFF_TYPE_DROPDOWN[locationData.location_type]}
              screenTitle={REQUEST_SCREEN_TITLES.NOTIFY_CALL_OFF}
              disableManualInput={locationData.location_type === LOCATION_TYPE_STORE}
            />
          )}
        </Grid>
        {renderErrorMessage()}
      </>
    )
  }

  return renderDetail()
}

export default RequestCallOffDetail
