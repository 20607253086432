import { Divider } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../../../../auth/hooks/useUser'
import { CATEGORY_FEATURE_MAP, FAILED, PUNCH_CORRECTION } from '../../../../../constants/RequestConstants'
import { setControlPanelSettings } from '../../../../../store/leader/actionCreator'
import TotalTimeOffRequestsByStatusButton from '../../TotalTimeOffRequestsByStatusButton'

const PunchCorrectionPartialLeaderDailyActions = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { configMap } = useUser().leaderConfig

  const handleTimeOffRequestsButtonClick = (statuses) => {
    let filterOverrides = { statuses: { selectedValues: statuses } }

    dispatch(
      setControlPanelSettings(
        PUNCH_CORRECTION,
        configMap[PUNCH_CORRECTION].defaultStartDate,
        configMap[PUNCH_CORRECTION].defaultEndDate,
        filterOverrides,
        configMap[PUNCH_CORRECTION].searchText,
        configMap[PUNCH_CORRECTION].selectedSortByIndex,
        configMap[PUNCH_CORRECTION].sortOrder,
      ),
    )
    navigate('/leader/myteam')
  }

  return (
    <>
      <Divider aria-hidden="true" />
      <TotalTimeOffRequestsByStatusButton
        status={FAILED}
        startDate={configMap[PUNCH_CORRECTION].defaultStartDate}
        endDate={configMap[PUNCH_CORRECTION].defaultEndDate}
        handleButtonClick={() => handleTimeOffRequestsButtonClick(['Failed'])}
        buttonMessage={'Failed Punch Correction Requests'}
        selectedCategory={CATEGORY_FEATURE_MAP.get(PUNCH_CORRECTION)}
        categoryName={PUNCH_CORRECTION}
      />
    </>
  )
}

export default PunchCorrectionPartialLeaderDailyActions
