import { SearchOutlined } from '@mui/icons-material'
import { IconButton, InputAdornment, MenuItem, Paper, TextField, useTheme } from '@mui/material'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import 'date-fns'
import deburr from 'lodash/deburr'
import { useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '../../auth/hooks/useUser'
import { getTeamMembers } from '../../store/swapShift/actionCreator'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'

const hideTMChar = false

const getStyles = (theme) => ({
  root: {
    height: 50,
    flexGrow: 1,
    marginTop: '10px',
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
})

const AutoCompleteTME = (props) => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const user = useUser()
  const { handleTMChange } = props
  const [single, setSingle] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [suggestionsArr, setSuggestionsArr] = useState([])
  const fetchedTMs = useSelector((state) => state.swapShift.teamMembers)

  const dispatch = useDispatch()

  useEffect(() => {
    setSuggestions([])
    setSuggestionsArr([])
  }, [])

  useEffect(() => {
    let workerArr
    let tmArr = []
    workerArr = fetchedTMs?.worker_details
    if (workerArr) {
      for (let workerObj of workerArr) {
        let tmObj = {}
        if (hideTMChar) {
          tmObj.label = `${workerObj.short_name}`
        } else {
          tmObj.label = `${workerObj.short_name}`
        }
        tmObj.tmNumber = workerObj.worker_id
        if (padEmpIdWithZeros(tmObj.tmNumber, 10) !== user.userData.worker_id) {
          tmArr.push(tmObj)
        }
      }
      setSuggestions(tmArr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedTMs])

  useEffect(() => {
    if (suggestions.length === 0) {
      setSuggestionsArr([])
    } else {
      setSuggestionsArr(getSuggestions(single))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions])

  const renderInputComponent = (inputProps) => {
    const { inputRef = () => {}, ref, ...other } = inputProps

    return (
      <TextField
        data-cy="tm-search-input"
        className={'errorCls'}
        // required
        fullWidth
        InputProps={{
          inputRef: (node) => {
            ref(node)
            inputRef(node)
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="large">
                <SearchOutlined />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...other}
      />
    )
  }

  let count = 0
  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    count++
    const matches = match(suggestion.label, query, { insideWords: true })
    const parts = parse(suggestion.label, matches)

    return (
      <MenuItem selected={isHighlighted} component="div" data-cy={`selectedTmName-${count}`}>
        <div>
          {parts.map((part, index) =>
            !hideTMChar && part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            ),
          )}
        </div>
      </MenuItem>
    )
  }

  const fetchTeamMembers = (inputValue) => {
    if (inputValue.length > 0) {
      let searchTerm = inputValue.toLowerCase()
      dispatch(getTeamMembers(user.locationData.location_id, searchTerm))
    } else {
      handleTMChange('')
    }
  }

  const getSuggestions = (value) => {
    const inputValue = deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length

    let count = 0

    return inputLength === 0
      ? []
      : suggestions.filter((suggestion) => {
          const keep = count < 5

          if (keep) {
            count += 1
          }
          return keep
        })
  }

  function getSuggestionValue(suggestion) {
    return suggestion.label
  }

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestionsArr(getSuggestions(value))
  }

  const handleSuggestionsClearRequested = () => {
    setSuggestionsArr([])
  }

  const handleChange =
    (name) =>
    (event, { newValue }) => {
      setSingle(newValue)
      fetchTeamMembers(newValue)
    }

  const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    handleTMChange(suggestion.tmNumber)
  }

  const autosuggestProps = {
    renderInputComponent,
    suggestions: suggestionsArr,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
    onSuggestionSelected,
    focusInputOnSuggestionClick: false,
  }

  return (
    <div style={styles.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          label: 'search a team member',
          placeholder: 'short name/last 4 digit TM number',
          value: single,
          onChange: handleChange('single'),
        }}
        theme={{
          container: styles.container,
          suggestionsContainerOpen: styles.suggestionsContainerOpen,
          suggestionsList: styles.suggestionsList,
          suggestion: styles.suggestion,
        }}
        renderSuggestionsContainer={(options) => {
          return (
            <Paper {...options.containerProps} key={options.containerProps.key} square>
              {options.children}
            </Paper>
          )
        }}
      />
    </div>
  )
}

export default AutoCompleteTME
