import axios from 'axios'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import {
  ACCEPTER_SWAP_SHIFT_REQUEST_POST_ERROR,
  ACCEPTER_SWAP_SHIFT_REQUEST_POST_SUCCESS,
  CLEAR_ACCEPTER_REQUEST,
  CLEAR_INITIATE_POST,
  CLEAR_SHIFTS_RESULTS,
  GET_SHIFTS_ERROR,
  GET_SHIFTS_SUCCESS,
  GET_TEAM_MEMBER_SEARCH_RESULTS_ERROR,
  GET_TEAM_MEMBER_SEARCH_RESULTS_SUCCESS,
  INITIATE_SWAP_SHIFT_REQUEST_ERROR,
  INITIATE_SWAP_SHIFT_REQUEST_SUCCESS,
  STORE_REQUESTER_CURRENT_SHIFT,
  STORE_REVIEWER_CURRENT_SHIFT,
} from './actionType'

export function getShiftsForSwap(
  locationId,
  requesterTMNumber,
  teamMemberNumber,
  jobs,
  shiftLength,
  shiftStartsAfter,
  shiftEndsBefore,
  requesterShiftDate,
  requesterShiftStart,
  requesterShiftEnd,
  requesterOrgPath,
) {
  let apiUrl =
    `${apiConfig.shiftswap.url}` +
    '/shift_swap_schedules' +
    '?location_id=' +
    locationId +
    '&requester_worker_id=' +
    requesterTMNumber +
    jobs +
    '&worker_ids=' +
    teamMemberNumber +
    '&shift_length=' +
    shiftLength +
    '&shift_starts_after=' +
    shiftStartsAfter +
    '&shift_ends_before=' +
    shiftEndsBefore +
    '&requester_shift_date=' +
    requesterShiftDate +
    '&requester_shift_start=' +
    requesterShiftStart +
    '&requester_shift_end=' +
    requesterShiftEnd +
    '&requester_org_path=' +
    requesterOrgPath +
    '&key=' +
    `${apiConfig.shiftswap.key}`

  return (dispatch) => {
    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(getShiftsSuccess(response))
      })
      .catch((response) => {
        dispatch(getShiftsError(response))
      })
  }
}

export function getShiftsSuccess(record) {
  return {
    type: GET_SHIFTS_SUCCESS,
    record,
  }
}

export function getShiftsError(record) {
  return {
    type: GET_SHIFTS_ERROR,
    record,
  }
}

export function clearShiftResults() {
  return {
    type: CLEAR_SHIFTS_RESULTS,
  }
}

export function clearInitiatePostRequests() {
  return {
    type: CLEAR_INITIATE_POST,
  }
}

export function clearAccepterRequests() {
  return {
    type: CLEAR_ACCEPTER_REQUEST,
  }
}

export function initiateRequestToSwapRequest(requestBody) {
  let apiUrl = `${apiConfig.shiftswap.url}` + '/shift_swap_requests' + '?key=' + `${apiConfig.shiftswap.key}`

  return (dispatch) => {
    return axios
      .post(apiUrl, requestBody, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(initiateSwapShiftRequestSuccess(response))
      })
      .catch((response) => {
        dispatch(initiateSwapShiftRequestError(response))
      })
  }
}

export function initiateSwapShiftRequestSuccess(record) {
  return {
    type: INITIATE_SWAP_SHIFT_REQUEST_SUCCESS,
    record,
  }
}

export function initiateSwapShiftRequestError(record) {
  return {
    type: INITIATE_SWAP_SHIFT_REQUEST_ERROR,
    record,
  }
}

/* Accepter POST Call Starts here*/
export function accepterSwapRequestPost(requestBody) {
  let apiUrl = `${apiConfig.shiftswap.url}` + '/shift_swap_requests/accepts' + '?key=' + `${apiConfig.shiftswap.key}`

  return (dispatch) => {
    return axios
      .post(apiUrl, requestBody, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(accepterSwapRequestPostSuccess(response))
      })
      .catch((response) => {
        dispatch(accepterSwapRequestPostError(response))
      })
  }
}

export function accepterSwapRequestPostSuccess(record) {
  return {
    type: ACCEPTER_SWAP_SHIFT_REQUEST_POST_SUCCESS,
    record,
  }
}

export function accepterSwapRequestPostError(record) {
  return {
    type: ACCEPTER_SWAP_SHIFT_REQUEST_POST_ERROR,
    record,
  }
}

/* Accepter POST Call Ends here*/

export function getTeamMembers(locationId, searchableName) {
  let apiUrl =
    `${apiConfig.worker.url}` +
    '/worker_details?' +
    'location_id=' +
    locationId +
    '&page=1' +
    '&per_page=5' +
    // '&searchable_name=' +
    '&short_name=' +
    searchableName +
    '&key=' +
    `${apiConfig.worker.key}`

  return (dispatch) => {
    // let apiUrl = `${apiConfig.shiftswap.url}/tm_search_results.json`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(getTeamMembersSuccess(response))
      })
      .catch((response) => {
        dispatch(getTeamMembersError(response))
      })
  }
}

export function getTeamMembersSuccess(record) {
  return {
    type: GET_TEAM_MEMBER_SEARCH_RESULTS_SUCCESS,
    record,
  }
}

export function getTeamMembersError(record) {
  return {
    type: GET_TEAM_MEMBER_SEARCH_RESULTS_ERROR,
    record,
  }
}

export function storeRequesterCurrentShift(currentShift) {
  return {
    type: STORE_REQUESTER_CURRENT_SHIFT,
    currentShift,
  }
}

export function storeReviewerCurrentShift(currentShift) {
  return {
    type: STORE_REVIEWER_CURRENT_SHIFT,
    currentShift,
  }
}
