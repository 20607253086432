import { useContext } from 'react'
import { User } from '../User'
import UserContext from '../UserContext'

export const useUser = (): User => {
  const user = useContext(UserContext)

  if (user === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }

  return user.user
}
