import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useUser } from '../../../auth/hooks/useUser'
import { REQUEST_SCREEN_TITLES } from '../../../constants/RequestConstants'
import { LOCATION_TYPE_STORE } from '../../../constants/locationConstants'
import RequestTimeOffRowInput from '../RequestTimeOffRowInput'

const RequestCallInDetail = (props) => {
  const { rowDetail, handleRowDetail, rowDetailError } = props
  const { locationData } = useUser()

  const renderErrorMessage = () => {
    if (rowDetailError) {
      return (
        <Typography
          data-cy={`requestCallInCallOffDetail_error_message`}
          color={'primary'}
          sx={{ padding: '0px 16px 11px 16px' }}
          value={rowDetailError}
        >
          {rowDetailError}
        </Typography>
      )
    }
  }

  const renderDetail = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          data-cy={`requestCallInCallOffDetail_grid`}
          sx={{ padding: '11px 16px 11px 16px' }}
        >
          <RequestTimeOffRowInput
            rowDetail={rowDetail}
            handleRowDetail={handleRowDetail}
            requestTypes={null}
            screenTitle={REQUEST_SCREEN_TITLES.NOTIFY_CALL_IN}
            disableManualInput={locationData.location_type === LOCATION_TYPE_STORE}
          />
        </Grid>
        {renderErrorMessage()}
      </>
    )
  }

  return renderDetail()
}

export default RequestCallInDetail
