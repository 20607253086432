import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { groupBy } from 'lodash'
import { useUser } from '../../auth/hooks/useUser'
import apiConfig from '../../config/apiConfig'
import {
  AVAILABLE_SHIFT_TYPE_ALL,
  AVAILABLE_SHIFT_TYPE_OPEN_SHIFT,
  AVAILABLE_SHIFT_TYPE_REQUEST_TO_COVER,
} from '../../constants/availableShiftConstants'

const locationsToRemove = [
  '1309',
  '2479',
  '2774',
  '2775',
  '3247',
  '2776',
  '3427',
  '3216',
  '3346',
  '3217',
  '1809',
  '3218',
  '3263',
  '3231',
  '3288',
  '1408',
  '3294',
  '3285',
  '0637',
  '1284',
  '2705',
  '2786',
  '3347',
  '2766',
  '3345',
  '2768',
  '3202',
  '3264',
  '2124',
  '3267',
  '3359',
  '0183',
  '0228',
  '2418',
  '0288',
  '0294',
  '1443',
  '0299',
  '3371',
  '1306',
  '3206',
  '1307',
  '3262',
  '2020',
  '2143',
  '3393',
  '3227',
  '2329',
]
const getAvailableShiftType = (user) => {
  if (
    user.is('open_shifts') &&
    user.is('request_to_cover') &&
    !locationsToRemove.includes(user.locationData.location_id)
  ) {
    return AVAILABLE_SHIFT_TYPE_ALL
  }
  if (user.is('open_shifts') && !locationsToRemove.includes(user.locationData.location_id)) {
    return AVAILABLE_SHIFT_TYPE_OPEN_SHIFT
  }
  if (user.is('request_to_cover')) {
    return AVAILABLE_SHIFT_TYPE_REQUEST_TO_COVER
  }
  return null
}

const getAvailableShifts = async (tmNumber, locationIds, startDate, endDate, pageOrigin, availableShiftType) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Page-Origin': pageOrigin,
    },
  }

  let apiUrl =
    `${apiConfig.availableShifts.url}` +
    '/available_shifts' +
    '?worker_id=' +
    tmNumber +
    '&location_ids=' +
    locationIds +
    '&start_date=' +
    startDate +
    '&end_date=' +
    endDate +
    '&page_origin=' +
    pageOrigin +
    (availableShiftType ? `&available_shift_type=${availableShiftType}` : '') +
    '&key=' +
    `${apiConfig.availableShifts.key}`

  const { data } = await axios.get(apiUrl, requestConfig)

  for (let shift of data.available_shifts) {
    shift.shift_date_with_no_time = shift.shift_start.split('T')[0]
    if (shift.jobs && shift.jobs.length > 0) {
      shift.jobList = []
      shift.jobs.forEach((job, elemIndex) => {
        if (elemIndex !== 0) {
          let lastIndex = job.lastIndexOf('/')
          shift.jobList.push(job.substr(lastIndex + 1, job.length))
        }
      })
    }
  }

  data.available_shifts_by_date = groupBy(data.available_shifts, 'shift_date_with_no_time')

  return data
}

/**
 * This hook provides caller with status and data objects to use when rendering available shifts data.
 * @param {String} pageOrigin e.g. 'AVAILABLE_SHIFTS'
 * @param {String} startDate e.g. 2021-10-17
 * @param {String} endDate e.g. 2021-10-23
 * @param {Array} locationIds e.g. [1234, 2345]
 * @returns {UseQueryResults} with state information about the request.
 */
export function useAvailableShifts(pageOrigin, startDate, endDate, locationIds = []) {
  const { isAuthenticated, login } = useAuth()
  const user = useUser()

  let availableShiftType = getAvailableShiftType(user)

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  if (locationIds.length === 0 && pageOrigin === 'HOME_PAGE') {
    locationIds.push(user.locationData.location_id)
  }

  let locationsToSend = locationIds.sort().map((location) => {
    return location.toString().padStart(4, '0')
  })

  return useQuery(
    ['availableShifts', { startDate, endDate, locationsToSend }],
    () =>
      getAvailableShifts(user.userData.worker_id, locationsToSend, startDate, endDate, pageOrigin, availableShiftType),
    {
      staleTime: 1000 * 30,
      keepPreviousData: true,
      enabled: locationIds.length !== 0 && isAuthenticated(),
    },
  )
}
