import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import { useUser } from '../../../auth/hooks/useUser'
import MyProtectedElement from '../../../auth/MyProtectedElement'
import GenericCard from '../../common/cards/GenericCard'
import CallInAtAGlance from './Categories/CallIn/CallInAtAGlance'
import CallOffAtAGlance from './Categories/CallOff/CallOffAtAGlance'

const AtAGlanceCard = () => {
  const user = useUser()

  return (
    <MyProtectedElement allowed={['call_in', 'call_off']} view={'leader'} renderUnauthorized={() => <></>}>
      <GenericCard title="At A Glance" icon={<AssignmentIndOutlinedIcon />}>
        <div style={{ paddingTop: '8px' }}>
          {user.is('call_off', 'leader') && <CallOffAtAGlance />}
          {user.is('call_in', 'leader') && <CallInAtAGlance />}
        </div>
      </GenericCard>
    </MyProtectedElement>
  )
}

export default AtAGlanceCard
