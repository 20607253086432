import {
  addDays,
  addMinutes,
  addWeeks,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isValid,
  startOfWeek,
  subWeeks,
} from 'date-fns'

import moment from 'moment-timezone'
import { OVERNIGHT_DAY_IN_MIN_OFFSET } from '../constants/RequestConstants'

export const DATE_TIME_FORMATTING = {
  /**
   * weekdayMonthDayFormat
   * @param notFormattedDate
   * @returns {string} in format equivalent to 'dddd, MMMM DD'
   */
  weekdayMonthDayFormat(notFormattedDate) {
    return new Intl.DateTimeFormat('en-US', { weekday: 'long', month: 'long', day: 'numeric' })?.format(
      notFormattedDate,
    )
  },
  smallDate: 'MMM DD, YYYY',
  mediumDate: 'MMMM DD, YYYY',
  smallDateTime: 'M/D/YY h:mm A',
  largeDateTime: 'MMM DD, YYYY hh:mm a',
  time: 'hh:mm a',
}

/**
 * Checks if date is before a date (at midnight) that is numWeeks before the Sunday of the current week.
 * If isoTimezoneCode is provided with a value, then the current week is determined based
 * on this timezone, else it uses the devices timezone to determine current week.
 * If comparing just the date (and not time) make sure the date param has a time of midnight.
 * @param date
 * @param numWeeks
 * @param isoTimezoneCode
 * @returns {boolean | *}
 */
export const isDateBeforeNumberOfWeeksFromToday = (date, numWeeks, isoTimezoneCode) => {
  let today
  if (isoTimezoneCode) {
    today = getNowDateInTimezone(isoTimezoneCode)
  } else {
    today = new Date()
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate())
  }
  let rangeStartDate = subWeeks(startOfWeek(today), numWeeks)
  return isBefore(date, rangeStartDate)
}

/**
 * Checks if date is after a date (at midnight) that is numWeeks after the Saturday of the current week.
 * If isoTimezoneCode is provided with a value, then the current week is determined based
 * on this timezone, else it uses the devices timezone to determine current week.
 * If comparing just the date (and not time) make sure the date param has a time of midnight.
 * @param date
 * @param numWeeks
 * @param isoTimezoneCode
 * @returns {boolean | *}
 */
export const isDateAfterNumberOfWeeksFromToday = (date, numWeeks, isoTimezoneCode) => {
  let today
  if (isoTimezoneCode) {
    today = getNowDateInTimezone(isoTimezoneCode)
  } else {
    today = new Date()
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate())
  }
  let rangeEndDate = addWeeks(endOfWeek(today), numWeeks)
  return isAfter(date, rangeEndDate)
}

/**
 * Gets the current date in the timezone provided with its time representing the current time in the timezone provided.
 * @param isoTimezoneCode example: America/Chicago
 * @returns {Date}
 */
export const getNowDateTimeInTimezone = (isoTimezoneCode) => {
  let formattedStringFromMoment = moment().tz(isoTimezoneCode)?.format('MM/DD/YYYY hh:mm:ss A')
  return new Date(formattedStringFromMoment)
}

/**
 * Gets the current date in the timezone provided with its time representing 12:00:00 AM or midnight.
 * Useful for comparing Date() without time information.
 * @param isoTimezoneCode example: America/Chicago
 * @returns {Date}
 */
export const getNowDateInTimezone = (isoTimezoneCode) => {
  let tzDate = getNowDateTimeInTimezone(isoTimezoneCode)
  return new Date(tzDate.getFullYear(), tzDate.getMonth(), tzDate.getDate())
}

export const getDateObjAtMidnight = (date) => {
  if (isValid(new Date(date))) {
    let d = new Date(date.split('-'))
    return new Date(d.getFullYear(), d.getMonth(), d.getDate())
  }
}

/**
 * Strips Timestamp and TimeZone off a date and formats string in ISO format!
 *
 * @param date
 * @returns {string}
 */
export const toIsoStringWithoutTime = (date) => {
  return format(date, 'yyyy-MM-dd')
}

/**
 *
 * Creates a date of today with no time
 *
 */
export const getDateOfTodayWithNoTimestamp = () => {
  let today = new Date()
  return new Date(today.getFullYear(), today.getMonth(), today.getDate())
}

/**
 * Returns a formatted timestamp current date in the timezone provided with its time representing 12:00:00 AM or midnight.
 * Useful for comparing Date() without time information.
 * @param timestamp
 * @returns {String} in the format of Monday/Day/Year Hour:Minutes PM/AM
 */
export const getFormattedTimestamp = (timestamp) => {
  let formattedDate = new Date(timestamp)
  let hours = formattedDate.getHours()
  const amOrPm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12
  let minutes = formattedDate.getMinutes()
  minutes = minutes === 0 ? '00' : minutes
  minutes = ('0' + minutes).slice(-2)
  const month = formattedDate.getMonth() + 1
  const day = formattedDate.getDate()
  let year = formattedDate.getFullYear().toString()
  year = year.substr(2)
  return `${month}/${day}/${year} ${hours}:${minutes} ${amOrPm}`
}

export const calculateHoursInSpan = (startTime, endTime, precision) => {
  let startInMinutes = startTime.hour() * 60 + startTime.minute()
  let endInMinutes = endTime.hour() * 60 + endTime.minute()

  // if end is not after start, then it's cross midnight, so add a full day of minutes to end
  if (endInMinutes <= startInMinutes) {
    endInMinutes += OVERNIGHT_DAY_IN_MIN_OFFSET
  }

  let hours = (endInMinutes - startInMinutes) / 60
  if (precision && (endInMinutes - startInMinutes) % 60 !== 0) {
    hours = hours.toFixed(precision)
  }

  return hours
}

export const calculateMinutesInSpan = (startTime, endTime) => {
  let startInMinutes = startTime.hour() * 60 + startTime.minute()
  let endInMinutes = endTime.hour() * 60 + endTime.minute()

  // if end is not after start, then it's cross midnight, so add a full day of minutes to end
  if (endInMinutes <= startInMinutes) {
    endInMinutes += OVERNIGHT_DAY_IN_MIN_OFFSET
  }

  return endInMinutes - startInMinutes
}

export const getDateTimeWithNoTimezone = (zoneDateTime) => {
  if (zoneDateTime) return zoneDateTime.substr(0, 19)
}

/**
 * Returns json object with startDate and endDate for this week or next
 * @param {Boolean} date
 * @param {String} formatString string format to have dates in
 * @returns {endDate: string, startDate: string} endDate
 */
export const getStartEndDatesOfWeek = (date, formatString = 'YYYY-MM-DD') => {
  const weekStart = startOfWeek(date)
  const startDate = moment(weekStart)?.format(formatString)
  const endDate = moment(endOfWeek(weekStart))?.format(formatString)

  return { startDate, endDate }
}

/**
 * Bool if date is in current week
 * @param {*} date
 * @returns {Boolean}
 */
export const isDateInCurrentWeek = (date) => {
  let today = new Date()
  return !(isBefore(date, startOfWeek(today)) || isAfter(date, endOfWeek(today)))
}

export const isDateBeforeCurrentWeek = (date, isoTimezoneCode) => {
  let today = getNowDateInTimezone(isoTimezoneCode)
  return isBefore(date, startOfWeek(today))
}

export const getDateRangeAsStringFromDates = (startDate, endDate) => {
  let dateRange

  // compare the date portion of the selectedStartDate and selectedEndDate
  if (startDate?.toDateString() === endDate?.toDateString()) {
    dateRange = `${DATE_TIME_FORMATTING.weekdayMonthDayFormat(startDate)}`
  } else {
    dateRange = `${DATE_TIME_FORMATTING.weekdayMonthDayFormat(startDate)} -
      ${DATE_TIME_FORMATTING.weekdayMonthDayFormat(endDate)}`
  }
  return dateRange
}

export const formatTime = (date) => {
  const dateFormat = 'h:mm a'
  let actionDate = 'Invalid time'
  if (isValid(new Date(date))) {
    actionDate = format(new Date(date), dateFormat)
  }
  return actionDate
}

export const formatDateString = (dateString, currentFormat, newFormat, strictMode = true) => {
  return moment(dateString, currentFormat, strictMode).isValid()
    ? moment(dateString, currentFormat, strictMode).format(newFormat)
    : 'Invalid Date'
}

export const formatDateRangeString = (startDateString, endDateString, currentFormat, newFormat, strictMode = true) => {
  return endDateString && startDateString !== endDateString
    ? `${formatDateString(startDateString, currentFormat, newFormat, strictMode)} - ${formatDateString(
        endDateString,
        currentFormat,
        newFormat,
        strictMode,
      )}`
    : `${formatDateString(startDateString, currentFormat, newFormat, strictMode)}`
}

export const convertAmPmTimeFormat = (time) => {
  let hour = parseInt(time.split(':')[0])
  let mins = parseInt(time.split(':')[1])

  const amOrPm = hour >= 12 ? 'PM' : 'AM'

  if (hour === 0) {
    hour = 12
  } else if (hour > 12) {
    hour = hour - 12
  }

  hour = hour.toString().padStart(2, 0)
  mins = mins.toString().padStart(2, 0)

  return `${hour}:${mins} ${amOrPm}`
}

export const convertToLocalDateAndTime = (dateTime) => {
  let date = moment(dateTime.split('T')[0]).format('M/DD/YYYY')
  let time = convertAmPmTimeFormat(dateTime.split('T')[1])
  return `${date} ${time}`
}

export const convertToLocalTimeForPucnCorrections = (dateTime) => {
  let date = moment(dateTime.split('T')[0]).format('M/DD/YYYY')
  let time = convertAmPmTimeFormat(dateTime.split('T')[1])
  return new Date(moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss'))
}

export const extractTime = (dateTime) => {
  let hours = dateTime.getHours()
  let mins = dateTime.getMinutes()
  let seconds = '00'

  hours = hours.toString().padStart(2, 0)
  mins = mins.toString().padStart(2, 0)

  return `${hours}:${mins}:${seconds}`
}

export const extractTimeForPCSubmission = (dateTime) => {
  let time = dateTime.split('T')[1]
  return `${time.substr(0, 8)}`
}

export function getScheduleGenerationCutoffDate() {
  const nowInCT = moment.tz('America/Chicago') // Convert the current time to CT (Central Time)
  const thisWeekMondayNoon = nowInCT.clone().startOf('week').add(1, 'days').hours(12).minutes(0) // This week's Monday 12 PM in CT

  const twoWeeksFromNowSunday = moment().add(2, 'weeks').startOf('week') // Two weeks from now Sunday in CT

  const threeWeeksFromNowSunday = moment().add(3, 'weeks').startOf('week') // Three weeks from now Sunday in CT

  return nowInCT.isBefore(thisWeekMondayNoon) ? twoWeeksFromNowSunday : threeWeeksFromNowSunday
}

export function isDatePastScheduleGenerationCutoff(dateString) {
  const date = moment(dateString, 'MM-DD-YYYY')
  const cutOffDate = getScheduleGenerationCutoffDate()

  return date.isSameOrAfter(cutOffDate)
}

// must pass date
export const createCypressDate = (date) => format(date, 'eeee, MMMM d')

export function formatMinutesToDisplay(minutes, format = 'medium', showInDays = false, exact = false) {
  let isNegative = minutes < 0
  let absoluteValue = Math.abs(minutes)
  absoluteValue = Math.round(absoluteValue)

  if (minutes === 0) {
    if (format === 'small') {
      return '0m'
    } else if (format === 'large') {
      return '0 minutes'
    } else {
      return '0 mins'
    }
  }

  let days = Math.floor(absoluteValue / (60 * 24))
  let hours = Math.floor((absoluteValue % (60 * 24)) / 60)
  let remainderMinutes = absoluteValue % 60

  let result = ''

  // Add day hours to total hours if showInDays is false
  if (!showInDays) {
    hours += days * 24
  }

  // Handling days
  if (days > 0 && showInDays) {
    if (format === 'small') {
      result += `${days}d `
    } else if (format === 'large') {
      result += `${days} day${days > 1 ? 's' : ''} `
    } else {
      result += `${days} ${days > 1 ? 'days' : 'day'} `
    }
  }

  // Handling hours and minutes
  if ((showInDays && exact) || (!showInDays && !exact) || days === 0) {
    if (hours > 0) {
      result +=
        format === 'small'
          ? `${hours}h `
          : format === 'large'
            ? `${hours} hour${hours > 1 ? 's' : ''} `
            : `${hours} ${hours > 1 ? 'hrs' : 'hr'} `
    }
    if (remainderMinutes > 0) {
      result +=
        format === 'small'
          ? `${remainderMinutes}m`
          : format === 'large'
            ? `${remainderMinutes} minute${remainderMinutes > 1 ? 's' : ''}`
            : `${remainderMinutes} ${remainderMinutes > 1 ? 'mins' : 'min'}`
    }
  }

  // Handling negative balance
  if (isNegative) {
    result = '-' + result
  }
  return result.trim()
}

// Takes minutes and formats them as hours, 2 decimals
export function formatHoursToDisplay(minutes, format = 'medium') {
  let isNegative = minutes < 0
  let absoluteValue = Math.abs(minutes)
  let hours = (absoluteValue / 60).toFixed(2)

  let result = ''

  // Handling hours and minutes
  if (hours !== 0) {
    result +=
      format === 'small'
        ? `${hours}h `
        : format === 'large'
          ? `${hours} hour${hours > 1 ? 's' : ''} `
          : `${hours} ${hours > 1 ? 'hrs' : 'hr'} `
  }

  // Handling negative balance
  if (isNegative) {
    result = '- ' + result
  }
  return result
}

export function doesCrossMidnight(startDateTime, minutes) {
  // Clone the original date to avoid modifying it
  let endTime = new Date(startDateTime.getTime())

  // Add the minutes to the start date time
  endTime = addMinutes(endTime, minutes)

  // Check if the date has changed and the time isn't exactly midnight
  return endTime.getDate() !== startDateTime.getDate() && !(endTime.getHours() === 0 && endTime.getMinutes() === 0)
}

export function validateMinutes(selectedMinutes, minMinutes, maxMinutes) {
  let error = ''
  if (selectedMinutes < minMinutes) {
    error = `Min ${minMinutes} minutes`
  } else if (selectedMinutes > maxMinutes) {
    error = `Max ${maxMinutes / 60} hours`
  }

  return error
}

export function doesOverlap(startTime1, duration1, startTime2, duration2) {
  // Parse the start time strings into JavaScript Date objects
  const start1 = new Date(startTime1)
  const start2 = new Date(startTime2)

  // Calculate the end times by adding the durations (in minutes)
  const end1 = new Date(start1.getTime() + duration1 * 60000)
  const end2 = new Date(start2.getTime() + duration2 * 60000)

  // Define the earliest start time and latest end time
  let latestStart, earliestEnd
  // Determine which event starts first
  if (start1 <= start2) {
    latestStart = start2
    earliestEnd = end1
  } else {
    latestStart = start1
    earliestEnd = end2
  }

  latestStart.setSeconds(0, 0)
  earliestEnd.setSeconds(0, 0)

  // If the event with the earliest start time ends after the other event starts, then there's an overlap.
  // If the event with the earliest start time ends exactly when the other event starts, it will not be considered as overlapping.
  return earliestEnd.getTime() > latestStart.getTime()
}

function getFiscalYearEnd(year) {
  // Create a date object for January 31st of the given year
  var jan31 = new Date(year, 0, 31)

  // Get the day of the week for January 31st (0 is Sunday, 6 is Saturday)
  var dayOfWeek = jan31.getDay()

  // Calculate the difference to the nearest Saturday
  var diffToSaturday = 6 - dayOfWeek

  // Adjust the date to the nearest Saturday
  return new Date(jan31.setDate(jan31.getDate() + diffToSaturday))
}

const formatDateInCentralTime = (date) => {
  return moment(date).tz('America/Chicago').format('MMMM Do YYYY, h:mm a')
}

const FISCAL_YEAR_AND_MASS_VACATION_EDIT_OPEN_LEADER = moment('2025-01-14') // When mass vacation opens for decisions and leaders can see the next fiscal year.
const FISCAL_YEAR_OPEN_TEAM_MEMBER = moment('2025-01-27') // When fiscal year opens for TMs normal Time Off
export const massVacation = {
  submissionStart: moment.tz('2025-01-02 08:00', 'America/Chicago').format(),
  submissionEnd: moment.tz('2025-01-15', 'America/Chicago').format(),
  decisionEnd: moment.tz('2025-01-27', 'America/Chicago').format(),
}

// leader || team_member_time_off || mass_vacation
export function getFiscalYearStartEnd(forTmType) {
  let fiscalOpen = FISCAL_YEAR_OPEN_TEAM_MEMBER
  if (forTmType === 'leader') {
    fiscalOpen = FISCAL_YEAR_AND_MASS_VACATION_EDIT_OPEN_LEADER
  } else if (forTmType === 'mass_vacation') {
    fiscalOpen = massVacation.submissionStart
  }

  let fiscalOpenDate = moment(fiscalOpen)

  var prevOpenYear = fiscalOpenDate.clone().subtract(1, 'years').year()
  var openYear = fiscalOpenDate.clone().year()
  var nextYear = fiscalOpenDate.clone().add(1, 'years').year()

  let today = moment()

  let start = today.isBefore(fiscalOpen)
    ? addDays(getFiscalYearEnd(prevOpenYear), 1)
    : addDays(getFiscalYearEnd(openYear), 1)
  let end = today.isBefore(fiscalOpen) ? getFiscalYearEnd(openYear) : getFiscalYearEnd(nextYear)

  return { start, end }
}

const MV_DESCRIPTION =
  'Submit up to 10 requests for Mass Vacation. You will rank your vacations in priority order with 1 being the highest priority.'

export const massVacationManager = () => {
  const now = moment()

  let { submissionStart, submissionEnd, decisionEnd } = massVacation

  const fiscalDates = getFiscalYearStartEnd('mass_vacation')

  if (now.isBefore(submissionStart)) {
    return {
      status: 'before',
      message: `Submission period will begin on ${formatDateInCentralTime(submissionStart)} CST.`,
      submitDisabled: true,
      decisionDisabled: true,
      hideForTM: false,
    }
  } else if (now.isSameOrAfter(submissionStart) && now.isBefore(submissionEnd)) {
    return {
      status: 'during',
      message: `Submission period is ongoing and will end in ${formatMinutesToDisplay(
        moment(submissionEnd).diff(now, 'minutes'),
        'large',
        true,
        true,
      )} on ${formatDateInCentralTime(submissionEnd)} CST.`,
      description: MV_DESCRIPTION,
      minDate: fiscalDates.start,
      maxDate: fiscalDates.end,
      submitDisabled: false,
      decisionDisabled: true,
      hideForTM: false,
    }
  } else if (now.isSameOrAfter(submissionEnd) && now.isBefore(decisionEnd)) {
    return {
      status: 'decisions',
      message: `Submission period ended on ${formatDateInCentralTime(submissionEnd)} CST.`,
      submitDisabled: true,
      decisionDisabled: false,
      hideForTM: true,
    }
  } else {
    return {
      status: 'close',
      message: `Submission period ended on ${formatDateInCentralTime(submissionEnd)} CST.`,
      submitDisabled: true,
      decisionDisabled: false,
      hideForTM: true,
    }
  }
}
