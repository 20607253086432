import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../auth/hooks/useUser'
import {
  REQUEST_TYPE_CALL_IN,
  REQUEST_TYPE_CALL_OFF,
  REQUEST_TYPE_TIME_OFF,
  TIME_OFF_GROUPS,
} from '../../constants/RequestConstants'
import { LOCATION_TYPE_STORE } from '../../constants/locationConstants'
import SelectShift from './RequestSelectShift'

const DIALOG_TITLE = 'Select Request Type'
const REQUEST_FEATURE_T0_LABEL = {
  [REQUEST_TYPE_TIME_OFF]: TIME_OFF_GROUPS.TIME_OFF,
  [REQUEST_TYPE_CALL_IN]: TIME_OFF_GROUPS.CALL_IN,
  [REQUEST_TYPE_CALL_OFF]: TIME_OFF_GROUPS.CALL_OFF,
}
const REQUEST_LABEL_T0_PATH = {
  [TIME_OFF_GROUPS.TIME_OFF]: '/team-member/requests/time_off/timeOff?reDirectFrom=requests',
  [TIME_OFF_GROUPS.CALL_IN]: '/team-member/requests/time_off/callIn?reDirectFrom=requests',
  [TIME_OFF_GROUPS.CALL_OFF]: '/team-member/requests/time_off/callOff?reDirectFrom=requests',
}

const RequestTypeDialog = (props) => {
  const { handleClickCancel } = props

  const { userData, locationData } = useUser()
  const navigate = useNavigate()
  const [selectedType, setSelectedType] = useState('')
  const [segmentStart, setSegmentStart] = useState('')
  const [segmentEnd, setSegmentEnd] = useState('')
  const [scheduleDate, setScheduleDate] = useState('')

  // create a function that calls navigate to go to call in or call off screen with schedule state like the schedule screen does
  const handleConfirm = () => {
    navigate(selectedType === '' ? '/' : REQUEST_LABEL_T0_PATH[selectedType], {
      state: {
        segmentStart: segmentStart,
        segmentEnd: segmentEnd,
        scheduleDate: scheduleDate,
      },
    })
  }

  // create a function that is passed as a callback to SelectShift so we can updated selected shift state
  const handleSelectSchedule = (schedule, index) => {
    setScheduleDate(schedule?.schedule_date)
    setSegmentStart(schedule?.display_segments[index]?.segment_start)
    setSegmentEnd(schedule?.display_segments[index]?.segment_end)
  }

  const onChange = (event) => {
    setSelectedType(event.target.value)
  }

  const renderTypeSection = () => {
    const requestTypeMenuItems = Object.keys(REQUEST_FEATURE_T0_LABEL).map((feature) => {
      if (userData.features?.[feature]?.['team_member']) {
        let val = REQUEST_FEATURE_T0_LABEL[feature]
        return (
          <MenuItem key={val} value={val}>
            {val}
          </MenuItem>
        )
      } else {
        return null
      }
    })

    return (
      <TextField
        data-cy={`request_type_dialog_dropdown`}
        id="type"
        select
        label="Type"
        sx={{ display: 'flex' }}
        value={selectedType}
        onChange={onChange}
        margin="normal"
      >
        {requestTypeMenuItems}
      </TextField>
    )
  }

  return (
    <Dialog
      data-cy="requestTypeDialog"
      open={true}
      onClose={handleClickCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{DIALOG_TITLE}</DialogTitle>

      <DialogContent>
        {renderTypeSection()}
        {locationData.location_type === LOCATION_TYPE_STORE &&
        [TIME_OFF_GROUPS.CALL_IN, TIME_OFF_GROUPS.CALL_OFF].includes(selectedType) ? (
          <SelectShift handleSelectSchedule={handleSelectSchedule} />
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickCancel} color="primary">
          CANCEL
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={
            selectedType === '' ||
            (scheduleDate === '' &&
              locationData.location_type === LOCATION_TYPE_STORE &&
              [TIME_OFF_GROUPS.CALL_IN, TIME_OFF_GROUPS.CALL_OFF].includes(selectedType))
          }
        >
          CONTINUE
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RequestTypeDialog
