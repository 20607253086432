import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { showNotificationError } from '../../store/notification/actionCreator'

const getAvailablePersonas = async () => {
  let apiUrl = `${apiConfig.userProfiles.urlv2}/available_personas?key=${apiConfig.userProfiles.key}`
  return axios.get(apiUrl, BASIC_REQUEST_CONFIG)
}

export function useAvailablePersonas() {
  const { isAuthenticated, login } = useAuth()
  const dispatch = useDispatch()
  const authenticated = isAuthenticated()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  return useQuery({
    queryKey: ['available_personas'],
    queryFn: getAvailablePersonas,
    staleTime: 1000 * 60 * 10, // 10 min
    enabled: authenticated,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
    keepPreviousData: true,
    onError: () => {
      dispatch(showNotificationError(true, 'Error loading available personas'))
    },
  })
}
