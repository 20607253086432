import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useCommunication } from '../../api/userProfiles/useCommunication'
import HomePageCard from '../common/HomePageCard'
import CommunicationDialog from '../common/dialog/CommunicationDialog'

export default function CommunicationCard() {
  const [activeStep, setActiveStep] = useState(0)
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const { data, isFetching } = useCommunication(false, 1, 25)
  const communications = data?.data

  const handleOpen = () => {
    setActiveStep(0)
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      // TODO: track event with praxis firefly, how to not send same one twice? local storage check.
      return prevActiveStep + 1
    })
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  if (!communications || communications?.total_communications === 0) return

  return (
    <Grid
      item
      xs={12}
      sx={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1), marginTop: '6px', marginBottom: '6px' }}
    >
      <HomePageCard title="Communication" icon={<ChatBubbleOutlineIcon />} isRefetching={isFetching}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <Typography component="p">{communications?.total_communications} Communications</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleOpen}>
              View
            </Button>
          </Grid>
        </Grid>
      </HomePageCard>
      <CommunicationDialog
        handleBack={handleBack}
        handleClose={handleClose}
        handleNext={handleNext}
        isOpen={isOpen}
        activeStep={activeStep}
        communications={communications}
      />
    </Grid>
  )
}
