import JobIcon from '@mui/icons-material/Label'
import { Box, Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useUser } from '../../auth/hooks/useUser'
import { accepterSwapRequestPost } from '../../store/swapShift/actionCreator'
import DisplaySegmentShiftSwap from '../DisplaySegments/DisplaySegmentShiftSwap'
import InnerLoader from '../Loader/InnerLoader'
import ShiftSwapVerticalIcon from './../Icons/ShiftSwapVerticalIcon'

const AcceptSwapRequestModal = (props) => {
  const { styles, shift, selectedShift, resetActions } = props
  const user = useUser()

  const [disableActions, setDisableActions] = useState(!resetActions)
  const dispatch = useDispatch()

  useEffect(() => {
    setDisableActions(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetActions])

  const handleSubmit = () => {
    //make api call and show success/failure notiification as a response
    //setProcessIndicator(true)
    //selectedShift.status !== 'PENDING' ? processPostShiftRequest() : processRetractShiftRequest()

    processPostShiftRequest()
  }

  const processPostShiftRequest = () => {
    //const { segment_start, segment_end, job_path, location, jobs, shift_label, selectedShift } = props.selectedShift
    const { shift, selectedShift } = props

    const requestBody = {
      location_id: shift.location,
      request_id: selectedShift.request_id,
      requester: {
        location_id: selectedShift.location_id,
        org_ids_id: selectedShift.org_ids_id,
        org_path: selectedShift.org_path,
        jobs: selectedShift.jobs,
        shift_date: selectedShift.shift_date,
        shift_start: selectedShift.shift_start,
        shift_end: selectedShift.shift_end,
        shift_label: selectedShift.shift_label,
        worker_id: selectedShift.worker_id,
      },
      accepter: {
        location_id: shift.location,
        org_ids_id: shift.org_ids_id,
        org_path: shift.job_path,
        jobs: shift.jobs,
        shift_date: shift.schedule_date,
        shift_start: moment.tz(shift.schedule_start, user.locationData.iso_time_zone_code)?.format(),
        shift_end: moment.tz(shift.schedule_end, user.locationData.iso_time_zone_code)?.format(),
        shift_label: shift.shift_label,
        worker_id: user.userData.worker_id,
      },
    }

    dispatch(accepterSwapRequestPost(requestBody))
    setDisableActions(true)
  }

  const renderJob = (jobs) => {
    let jobList = []
    if (jobs && jobs.length > 0) {
      jobs.forEach((job, elemIndex) => {
        if (elemIndex !== 0) {
          let lastIndex = job.org_path.lastIndexOf('/')
          jobList.push(job.org_path.substr(lastIndex + 1, job.org_path.length))
        }
      })
    }
    if (jobList.length > 0) {
      return (
        <Box sx={styles.addtionalJobsContainer}>
          <Typography variant="body2">This shift contains job transfers:</Typography>
          {jobList.map((job, index) => (
            <Box sx={styles.jobContainerAtPopup} key={index}>
              <JobIcon color="primary" sx={styles.iconMargin} />
              <Typography variant="body2" sx={styles.jobName}>
                {job}
              </Typography>
            </Box>
          ))}
        </Box>
      )
    } else {
      return null
    }
  }

  const handleClose = () => {
    props.handleClose()
  }

  // TODO: implement when rtc cancel posted shift is enabled 'Retract Shift'
  const handleDialogTitle = () => {
    // return selectedShift.status !== 'PENDING' ? 'Post Shift' : 'Posted Shift'
    return 'Accept Swap Request'
  }

  const renderDialogInfoText = () => {
    return (
      <>
        <Typography sx={styles.displaySegmentContainer} variant="body2">
          <Box component={'span'}>
            <b>Attention:</b> You are responsible for your current shift until schedule reflects swap changes.
          </Box>
        </Typography>
      </>
    )
  }

  // TODO: remove ternary operator for DialogActions and keep Button and ConfirmGiveUpShift when Retract shift implemented
  if (shift) {
    return (
      <Dialog
        disableEscapeKeyDown
        open={props.open}
        onClose={handleClose}
        aria-label="responsive-dialog-title"
        sx={styles.dialogPaper}
      >
        <DialogTitle sx={styles.titleContainerModal} id="responsive-dialog-title">
          {handleDialogTitle()}
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} sm={12} sx={styles.displaySegmentContainerModal}>
            <Typography>Are you sure want to accept the request to swap these shifts?</Typography>
          </Grid>
          <Box sx={styles.requestContainerModal}>
            <DisplaySegmentShiftSwap shift={shift} ownShift={true} styles={styles} />
          </Box>
          <Box sx={styles.verticalGap}>
            <ShiftSwapVerticalIcon />
          </Box>
          <Box sx={styles.requestContainerModal}>
            <DisplaySegmentShiftSwap shift={selectedShift} styles={styles} />
          </Box>
          {renderJob(selectedShift.jobs)}
          {renderDialogInfoText()}
        </DialogContent>
        <DialogActions>
          {/* {selectedShift.status !== 'PENDING' ? ( */}
          <>
            {disableActions && (
              <Box component={'span'} sx={styles.loaderStyles}>
                <InnerLoader size={25} />
              </Box>
            )}
            <Button onClick={handleClose} color="primary" disabled={disableActions}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleSubmit}
              sx={styles.mobileButton}
              disabled={disableActions}
              data-cy="accept-swap-Button"
            >
              Accept Swap
            </Button>
          </>
        </DialogActions>
      </Dialog>
    )
  } else {
    return null
  }
}

export default AcceptSwapRequestModal
