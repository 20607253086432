import { Badge, Menu } from '@mui/material'
import Button from '@mui/material/Button'
import { addDays, addHours, isAfter, isBefore } from 'date-fns'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useUser } from '../../auth/hooks/useUser'
import InnerLoader from '../Loader/InnerLoader'
import CallInAction from '../Requests/CallIn/CallInAction'
import CallOffAction from '../Requests/CallOff/CallOffAction'
import SearchSwapShiftsAction from '../SwapShift/SearchSwapShiftsAction'
import SearchSwapShiftsModal from '../SwapShift/SearchSwapShiftsModal'
import WithdrawSwapRequestModal from '../SwapShift/WithdrawSwapRequestModal'
import ConfirmPostShiftModal from '../TeamMemberAvailableShifts/ConfirmPostShiftModal'
import PostShiftAction from '../TeamMemberAvailableShifts/PostShiftAction'

const ManageShift = (props) => {
  const { displaySegment, schedule, styles } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const postShiftPostResponse = useSelector((state) => state.weeklySchedule.postShiftPostResponse)
  const postShiftPostErrResponse = useSelector((state) => state.weeklySchedule.postShiftPostErrResponse)

  const user = useUser()
  const [showCallIn, setShowCallIn] = useState(false)
  const [showCallOff, setShowCallOff] = useState(false)
  const [showRequestToCover, setShowRequestToCover] = useState(false)
  const [showSearchSwapShifts, setShowSearchSwapShifts] = useState(false)
  const [confirmPostShiftModalOpen, setConfirmPostShiftModalOpen] = useState(false)
  const [searchSwapShiftsModalOpen, setSearchSwapShiftsModalOpen] = useState(false)
  const [withdrawSwapRequestModalOpen, setWithdrawSwapRequestModalOpen] = useState(false)
  const [processIndicator, setProcessIndicator] = useState(false)

  const [badgeIndicator, setBadgeIndicator] = useState(null)

  useEffect(() => {
    if (displaySegment && schedule && user) {
      if (user.is('call_in') || user.is('call_off')) {
        let startDateString = new Date(displaySegment?.segment_start).toLocaleString()
        let nowDateInLocationString = new Date().toLocaleString('en-US', {
          timeZone: user.locationData.iso_time_zone_code,
        })

        let startDate = new Date(startDateString)
        let nowDate = new Date(nowDateInLocationString)

        let isInMoreThan2HoursInPast = isBefore(startDate, addHours(nowDate, -2))
        let isMoreThan24HoursInFuture = isAfter(startDate, addDays(nowDate, 1))

        if (!isInMoreThan2HoursInPast && !isMoreThan24HoursInFuture) {
          setShowCallIn(true)
          setShowCallOff(true)
        }
      }
      if (
        user.is('request_to_cover') &&
        displaySegment.status &&
        displaySegment.status !== 'PAST' &&
        !displaySegment.isShiftBeingCovered &&
        displaySegment.swapStatusAsRequester !== 'OPEN'
      ) {
        setShowRequestToCover(true)
      }
      if (
        user.is('shift_swap') &&
        displaySegment.swapStatusAsRequester &&
        displaySegment.swapStatusAsRequester !== 'PAST' &&
        !displaySegment.isShiftBeingCovered &&
        displaySegment.status !== 'PENDING'
      ) {
        setShowSearchSwapShifts(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule, displaySegment, user])

  useEffect(() => {
    if (displaySegment?.swapStatusAsRequester === 'OPEN') {
      setBadgeIndicator('!')
      setShowRequestToCover(false)
    } else if (displaySegment.status === 'PENDING') {
      setBadgeIndicator('!')
      setShowSearchSwapShifts(false)
    } else {
      setBadgeIndicator(null)
    }
  }, [displaySegment])

  useEffect(() => {
    setProcessIndicator(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postShiftPostResponse, postShiftPostErrResponse])

  const disableCoverShiftButton = () => {
    setProcessIndicator(true)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSearchShiftsToSwapClick = () => {
    setAnchorEl(null)
    setSearchSwapShiftsModalOpen(true)
  }

  const handleWithdrawSwapRequestClick = () => {
    setAnchorEl(null)
    setWithdrawSwapRequestModalOpen(true)
  }

  const handlePostShiftActionClick = () => {
    setAnchorEl(null)
    setConfirmPostShiftModalOpen(true)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleCallInActionClick = () => {
    setAnchorEl(null)
  }

  const handleCallOffActionClick = () => {
    setAnchorEl(null)
  }

  const handleConfirmPostShiftModalClose = () => {
    setConfirmPostShiftModalOpen(false)
  }

  const handleWithdrawSwapRequestModalClose = () => {
    setWithdrawSwapRequestModalOpen(false)
  }

  const handleSearchSwapShiftsModalClose = () => {
    setSearchSwapShiftsModalOpen(false)
  }

  const shouldDisplayButton = () => {
    return showCallIn || showCallOff || showRequestToCover || showSearchSwapShifts
  }

  const findOpenRequest = (requestsArr) => {
    if (requestsArr && requestsArr.length > 0) {
      const openShifts = requestsArr.filter((item) => item.status.toLowerCase() === 'open')
      return openShifts[0]
    } else {
      return null
    }
  }

  const renderPostShiftModal = () => {
    if (showRequestToCover && schedule.total_display_segments > 0) {
      return (
        <ConfirmPostShiftModal
          open={confirmPostShiftModalOpen}
          handleClose={() => handleConfirmPostShiftModalClose()}
          styles={styles}
          selectedShift={displaySegment}
          schedule={props.schedule}
          disableCoverShiftButton={() => disableCoverShiftButton()}
        />
      )
    }
  }

  const renderSearchSwapShiftsModal = () => {
    if (showSearchSwapShifts && schedule.total_display_segments > 0) {
      return (
        <SearchSwapShiftsModal
          open={searchSwapShiftsModalOpen}
          handleClose={() => handleSearchSwapShiftsModalClose()}
          styles={styles}
          shift={displaySegment}
          schedule={props.schedule}
          disableCoverShiftButton={() => disableCoverShiftButton()}
        />
      )
    }
  }

  const renderWithdrawSwapRequestModal = () => {
    if (showSearchSwapShifts) {
      return (
        <WithdrawSwapRequestModal
          open={withdrawSwapRequestModalOpen}
          handleClose={() => handleWithdrawSwapRequestModalClose()}
          styles={styles}
          shift={findOpenRequest(displaySegment.swapshiftRequest)}
          schedule={props.schedule}
          disableCoverShiftButton={() => disableCoverShiftButton()}
        />
      )
    }
  }

  const renderManageShift = () => {
    if (shouldDisplayButton()) {
      return (
        <>
          {renderButton()}
          {renderMenuItemList()}
          {renderPostShiftModal()}
          {renderSearchSwapShiftsModal()}
          {renderWithdrawSwapRequestModal()}
        </>
      )
    }
  }

  const renderButton = () => {
    if (processIndicator) {
      return (
        <span style={styles.loaderStyles}>
          <InnerLoader size={25} />
        </span>
      )
    } else {
      return (
        <Badge color="secondary" badgeContent={badgeIndicator} sx={{ overlap: 'rectangular' }}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            data-cy="manageShiftButton"
            aria-controls="manage-shift-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{
              fontSize: '10px',
              width: '70px',
              height: '40px',
              margin: '2px',
            }}
          >
            MANAGE SHIFT
          </Button>
        </Badge>
      )
    }
  }

  const renderMenuItemList = () => {
    return (
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {showSearchSwapShifts && (
          // Adding div between Menu & MenuItem fixes warning according to github.com/mui/material-ui/issues/15903
          <div>
            <SearchSwapShiftsAction
              displaySegment={displaySegment}
              handleSearchShiftsToSwapClick={handleSearchShiftsToSwapClick}
              handleWithdrawSwapRequestClick={handleWithdrawSwapRequestClick}
              menuKey={1}
            />
          </div>
        )}
        {showRequestToCover && (
          <div>
            <PostShiftAction
              displaySegment={displaySegment}
              handlePostShiftActionClick={handlePostShiftActionClick}
              menuKey={2}
            />
          </div>
        )}
        {showCallIn && (
          <div>
            <CallInAction
              handleCallInClose={handleCallInActionClick}
              menuKey={3}
              scheduleDate={schedule.schedule_date}
              displaySegment={displaySegment}
            />
          </div>
        )}
        {showCallOff && (
          <div>
            <CallOffAction
              handleCallOffClose={handleCallOffActionClick}
              menuKey={4}
              scheduleDate={schedule.schedule_date}
              displaySegment={displaySegment}
            />
          </div>
        )}
      </Menu>
    )
  }

  return <>{renderManageShift()}</>
}

export default ManageShift
