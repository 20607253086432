import { useTheme } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React from 'react'
import { useUser } from '../../auth/hooks/useUser'
import { LOCATION_TYPE_OFFICE, LOCATION_TYPE_RGD } from '../../constants/locationConstants'
import { formatMinutesToDisplay, getDateTimeWithNoTimezone } from '../../utils/DateUtil'
import { doesAllowPartialAction } from '../LeaderViewAutomation/TimeOffRequestLeaderDialog'
import RequestStatusIcon from './RequestStatusIcon'

const getStyles = (theme) => ({
  requestHeading: {
    fontWeight: 'bold',
    fontSize: 'large',
  },
  subText: {
    fontSize: 'medium',
    color: theme.infoMessages.color,
  },
  details: {
    flexDirection: 'column',
    padding: '0px 15px',
    marginBottom: '5px',
  },
  summary: {
    flex: 1,
  },
  requestDetailsExpandedColNames: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  requestDetailsColName: {
    display: 'flex',
    flex: 1,
    fontSize: 'small',
    fontWeight: 'bold',
  },
  requestDetailsExpanded: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  requestDetails: {
    flex: 1,
    fontSize: 'medium',
    color: theme.infoMessages.color,
    paddingTop: '2px',
    paddingBottom: '3px',
  },
  datesExpanded: {
    margin: '5px 0',
  },
  statusTextField: {
    marginLeft: '5px',
  },
  statusListItem: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  root: {
    '& .MuiFilledInput-inputHiddenLabel': {
      paddingTop: 3,
      paddingBottom: 2,
    },
    '& .MuiInputBase-input': {
      paddingTop: 3,
      paddingBottom: 2,
    },
  },
})

const RequestDetailsTable = ({
  requestDetails,
  selectableUpdateStatuses,
  statusChangeMap,
  handleStatusChange,
  disableStatusUpdates,
  isTimeOffTeamMember,
}) => {
  const subOutputDateFormat = isTimeOffTeamMember ? 'MM/DD' : 'MM/DD/YY' // date for request information when accordion is expanded
  const startTimeOutputDateFormat = 'h:mma' // time for request information when accordion is expanded
  const { locationData } = useUser()
  const theme = useTheme()
  const styles = getStyles(theme)

  const renderRequestDetailsHeader = () => {
    return (
      <div style={styles.requestDetailsExpandedColNames}>
        <Typography sx={{ ...styles.requestDetailsColName, flex: 0.7 }}>Date</Typography>
        <Typography sx={{ ...styles.requestDetailsColName, flex: 1 }}>Start Time</Typography>
        <Typography sx={{ ...styles.requestDetailsColName, flex: 0.85 }}>Hours</Typography>
        <Typography sx={{ ...styles.requestDetailsColName, flex: 1.5 }}>Type</Typography>
        {selectableUpdateStatuses ? renderRequestStatusDetailsHeader() : null}
        {isTimeOffTeamMember ? renderStatusForTeamMember() : null}
      </div>
    )
  }

  const renderStatusForTeamMember = () => {
    return (
      <React.Fragment>
        <Typography sx={styles.requestDetailsColName} style={{ flex: 0.5 }}>
          Status
        </Typography>
      </React.Fragment>
    )
  }

  const renderRequestStatusDetailsHeader = () => {
    return (
      <React.Fragment>
        <Typography sx={styles.requestDetailsColName} style={{ flex: 1 }}>
          Status
        </Typography>
        <Typography sx={styles.requestDetailsColName} style={{ flex: 1.25 }}>
          Update Status
        </Typography>
      </React.Fragment>
    )
  }

  const renderRequestDetail = (detail, index) => {
    let startTime = moment(getDateTimeWithNoTimezone(detail.start_time))
    let totalDuration = moment(detail.end_time).diff(moment(detail.start_time), 'minutes')

    return (
      <div key={index} style={styles.requestDetailsExpanded}>
        <Typography sx={{ ...styles.requestDetails, flex: 0.7 }}>{startTime?.format(subOutputDateFormat)}</Typography>
        <Typography sx={{ ...styles.requestDetails, flex: 1 }}>
          {startTime?.format(startTimeOutputDateFormat)}
        </Typography>
        <Typography sx={{ ...styles.requestDetails, flex: 0.85 }}>
          {formatMinutesToDisplay(totalDuration, 'small')}
        </Typography>
        <Typography
          sx={{
            ...styles.requestDetails,
            flex: 1.5,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {detail.type}
        </Typography>
        {selectableUpdateStatuses ? renderRequestStatusDetail(detail, index) : null}
        {isTimeOffTeamMember ? renderStatusDetailForTeamMember(detail, index) : null}
      </div>
    )
  }

  const renderStatusDetailForTeamMember = (detail, index) => {
    return (
      <div style={{ flex: 0.5 }}>
        <RequestStatusIcon
          key={index}
          requestType={detail.type}
          status={detail.status}
          isTimeOffTeamMember={isTimeOffTeamMember}
          showFailed={[LOCATION_TYPE_OFFICE, LOCATION_TYPE_RGD].includes(locationData.location_type)}
        />
      </div>
    )
  }

  const renderRequestStatusDetail = (detail, index) => {
    return (
      <React.Fragment>
        <Typography
          sx={{
            ...styles.requestDetails,
            flex: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {detail.status}
        </Typography>
        {doesAllowPartialAction(detail.status, detail.type, detail.end_time, locationData.iso_time_zone_code)
          ? renderRequestStatusDropDown(index)
          : renderBlankTypography()}
      </React.Fragment>
    )
  }
  const renderBlankTypography = () => {
    return <Typography sx={{ ...styles.requestDetails, flex: 1.25 }} />
  }

  const renderRequestStatusDropDown = (index) => {
    const statusList = selectableUpdateStatuses.map((status) => {
      return (
        <MenuItem key={status} value={status}>
          <div style={styles.statusListItem}>
            <RequestStatusIcon status={status} isTimeOffTeamMember={isTimeOffTeamMember} />
            <div style={styles.statusTextField}>{status}</div>
          </div>
        </MenuItem>
      )
    })

    return (
      <TextField
        style={{ flex: 1.25 }}
        hiddenLabel
        id={`requestDetailsTable_status_${index}`}
        variant="standard"
        size="small"
        data-cy={`requestDetailsTable_status_${index}`}
        select
        disabled={disableStatusUpdates}
        sx={styles.root}
        value={statusChangeMap.get(index) == null ? '' : statusChangeMap.get(index)}
        onChange={(e) => handleStatusChange(e.target.value, index)}
        margin="none"
      >
        {statusList}
      </TextField>
    )
  }

  return (
    <React.Fragment>
      {renderRequestDetailsHeader()}
      <div style={styles.subText}>
        <div style={styles.datesExpanded}>
          {requestDetails.map((detail, index) => renderRequestDetail(detail, index))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default RequestDetailsTable
