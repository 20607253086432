import { useTheme } from '@mui/material'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import { useQueryClient } from '@tanstack/react-query'
import { addDays } from 'date-fns'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useUser } from '../../../auth/hooks/useUser'
import { REQUEST_SCREEN_TITLES, REQUEST_TYPES, TIME_OFF_TYPES } from '../../../constants/RequestConstants'
import { ERROR_CODES_SHOW_MESSAGE, SUCCESS_ON_SAVE, UNABLE_TO_SAVE } from '../../../constants/errorConstants'
import { LOCATION_TYPE_DC, LOCATION_TYPE_STORE } from '../../../constants/locationConstants'
import { showNotificationError, showNotificationSuccess } from '../../../store/notification/actionCreator'
import { clearPreviousState, postRequestsData } from '../../../store/requestTimeOff/actionCreator'
import { getNowDateInTimezone } from '../../../utils/DateUtil'
import { formatErrorCode } from '../../../utils/ErrorHandling'
import HeaderTitle from '../../Header/HeaderTitle'
import RequestCommentForm from '../RequestCommentForm'
import RequestCallInDetail from './RequestCallInDetail'
import RequestCallInSubmitCancel from './RequestCallInSubmitCancel'

const getStyles = (theme) => ({
  mainContainerPosition: theme.mainContainerPosition,
  loadingIconContainer: theme.loadingIconContainer,
  datePickerContainer: {
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  timeOffRequestScrollableContainer: {
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  bottomSectionContainer: {
    textAlign: 'right',
    borderTop: `1px solid ${theme.palette.tertiary.main}`,
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
  },
  typeTextField: {
    display: 'flex',
    width: '250px',
    alignSelf: 'center',
    marginBottom: '10px',
  },
})

const MY_REQUESTS_PAGE_PATH = '/team-member/requests'
export const COMMENT_MAX_LENGTH = 280
const TIME_OFF_ERROR_CODE = 'wfm-15-0'
const COMMENT_REQUIRED = 'Comment Required *'
const MAX_TOTAL_MINUTES = 720
const MIN_TOTAL_MINUTES = 15
const GREATER_THAN_12_HOURS_MESSAGE = 'The total time must not be greater than 12 hours'
const NO_HOURS_MESSAGE = 'The total time must be 15 minutes or greater'

const RequestCallIn = () => {
  const location = useLocation()
  const theme = useTheme()
  const styles = getStyles(theme)
  const dispatch = useDispatch()
  const user = useUser()
  const [formIncomplete, setFormIncomplete] = useState(true)
  const [selectableDatesMap, setSelectableDatesMap] = useState(new Map())
  const [selectedDate, setSelectedDate] = useState(null)
  const [comment, setComment] = useState('')
  const [callInCallOffDetail, setCallInCallOffDetail] = useState({
    type: TIME_OFF_TYPES.CALL_IN_LATE,
    startTime: null,
    hours: '',
    minutes: '',
  })
  const [rowDetailError, setRowDetailError] = useState(null)

  const [navigateToMyRequests, setNavigateToMyRequests] = useState(false)
  const navigate = useNavigate()

  const timeOffPostResponse = useSelector((state) => state.requestTimeOff.timeOffPostResponse)
  const timeOffPostErrorResponse = useSelector((state) => state.requestTimeOff.timeOffPostErrorResponse)
  const queryClient = useQueryClient()

  const initSelectableDatesMap = () => {
    const outputDateFormat = 'dddd, MMMM D YYYY'
    let todayDate = getNowDateInTimezone(user.locationData.iso_time_zone_code)
    let tomorrowDate = addDays(todayDate, 1)
    let todayDateFormatted = moment(todayDate, moment.HTML5_FMT.DATE)?.format(outputDateFormat)
    let tomorrowDateFormatted = moment(tomorrowDate, moment.HTML5_FMT.DATE)?.format(outputDateFormat)
    let newMap = new Map()
    newMap.set(todayDateFormatted, todayDate)
    newMap.set(tomorrowDateFormatted, tomorrowDate)

    setSelectableDatesMap(newMap)
  }

  useEffect(() => {
    if (location?.state?.scheduleDate) {
      const { scheduleDate, segmentStart } = location.state
      const inputDateTimeFormat = 'YYYY-MM-DD hh:mm:ss'
      const outputDateFormat = 'dddd, MMMM D YYYY'

      let startTime = moment(segmentStart, inputDateTimeFormat)

      setSelectedDate(moment(scheduleDate, moment.HTML5_FMT.DATE)?.format(outputDateFormat))
      setCallInCallOffDetail({
        type: TIME_OFF_TYPES.CALL_IN_LATE,
        startTime: startTime.toDate(),
        hours: '',
        minutes: '',
      })
    }
  }, [location])

  useEffect(() => {
    if (timeOffPostResponse) {
      dispatch(showNotificationSuccess(true, SUCCESS_ON_SAVE))
      dispatch(clearPreviousState())
      queryClient.invalidateQueries(['requests'], { requestType: REQUEST_TYPES.time_off })
      navigate(-1)
    } else if (timeOffPostErrorResponse) {
      if (
        timeOffPostErrorResponse.code &&
        timeOffPostErrorResponse.message &&
        ERROR_CODES_SHOW_MESSAGE.includes(timeOffPostErrorResponse.code)
      ) {
        dispatch(showNotificationError(true, timeOffPostErrorResponse.message))
      } else {
        dispatch(
          showNotificationError(
            true,
            UNABLE_TO_SAVE + ' ' + formatErrorCode(timeOffPostErrorResponse, TIME_OFF_ERROR_CODE),
          ),
        )
      }
      dispatch(clearPreviousState())
    }
  }, [timeOffPostResponse, timeOffPostErrorResponse, dispatch, queryClient, navigate])

  useEffect(() => {
    if (
      callInCallOffDetail.startTime !== null &&
      callInCallOffDetail.hours !== '' &&
      callInCallOffDetail.minutes !== '' &&
      callInCallOffDetail.type !== ''
    ) {
      setFormIncomplete(false)
    }

    if (callInCallOffDetail.hours !== '' && callInCallOffDetail.minutes !== '') {
      let totalMinutes = callInCallOffDetail.hours * 60 + callInCallOffDetail.minutes

      if (totalMinutes > MAX_TOTAL_MINUTES) {
        setRowDetailError(GREATER_THAN_12_HOURS_MESSAGE)
        setFormIncomplete(true)
      } else if (totalMinutes < MIN_TOTAL_MINUTES) {
        setRowDetailError(NO_HOURS_MESSAGE)
        setFormIncomplete(true)
      } else {
        setRowDetailError(null)
      }
    }
  }, [callInCallOffDetail])

  const handleDateClick = (date) => {
    setSelectedDate(date)
  }

  const handleCallInCallOffDetail = (key, value) => {
    setCallInCallOffDetail((prevState) => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }

  const renderDatePickerSection = () => {
    if (user.locationData.iso_time_zone_code) {
      if (selectableDatesMap.size === 0) {
        initSelectableDatesMap()
      }
      let formattedDates = [...selectableDatesMap.keys()]
      let menuItems = formattedDates.map((formattedDate) => {
        return (
          <MenuItem key={formattedDate} value={formattedDate}>
            {formattedDate}
          </MenuItem>
        )
      })
      return (
        <Paper sx={styles.datePickerContainer} elevation={1} square>
          <TextField
            data-cy={`requestCallInCallOff_dateSelector`}
            id="date"
            select
            label="Date"
            aria-label="Select date for Call In Late request"
            sx={styles.typeTextField}
            value={selectedDate == null ? '' : selectedDate}
            onChange={(e) => handleDateClick(e.target.value)}
            margin="normal"
            disabled={user.locationData.location_type === LOCATION_TYPE_STORE}
          >
            {menuItems}
          </TextField>
        </Paper>
      )
    }
  }

  const renderScrollableContent = () => {
    return (
      <Paper
        sx={styles.timeOffRequestScrollableContainer}
        id="scrollableContainer"
        data-cy="scrollableContainer_weekly"
        elevation={1}
        square
      >
        {selectedDate ? (
          <>
            <RequestCallInDetail
              requestDate={selectableDatesMap.get(selectedDate)}
              rowDetail={callInCallOffDetail}
              handleRowDetail={handleCallInCallOffDetail}
              rowDetailError={rowDetailError}
            />
            <Divider />
            {user.locationData.location_type === LOCATION_TYPE_DC && (
              <RequestCommentForm
                key={COMMENT_REQUIRED}
                setComment={setComment}
                value={comment}
                label={COMMENT_REQUIRED}
                maxLength={COMMENT_MAX_LENGTH}
              />
            )}
          </>
        ) : null}
      </Paper>
    )
  }

  const handleSubmitRequest = () => {
    let requestDetailMap = new Map()
    requestDetailMap.set(selectedDate, [callInCallOffDetail])
    dispatch(postRequestsData(user, requestDetailMap, comment))
  }

  const doesCommentDisableSubmit = () => {
    return user.locationData.location_type === LOCATION_TYPE_DC && !/\S/.test(comment)
  }

  const renderBottomButtons = () => {
    return (
      <Paper sx={styles.bottomSectionContainer} elevation={1} square>
        <RequestCallInSubmitCancel
          showCancelDialog={selectedDate !== null}
          disableSubmit={formIncomplete || doesCommentDisableSubmit()}
          setNavigateToMyRequests={setNavigateToMyRequests}
          handleSubmitRequest={() => handleSubmitRequest()}
          selectedDate={selectedDate}
          callInCallOffDetail={callInCallOffDetail}
        />
      </Paper>
    )
  }

  if (navigateToMyRequests) {
    dispatch(clearPreviousState())
    return <Navigate to={MY_REQUESTS_PAGE_PATH} />
  }

  return (
    <React.Fragment>
      <HeaderTitle title={REQUEST_SCREEN_TITLES.NOTIFY_CALL_IN} />
      <React.Fragment>
        {renderDatePickerSection()}
        {renderScrollableContent()}
        {renderBottomButtons()}
      </React.Fragment>
    </React.Fragment>
  )
}

export default RequestCallIn
