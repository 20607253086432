import { useAuth } from '@praxis/component-auth'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useUser } from '../../auth/hooks/useUser'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { SUCCESS_ON_SAVE } from '../../constants/errorConstants'
import { showNotificationError, showNotificationSuccess } from '../../store/notification/actionCreator'
import { removeNullValues } from '../../utils/objects'

const updateCommunication = async (communication, workerId) => {
  let apiUrl = `${apiConfig.userProfiles.url}/communications?key=${apiConfig.userProfiles.key}`
  let communicationBody = removeNullValues(communication)

  communicationBody.worker_id = workerId

  return axios.put(apiUrl, communicationBody, BASIC_REQUEST_CONFIG)
}

export function useUpdateCommunication() {
  const { isAuthenticated, login } = useAuth()
  const user = useUser()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const authenticated = isAuthenticated()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  return useMutation({
    mutationFn: (communicationBody) => updateCommunication(communicationBody, user.userData.worker_id),
    enabled: authenticated,
    onSuccess: (data, variables, context) => {
      dispatch(showNotificationSuccess(true, SUCCESS_ON_SAVE))
      queryClient.invalidateQueries({ queryKey: ['communications'] })
    },
    onError: (error) => {
      dispatch(
        showNotificationError(true, error?.response?.data?.message || 'Error updating or creating communication'),
      )
    },
  })
}
