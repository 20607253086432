import MyProtectedElement from '../../../auth/MyProtectedElement'
import { useUser } from '../../../auth/hooks/useUser'
import PendingActionsIcon from '../../Icons/PendingActionsIcon'
import GenericCard from '../../common/cards/GenericCard'
import AvailabilityDailyActions from './Categories/Availability/AvailabilityDailyActions'
import AvailabilityPatialLeaderDailyActions from './Categories/Availability/AvailabilityPatialLeaderDailyActions'
import PunchCorrectionDailyActions from './Categories/PunchCorrection/PunchCorrectionDailyActions'
import PunchCorrectionPartialLeaderDailyActions from './Categories/PunchCorrection/PunchCorrectionPartialLeaderDailyActions'
import TimeOffDailyActions from './Categories/TimeOff/TimeOffDailyActions'
import TimeOffPartialLeaderDailyActions from './Categories/TimeOff/TimeOffPartialLeaderDailyActions'

const DailyActionsCard = () => {
  const user = useUser()
  return (
    <MyProtectedElement
      allowed={['availability', 'time_off', 'punch_correction']}
      view={'leader'}
      renderUnauthorized={() => <></>}
      permissions={['partial', 'read', 'write']}
      checkCompensable={false}
    >
      <GenericCard title="Daily Actions" icon={<PendingActionsIcon />}>
        <div style={{ paddingTop: '8px' }}>
          {user.is('availability', 'leader') && <AvailabilityDailyActions />}
          {user.is('punch_correction', 'leader') && <PunchCorrectionDailyActions />}
          {user.is('time_off', 'leader') && <TimeOffDailyActions />}
          {user.is('availability', 'leader', 'partial') && <AvailabilityPatialLeaderDailyActions />}
          {user.is('punch_correction', 'leader', 'partial') && <PunchCorrectionPartialLeaderDailyActions />}
          {user.is('time_off', 'leader', 'partial') && <TimeOffPartialLeaderDailyActions />}
          {user.is('availability', 'leader', 'read', true) && <AvailabilityPatialLeaderDailyActions />}
          {user.is('punch_correction', 'leader', 'read', true) && <PunchCorrectionPartialLeaderDailyActions />}
          {user.is('time_off', 'leader', 'read', true) && <TimeOffPartialLeaderDailyActions />}
        </div>
      </GenericCard>
    </MyProtectedElement>
  )
}

export default DailyActionsCard
