import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { format } from 'date-fns'
import { useDispatch } from 'react-redux'
import { useUser } from '../../auth/hooks/useUser'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { showNotificationError } from '../../store/notification/actionCreator'
import { getDateOfTodayWithNoTimestamp } from '../../utils/DateUtil'
import { ITimecards } from './responses/timecardDaily'

interface TimecardDailyParams {
  workerId?: string
  locationId?: string
  selectedDate?: Date | number
}

export const getTimeCardDaily = async ({
  workerId,
  locationId,
  selectedDate,
}: TimecardDailyParams): Promise<ITimecards> => {
  let date = format(selectedDate ? selectedDate : getDateOfTodayWithNoTimestamp(), 'yyyy-MM-dd')

  let apiUrl =
    `${apiConfig.timecards.url}` +
    '/timecard_daily_view' +
    '?date=' +
    date +
    '&location_id=' +
    locationId +
    '&worker_id=' +
    workerId +
    '&key=' +
    `${apiConfig.timecards.key}`

  const { data } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)

  return data
}

interface UseTimecardDailyParams {
  selectedDate?: Date
  isEnabled?: boolean
}

export function useTimeCardDaily({
  selectedDate = getDateOfTodayWithNoTimestamp(),
  isEnabled,
}: UseTimecardDailyParams) {
  const { isAuthenticated, login } = useAuth()
  const dispatch = useDispatch()
  const user = useUser()

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  return useQuery({
    queryKey: [
      'timeCardDaily',
      {
        workerId: user.userData.worker_id,
        locationId: user.locationData.location_id,
        selectedDate: format(selectedDate, 'yyyy-MM-dd'),
      },
    ],
    queryFn: () =>
      getTimeCardDaily({
        workerId: user.userData.worker_id,
        locationId: user.locationData.location_id,
        selectedDate: selectedDate,
      }),
    staleTime: 1000 * 10,
    enabled: isEnabled && !!selectedDate && isAuthenticated(),
    onError: () => {
      dispatch(showNotificationError(true, 'Error loading time card data'))
    },
  })
}
