import { Button, Grid, Paper, Typography, useTheme } from '@mui/material'
import Divider from '@mui/material/Divider'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import RequestStatusIcon from '../../Requests/RequestStatusIcon'
import CategoryDetailDataSection from './CategoryDetailDataSection'
// import CommentSection from './CommentSection'
import { useDispatch, useSelector } from 'react-redux'
import { FIXED_END_DATE_FROM_UKG } from '../../../constants/AvailabilityConstants'
import { ERROR_CODES_SHOW_MESSAGE, SUCCESS_ON_SAVE, UNABLE_TO_SAVE } from '../../../constants/errorConstants'
import { LEADER_DATE_HEADER_FORMAT } from '../../../constants/leaderConstants'
import { clearPreviousState, updateAvailabilityRequestsData } from '../../../store/availability/actionCreator'
import { showNotificationError, showNotificationSuccess } from '../../../store/notification/actionCreator'
import { formatDateString } from '../../../utils/DateUtil'
import { formatErrorCode } from '../../../utils/ErrorHandling'
import { getAvailabilityApiData } from './AvailabilityApiData'
import AvailabilityDayWiseDetails from './Categories/Availability/AvailabilityDayWiseDetails'
import AvailabilityLeaderApproveReject from './Categories/Availability/AvailabilityLeaderApproveReject'

import { useUser } from '../../../auth/hooks/useUser'
import AvailabilityAuditDialog from '../../AuditTrail/AvailabilityAuditDialog'

const getHeaderSecondary = (data, DataEnum, AvailabilityDataMap) => {
  let fullName = AvailabilityDataMap.get(DataEnum.FullName)?.value(data)
  let workerId = AvailabilityDataMap.get(DataEnum.WorkerId)?.value(data)

  return [`${fullName} (${workerId})`]
}

const AVAILABILITY_REQUEST_ERROR_CODE = 'wfm-18-0'
const finalStateMessage = 'This request has reached its final state and can no longer be updated.'
const failedStatusMessage =
  'Please take the necessary action in UKG and then update the Failed status below accordingly.'

const getStyles = (theme) => ({
  readContentContainer: {
    flex: '1 1 auto',
    overflowY: 'auto',
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
  },
  actionContentContainer: {
    ...theme.mainScrollContainer,
    textAlign: 'right',
  },
  bottomButtons: {
    margin: '10px',
  },
  markAsProcessedContainer: {
    textAlign: 'left',
    padding: '10px',
  },
  markAsProcessedBtn: {
    textAlign: 'right',
  },
  markAsProcessedText: {
    margin: '0px 0px 15px 0px',
    color: 'tertiary.contrastText',
  },
  auditLink: {
    fontSize: '0.930rem',
    margin: '0px 0 15px 7px',
    textDecoration: 'underline',
    '&:focus': {
      textDecoration: 'underline',
    },
  },
})

const AvailabilityDetailPage = ({ data, getLabelValueList }) => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const user = useUser()
  const { DataEnum, AvailabilityDataMap } = useMemo(() => getAvailabilityApiData(), [])
  const [newAvailabilityRequestDataForPost, setNewAvailabilityRequestDataForPost] = useState()
  const userAvailabilityUpdateResponse = useSelector((state) => state.availability.userAvailabilityUpdateResponse)
  const userAvailabilityUpdateErrResponse = useSelector((state) => state.availability.userAvailabilityUpdateErrResponse)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [openAudit, setOpenAudit] = useState(false)

  let formattedStartDate = useMemo(
    () =>
      formatDateString(
        AvailabilityDataMap.get(DataEnum.StartDate).value(data),
        AvailabilityDataMap.get(DataEnum.StartDate).format,
        LEADER_DATE_HEADER_FORMAT,
      ),
    [AvailabilityDataMap, DataEnum.StartDate, data],
  )
  let formattedEndDate = useMemo(
    () =>
      formatDateString(
        AvailabilityDataMap.get(DataEnum.EndDate).value(data),
        AvailabilityDataMap.get(DataEnum.EndDate).format,
        LEADER_DATE_HEADER_FORMAT,
      ),
    [AvailabilityDataMap, DataEnum.EndDate, data],
  )

  //
  useEffect(() => {
    if (user.is('availability', 'leader', 'read', true)) {
      setButtonDisabled(true)
      return
    }

    if (user.is('availability', 'leader', 'partial') && data.status.toLowerCase() === 'pending') {
      setButtonDisabled(true)
    } else {
      setButtonDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Handle Update Sucess and failure case */
  useEffect(() => {
    if (userAvailabilityUpdateResponse) {
      dispatch(showNotificationSuccess(true, SUCCESS_ON_SAVE))
      dispatch(clearPreviousState())
      navigate('/leader/dashboard')
      queryClient.invalidateQueries(['availabilityRequests'])
    } else if (userAvailabilityUpdateErrResponse) {
      if (
        userAvailabilityUpdateErrResponse.code &&
        userAvailabilityUpdateErrResponse.message &&
        ERROR_CODES_SHOW_MESSAGE.includes(userAvailabilityUpdateErrResponse.code)
      ) {
        dispatch(showNotificationError(true, userAvailabilityUpdateErrResponse.message))
      } else {
        dispatch(
          showNotificationError(
            true,
            UNABLE_TO_SAVE + ' ' + formatErrorCode(userAvailabilityUpdateErrResponse, AVAILABILITY_REQUEST_ERROR_CODE),
          ),
        )
      }
      dispatch(clearPreviousState())
    }
  }, [userAvailabilityUpdateResponse, userAvailabilityUpdateErrResponse, dispatch, queryClient, navigate])

  const setDataForSubmission = (data) => {
    let obj = {}

    let availabilityRequestVO = {}
    availabilityRequestVO.availabilities = [data]

    obj.availabilityRequestVO = availabilityRequestVO

    setNewAvailabilityRequestDataForPost(obj)
  }

  const renderApproveCancel = () => {
    return (
      <Paper elevation={1} square>
        <AvailabilityLeaderApproveReject
          approveClicked={approveClicked}
          denyClicked={denyClicked}
          buttonDisabled={buttonDisabled}
        />
      </Paper>
    )
  }

  const renderProcessed = () => {
    return (
      <Paper elevation={1} square sx={styles.markAsProcessedContainer}>
        <Grid container>
          <Grid>
            <Typography sx={styles.markAsProcessedText}>{failedStatusMessage}</Typography>
          </Grid>
          <Grid item xs={12} md={12} sx={styles.markAsProcessedBtn}>
            <Button
              data-cy="availabilityMarkAsProcessed"
              variant="contained"
              color="primary"
              onClick={processedClicked}
            >
              Processed
            </Button>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const renderEndStateMessage = () => {
    return (
      <>
        <Typography sx={{ padding: '10px', textAlign: 'center', color: 'tertiary.contrastText' }}>
          {finalStateMessage}
        </Typography>
      </>
    )
  }

  const approveClicked = () => {
    callApi('Approved', 2)
  }

  const processedClicked = () => {
    callApi('Processed', 7)
  }

  const denyClicked = () => {
    callApi('Denied', 5)
  }

  const callApi = (status, statusId) => {
    let finalData = {
      leader_id: user.userData.worker_id,
      location_id: user.locationData.location_id,
      availability_id: newAvailabilityRequestDataForPost.availabilityRequestVO.availabilities[0].availability_id,
      // comment: '',
      status: status,
      status_id: statusId,
    }
    dispatch(updateAvailabilityRequestsData(finalData))
  }

  const renderActions = () => {
    if (data.status.toLowerCase() === 'pending') {
      return renderApproveCancel()
    } else if (data.status.toLowerCase() === 'failed') {
      return renderProcessed()
    } else {
      return renderEndStateMessage()
    }
  }

  const handleDialogOpen = () => {
    setOpenAudit(true)
  }

  const handleAuditDialogClose = () => {
    setOpenAudit(false)
  }

  return (
    <>
      {openAudit && (
        <AvailabilityAuditDialog
          requestId={AvailabilityDataMap.get(DataEnum.RequestId).value(data)}
          statusIcon={
            <RequestStatusIcon
              status={AvailabilityDataMap.get(DataEnum.Status).value(data)}
              isTimeOffTeamMember={false}
            />
          }
          headerPrimary={`${
            AvailabilityDataMap.get(DataEnum.EndDate).value(data) === FIXED_END_DATE_FROM_UKG
              ? formattedStartDate + ' - FOREVER'
              : formattedStartDate + ' - ' + formattedEndDate
          }`}
          headerSecondary={getHeaderSecondary(data, DataEnum, AvailabilityDataMap)}
          // auditData={auditData.data}
          auditDialogOpen={openAudit}
          handleClose={handleAuditDialogClose}
        />
      )}
      <Paper sx={styles.readContentContainer} elevation={1} square data-cy={'detailPageContent-detailPage'}>
        <CategoryDetailDataSection
          statusIcon={
            <RequestStatusIcon
              status={AvailabilityDataMap.get(DataEnum.Status).value(data)}
              isTimeOffTeamMember={false}
            />
          }
          headerPrimary={`${
            AvailabilityDataMap.get(DataEnum.EndDate).value(data) === FIXED_END_DATE_FROM_UKG
              ? formattedStartDate + ' - FOREVER'
              : formattedStartDate + ' - ' + formattedEndDate
          }`}
          headerSecondary={getHeaderSecondary(data, DataEnum, AvailabilityDataMap)}
          labelValueList={getLabelValueList(data, DataEnum, AvailabilityDataMap)}
        />
        <Button data-cy="view-full-audit-report-btn" sx={{ ...styles.auditLink }} onClick={handleDialogOpen}>
          View full audit report
        </Button>
        <AvailabilityDayWiseDetails
          requestId={AvailabilityDataMap.get(DataEnum.RequestId).value(data)}
          workerId={AvailabilityDataMap.get(DataEnum.WorkerId).value(data)}
          startDate={AvailabilityDataMap.get(DataEnum.StartDate).value(data)}
          endDate={AvailabilityDataMap.get(DataEnum.EndDate).value(data)}
          patternName={AvailabilityDataMap.get(DataEnum.PatternName).value(data)}
          patternType={AvailabilityDataMap.get(DataEnum.PatternType).value(data)}
          status={AvailabilityDataMap.get(DataEnum.Status).value(data)}
          requestDetails={AvailabilityDataMap.get(DataEnum.RequestDetails)?.value(data)}
          setDataForSubmission={setDataForSubmission}
        />
        {/* <CommentSection comments={data?.comments} /> */}
      </Paper>
      <Paper
        sx={styles.actionContentContainer}
        elevation={1}
        square
        component="h3"
        aria-label={'Update status section'}
      >
        <Divider aria-hidden="true" />
        {/* {data.status === 'Pending' ? renderBottomButtons() : displayEndStateMessage()} */}
        {renderActions()}
      </Paper>
    </>
  )
}

export default AvailabilityDetailPage
