import { Info } from '@mui/icons-material'
import DownloadIcon from '@mui/icons-material/CloudDownload'
import { CircularProgress, Link, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '../../auth/hooks/useUser'
import {
  ERROR_CODES_SHOW_MESSAGE_REDEMPTION_CODE,
  ERROR_CODE_CANNOT_CONNECT_TO_WORKER_SERVER,
  LOAD_REDEMPTION_CODES_ERROR_MESSAGE,
} from '../../constants/errorConstants'
import {
  handleResetAppleIosDownloadPage,
  handleWorkerRedemptionCodeGetData,
  setIsAdditionalDeviceStepsDialogOpen,
  setLoading,
} from '../../store/workerRedemptionCode/actionCreator'
import { formatErrorCode } from '../../utils/ErrorHandling'
import HeaderTitle from '../Header/HeaderTitle'
import AppleIcon from '../Icons/AppleIcon'
import AdditionalDeviceStepsDialog from './AdditionalDeviceStepsDialog'
import IssueAndRedeemCard from './IssueAndRedeemCard'
import IssueCodeDialog from './IssueCodeDialog'
import RedeemCodeDialog from './RedeemCodeDialog'

const getStyles = (theme) => ({
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  infoMessage: theme.infoMessages,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  gridItemCard: {
    padding: '8px 6px 8px 6px',
  },
  gridItemLogo: {
    padding: '12px 20px 0px 20px',
    display: 'flex',
    justifyContent: 'center',
  },
  gridItemText: {
    padding: '12px 20px 12px 20px',
  },
  gridItemTextList: {
    paddingLeft: '20px',
  },
  loader: theme.loadingIconContainer,
  cardTitleContainer: {
    display: 'flex',
    flexGrow: '1',
  },
  cardTitle: {
    marginLeft: '.4em',
    fontWeight: 'bold',
  },
  cardAction: {
    padding: '4px 16px 4px 16px',
    display: 'flex',
  },
  cardDescription: {
    paddingBottom: '12px',
  },
  errorIcon: {
    marginRight: '8px',
    color: theme.palette.primary.main,
  },
  issuedCodeMsg: {
    color: theme.palette.tertiary.contrastText,
    fontStyle: 'italic',
  },
  messageContainer: {
    display: 'flex',
    maxHeight: 120,
    overflowY: 'auto',
  },
  buttonContainer: {
    width: '130px',
  },
  buttonWarningMessageContainer: {
    display: 'flex',
  },
  icon: {
    marginRight: '8px',
    color: '#EED202',
    fontSize: '20px',
  },
  downloadIcon: {
    margin: '0px 16px 0px 16px',
    fontSize: '48px',
  },
  infoIcon: {
    marginRight: '4px',
    fontSize: '12px',
  },
})

const AppleIosDownloadPage = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const INFO_MSG_1 =
    'This page will help you get the app version of myTime downloaded/installed onto your Apple/iOS device.'
  const INFO_MSG_2 =
    'The myTime for Target app is not available to the public in the App Store. Only Target team members have access to download the app via a special process where you will get issued a unique code from Target and then redeem it with Apple. '
  const OTHER_SCENARIOS_HEADER = 'Other Scenarios'
  const INFO_MSG_3 = 'If you want the app for an Android device, please visit the public Google Play store.'
  const INFO_MSG_4a =
    'If you have already installed the app by redeeming a code, but now want the app on a different Apple/iOS device, please click '
  const INFO_MSG_4b = ' for instructions.'

  const dispatch = useDispatch()

  const user = useUser()

  const { data, getError, loading, isIssueCodeDialogOpen, isRedeemCodeDialogOpen, isAdditionalDeviceStepsDialogOpen } =
    useSelector((state) => state.workerRedemptionCode)

  useEffect(() => {
    dispatch(setLoading(true))
    dispatch(handleWorkerRedemptionCodeGetData(user.userData.worker_id, false))
  }, [dispatch, user.userData.worker_id])

  // Unmounting the component
  useEffect(() => {
    return () => {
      dispatch(handleResetAppleIosDownloadPage())
    }
  }, [dispatch])

  const handleOtherDeviceStepsClick = () => {
    dispatch(setIsAdditionalDeviceStepsDialogOpen(true))
  }

  const renderGeneralInstructionsText = () => {
    return (
      <React.Fragment>
        <Typography variant="body1" gutterBottom data-cy="AppleDownloadPageInfoMsg1">
          {INFO_MSG_1}
        </Typography>
        <Typography variant="caption" gutterBottom data-cy="AppleDownloadPageInfoMsg2">
          <Info sx={styles.infoIcon} />
          {INFO_MSG_2}
        </Typography>
      </React.Fragment>
    )
  }

  const renderOtherScenariosText = () => {
    return (
      <React.Fragment>
        <Typography variant="subtitle1" component="h3" data-cy="AppleDownloadPageOtherScenariosHeader">
          {OTHER_SCENARIOS_HEADER}
        </Typography>
        <ul style={styles.gridItemTextList}>
          <li>
            <Typography variant="body2" gutterBottom data-cy="AppleDownloadPageOtherScenariosMsg1">
              {INFO_MSG_3}
            </Typography>
          </li>
          <li>
            <Typography variant="body2" gutterBottom data-cy="AppleDownloadPageOtherScenariosMsg2">
              {INFO_MSG_4a}
              <Link onClick={handleOtherDeviceStepsClick} data-cy="AppleDownloadPageAdditionalDeviceLink">
                here
              </Link>
              {INFO_MSG_4b}
            </Typography>
          </li>
        </ul>
      </React.Fragment>
    )
  }
  const renderSavingSpinner = () => {
    return (
      <div style={styles.loader}>
        <CircularProgress size={48} />
      </div>
    )
  }

  const renderErrors = (errorObj) => {
    if (errorObj.message && errorObj.code && ERROR_CODES_SHOW_MESSAGE_REDEMPTION_CODE.includes(errorObj.code)) {
      return <p style={styles.errorMessage}>{errorObj.message}</p>
    } else {
      return (
        <p style={styles.errorMessage}>
          {LOAD_REDEMPTION_CODES_ERROR_MESSAGE}
          <span style={styles.errorCodeMessage}>
            {formatErrorCode(errorObj, ERROR_CODE_CANNOT_CONNECT_TO_WORKER_SERVER)}
          </span>
        </p>
      )
    }
  }

  const renderAppleIosDownloadPageContentWithData = () => {
    return (
      <Grid container>
        <Grid item xs={12} sx={styles.gridItemLogo}>
          <AppleIcon />
          <DownloadIcon sx={styles.downloadIcon} />
        </Grid>
        <Grid item xs={12} sx={styles.gridItemText}>
          {renderGeneralInstructionsText()}
        </Grid>
        <Grid item xs={12} sx={styles.gridItemCard}>
          <IssueAndRedeemCard />
        </Grid>
        <Grid item xs={12} sx={styles.gridItemText}>
          {renderOtherScenariosText()}
        </Grid>
      </Grid>
    )
  }

  const renderAppleIosDownloadPageContent = () => {
    if (loading) return <React.Fragment>{renderSavingSpinner()}</React.Fragment>
    if (data) {
      return renderAppleIosDownloadPageContentWithData()
    }
    if (getError) {
      return renderErrors(getError)
    }
    return <React.Fragment>{renderSavingSpinner()}</React.Fragment>
  }

  const renderDialogs = () => {
    return (
      <React.Fragment>
        {isIssueCodeDialogOpen ? <IssueCodeDialog /> : null}
        {isRedeemCodeDialogOpen ? (
          <RedeemCodeDialog code={data && data.redemption_codes ? data.redemption_codes[0] : null} />
        ) : null}
        {isAdditionalDeviceStepsDialogOpen ? (
          <AdditionalDeviceStepsDialog data-cy="AdditionalDeviceStepsDialog" />
        ) : null}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <HeaderTitle title="Apple/iOS Download" />
      {renderAppleIosDownloadPageContent()}
      {renderDialogs()}
    </React.Fragment>
  )
}

export default AppleIosDownloadPage
