import { createContext } from 'react'
import { User } from './User'

export type UserContextType = {
  user: User
}

const UserContext = createContext<UserContextType | undefined>(undefined)

export default UserContext
