import { SET_COMMUNICATION_OPEN, SET_CONFIRM, SET_HEADER_TITLE, SHOW_AUTH_POPUP } from './actionType'

export function showAuthPopup(popupType) {
  return {
    type: SHOW_AUTH_POPUP,
    payload: popupType,
  }
}

export function setHeaderTitle(headerTitle) {
  return {
    type: SET_HEADER_TITLE,
    payload: headerTitle,
  }
}

export function setConfirm(confirmObject) {
  return {
    type: SET_CONFIRM,
    payload: confirmObject,
  }
}

export function setCommunicationOpen(payload) {
  return {
    type: SET_COMMUNICATION_OPEN,
    payload,
  }
}
