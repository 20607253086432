import { Autocomplete, TextField } from '@mui/material'
import { useFeatures } from '../../../../api/userProfiles/useFeatures'

export default function FeatureDropdown({
  handleFormValueChange,
  handleInputValueChange,
  formValues,
  selectInputValues,
}) {
  const { data: features } = useFeatures()
  return (
    <Autocomplete
      name="audience_features"
      fullWidth
      multiple
      disableCloseOnSelect
      size="small"
      id="audience_features-select"
      options={features?.data || []}
      value={formValues.audience_features}
      onChange={(event, newValue) => {
        handleFormValueChange('audience_features', newValue)
      }}
      inputValue={selectInputValues.audience_features}
      onInputChange={(event, newInputValue) => {
        handleInputValueChange('audience_features', newInputValue)
      }}
      limitTags={3}
      renderInput={(params) => (
        <TextField
          {...params}
          key={params.key}
          variant="standard"
          label="Audience Features"
          placeholder="Leave blank for all features"
        />
      )}
    />
  )
}
