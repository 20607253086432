import { useAuth } from '@praxis/component-auth'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { useUser } from '../../auth/hooks/useUser'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

/**
 * Query params used in GET /leader_punch_correction_decisions in the punch_correction api (for a given range of dates, location and
 * optional parameters - list of keys, departments, jobs, statuses, types, perPage, sortOrder and for a given page
 * returns all the eligible team member punch correction records in the sort order specified).
 *
 * @param selectedLocationId Required - String - 4 number string representing location number
 * @param startDate Required - String (Format 'yyyy-MM-dd') - start range of which to get requests by their effective date.
 * @param endDate Required - String (Format 'yyyy-MM-dd') - end range of which to get requests by their effective date.
 * @param searchableName Optional - String - Character sequence used to match first or last name
 * @param columnSortBy Optional - String - Name of data to sort results by
 * @param sortOrder Optional - String (default: 'ASC') - Either 'ASC' or 'DESC'
 * @param filterParams Optional - Array of objects. Note: objects in array can have same key
 *                     Example [{ key: 'statuses', value: 'Pending' },
 *                              { key: 'statuses', value: 'Failed' },
 *                              { key: 'types', value: 'PTO - Vacation' }]
 * @param filterRequired Optional - Boolean - A flag to determine if a filter value is required depending on user.
 * @param perPage Optional - Number (default: 50) - The amount of records per page. Max is 500.
 * @param pageNum Optional - Number (default: 1) - The pagination page number. First page starts at 1.
 * @param category Optional - snake case value.
 */
export function PunchCorrectionRequestsQueryParams(
  selectedLocationId,
  startDate,
  endDate,
  searchableName,
  columnSortBy,
  sortOrder,
  filterParams,
  filterRequired,
  perPage,
  pageNum,
  category,
  supervisorId,
  shortPersona,
) {
  this.selectedLocationId = selectedLocationId
  this.startDate = startDate
  this.endDate = endDate
  this.searchableName = searchableName
  this.columnSortBy = columnSortBy
  this.sortOrder = sortOrder
  this.filterParams = filterParams
  this.filterRequired = filterRequired
  this.perPage = perPage
  this.pageNum = pageNum
  this.category = category
  this.supervisorId = supervisorId
  this.short_persona = shortPersona

  this.getUrlSearchParams = function () {
    const params = new URLSearchParams()
    params.append('key', `${apiConfig.timeOff.key}`)
    if (selectedLocationId) params.append('location_id', selectedLocationId)
    if (startDate) params.append('start_date', startDate)
    if (endDate) params.append('end_date', endDate)
    if (searchableName) params.append('searchable_name', searchableName)
    if (columnSortBy) params.append('sort_by', columnSortBy)
    if (sortOrder) params.append('sort_order', sortOrder)
    if (filterParams) {
      filterParams.forEach((queryParam) => {
        params.append(queryParam.key, queryParam.value)
      })
    }
    if (filterRequired !== null) params.append('filter_required', filterRequired)
    if (pageNum) params.append('page', pageNum)
    if (perPage) params.append('per_page', perPage)
    if (this.category) {
      params.append(
        'category',
        category === 'punch_correction_partial_action' || category === 'punch_correction_read_only'
          ? 'punch_correction'
          : category,
      )
    }
    if (supervisorId) params.append('supervisor_id', supervisorId)
    if (shortPersona) params.append('short_persona', shortPersona)
    return params
  }

  this.hasRequiredParams = function () {
    return !!(this.selectedLocationId && this.startDate && this.endDate && typeof this.filterRequired == 'boolean')
  }

  this.getQueryKey = function () {
    return [
      'punchCorrectionRequests',
      this.category,
      this.selectedLocationId,
      this.startDate,
      this.endDate,
      this.searchableName,
      this.columnSortBy,
      this.sortOrder,
      this.filterParams,
      this.filterRequired,
      this.perPage,
      this.pageNum,
      this.supervisorId,
      this.short_persona,
    ]
  }
}

const getPunchCorrectionRequests = (queryParamsString) => {
  let apiUrl = `${apiConfig.punchCorrection.url}` + `/leader_punch_correction_decisions?` + queryParamsString
  return axios.get(apiUrl, BASIC_REQUEST_CONFIG)
}

export function usePunchCorrectionRequests() {
  const { isAuthenticated, login } = useAuth()
  const authenticated = isAuthenticated()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }
  const selectedCategory = useSelector((state) => state.leader.selectedCategory)
  const selectedLocation = useSelector((state) => state.leader.selectedLocation)
  const selectedCategoryState = useSelector((state) => state.leader[selectedCategory])
  const { userData } = useUser()

  let queryParams = new PunchCorrectionRequestsQueryParams(
    selectedLocation,
    selectedCategoryState?.startDate ? format(selectedCategoryState?.startDate, 'yyyy-MM-dd') : null,
    selectedCategoryState?.endDate ? format(selectedCategoryState?.endDate, 'yyyy-MM-dd') : null,
    selectedCategoryState?.searchText,
    getSelectedSortBy(selectedCategoryState?.sortableColumns, selectedCategoryState?.selectedSortByIndex),
    selectedCategoryState?.sortOrder,
    getSelectedFilters(selectedCategoryState?.filters),
    false,
    selectedCategoryState?.rowsPerPage,
    selectedCategoryState?.page,
    selectedCategoryState?.configFeatureName,
    userData.worker_id,
    userData.short_persona,
  )

  return useQuery({
    queryKey: queryParams.getQueryKey(),
    queryFn: () => getPunchCorrectionRequests(queryParams.getUrlSearchParams().toString()),
    enabled: queryParams.hasRequiredParams(),
    keepPreviousData: true,
    staleTime: 1000 * 5,
    onError: (error) => {
      const loggingInfo = {
        message: 'GET /leader_punch_correction_decisions response has an error (usePunchCorrectionRequests).',
        url: window.location.href,
        error: {
          url: error?.config?.url,
          data: error?.response?.data,
          headers: error?.response?.headers,
          status: error?.response?.status,
          statusText: error?.response?.statusText,
          queryParams: queryParams,
        },
      }
      const optionalLoggingOptions = {
        level: LogLevel.Error,
      }
      logEvent(loggingInfo, optionalLoggingOptions)
    },
  })
}

const getSelectedFilters = (filters) => {
  let selectedArray = []
  if (filters) {
    Object.entries(filters).forEach(([key, filterObject]) => {
      filterObject?.selectedValues?.forEach((value) => {
        selectedArray.push({ key, value })
      })
    })
  }
  if (selectedArray === undefined || selectedArray?.length === 0) return null
  return selectedArray
}

const getSelectedSortBy = (sortableColumns, index) => {
  if (sortableColumns === undefined || index === undefined) return null
  return sortableColumns[index]?.columnName
}
