import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IError } from '../../api/globalResponses'
import MyProtectedElement from '../../auth/MyProtectedElement'
import { useUser } from '../../auth/hooks/useUser'
import { setDailyScheduleSelectedDate } from '../../store/dailySchedulePage/actionCreator'
import { setBackToComponent } from '../../store/header/actionCreator'
import { formatErrorCode } from '../../utils/ErrorHandling'
import HeaderTitle from '../Header/HeaderTitle'
import AvailableShiftsCard from './AvailableShiftsCard'
import CommunicationCard from './CommunicationCard'
import MandatoryGroupCard from './MandatoryGroupCard'
import MassVacationCard from './MassVacationCard'
import NextShiftCard from './NextShiftCard'
import SwapShiftsCard from './SwapShiftsCard'
import TimecardWeeklyStatusCard from './TimecardWeeklyStatusCard'

const HOME_PAGE_PATH = '/'

const getStyles = (theme: any) => ({
  mainScrollContainer: theme.mainScrollContainer,
  mainContainerPosition: theme.mainContainerPosition,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  loadingIconContainer: theme.loadingIconContainer,
  description: {
    margin: theme.spacing(1),
    fontSize: 'medium',
    fontWeight: 'bold',
    paddingTop: '.5em',
  },
})

function HomePage() {
  const theme = useTheme()
  const styles = getStyles(theme)

  const dispatch = useDispatch()
  const backToComponent = useSelector((state: any) => state.header.backToComponent)
  const user = useUser()

  useEffect(() => {
    if (backToComponent === HOME_PAGE_PATH) {
      dispatch(setBackToComponent(null))
    }
  }, [dispatch, backToComponent])

  const handleLink = (date: Date) => {
    dispatch(setBackToComponent(HOME_PAGE_PATH))
    dispatch(setDailyScheduleSelectedDate(date))
  }

  const renderHomePageCardError = (
    errorResponse: IError,
    defaultErrorMessage: string,
    errorCodesToDisplayMessage: string,
    cannotConnectErrorCode: string,
  ) => {
    if (errorResponse?.message && errorResponse?.code && errorCodesToDisplayMessage?.includes(errorResponse.code)) {
      return (
        <p style={styles.errorMessage} data-cy="HomePageCardErr">
          {errorResponse.message}
        </p>
      )
    } else {
      return (
        <p style={styles.errorMessage}>
          {defaultErrorMessage}
          <span style={styles.errorCodeMessage}>{formatErrorCode(errorResponse, cannotConnectErrorCode)}</span>
        </p>
      )
    }
  }

  return (
    <React.Fragment>
      <HeaderTitle title="Home" />
      <Grid container>
        <Grid item xs={12} md={12}>
          <p style={styles.description}> Welcome {user.userData.first_name}! </p>
        </Grid>

        <MyProtectedElement component={MassVacationCard} allowed={['mass_vacation']} renderUnauthorized={() => <></>} />

        <MyProtectedElement allowed={['schedule']} renderUnauthorized={() => <></>}>
          <NextShiftCard handleLink={handleLink} renderHomePageCardError={renderHomePageCardError} />
        </MyProtectedElement>

        <MyProtectedElement allowed={['shift_swap']} renderUnauthorized={() => <></>}>
          <SwapShiftsCard renderHomePageCardError={renderHomePageCardError} />
        </MyProtectedElement>

        <MyProtectedElement allowed={['open_shifts', 'request_to_cover']} renderUnauthorized={() => <></>}>
          <AvailableShiftsCard
            renderHomePageCardError={renderHomePageCardError}
            locationTimezone={user.locationData?.iso_time_zone_code}
          />
        </MyProtectedElement>

        <MyProtectedElement allowed={['timecard']} renderUnauthorized={() => <></>}>
          <TimecardWeeklyStatusCard
            renderHomePageCardError={renderHomePageCardError}
            locationTimezone={user.locationData?.iso_time_zone_code}
          />
        </MyProtectedElement>

        <MyProtectedElement allowed={['voluntary_availability']} renderUnauthorized={() => <></>}>
          <MandatoryGroupCard renderHomePageCardError={renderHomePageCardError} />
        </MyProtectedElement>

        {/*
        * This was for SUS Survey. We have no feature access for it in v2. we have 'feedback' but that is our own internal.
        <MyProtectedElement component={FeedbackSurveyCard} allowed={['survey']} renderUnauthorized={() => <></>} />
        */}

        <MyProtectedElement
          component={CommunicationCard}
          allowed={['communication']}
          renderUnauthorized={() => <></>}
        />
      </Grid>
    </React.Fragment>
  )
}

export default HomePage
