import { Navigate } from 'react-router-dom'
import { UnauthorizedCausedByEnum } from '../auth/User'
import { useUser } from '../auth/hooks/useUser'
import NotAuthorized from '../components/common/fallback/NotAuthorized'

const TEAM_MEMBER_HOME_PATH = '/team-member/home'
const LEGACY_LEADER_PATH = '/leaderView'
const LEADER_DASHBOARD_PATH = '/leader/dashboard'
const LEADER_MY_TEAM_PATH = '/leader/myteam'

function HomeRedirect() {
  const user = useUser()

  if (user.userData.total_features === 0) {
    return <NotAuthorized causedBy={UnauthorizedCausedByEnum.FEATURE} showHome={false} showRetry={true} />
  }

  if (user.is('schedule', 'team_member')) {
    return <Navigate to={TEAM_MEMBER_HOME_PATH} replace />
  }

  if (user.is('call_in', 'leader') || user.is('call_off', 'leader')) {
    return <Navigate to={LEADER_DASHBOARD_PATH} replace />
  }

  if (user.is('dashboard', 'leader')) {
    return <Navigate to={LEGACY_LEADER_PATH} replace />
  }

  if (
    user.is('punch_correction', 'leader', 'read', true) ||
    user.is('availability', 'leader', 'read', true) ||
    user.is('time_off', 'leader', 'read', true)
  ) {
    return <Navigate to={LEADER_MY_TEAM_PATH} replace />
  }

  return <Navigate to={TEAM_MEMBER_HOME_PATH} replace />
}

export default HomeRedirect
