import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useUser } from '../../auth/hooks/useUser'
import { User } from '../../auth/User'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

interface GetCommunicationsResponse {
  data: ICommunications
  headers: { 'x-total-count': string; [key: string]: string }
}

const getCommunication = async (
  user: User,
  isForPopup: boolean,
  page = 1,
  perPage = 25,
): Promise<GetCommunicationsResponse> => {
  let workerId = user.userData.worker_id
  let locationId = user.userData.location_id
  let featureCombinations = user.getAllFeatureViewCombos()
  let personaNoLocation = user.userData.persona_no_location

  let apiUrl =
    `${apiConfig.userProfiles.url}` +
    '/communications' +
    '?worker_id=' +
    workerId +
    '&location_id=' +
    locationId +
    '&feature_combinations=' +
    featureCombinations +
    '&persona_no_location=' +
    personaNoLocation +
    '&page=' +
    page +
    '&per_page=' +
    perPage +
    '&key=' +
    `${apiConfig.userProfiles.key}`

  const { data, headers } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)
  if (isForPopup) {
    return getUnviewedCommunications(data, headers)
  }

  return { data: data, headers: headers as GetCommunicationsResponse['headers'] }
}

export interface SavedCommunication {
  id: number
  updated_timestamp: string
}

const getUnviewedCommunications = (data: ICommunications, headers: any): GetCommunicationsResponse => {
  let savedViewedCommunications = JSON.parse(localStorage?.getItem('viewed_communications') || '[]')
  if (!savedViewedCommunications) return { data: data, headers: headers }

  let unviewedCommunications = data?.communications?.reduce<ICommunication[]>((acc, communication) => {
    let doesExist = savedViewedCommunications.some((savedCom: SavedCommunication) => {
      return savedCom.id === communication.id && savedCom.updated_timestamp === communication.updated_timestamp
    })

    if (!doesExist) {
      acc.unshift(communication)
    }

    return acc
  }, [])

  return {
    data: { ...data, communications: unviewedCommunications, total_communications: unviewedCommunications?.length },
    headers: headers,
  }
}

export function useCommunication(isForPopup: boolean, page: number, perPage: number) {
  const { isAuthenticated, login } = useAuth()
  const user = useUser()
  const authenticated = isAuthenticated()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  return useQuery({
    queryKey: ['communications', isForPopup, page, perPage],
    queryFn: () => getCommunication(user, isForPopup, page, perPage),
    enabled: authenticated,
    retry: isForPopup ? false : 1,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
    refetchInterval: isForPopup ? 1000 * 60 * 2 : false,
    staleTime: 1000 * 15,
    keepPreviousData: true,
  })
}
