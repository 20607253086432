export const NON_EXEMPT_USER_GROUPS = [
  'dc_team_member',
  'dc_non_exempt_manager',
  'store_team_member',
  'store_on_demand_team_member',
  'store_non_exempt_hr_manager',
  'dc_merit_team_member',
  'rgd_team_member',
  'office_team_member',
  'store_executive_team_member',
  'store_small_format_leader',
  'store_hourly_team_lead',
]

export const PERSONAS = {
  DC_LEADER_EX: 'dc:leader:EX',
  DC_HR_NE: 'dc:hr:NE',
}
