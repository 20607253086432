import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import NotificationError from '../Notifications/NotificationError'
import Notifications from '../Notifications/Notifications'
import NotificationSuccess from '../Notifications/NotificationSuccess'

import { useEffect } from 'react'
import { useUser } from '../../auth/hooks/useUser'
import MyProtectedElement from '../../auth/MyProtectedElement'
import useCommunicationData from '../../hooks/useCommunicationData'
import { setLeaderConfig } from '../../store/leader/actionCreator'
import '../../styles/index.css'
import CommunicationDialog from '../common/dialog/CommunicationDialog'
import ConfirmDialog from '../common/dialog/ConfirmDialog'
import { useDeviceRegistration } from './hooks/useDeviceRegistration'
import NavigationLayout from './NavigationLayout'

export const Layout = () => {
  useDeviceRegistration()
  const dispatch = useDispatch()

  const user = useUser()

  useEffect(() => {
    if (user.leaderConfig.selectableCategories.length > 0) {
      dispatch(setLeaderConfig(user.leaderConfig))
    }
  }, [dispatch, user.leaderConfig])

  return (
    <>
      <Helmet defaultTitle="myTime" titleTemplate="%s" />
      <NavigationLayout />
      <Outlet />
      <ConfirmDialog />
      <MyProtectedElement
        component={CommunicationDialogContainer}
        allowed={['communication']}
        renderUnauthorized={() => <></>}
      />
      <NotificationSuccess />
      <Notifications />
      <NotificationError />
    </>
  )
}

function CommunicationDialogContainer() {
  const communicationProps = useCommunicationData(true)
  const isOpen = useSelector((store: any) => store.layout.isCommunicationOpen)

  return <CommunicationDialog {...communicationProps} isOpen={isOpen} />
}

export default Layout
