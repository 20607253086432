import { DATE_TIME_FORMATTING } from '../utils/DateUtil'
import {
  ACKNOWLEDGED,
  AVAILABILITY,
  CALL_IN_LATE,
  CALL_OFF_ABSENT,
  CANCELLED,
  DENIED,
  FAILED,
  FAILED_CANCELLED,
  PENDING,
  PROCESSED,
  PUNCH_CORRECTION,
  SCHEDULED,
  TIME_OFF,
  WAITLISTED,
} from './RequestConstants'

export const LEADER_DASHBOARD_TITLE = 'Dashboard'
export const LEADER_MY_TEAM_TITLE = 'My Team'

export const CATEGORY_FEATURE_MAP = new Map([
  [TIME_OFF, 'paid_time_off'],
  [CALL_IN_LATE, 'call_in'],
  [CALL_OFF_ABSENT, 'call_off'],
  [AVAILABILITY, 'availability'],
  [PUNCH_CORRECTION, 'punch_correction'],
])

// NEW LEADER CONFIGURATION
export const UPDATE_TO_STATUS_MAP = {
  [CALL_IN_LATE]: {
    [PENDING]: [ACKNOWLEDGED],
    [FAILED]: [ACKNOWLEDGED],
  },
  [CALL_OFF_ABSENT]: {
    [PENDING]: [ACKNOWLEDGED],
    [FAILED]: [ACKNOWLEDGED],
  },
  [TIME_OFF]: {
    [PENDING]: [SCHEDULED, DENIED],
    [DENIED]: [SCHEDULED],
    [FAILED]: [DENIED, PROCESSED],
    [FAILED_CANCELLED]: [CANCELLED],
  },
}

// LEGACY LEADER CONFIGURATION
export const STATUS_MAP_BY_REQUEST_TYPE_GROUP = {
  PTO_VACATION_AUTOMATION: {
    [PENDING]: [SCHEDULED, DENIED, WAITLISTED],
    [SCHEDULED]: [DENIED, WAITLISTED],
    [DENIED]: [SCHEDULED, WAITLISTED],
    [WAITLISTED]: [SCHEDULED, DENIED],
    [ACKNOWLEDGED]: [SCHEDULED, DENIED, WAITLISTED, PENDING, PROCESSED],
    [FAILED]: [DENIED, PROCESSED],
  },
  PTO_OTHER: {
    [PENDING]: [SCHEDULED, DENIED, WAITLISTED],
    [SCHEDULED]: [DENIED, WAITLISTED, PROCESSED],
    [DENIED]: [SCHEDULED, WAITLISTED],
    [WAITLISTED]: [SCHEDULED, DENIED],
    [PROCESSED]: [SCHEDULED, DENIED, WAITLISTED],
    [ACKNOWLEDGED]: [SCHEDULED, DENIED, WAITLISTED, PENDING, PROCESSED],
  },
  // automation & non-automation
  CALL_IN_OFF: {
    [PENDING]: [ACKNOWLEDGED],
    [FAILED]: [ACKNOWLEDGED],
  },
}

export const LEADER_DATE_HEADER_FORMAT = DATE_TIME_FORMATTING.smallDate
export const LEADER_TIMESTAMP_DETAIL_FORMAT = DATE_TIME_FORMATTING.smallDateTime
