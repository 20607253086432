import { Button, CircularProgress, Paper, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '../../auth/hooks/useUser'
import { clearPreviousState, postDesiredHours, setDesiredHoursLoading } from '../../store/desiredHours/actionCreator'
import { showNotificationError, showNotificationSuccess } from '../../store/notification/actionCreator'
import { formatErrorCode } from '../../utils/ErrorHandling'
import HeaderTitle from '../Header/HeaderTitle'

const ERROR_CODES_SHOW_MESSAGE = ['wfm-6-5', 'wfm-6-1']
const ERROR_CODE_INPUT_INVALID = 'wfm-6-2'
const ERROR_INVALID_INPUT = 'The hours you input were invalid, please input only whole numbers.'
const SAVE_ERROR_MESSAGE =
  'Unable to save your desired hours at this time. Please try again later and if the issue persists, contact the CSC.'
const ERROR_CODE_CANNOT_CONNECT_TO_SERVER = 'wfm-6-0'
const DESIRED_HOURS_ERROR = 'Valid values are 4 - 40'
const ABOUT_TEXT =
  'Target is committed to do all we can to support TM’s hours and schedule preferences. While leaders will try to ' +
  'schedule to your desired hours each week, we’re unable to provide a guaranteed number of hours each week.'
const QUESTION_PROMPT = 'How many hours, on average, would you like to work each week?'
const symbolsArr = ['e', 'E', '+', '-', '.']

const getStyles = (theme) => ({
  mainScrollContainer: {
    ...theme.mainScrollContainer,
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '20px',
      marginTop: '20px',
    },
  },
  mainContainerPosition: theme.mainContainerPosition,
  infoMessage: theme.infoMessages,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  loader: theme.loadingIconContainer,
  errorIcon: {
    marginRight: '8px',
    color: theme.palette.primary.main,
  },
  messageContainer: {
    display: 'flex',
    maxHeight: 120,
    overflowY: 'auto',
  },
  contentContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  headerTitle: {
    display: 'flex',
    fontSize: '20px',
    justifyContent: 'center',
    paddingBottom: '20px',
    fontWeight: 'bold',
  },
  aboutTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px 0',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    width: '85%',
    textAlign: 'center',
  },
  formInput: {
    width: '175px',
    paddingRight: '10px',
  },
  formSubmit: {
    height: '40px',
  },
  infoTextContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingBottom: '10px',
  },
  infoText: {
    color: theme.palette.text.secondary,
    width: '85%',
    textAlign: 'center',
  },
})

const DesiredHours = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const user = useUser()
  const dispatch = useDispatch()
  const [desiredHours, setDesiredHours] = useState('')
  const [isError, setIsError] = useState(false)
  const [helperMessage, setHelperMessage] = useState('')
  const { isLoading, data, error } = useSelector((state) => state.desiredHours)

  useEffect(() => {
    if (desiredHours !== '' && (desiredHours < 4 || desiredHours > 40)) {
      setIsError(true)
      setHelperMessage(DESIRED_HOURS_ERROR)
    } else {
      setIsError(false)
      setHelperMessage(null)
    }
  }, [desiredHours])

  useEffect(() => {
    if (error) {
      if (error.code === ERROR_CODE_INPUT_INVALID) {
        dispatch(showNotificationError(true, ERROR_INVALID_INPUT))
      } else if (error.message && error.code && ERROR_CODES_SHOW_MESSAGE.includes(error.code)) {
        dispatch(showNotificationError(true, error.message))
      } else {
        dispatch(
          showNotificationError(
            true,
            SAVE_ERROR_MESSAGE + ' ' + formatErrorCode(error, ERROR_CODE_CANNOT_CONNECT_TO_SERVER),
          ),
        )
      }
    }
    if (data) {
      dispatch(showNotificationSuccess(true, `Successfully changed desired hours to ${data.desired_hours}`))
    }
    dispatch(clearPreviousState())
  }, [dispatch, error, data])

  const renderSavingSpinner = () => {
    return (
      <div style={styles.loader}>
        <CircularProgress size={48} />
      </div>
    )
  }

  const handleSubmitDesiredHours = () => {
    const requestBody = {
      worker_id: user.userData.worker_id,
      desired_hours: desiredHours,
    }
    dispatch(setDesiredHoursLoading(true))
    dispatch(postDesiredHours(requestBody))
    setDesiredHours('')
  }

  const renderDesiredHoursForm = () => {
    return (
      <form style={styles.form}>
        <div style={styles.infoTextContainer}>
          <label style={styles.text}>{QUESTION_PROMPT}</label>
        </div>
        <div style={styles.inputContainer}>
          <TextField
            data-cy={'desired-hours-input'}
            sx={styles.formInput}
            type={'number'}
            inputMode={'numeric'}
            aria-label={'Input Desired Hours'}
            label={'Desired Hours'}
            variant={'outlined'}
            size={'small'}
            value={desiredHours}
            onChange={(data) => setDesiredHours(data.target.value)}
            error={isError}
            aria-errormessage={helperMessage}
            helperText={helperMessage}
            onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
          />
          {isLoading ? (
            renderSavingSpinner()
          ) : (
            <Button
              data-cy={'desired-hours-submit'}
              sx={styles.formSubmit}
              type={'submit'}
              variant={'contained'}
              color={'primary'}
              size={'small'}
              disabled={isError || desiredHours === ''}
              onClick={(event) => handleSubmitDesiredHours(event)}
            >
              Submit
            </Button>
          )}
        </div>
      </form>
    )
  }

  const renderDesiredHoursPageContent = () => {
    return (
      <Paper elevation={1} square sx={styles.mainScrollContainer}>
        <div style={styles.contentContainer}>
          <Typography variant={'h1'} component={'h3'} aria-label={'Desired Hours Input'} sx={styles.headerTitle}>
            Desired Hours
          </Typography>
          <div style={styles.infoTextContainer}>
            <p style={styles.text}>{ABOUT_TEXT}</p>
          </div>
          {renderDesiredHoursForm()}
        </div>
      </Paper>
    )
  }

  return (
    <React.Fragment>
      <HeaderTitle title="Desired Hours" />

      <div style={styles.mainContainerPosition}>{renderDesiredHoursPageContent()}</div>
    </React.Fragment>
  )
}

export default DesiredHours
