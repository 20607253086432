import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import React, { ComponentType, useEffect, useMemo } from 'react'
import { Outlet, OutletProps, useNavigate } from 'react-router-dom'
import { getTimeCardDaily } from '../api/timecard/useTimeCardDaily'
import NotAuthorized, { NotAuthorizedProps } from '../components/common/fallback/NotAuthorized'
import Loader from '../components/common/loaders/Loader'
import { getDateOfTodayWithNoTimestamp } from '../utils/DateUtil'
import { AuthorizedAccess, FeatureName, PermissionName, UnauthorizedCausedByEnum, ViewName } from './User'
import { useUser } from './hooks/useUser'

interface RenderNoAuthProps {
  redirect: string
  render: any
  callback: any
  authorizedAccess: AuthorizedAccess
}

const RenderNoAuthorization = ({ redirect, render: Render, callback, authorizedAccess }: RenderNoAuthProps) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (callback) callback({ redirect: window.location.href })
    else {
      navigate(redirect, {
        state: {
          causedByFeature: authorizedAccess.causedByFeature,
          causedBy: authorizedAccess.causedBy,
          showRetry: true,
        },
      })
    }
  }, [callback, navigate, redirect, authorizedAccess.causedByFeature, authorizedAccess.causedBy])

  if (Render) {
    return (
      <Render
        causedByFeature={authorizedAccess.causedByFeature}
        causedBy={authorizedAccess.causedBy}
        showRetry={true}
      />
    )
  }
  return null
}

export interface BaseProtectedElementProps<Path extends string = string> extends OutletProps {
  /**
   * An array of myTime features that are allowed to access this `path`
   * @note values must match the feature name defined in user access tables
   * @note users can access the path if they have *at least* one of the listed allowed features
   * @note will not get access if compensable check is enabled and required for that feature by default. (look at checkCompensable for more info)
   * @defaultValue [] (an empty array)
   */
  allowed?: FeatureName[]

  view?: ViewName

  /**
   * An array of permissions (on a feature) that are allowed to access  this `path`
   * @note only works if a allowed feature is passed in
   * @note users can access the path if they have *at least* one of the listed permissions + feature + view combos.
   */
  permissions?: PermissionName[]
  /**
   * Show loading icon if authorization / punch status is still being checked
   * @note this will probably only show if checking punch status. Even then it should be quick.
   * @note uses default full screen Loader from common. If a smaller loader is needed, can supply
   * @defaultValue true
   */
  blockWhileLoading?: boolean

  /**
   * Cover whole screen when loading
   * @note will only matter if blockWhileLoading is true (default true)
   * @defaultValue true
   */
  fullScreenLoading?: boolean

  /** callback function that is called when given session is not authorized */
  onUnauthorized?: (redirect: string) => void

  /** Component to render if given session is not authorized, will not redirect to unauthorizedRoute if this is passed. */
  renderUnauthorized?: ComponentType<NotAuthorizedProps> | null

  renderNotPunchedIn?: ComponentType<NotAuthorizedProps> | null

  /**
   * URL to redirect the user when current session is not authorized against given `allowed` prop
   * @defaultValue https://logonservices.iam.target.com/login/responses/accessdenied.html
   */
  unauthorizedRoute?: Path

  /**
   * Enable api call to check punch status if the feature and person requires it
   * @defaultValue true
   */
  checkCompensable?: boolean

  /**
   * Enable punch api check to poll every minute.
   * @note this will only poll if component still mounted and browser tab/window is not in the background.
   * @note this will allow the page to automatically refresh if it detects the user punch status changes
   * @note this is ignored if checkCompensable is set to false
   * @defaultValue false
   */
  enablePunchStatusPolling?: boolean
}

type PassThroughOutletProps = Partial<OutletProps>
type ContentRenderProps = {
  component: React.ComponentType<PassThroughOutletProps> | React.ComponentType<any>
  render: (props: PassThroughOutletProps) => React.ReactNode
  children: ((props: PassThroughOutletProps) => React.ReactNode) | React.ReactNode
}

/**
 * Utility type that allows only a single key for an object.
 */
type PickOne<T> = {
  [P in keyof T]: Record<P, T[P]> & Partial<Record<Exclude<keyof T, P>, undefined>>
}[keyof T]

export type ProtectedElementProps<Path extends string = string> = BaseProtectedElementProps<Path> &
  Partial<PickOne<ContentRenderProps>>

/**
 * Checks authorization against the UserContext and its current user
 *
 * @component
 * @example
 * import { Route } from 'react-router-dom'
 * import { MyProtectedElement } from '../auth/MyProtectedElement'
 * import { MyComponent } from './MyComponent'
 *
 * <Route path="/protected" element={<MyProtectedElement allowed={['MY_FEATURE']} />}>
 *   <MyComponent />
 * </Route>
 */
export default function MyProtectedElement({
  allowed = [],
  view = 'team_member',
  permissions = ['read'],
  blockWhileLoading = false,
  onUnauthorized,
  renderUnauthorized = NotAuthorized,
  renderNotPunchedIn,
  unauthorizedRoute = '/unauthorized',
  fullScreenLoading = true,
  checkCompensable = true,
  enablePunchStatusPolling = false,
  context,
  component,
  render,
  children,
}: ProtectedElementProps) {
  const user = useUser()

  const authorizedAccess: AuthorizedAccess = useMemo(() => {
    if (!user) {
      return { hasAccess: false, causedBy: null, causedByFeature: null, isCompensable: null }
    }
    return user.hasAccess(allowed, view, permissions, checkCompensable)
  }, [user, allowed, view, permissions, checkCompensable])

  const requireCompensableCheck = authorizedAccess.isCompensable !== null && checkCompensable

  const {
    data: hasPunchedIn,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      'timeCardDaily',
      {
        selectedDate: format(getDateOfTodayWithNoTimestamp(), 'yyyy-MM-dd'),
        workerId: user.userData.worker_id,
        locationId: user.locationData.location_id,
      },
    ],
    queryFn: () => getTimeCardDaily({ workerId: user.userData.worker_id, locationId: user.locationData.location_id }),
    staleTime: 1000 * 2,
    refetchInterval: enablePunchStatusPolling ? 1000 * 60 * 1 : false,
    enabled: requireCompensableCheck,
    select: (data) => data?.timecards[0]?.has_punched_in,
  })

  const isCompensableMet: boolean = useMemo(() => {
    if (requireCompensableCheck && authorizedAccess.isCompensable !== null) {
      return (authorizedAccess.isCompensable && hasPunchedIn) || (!authorizedAccess.isCompensable && !hasPunchedIn)
    }
  }, [hasPunchedIn, authorizedAccess.isCompensable, requireCompensableCheck])

  if (isCompensableMet) {
    // If compensable check is met, allow access. but only if we are supposed to check for compensability
    authorizedAccess.hasAccess = true
  }

  onUnauthorized = onUnauthorized || (renderUnauthorized ? () => {} : undefined)

  // Render loading if auth check still going rather than showing screen content.
  if (blockWhileLoading && requireCompensableCheck && (isLoading || isFetching)) {
    return <Loader fullScreen={fullScreenLoading} />
  }

  if (
    (authorizedAccess.causedBy === UnauthorizedCausedByEnum.PUNCHIN ||
      authorizedAccess.causedBy === UnauthorizedCausedByEnum.PUNCHOUT) &&
    renderNotPunchedIn &&
    !authorizedAccess.hasAccess
  ) {
    return (
      <RenderNoAuthorization
        redirect={unauthorizedRoute}
        render={renderNotPunchedIn}
        callback={onUnauthorized}
        authorizedAccess={authorizedAccess}
      />
    )
  }

  if (!authorizedAccess.hasAccess) {
    return (
      <RenderNoAuthorization
        redirect={unauthorizedRoute}
        callback={onUnauthorized}
        render={renderUnauthorized}
        authorizedAccess={authorizedAccess}
      />
    )
  }

  if (children) {
    if (typeof children === 'function') {
      return <>{children({ context })}</>
    }
    return <>{children}</>
  }

  if (component) {
    return React.createElement(component, { context })
  }

  if (render) {
    return <>{render({ context })}</>
  }

  return <Outlet context={context} />
}
