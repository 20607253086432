import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import { isValidLocationsStringInput } from './BulkAdd'

export default function LocationDropdown({ handleFormValueChange, formValues, handleBulkAddButton }) {
  return (
    <Grid container item xs={12}>
      <Grid item flexGrow={1}>
        <Autocomplete
          name="audience_locations"
          id="audience_locations-select"
          size="small"
          fullWidth
          multiple
          options={[]}
          disableCloseOnSelect
          freeSolo
          value={formValues.audience_locations}
          onChange={(event, newValue, reason) => {
            if (reason === 'createOption') {
              let { isValid, value } = isValidLocationsStringInput(event.target.value)
              if (isValid) {
                newValue.pop()
                newValue.push(value)
                handleFormValueChange('audience_locations', newValue)
              }
            } else {
              handleFormValueChange('audience_locations', newValue)
            }
          }}
          limitTags={7}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Audience Locations"
              placeholder="Leave blank for all locations"
            />
          )}
        />
      </Grid>
      <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleBulkAddButton}>Bulk Add</Button>
      </Grid>
    </Grid>
  )
}
