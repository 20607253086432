import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { showNotificationError } from '../../store/notification/actionCreator'
import { getLocation } from './api/locations'

export function useLocationDetails() {
  const { session, isAuthenticated } = useAuth()
  const dispatch = useDispatch()
  const authenticated = isAuthenticated()

  const locationDetails = useQuery({
    queryKey: ['locationDetails'],
    queryFn: () => getLocation({ locationId: session?.userInfo?.locationid }),
    staleTime: 1000 * 60 * 10, // 10 minutes
    enabled: authenticated && !!session,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
    keepPreviousData: true,
    // 🚀 only server errors will go to the Error Boundary
    useErrorBoundary: (error: any) => error?.response?.status >= 500,
  })

  useEffect(() => {
    if (locationDetails.error) {
      dispatch(showNotificationError(true, `Error loading location details`))
    }
  }, [locationDetails.error, dispatch])

  return locationDetails
}
