import { useTheme } from '@mui/material'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import { useAvailableShifts } from '../../api/availableShifts/useAvailableShifts'
import { useUser } from '../../auth/hooks/useUser'
import { MISSED_PUNCH_MESSAGE } from '../../constants/errorConstants'
import { getNowDateInTimezone, getStartEndDatesOfWeek } from '../../utils/DateUtil'
import CustomAvailableShiftsIcon from '../Icons/CustomAvailableShiftsIcon'
import InnerLoader from '../Loader/InnerLoader'
import HomePageCard from '../common/HomePageCard'
import NextAvailableShift from './NextAvailableShift'
import NextAvailableWeek from './NextAvailableWeek'

const CSC_ERROR_MESSAGE = '  Please try again later and if the issue persists, contact the CSC.'
const AVAIL_SHIFTS_ERROR_CODES_SHOW_MESSAGE = ['wfm-2-5', 'wfm-2-11', 'wfm-14-5', 'wfm-14-11']
const AVAIL_SHIFTS_ERROR_MESSAGE = 'Unable to load your available shifts at this time.' + CSC_ERROR_MESSAGE
const ERROR_CODE_CANNOT_CONNECT_TO_AVAIL_SHIFTS = 'wfm-14-0'

const getStyles = (theme) => ({
  gridItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '6px',
    marginBottom: '6px',
  },
  nextAvailableShift: {
    marginRight: theme.spacing(1),
  },
  nextAvailableWeek: {
    marginLeft: theme.spacing(1),
  },
})

function AvailableShiftsCard(props) {
  const theme = useTheme()
  const styles = getStyles(theme)
  const { renderHomePageCardError, locationTimezone } = props

  const user = useUser()
  const { startDate, endDate } = getStartEndDatesOfWeek(getNowDateInTimezone(locationTimezone))
  const { data, error, isRefetching } = useAvailableShifts('HOME_PAGE', startDate, endDate)

  const getNextAvailableShift = () => {
    return moment.min(Object.keys(data.available_shifts_by_date).map((d) => moment(d)))
  }

  const renderAvailableShiftsData = () => {
    const nextAvailableShift = getNextAvailableShift()

    const nextAvlShiftCount =
      data.available_shifts && data.available_shifts.length > 0
        ? data.available_shifts_by_date[moment(nextAvailableShift)?.format('YYYY-MM-DD')].length
        : 0

    if (data.has_missed_punch) {
      return (
        <p style={styles.errorMessage} data-cy="HomePageCardErr">
          {MISSED_PUNCH_MESSAGE}
        </p>
      )
    }

    return (
      <Grid container>
        <Grid item xs sx={styles.nextAvailableShift}>
          <NextAvailableShift nextAvlShiftDate={nextAvailableShift} avlShiftCount={nextAvlShiftCount} />
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem />

        <Grid item xs sx={styles.nextAvailableWeek}>
          <NextAvailableWeek weekStart={startDate} weekEnd={endDate} avlShiftWeekCount={data.available_shifts.length} />
        </Grid>
      </Grid>
    )
  }

  /**
   * wondering why we check data first and not isLoading?
   * Check out this blog: https://tkdodo.eu/blog/status-checks-in-react-query
   */
  const renderAvailableShiftsResponse = () => {
    if (data) return renderAvailableShiftsData()
    if (error) {
      return renderHomePageCardError(
        error?.response?.data,
        AVAIL_SHIFTS_ERROR_MESSAGE,
        AVAIL_SHIFTS_ERROR_CODES_SHOW_MESSAGE,
        ERROR_CODE_CANNOT_CONNECT_TO_AVAIL_SHIFTS,
      )
    }
    return <InnerLoader size={48} />
  }

  const renderMultilocationSubtitle = () => {
    return user.is('multi_location') ? '(Home Store Only)' : null
  }

  return (
    <Grid item xs={12} md={12} sx={styles.gridItem}>
      <HomePageCard
        title="Available Shifts"
        subTitle={renderMultilocationSubtitle()}
        icon={<CustomAvailableShiftsIcon color="#000" />}
        isRefetching={isRefetching}
      >
        {renderAvailableShiftsResponse()}
      </HomePageCard>
    </Grid>
  )
}

export default AvailableShiftsCard
