import { useAuth } from '@praxis/component-auth'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { toLower } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '../../auth/hooks/useUser'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { LOCATION_TYPE_OFFICE, LOCATION_TYPE_STORE } from '../../constants/locationConstants'
import { setLeaderFilterConfig } from '../../store/leader/actionCreator'
import { showNotificationError } from '../../store/notification/actionCreator'

const getConfiguration = async (configFeatureName, locationId, locationType, isOfficeManager) => {
  // TODO: ADMIN DEFAULTS - if user is an admin, set to default store for now
  if (locationType === LOCATION_TYPE_OFFICE) {
    locationType = LOCATION_TYPE_STORE
    locationId = '2180'
  }

  let feature = configFeatureName

  if (feature === 'time_off') {
    feature = `${feature}_${toLower(locationType)}`
  }

  if (configFeatureName === 'punch_correction' && isOfficeManager) {
    feature = 'punch_correction_office'
  }

  let apiUrl =
    `${apiConfig.configuration.urlv2}` +
    '/team_member_enablement_filters' +
    '?location_id=' +
    locationId +
    '&location_type=' +
    locationType +
    '&feature=' +
    feature +
    '&key=' +
    `${apiConfig.configuration.key}`

  return axios.get(apiUrl, BASIC_REQUEST_CONFIG)
}

function getFiltersArray(data) {
  return data?.filters?.reduce((filterObject, filterValues) => {
    filterObject[filterValues.key] = { ...filterValues, selectedValues: [] }
    return filterObject
  }, {})
}

export function useLeaderFilterConfiguration() {
  const { isAuthenticated, login } = useAuth()
  const dispatch = useDispatch()
  const user = useUser()

  const selectedCategory = useSelector((state) => state.leader.selectedCategory)
  const configFeatureName = useSelector((state) => state.leader[selectedCategory]?.configFeatureName)
  const authenticated = isAuthenticated()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  return useQuery({
    queryKey: [
      'configuration',
      {
        configFeatureName: configFeatureName,
        locationId: user.locationData.location_id,
        locationType: user.locationData.location_type,
      },
    ],
    queryFn: () =>
      getConfiguration(
        configFeatureName,
        user.locationData.location_id,
        user.locationData.location_type,
        user.userData.short_persona === 'office:leader',
      ),
    staleTime: Infinity,
    enabled: authenticated && !!user.locationData.location_id && !!configFeatureName,
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
    onSuccess: (response) => {
      let filterData = getFiltersArray(response?.data)
      dispatch(setLeaderFilterConfig(filterData))
    },
    onError: (error) => {
      const loggingInfo = {
        message: 'GET /team_member_enablement_filters response has an error.',
        url: window.location.href,
        error: {
          url: error?.config?.url,
          data: error?.response?.data,
          headers: error?.response?.headers,
          status: error?.response?.status,
          statusText: error?.response?.statusText,
          selectedCategory: selectedCategory,
          configFeatureName: configFeatureName,
          location: user.locationData.location_id,
          persona: user.userData.persona,
          workerId: user.userData.worker_id,
        },
      }
      const optionalLoggingOptions = {
        level: LogLevel.Error,
      }
      logEvent(loggingInfo, optionalLoggingOptions)
    },
    onSettled: (response, error) => {
      if (!response?.data && !!error) {
        dispatch(showNotificationError(true, 'Error loading filter configuration'))
      }
    },
  })
}
