import axios from 'axios'
import { endOfWeek, format, startOfWeek } from 'date-fns'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import {
  CLEAR_WITHDRAW_POST,
  GET_AVAILABLE_SHIFT_STATUS_ERROR,
  GET_AVAILABLE_SHIFT_STATUS_SUCCESS,
  GET_SWAP_REQUESTS_ERROR,
  GET_SWAP_REQUESTS_SUCCESS,
  GET_WEEKLY_SCHEDULE_ERROR,
  GET_WEEKLY_SCHEDULE_SUCCESS,
  GIVE_UP_SHIFT_POST_ERROR,
  GIVE_UP_SHIFT_POST_SUCCESS,
  SAVE_SWAP_REQUESTS_DATETIME_WISE_MAP,
  SET_AVAILABLE_SHIFT_STATUS_LOADING,
  SET_WEEKLY_SCHEDULE_LOADING,
  SET_WEEKLY_SCHEDULE_SELECTED_DATE,
  WITHDRAW_SWAP_SHIFT_REQUEST_ERROR,
  WITHDRAW_SWAP_SHIFT_REQUEST_SUCCESS,
} from './actionType'

export function handleGetAvailableShiftStatus(tmNumber, selectedDate, locationId) {
  const dateFormat = 'yyyy-MM-dd'

  let startDate = format(startOfWeek(selectedDate), dateFormat)
  let endDate = format(endOfWeek(selectedDate), dateFormat)

  let apiUrl =
    `${apiConfig.availableShifts.url}` +
    '/team_member_available_shifts' +
    '?worker_id=' +
    tmNumber +
    '&start_date=' +
    startDate +
    '&end_date=' +
    endDate +
    '&location_id=' +
    locationId +
    '&key=' +
    `${apiConfig.availableShifts.key}`

  return (dispatch) => {
    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGetAvailableShiftStatusSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetAvailableShiftStatusError(response))
      })
  }
}

export function handleGetSwapRequests(tmNumber, startDate, endDate) {
  let apiUrl =
    `${apiConfig.shiftswap.url}` +
    '/shift_swap_requests' +
    '?worker_id=' +
    tmNumber +
    '&start_date=' +
    startDate +
    '&end_date=' +
    endDate +
    '&key=' +
    `${apiConfig.shiftswap.key}`

  return (dispatch) => {
    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        if (response.data.total_shift_swap_request_received > 0) {
          let shift_swap_request_received = response.data.shift_swap_request_received
          for (let element of shift_swap_request_received) {
            element['requester']['request_id'] = element.request_id
          }
        }
        dispatch(handleGetSwapRequestsSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetSwapRequestsError(response))
      })
  }
}

export function handleGetSwapRequestsSuccess(record) {
  return {
    type: GET_SWAP_REQUESTS_SUCCESS,
    record,
  }
}

export function handleGetSwapRequestsError(record) {
  return {
    type: GET_SWAP_REQUESTS_ERROR,
    record,
  }
}

export function saveSwapRequestsDateTimeWiseMap(swapRequestsDateTimeWiseMap) {
  return {
    type: SAVE_SWAP_REQUESTS_DATETIME_WISE_MAP,
    swapRequestsDateTimeWiseMap,
  }
}

export function handleGetWeeklyScheduleData(tmNumber, selectedDate, locationId) {
  const dateFormat = 'yyyy-MM-dd'

  let startDate = format(startOfWeek(selectedDate), dateFormat)
  let endDate = format(endOfWeek(selectedDate), dateFormat)

  return (dispatch) => {
    let apiUrl =
      `${apiConfig.schedule.url}` +
      '/weekly_schedules' +
      '?team_member_number=' +
      tmNumber +
      '&start_date=' +
      startDate +
      '&end_date=' +
      endDate +
      '&location_id=' +
      locationId +
      '&key=' +
      `${apiConfig.schedule.key}`

    return axios
      .get(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGetWeeklyScheduleSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGetWeeklyScheduleError(response))
      })
  }
}

export function handleGetWeeklyScheduleError(record) {
  return {
    type: GET_WEEKLY_SCHEDULE_ERROR,
    record,
  }
}

export function handleGetWeeklyScheduleSuccess(record) {
  return {
    type: GET_WEEKLY_SCHEDULE_SUCCESS,
    record,
  }
}

export function handleGetAvailableShiftStatusError(record) {
  return {
    type: GET_AVAILABLE_SHIFT_STATUS_ERROR,
    record,
  }
}

export function handleGetAvailableShiftStatusSuccess(record) {
  return {
    type: GET_AVAILABLE_SHIFT_STATUS_SUCCESS,
    record,
  }
}

export function setLoading(text) {
  return {
    type: SET_WEEKLY_SCHEDULE_LOADING,
    payload: text,
  }
}

export function setAvailableShiftStatusLoading(text) {
  return {
    type: SET_AVAILABLE_SHIFT_STATUS_LOADING,
    payload: text,
  }
}

export function setWeeklyScheduleSelectedDate(date) {
  return {
    type: SET_WEEKLY_SCHEDULE_SELECTED_DATE,
    date,
  }
}

/**
 * Post a TeamMemberAvailableShift to available shifts board.
 * @param requestBody
 * @param counter: '5_1' this identifies the index of what day of week and what display segment was clicked, needed to
 * add the information to correct display segment so no new api request is required.
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function giveUpShift(requestBody) {
  return (dispatch) => {
    let apiUrl = `${apiConfig.availableShifts.url}/team_member_available_shifts?key=${apiConfig.availableShifts.key}`
    return axios
      .post(apiUrl, requestBody, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGiveUpShiftSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGiveUpShiftError(response))
      })
  }
}

/**
 * Cancel TM shift from available shift board.
 * @param requestBody
 * @param counter: '5_1' this identifies the index of what day of week and what display segment was clicked, needed to
 * add the information to correct display segment so no new api request is required.
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function rtcCancelShift(requestBody) {
  return (dispatch) => {
    let apiUrl = `${apiConfig.availableShifts.url}/team_member_available_shifts/statuses?key=${apiConfig.availableShifts.key}`

    return axios
      .put(apiUrl, requestBody, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleGiveUpShiftSuccess(response))
      })
      .catch((response) => {
        dispatch(handleGiveUpShiftError(response))
      })
  }
}

export function handleGiveUpShiftError(record) {
  return {
    type: GIVE_UP_SHIFT_POST_ERROR,
    record,
  }
}

export function handleGiveUpShiftSuccess(record) {
  return {
    type: GIVE_UP_SHIFT_POST_SUCCESS,
    record,
  }
}

/**
 * Cancel TM shift from available shift board.
 * @param requestId: whose status will be set as 'Withdrawn'
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function withdrawSwapRequest(requestId) {
  return (dispatch) => {
    let apiUrl = `${apiConfig.shiftswap.url}/shift_swap_requests/withdrawals?request_id=${requestId}&key=${apiConfig.shiftswap.key}`

    return axios
      .post(apiUrl, BASIC_REQUEST_CONFIG)
      .then((response) => {
        dispatch(handleWithdrawSuccess(response))
      })
      .catch((response) => {
        dispatch(handleWithdrawError(response))
      })
  }
}

export function handleWithdrawSuccess(record) {
  return {
    type: WITHDRAW_SWAP_SHIFT_REQUEST_SUCCESS,
    record,
  }
}

export function handleWithdrawError(record) {
  return {
    type: WITHDRAW_SWAP_SHIFT_REQUEST_ERROR,
    record,
  }
}

export function clearWithdrawPostRequests() {
  return {
    type: CLEAR_WITHDRAW_POST,
  }
}
