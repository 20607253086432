import DownArrow from '@mui/icons-material/ArrowDownward'
import UpArrow from '@mui/icons-material/ArrowUpward'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronDown from '@mui/icons-material/KeyboardArrowDown'
import ChevronUp from '@mui/icons-material/KeyboardArrowUp'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Slide,
  Typography,
} from '@mui/material'
import { withAuth } from '@praxis/component-auth'
import { addDays, endOfWeek, getDay, isAfter, isBefore, parse, parseISO, startOfWeek } from 'date-fns'
import moment from 'moment-timezone'
import { object, shape } from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { Element, scroller } from 'react-scroll'
import { bindActionCreators } from 'redux'
import { showNotificationError, showNotificationSuccess } from '../../store/notification/actionCreator'
import {
  loadGetRequest,
  loadPostRequest,
  setCancelDialogOpen,
  setDifferentWeekDialogOpen,
  setExitDialogOpen,
  setExpand,
  setLoading,
  setNavigateToNextLocation,
  setNextLocation,
  setPersistedData,
  setPostError,
  setPostResponse,
  setSaveDialogOpen,
  setSelectedDate,
  setSessionData,
  setUpdatedSessionData,
} from '../../store/tmOptionalAvailability/actionCreator'
import {
  getNowDateInTimezone,
  isDateAfterNumberOfWeeksFromToday,
  isDateBeforeNumberOfWeeksFromToday,
  toIsoStringWithoutTime,
} from '../../utils/DateUtil'
import { formatErrorCode } from '../../utils/ErrorHandling'
import WeeklyCalendar from '../Calendar/WeeklyCalendar'
import HeaderTitle from '../Header/HeaderTitle'

import UserContext from '../../auth/UserContext'
import praxisTheme from '../../config/themeConfig'
import OptionalAvailabilityPromptContainer from './OptionalAvailabilityPromptContainer'

/* global _ */

const styles = {
  mainScrollContainer: {
    ...praxisTheme.mainScrollContainer,
  },
  mainContainerPosition: praxisTheme.mainContainerPosition,
  infoMessage: praxisTheme.infoMessages,
  infoMessageDetail: praxisTheme.infoMessageDetail,
  errorCodeMessage: praxisTheme.errorCodeMessages,
  errorMessage: praxisTheme.errorMessages,
  loadingIconContainer: praxisTheme.loadingIconContainer,
  expandCollapseIcon: {
    fontSize: '28px',
  },
  topButtons: {
    fontWeight: 'bold',
    fontSize: '105%',
  },
  topButtonsContainerPosition: {
    width: '100%',
  },
  topButtonsContainer: {
    textAlign: 'center',
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
    padding: '0',
    display: 'flex',
    background: praxisTheme.palette.tertiary.light,
    borderTop: '1px solid',
    borderTopColor: praxisTheme.palette.tertiary.dark,
    borderBottom: '1px solid',
    borderBottomColor: praxisTheme.palette.tertiary.dark,
  },
  topButtonsContainerLeft: {
    flexGrow: '1',
    flexBasis: 0,
    alignItems: 'center',
    display: 'flex',
  },
  topButtonsContainerRight: {
    flexGrow: '1',
    flexBasis: 0,
    justifyContent: 'right',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  topButton: {
    maxWidth: '5em',
    width: '75%',
    flexGrow: '1',
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    background: praxisTheme.palette.tertiary.light,
    flexBasis: 0,
    height: '2.5em',
    '&:hover': {
      transition: '.25s ease-out',
      backgroundColor: praxisTheme.palette.tertiary.main,
    },
  },
  topButtonDisabled: {
    maxWidth: '5em',
    width: '75%',
    flexGrow: '1',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    background: praxisTheme.palette.tertiary.light,
    flexBasis: 0,
    height: '2.5em',
    opacity: '.65',
  },
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingTop: '0',
    paddingBottom: '0',
  },
  dateHeading: {
    fontWeight: 'bold',
    fontSize: 'medium',
    display: 'flex',
    alignItems: 'center',
    flexGrow: '1',
    justifyContent: 'space-between',
  },
  labelHeading: {
    fontWeight: 'bold',
    fontSize: 'medium',
  },
  lastEdit: {
    color: praxisTheme.palette.secondary.light,
    fontSize: 'x-small',
  },
  expansionPanel: {
    background: '#F9F9F9',
    padding: 0,
  },
  listItemGutter: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  listItemRoot: {
    paddingBottom: '1px',
    justifyContent: 'center',
  },
  availabilityButtons: {
    flexGrow: '1',
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    border: '.5px solid',
    borderColor: '#F9F9F9',
    background: praxisTheme.palette.tertiary.light,
    flexBasis: 0,
    height: '2.5em',
    '&:hover': {
      transition: '.25s ease-out',
      backgroundColor: praxisTheme.palette.tertiary.main,
    },
  },
  availabilityButtonsSelected: {
    flexGrow: '1',
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    border: '.5px solid',
    borderColor: praxisTheme.palette.tertiary.dark,
    background: praxisTheme.palette.tertiary.dark,
    flexBasis: 0,
    height: '2.5em',
    '&:hover': {
      transition: '.25s ease-out',
      backgroundColor: praxisTheme.palette.tertiary.main,
    },
  },
  availabilityButtonsDisabled: {
    flexGrow: '1',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    border: '.5px solid',
    borderColor: '#F9F9F9',
    background: praxisTheme.palette.tertiary.light,
    flexBasis: 0,
    height: '2.5em',
  },
  availabilityButtonsSelectedDisabled: {
    flexGrow: '1',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    border: '.5px solid',
    borderColor: praxisTheme.palette.tertiary.dark,
    background: praxisTheme.palette.tertiary.dark,
    flexBasis: 0,
    height: '2.5em',
  },
  availabilitySpace: {
    flexGrow: '1',
    flexBasis: 0,
    height: '2.5em',
  },
  availabilityButtonsContainer: {
    display: 'flex',
    lineHeight: '1.5',
    width: '100%',
  },
  availabilityButtonsText: {
    fontSize: '.65em',
    letterSpacing: '.075em',
    fontWeight: '400',
  },
  availabilityButtonsTextSelected: {
    fontSize: '.7rem',
    letterSpacing: '.075em',
    fontWeight: '999',
  },
  disabled: {
    opacity: '.65',
  },
}

const SAVE_DIALOG_TITLE = 'Are you sure you want to save?'
const SAVE_DIALOG_TEXT = 'I acknowledge that this is a voluntary selection.'
const CANCEL_DIALOG_TITLE = 'Are you sure you want to cancel?'
const CANCEL_DIALOG_TEXT = 'You have unsaved changes to this page. If you cancel, you will lose these changes.'
const DIFF_WEEK_DIALOG_TITLE = 'Are you sure you want to continue to a different week?'
const DIFF_WEEK_DIALOG_TEXT = 'You have unsaved changes to this page. If you continue, you will lose these changes.'
const PREFERENCES_SAVED_MESSAGE = 'Preferences saved successfully'
const PREFERENCES_PARTIALLY_SAVED_MESSAGE =
  'Your preferences did not save for the following dates because a scheduling decision is in progress: '
const LOAD_ERROR_MESSAGE =
  'Unable to load your preferences at this time. Please try again later and if the issue persists, contact the CSC.'
const SAVE_ERROR_MESSAGE =
  'Unable to save your preferences at this time. Please try again later and if the issue persists, contact the CSC.'
const ERROR_CODES_SHOW_MESSAGE = ['wfm-3-11', 'wfm-3-5']
const ERROR_CODE_CANNOT_CONNECT_TO_SERVER = 'wfm-3-0'
const DATE_BEFORE_RANGE_MESSAGE = 'Voluntary Availability is only available 13 weeks in the past.'
const DATE_AFTER_RANGE_MESSAGE = 'Voluntary Availability is only available 4 weeks into the future.'
const buttonNames = ['FULL', 'HALF EARLY', 'HALF LATE', 'EXTEND EARLY', 'EXTEND LATE', 'ANY']
const DATE_RANGE_PAST_WEEKS = 13
const DATE_RANGE_FUTURE_WEEKS = 4

const buttonNamesMapping = {
  up: {
    [buttonNames[0]]: 'full_up',
    [buttonNames[1]]: 'half_early_up',
    [buttonNames[2]]: 'half_late_up',
    [buttonNames[3]]: 'extend_early',
    [buttonNames[4]]: 'extend_late',
    [buttonNames[5]]: 'any_up',
  },
  down: {
    [buttonNames[0]]: 'full_down',
    [buttonNames[1]]: 'half_early_down',
    [buttonNames[2]]: 'half_late_down',
    [buttonNames[5]]: 'any_down',
  },
}

const defaultSessionDataUpdates = {
  worker_id: null,
  location_id: null,
  total_voluntary_availability: null,
  voluntary_availability_preferences: [],
}

const defaultSessionData = {
  worker_id: null,
  location_id: null,
  start_date: null,
  end_date: null,
  total_voluntary_availability: null,
  voluntary_availability_preferences: [
    {
      last_updated_timestamp: null,
      schedule_date: null,
      half_early_up: false,
      half_late_up: false,
      full_up: false,
      any_up: false,
      half_early_down: false,
      half_late_down: false,
      full_down: false,
      any_down: false,
      extend_early: false,
      extend_late: false,
    },
    {
      last_updated_timestamp: null,
      schedule_date: null,
      half_early_up: false,
      half_late_up: false,
      full_up: false,
      any_up: false,
      half_early_down: false,
      half_late_down: false,
      full_down: false,
      any_down: false,
      extend_early: false,
      extend_late: false,
    },
    {
      last_updated_timestamp: null,
      schedule_date: null,
      half_early_up: false,
      half_late_up: false,
      full_up: false,
      any_up: false,
      half_early_down: false,
      half_late_down: false,
      full_down: false,
      any_down: false,
      extend_early: false,
      extend_late: false,
    },
    {
      last_updated_timestamp: null,
      schedule_date: null,
      half_early_up: false,
      half_late_up: false,
      full_up: false,
      any_up: false,
      half_early_down: false,
      half_late_down: false,
      full_down: false,
      any_down: false,
      extend_early: false,
      extend_late: false,
    },
    {
      last_updated_timestamp: null,
      schedule_date: null,
      half_early_up: false,
      half_late_up: false,
      full_up: false,
      any_up: false,
      half_early_down: false,
      half_late_down: false,
      full_down: false,
      any_down: false,
      extend_early: false,
      extend_late: false,
    },
    {
      last_updated_timestamp: null,
      schedule_date: null,
      half_early_up: false,
      half_late_up: false,
      full_up: false,
      any_up: false,
      half_early_down: false,
      half_late_down: false,
      full_down: false,
      any_down: false,
      extend_early: false,
      extend_late: false,
    },
    {
      last_updated_timestamp: null,
      schedule_date: null,
      half_early_up: false,
      half_late_up: false,
      full_up: false,
      any_up: false,
      half_early_down: false,
      half_late_down: false,
      full_down: false,
      any_down: false,
      extend_early: false,
      extend_late: false,
    },
  ],
}

const expandAll = {
  panel0: true,
  panel1: true,
  panel2: true,
  panel3: true,
  panel4: true,
  panel5: true,
  panel6: true,
}

const collapseAll = {
  panel0: false,
  panel1: false,
  panel2: false,
  panel3: false,
  panel4: false,
  panel5: false,
  panel6: false,
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

class TMOptionalAvailabilityPage extends React.Component {
  static propTypes = {
    classes: object,
    layoutActions: shape({}),
  }

  static contextType = UserContext

  constructor(props) {
    super(props)
    this.state = {
      // Temporary date state used during popup when user navigates to a different week with pending changes
      differentWeekDate: undefined,
      panelChange: undefined,
      dateBeforeRange: false,
      dateAfterRange: false,
    }
  }

  /**
   * Autoscroll content section to desired panel
   * @param panelNum - valid numbers 0 to 6
   */
  static autoScrollToPanel(panelNum) {
    scroller.scrollTo(panelNum, {
      duration: 400,
      delay: 0,
      smooth: 'easeInOutQuart',
      containerId: 'scrollableContainer',
      offset: 0,
    })
  }

  componentDidMount() {
    let currentSelectedDate = getNowDateInTimezone(this.context.user.locationData.iso_time_zone_code)
    if (currentSelectedDate) {
      this.setSelectedDate(currentSelectedDate)
      this.setPersistedDataFromGetRequest(currentSelectedDate)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedDate, postResponse, persistedData, loading, sessionData, postError, auth } = this.props

    if (!auth.isAuthenticated()) {
      auth.login({ redirect: window.location.href })
    }

    let currentSelectedDate
    if (!this.props.selectedDate) {
      currentSelectedDate = getNowDateInTimezone(this.context.user.locationData.iso_time_zone_code)
      this.setSelectedDate(currentSelectedDate)
    } else {
      currentSelectedDate = selectedDate
    }

    let selectedDateInCurrentWeek = this.isSelectedDateInCurrentScheduleDataWeek()

    if (postResponse) {
      this.showNotificationBasedOnVoluntaryResponse(postResponse)
      this.props.setPostResponse(null)
      this.setPersistedDataFromGetRequest(currentSelectedDate)
    }

    if (postError) {
      this.props.setPostError(null)
      if (postError.message && postError.code && ERROR_CODES_SHOW_MESSAGE.includes(postError.code)) {
        this.props.showNotificationError(true, postError.message)
      } else {
        this.props.showNotificationError(
          true,
          SAVE_ERROR_MESSAGE + ' ' + formatErrorCode(postError, ERROR_CODE_CANNOT_CONNECT_TO_SERVER),
        )
      }
    }

    if (persistedData && prevProps.persistedData !== persistedData) {
      if (persistedData.worker_id) {
        this.setSessionDataFromPersistedAndDefaultData()
      } else {
        this.setSessionDataFromPersistedData()
      }
    }
    // Auto scroll if there's session data, not loading, and only for a date change event
    if (loading === 'N' && sessionData && sessionData.worker_id) {
      if (
        (selectedDateInCurrentWeek && prevProps.selectedDate !== currentSelectedDate) ||
        (prevProps.sessionData &&
          prevProps.sessionData.start_date &&
          prevProps.sessionData.start_date !== sessionData.start_date) ||
        !prevProps.sessionData
      ) {
        TMOptionalAvailabilityPage.autoScrollToPanel(getDay(currentSelectedDate).toString())
      } else if (this.state.panelChange) {
        TMOptionalAvailabilityPage.autoScrollToPanel(this.state.panelChange)
        this.setState({
          panelChange: undefined,
        })
      }
    }
  }

  componentWillUnmount() {
    this.handleCollapseAll()
    this.props.setNavigateToNextLocation(false)
    this.props.setNextLocation(null)
    this.props.setSessionData(null)
  }

  /**
   * Sets the sessionData store to a copy of the persistedData store. This function
   * should be used when persistedData holds a message instead of the expected payload.
   */
  setSessionDataFromPersistedData = () => {
    this.props.setSessionData(JSON.parse(JSON.stringify(this.props.persistedData)))
  }

  /**
   * Sets the sessionData store to a copy of the persistedData. For days of the week that persistedData
   * doesn't have data, sessionData is set to default values.
   */
  setSessionDataFromPersistedAndDefaultData = () => {
    // Update the sessionData
    let newSessionData = JSON.parse(JSON.stringify(defaultSessionData))
    let persistedData = JSON.parse(JSON.stringify(this.props.persistedData))

    newSessionData.worker_id = persistedData.worker_id
    newSessionData.location_id = persistedData.location_id
    newSessionData.start_date = persistedData.start_date
    newSessionData.end_date = persistedData.end_date
    newSessionData.total_voluntary_availability = persistedData.total_voluntary_availability

    let daysToProcess = [
      persistedData.start_date,
      this.getDateNumDaysAfterDate(persistedData.start_date, 1),
      this.getDateNumDaysAfterDate(persistedData.start_date, 2),
      this.getDateNumDaysAfterDate(persistedData.start_date, 3),
      this.getDateNumDaysAfterDate(persistedData.start_date, 4),
      this.getDateNumDaysAfterDate(persistedData.start_date, 5),
      this.getDateNumDaysAfterDate(persistedData.start_date, 6),
    ]

    let daysProcessed = []

    if (persistedData.voluntary_availability_preferences) {
      Array.from(persistedData.voluntary_availability_preferences).forEach(function (element) {
        newSessionData.voluntary_availability_preferences[getDay(parseISO(element['schedule_date']))] = JSON.parse(
          JSON.stringify(element),
        )
        daysProcessed.push(element['schedule_date'])
      })
    }

    Array.from(daysToProcess).forEach(function (element) {
      if (!daysProcessed.includes(element)) {
        newSessionData.voluntary_availability_preferences[getDay(parseISO(element))].schedule_date = element
      }
    })

    this.props.setSessionData(newSessionData)
  }

  /**
   * Sets the persistedData store to the API response.
   */
  setPersistedDataFromGetRequest = (selectedDate) => {
    if (this.isSelectedDateBeforeValidRange(selectedDate)) {
      this.props.setPersistedData(null)
      this.props.setSessionData(null)
      this.setState({
        dateBeforeRange: true,
        dateAfterRange: false,
      })
    } else if (this.isSelectedDateAfterValidRange(selectedDate)) {
      this.props.setPersistedData(null)
      this.props.setSessionData(null)
      this.setState({
        dateAfterRange: true,
        dateBeforeRange: false,
      })
    } else {
      this.setState({
        dateBeforeRange: false,
        dateAfterRange: false,
      })
      this.props.setLoading('Y')
      this.props.loadGetRequest(
        this.context.user.userData.worker_id,
        selectedDate,
        this.context.user.locationData.location_id,
      )
    }
  }

  setSelectedDate = (date) => {
    this.props.setSelectedDate(date)
  }

  handleDateChange = (date) => {
    let inCurrentWeek = this.isSelectedDateInWeekOfPrevSelectedDate(date)

    if (!inCurrentWeek) {
      this.handleDifferentWeekDateChange(date)
    } else {
      this.setSelectedDate(date)
    }
  }

  handleDifferentWeekDateChange = (date) => {
    if (this.hasUpdatedSessionData()) {
      this.setState({
        differentWeekDate: date,
      })
      this.handleOpeningDifferentWeekDialog()
    } else {
      this.setSelectedDate(date)
      this.setPersistedDataFromGetRequest(date)
      this.handleCollapseAll()
    }
  }

  handleCancelButton = () => {
    if (this.hasUpdatedSessionData()) {
      this.handleOpeningCancelDialog()
    }
  }

  handleSaveButton = () => {
    let dataUpdates = this.processUpdatedSessionData()

    if (dataUpdates.voluntary_availability_preferences.length !== 0) {
      this.props.setUpdatedSessionData(dataUpdates)
      this.handleOpeningSaveDialog()
    }
  }

  /**
   * Calculates what has been updated in sessionData by comparing it against the persisted data,
   * and default data (if persisted data doesn't exist for a day). Returns a updatedSessionData
   * object with day configurations of the days that have been updated.
   * @returns {any}
   */
  processUpdatedSessionData = () => {
    let sessionData = JSON.parse(JSON.stringify(this.props.sessionData))
    let dataUpdates = JSON.parse(JSON.stringify(defaultSessionDataUpdates))
    dataUpdates.worker_id = sessionData.worker_id
    dataUpdates.location_id = sessionData.location_id

    Array.from(sessionData.voluntary_availability_preferences).forEach((element) => {
      // Check if persisted data exists for the day being processed.
      if (this.persistedDataContainDate(element.schedule_date)) {
        if (this.doesPersistedDataContainDateAndNotMatchSessionData(element)) {
          // Doesn't match. Add this day's data to the output
          delete element.last_updated_timestamp
          dataUpdates.voluntary_availability_preferences.push(element)
        }
      } else {
        // compare session data against default data
        Object.keys(element).some((key) => {
          if (element[key] === true) {
            delete element.last_updated_timestamp
            dataUpdates.voluntary_availability_preferences.push(element)
            return true
          } else {
            return false
          }
        })
      }
    })

    dataUpdates.total_voluntary_availability = dataUpdates.voluntary_availability_preferences.length
    return dataUpdates
  }

  showNotificationBasedOnVoluntaryResponse = (postResponse) => {
    let errorList = []

    if (postResponse) {
      postResponse.voluntary_availability_preferences.forEach((postResponseElement) => {
        if (postResponseElement.status_change === 'Failed') {
          errorList.push(' ' + postResponseElement.schedule_date)
        }
      })
    }

    if (errorList.length) {
      this.props.showNotificationError(true, PREFERENCES_PARTIALLY_SAVED_MESSAGE + errorList.join(','))
    } else {
      this.props.showNotificationSuccess(true, PREFERENCES_SAVED_MESSAGE)
    }
  }

  handleExpandAll = () => {
    let newPanel = Object.assign({}, expandAll)

    this.props.setExpand(newPanel)
  }

  handleCollapseAll = () => {
    let newPanel = Object.assign({}, collapseAll)

    this.props.setExpand(newPanel)
  }

  handleExpandPanel = (panel) => {
    let newPanel = Object.assign({}, this.props.expandPanel)
    newPanel[panel] = !this.props.expandPanel[panel]

    this.setState({
      panelChange: panel.substring(5),
    })
    this.props.setExpand(newPanel)
  }

  handlePreferenceButtons = (buttonName, upDown, panelNum) => {
    let newSessionData = JSON.parse(JSON.stringify(this.props.sessionData))
    let currentButtonState =
      newSessionData.voluntary_availability_preferences[panelNum][buttonNamesMapping[upDown][buttonName]]

    newSessionData.voluntary_availability_preferences[panelNum][buttonNamesMapping[upDown][buttonName]] =
      !currentButtonState

    let upDownReverse = this.getUpDownReverse(upDown)

    Array.from(buttonNames).forEach(function (element) {
      // Set all OTHER buttons on current row to unclicked
      if (element !== buttonName && buttonNamesMapping[upDown][element]) {
        newSessionData.voluntary_availability_preferences[panelNum][buttonNamesMapping[upDown][element]] = false
      }
      // // Set all buttons on other row to unclicked
      if (buttonNamesMapping[upDownReverse][element]) {
        newSessionData.voluntary_availability_preferences[panelNum][buttonNamesMapping[upDownReverse][element]] = false
      }
    })

    this.props.setSessionData(newSessionData)
  }

  handleOpeningSaveDialog = () => {
    this.props.setSaveDialogOpen(true)
  }

  handleClosingSaveDialog = () => {
    this.props.setUpdatedSessionData(null)
    this.props.setSaveDialogOpen(false)
  }

  handleSaveDialogYesButton = () => {
    this.props.loadPostRequest(this.props.updatedSessionData)
    this.props.setUpdatedSessionData(null)
    this.props.setSaveDialogOpen(false)
  }

  handleOpeningCancelDialog = () => {
    this.props.setCancelDialogOpen(true)
  }

  handleClosingCancelDialog = () => {
    this.props.setCancelDialogOpen(false)
  }

  handleCancelDialogYesButton = () => {
    this.props.setCancelDialogOpen(false)
    this.setSessionDataFromPersistedAndDefaultData()
  }

  handleOpeningExitDialog = (nextLocation) => {
    this.props.setNextLocation(nextLocation)
    this.props.setExitDialogOpen(true)
  }

  handleClosingExitDialog = () => {
    this.props.setNextLocation(null)
    this.props.setExitDialogOpen(false)
  }

  handleExitDialogYesButton = () => {
    this.props.setExitDialogOpen(false)
    this.props.setNavigateToNextLocation(true)
  }

  handleOpeningDifferentWeekDialog = () => {
    this.props.setDifferentWeekDialogOpen(true)
  }

  handleClosingDifferentWeekDialog = () => {
    this.props.setDifferentWeekDialogOpen(false)
    this.setState({
      differentWeekDate: undefined,
    })
  }

  handleDifferentWeekDialogYesButton = () => {
    this.props.setDifferentWeekDialogOpen(false)
    this.setPersistedDataFromGetRequest(this.state.differentWeekDate)
    this.setSelectedDate(this.state.differentWeekDate)
    this.setState({
      differentWeekDate: undefined,
    })
    this.handleCollapseAll()
  }

  /**
   * This function gets called during the ReactRouter/Prompt execution. It's meant to show our own awesome popup dialog
   * instead of the default ugly one provided by Prompt.
   * @param nextLocation
   * @returns {boolean}
   */
  handleNavigation = (nextLocation) => {
    if (this.hasUpdatedSessionData()) {
      this.handleOpeningExitDialog(nextLocation)
      return false
    }

    return true
  }

  hasUpdatedSessionData = () => {
    // Only check for updated session data if session data isn't an error message
    if (this.props.sessionData && this.props.sessionData.worker_id) {
      let dataUpdates = this.processUpdatedSessionData()

      return dataUpdates.voluntary_availability_preferences.length !== 0
    } else {
      return false
    }
  }

  persistedDataContainDate = (date) => {
    if (this.props.persistedData && this.props.persistedData.voluntary_availability_preferences) {
      return this.props.persistedData.voluntary_availability_preferences.some(function (element) {
        return element.schedule_date === date
      })
    } else {
      return false
    }
  }

  doesPersistedDataContainDateAndNotMatchSessionData = (sessionPreference) => {
    if (this.props.persistedData && this.props.persistedData.voluntary_availability_preferences) {
      return this.props.persistedData.voluntary_availability_preferences.some(function (element) {
        return element.schedule_date === sessionPreference.schedule_date && !_.isEqual(element, sessionPreference)
      })
    } else {
      return false
    }
  }

  /**
   * Gets a date string representing the date after adding the daysToAdd to the referenceDate
   * @param referenceDate javascript Date
   * @param daysToAdd integer number of days to add to reference date
   * @returns {string} a date string in format "yyyy-MM-dd"
   */
  getDateNumDaysAfterDate = (referenceDate, daysToAdd) => {
    let date = addDays(parseISO(referenceDate), daysToAdd)
    return toIsoStringWithoutTime(date)
  }

  isSelectedDateInCurrentScheduleDataWeek = () => {
    if (this.props.persistedData && this.props.persistedData['worker_id']) {
      let firstDayOfWeek = parse(this.props.persistedData['start_date'], 'yyyy-MM-dd', new Date())
      let lastDayOfWeek = parse(this.props.persistedData['end_date'], 'yyyy-MM-dd', new Date())
      if (!(isBefore(this.props.selectedDate, firstDayOfWeek) || isAfter(this.props.selectedDate, lastDayOfWeek))) {
        return true
      }
    }
    return false
  }

  isSelectedDateInWeekOfPrevSelectedDate = (selectedDate) => {
    return !(
      isBefore(selectedDate, startOfWeek(this.props.selectedDate)) ||
      isAfter(selectedDate, endOfWeek(this.props.selectedDate))
    )
  }

  isSelectedDateBeforeValidRange = (selectedDate) => {
    return isDateBeforeNumberOfWeeksFromToday(
      selectedDate,
      DATE_RANGE_PAST_WEEKS,
      this.context.user.locationData.iso_time_zone_code,
    )
  }

  isSelectedDateAfterValidRange = (selectedDate) => {
    return isDateAfterNumberOfWeeksFromToday(
      selectedDate,
      DATE_RANGE_FUTURE_WEEKS,
      this.context.user.locationData.iso_time_zone_code,
    )
  }

  /**
   * Creates a printable string containing the current POST response error code. If postError is null, then returns null.
   * If postError is not null but code is, then set the error code to 3-0 (which means unable to connect to server).
   * Example: "(error code: 3-0)"
   */
  formatErrorCode = (error) => {
    let errorCode = null

    if (error) {
      if (error.code) {
        errorCode = ' (error code: ' + error.code + ')'
      } else {
        errorCode = ' (error code: ' + ERROR_CODE_CANNOT_CONNECT_TO_SERVER + ')'
      }
    }
    return errorCode
  }

  getCurrentButtonState(buttonName, upDown, panelNum) {
    const { sessionData } = this.props

    return sessionData.voluntary_availability_preferences[panelNum][buttonNamesMapping[upDown][buttonName]]
  }

  getButtonStyling(currentButtonState) {
    return currentButtonState ? styles.availabilityButtonsSelected : styles.availabilityButtons
  }

  getButtonStylingDisabled(currentButtonState) {
    return currentButtonState ? styles.availabilityButtonsSelectedDisabled : styles.availabilityButtonsDisabled
  }

  getButtonTextStyling(currentButtonState) {
    return currentButtonState ? styles.availabilityButtonsTextSelected : styles.availabilityButtonsText
  }

  // ---------------- RENDER FUNCTIONS ---------------- //
  renderVoluntaryButtonText(buttonName, currentButtonState) {
    let buttonNameArray = buttonName.split(' ')

    if (buttonNameArray[1]) {
      return (
        <Box component={'span'} sx={this.getButtonTextStyling(currentButtonState)}>
          {buttonNameArray[0]}
          <br />
          {buttonNameArray[1]}
        </Box>
      )
    } else {
      return (
        <Box component={'span'} sx={this.getButtonTextStyling(currentButtonState)}>
          {buttonName}
        </Box>
      )
    }
  }

  /**
   * Renders an invisible div the same size as the voluntary buttons to use in proportional spacing of a row of buttons.
   * @param key a unique identifier for the div element
   * @returns {*}
   */
  renderVoluntarySpace(key) {
    return <Box sx={styles.availabilitySpace} key={key} />
  }

  renderVoluntaryButton(buttonName, upDown, panelNum) {
    let currentButtonState = this.getCurrentButtonState(buttonName, upDown, panelNum)

    return (
      <Box
        sx={this.getButtonStyling(currentButtonState)}
        key={buttonName}
        role="button"
        tabIndex="0"
        onKeyPress={null}
        onClick={() => this.handlePreferenceButtons(buttonName, upDown, panelNum)}
        data-cy={'voluntaryAvailabilityButton_' + panelNum + '_' + upDown + '_' + buttonName}
      >
        {this.renderVoluntaryButtonText(buttonName, currentButtonState)}
      </Box>
    )
  }

  renderVoluntaryButtonDisabled(buttonName, upDown, panelNum) {
    let currentButtonState = this.getCurrentButtonState(buttonName, upDown, panelNum)

    return (
      <Box sx={this.getButtonStylingDisabled(currentButtonState)} key={buttonName}>
        {this.renderVoluntaryButtonText(buttonName, currentButtonState)}
      </Box>
    )
  }

  renderVoluntaryButtons(panelNum, isDisabled, upDown) {
    if (upDown === 'up') {
      if (isDisabled) {
        return (
          <Box sx={styles.availabilityButtonsContainer}>
            {this.renderVoluntaryButtonDisabled(buttonNames[0], upDown, panelNum)}
            {this.renderVoluntaryButtonDisabled(buttonNames[1], upDown, panelNum)}
            {this.renderVoluntaryButtonDisabled(buttonNames[2], upDown, panelNum)}
            {this.renderVoluntaryButtonDisabled(buttonNames[3], upDown, panelNum)}
            {this.renderVoluntaryButtonDisabled(buttonNames[4], upDown, panelNum)}
            {this.renderVoluntaryButtonDisabled(buttonNames[5], upDown, panelNum)}
          </Box>
        )
      } else {
        return (
          <Box sx={styles.availabilityButtonsContainer}>
            {this.renderVoluntaryButton(buttonNames[0], upDown, panelNum)}
            {this.renderVoluntaryButton(buttonNames[1], upDown, panelNum)}
            {this.renderVoluntaryButton(buttonNames[2], upDown, panelNum)}
            {this.renderVoluntaryButton(buttonNames[3], upDown, panelNum)}
            {this.renderVoluntaryButton(buttonNames[4], upDown, panelNum)}
            {this.renderVoluntaryButton(buttonNames[5], upDown, panelNum)}
          </Box>
        )
      }
    } else {
      if (isDisabled) {
        return (
          <Box sx={styles.availabilityButtonsContainer}>
            {this.renderVoluntarySpace('left')}
            {this.renderVoluntaryButtonDisabled(buttonNames[0], upDown, panelNum)}
            {this.renderVoluntaryButtonDisabled(buttonNames[1], upDown, panelNum)}
            {this.renderVoluntaryButtonDisabled(buttonNames[2], upDown, panelNum)}
            {this.renderVoluntaryButtonDisabled(buttonNames[5], upDown, panelNum)}
            {this.renderVoluntarySpace('right')}
          </Box>
        )
      } else {
        return (
          <Box sx={styles.availabilityButtonsContainer}>
            {this.renderVoluntarySpace('left')}
            {this.renderVoluntaryButton(buttonNames[0], upDown, panelNum)}
            {this.renderVoluntaryButton(buttonNames[1], upDown, panelNum)}
            {this.renderVoluntaryButton(buttonNames[2], upDown, panelNum)}
            {this.renderVoluntaryButton(buttonNames[5], upDown, panelNum)}
            {this.renderVoluntarySpace('right')}
          </Box>
        )
      }
    }
  }

  /**
   * Renders the buttons (expand, collapse, save, cancel) in a fixed container below the calendar.
   * @returns {*}
   */
  renderTopButtons() {
    let madeUpdates = this.hasUpdatedSessionData()

    return (
      <Paper elevation={1} square sx={styles.topButtonsContainer}>
        <Box sx={styles.topButtonsContainerLeft}>
          <Box
            sx={styles.topButton}
            onClick={this.handleExpandAll}
            key="EXPAND"
            role="button"
            tabIndex="0"
            onKeyPress={null}
          >
            <ChevronDown sx={styles.expandCollapseIcon} />
          </Box>
          <Box
            sx={styles.topButton}
            onClick={this.handleCollapseAll}
            key="SAVE"
            role="button"
            tabIndex="0"
            onKeyPress={null}
          >
            <ChevronUp sx={styles.expandCollapseIcon} />
          </Box>
        </Box>
        <Box sx={styles.topButtonsContainerRight}>
          {madeUpdates ? this.renderEnabledCancelSave() : this.renderDisabledCancelSave()}
        </Box>
      </Paper>
    )
  }

  renderEnabledCancelSave() {
    return (
      <React.Fragment>
        <Box
          sx={styles.topButton}
          key="SAVE"
          role="button"
          tabIndex="0"
          onKeyPress={null}
          onClick={this.handleSaveButton}
          data-cy="voluntaryAvailabilitySave"
        >
          SAVE
        </Box>
        <Box
          sx={styles.topButton}
          key="CANCEL"
          role="button"
          tabIndex="0"
          onKeyPress={null}
          onClick={this.handleCancelButton}
        >
          CANCEL
        </Box>
      </React.Fragment>
    )
  }

  renderDisabledCancelSave() {
    return (
      <React.Fragment>
        <Box sx={styles.topButtonDisabled} key="SAVE">
          SAVE
        </Box>
        <Box sx={styles.topButtonDisabled} key="CANCEL">
          CANCEL
        </Box>
      </React.Fragment>
    )
  }

  renderExpansionPanel(scheduleDate, timestampDisplay, panelNum, isDisabled) {
    const { expandPanel } = this.props
    const ouputDateFormat = 'dddd, MMMM D'

    let panelStyle = null
    if (isDisabled) {
      panelStyle = styles.disabled
    }
    return (
      <Accordion
        expanded={expandPanel['panel' + panelNum]}
        onChange={() => this.handleExpandPanel('panel' + panelNum)}
        sx={panelStyle}
        data-cy={'voluntaryAvailabilityPanel_' + panelNum}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body2" component={'h2'} sx={styles.dateHeading}>
            <Box component={'span'}>{scheduleDate?.format(ouputDateFormat)}</Box>
            {timestampDisplay}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={styles.expansionPanel}>
          <List sx={styles.list}>
            <ListItem sx={styles.listItemRoot}>
              <Typography variant="body2" component={'h3'} aria-label={'Voluntary Up'} sx={styles.labelHeading}>
                VOLUNTARY
              </Typography>
              <UpArrow />
            </ListItem>
            <ListItem sx={styles.listItemGutter}>{this.renderVoluntaryButtons(panelNum, isDisabled, 'up')}</ListItem>
            <Divider />
            <ListItem sx={styles.listItemRoot}>
              <Typography variant="body2" component={'h3'} aria-label={'Voluntary Down'} sx={styles.labelHeading}>
                VOLUNTARY
              </Typography>
              <DownArrow />
            </ListItem>
            <ListItem sx={styles.listItemGutter}>{this.renderVoluntaryButtons(panelNum, isDisabled, 'down')}</ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    )
  }

  renderAvailabilityList = (thisRef) => {
    const { sessionData } = this.props

    let list = []
    Array.from(sessionData.voluntary_availability_preferences).forEach(function (element, i) {
      let scheduleDate = moment(element.schedule_date)
      let timestampDisplay = null
      // This render function will only be called if there is a timezone value, so it is a safe reference
      let today = getNowDateInTimezone(thisRef.context.user.locationData.iso_time_zone_code)
      let isDisabled = isBefore(new Date(scheduleDate), today)

      if (element.last_updated_timestamp) {
        timestampDisplay = thisRef.convertTimestamp(element.last_updated_timestamp)
      }

      list.push(
        <Grid item xs={12} md={12} key={i}>
          <Element name={i.toString()} sx="element">
            {thisRef.renderExpansionPanel(scheduleDate, timestampDisplay, i, isDisabled)}
          </Element>
        </Grid>,
      )
    })
    return list
  }

  getUpDownReverse = (upDown) => {
    return upDown === 'up' ? 'down' : 'up'
  }

  convertTimestamp(timeStamp) {
    const outputTimeFormat = 'MM/DD/YY, hh:mm:ss a'

    let lastUpdateTimeStamp = moment.utc(timeStamp)
    return (
      <Typography variant="caption" sx={styles.lastEdit}>
        Last Updated:{' '}
        {lastUpdateTimeStamp.tz(this.context.user.locationData.iso_time_zone_code)?.format(outputTimeFormat)}
      </Typography>
    )
  }

  render() {
    const { nextLocation, navigateToNextLocation, selectedDate, sessionData, getError } = this.props
    let ViewableCalendar = null
    let ViewableContent = null
    let ViewableSaveDialog = null
    let ViewableCancelDialog = null
    let ViewableDifferentWeekDialog = null

    if (navigateToNextLocation) {
      ViewableContent = <Navigate to={nextLocation.pathname} />
    } else {
      ViewableCalendar = (
        <WeeklyCalendar
          id="TMOptionalAvailabilityPage"
          selectedDate={selectedDate}
          onChange={this.handleDateChange.bind(this)}
        />
      )

      ViewableSaveDialog = (
        <Dialog
          open={this.props.saveDialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClosingSaveDialog}
        >
          <DialogTitle id="save-dialog-slide-title">{SAVE_DIALOG_TITLE}</DialogTitle>
          <DialogContent>
            <DialogContentText id="save-dialog-slide-description">{SAVE_DIALOG_TEXT}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClosingSaveDialog} color="primary">
              NO
            </Button>
            <Button onClick={this.handleSaveDialogYesButton} color="primary" data-cy="voluntaryAvailabilitySaveDialog">
              YES
            </Button>
          </DialogActions>
        </Dialog>
      )
      ViewableCancelDialog = (
        <Dialog
          open={this.props.cancelDialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClosingCancelDialog}
        >
          <DialogTitle id="save-dialog-slide-title">{CANCEL_DIALOG_TITLE}</DialogTitle>
          <DialogContent>
            <DialogContentText id="save-dialog-slide-description">{CANCEL_DIALOG_TEXT}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClosingCancelDialog} color="primary">
              NO
            </Button>
            <Button onClick={this.handleCancelDialogYesButton} color="primary">
              YES
            </Button>
          </DialogActions>
        </Dialog>
      )
      ViewableDifferentWeekDialog = (
        <Dialog
          open={this.props.differentWeekDialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClosingDifferentWeekDialog}
        >
          <DialogTitle id="diff-week-dialog-slide-title">{DIFF_WEEK_DIALOG_TITLE}</DialogTitle>
          <DialogContent>
            <DialogContentText id="diff-week-dialog-slide-description">{DIFF_WEEK_DIALOG_TEXT}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClosingDifferentWeekDialog} color="primary">
              NO
            </Button>
            <Button onClick={this.handleDifferentWeekDialogYesButton} color="primary">
              YES
            </Button>
          </DialogActions>
        </Dialog>
      )
      if (this.props.loading === 'Y') {
        ViewableContent = (
          <Box sx={styles.mainContainerPosition}>
            <Box sx={styles.loadingIconContainer}>
              <CircularProgress size={48} />
            </Box>
          </Box>
        )
      } else if (this.state.dateBeforeRange) {
        ViewableContent = (
          <Box sx={styles.mainContainerPosition}>
            <Box component={'p'} sx={styles.infoMessage}>
              {DATE_BEFORE_RANGE_MESSAGE}
            </Box>
          </Box>
        )
      } else if (this.state.dateAfterRange) {
        ViewableContent = (
          <Box sx={styles.mainContainerPosition}>
            <Box component={'p'} sx={styles.infoMessage}>
              {DATE_AFTER_RANGE_MESSAGE}
            </Box>
          </Box>
        )
      } else if (getError) {
        if (getError.message && getError.code && ERROR_CODES_SHOW_MESSAGE.includes(getError.code)) {
          ViewableContent = (
            <Box sx={styles.mainContainerPosition}>
              <Box component={'p'} sx={styles.errorMessage}>
                {getError.message}
              </Box>
            </Box>
          )
        } else {
          ViewableContent = (
            <Box sx={styles.mainContainerPosition}>
              <Box component={'p'} sx={styles.errorMessage}>
                {LOAD_ERROR_MESSAGE}
                <Box component={'span'} sx={styles.errorCodeMessage}>
                  {this?.formatErrorCode(getError)}
                </Box>
              </Box>
            </Box>
          )
        }
      } else if (sessionData && sessionData.worker_id && this.context) {
        ViewableContent = (
          <React.Fragment>
            <Box sx={styles.topButtonsContainerPosition}>{this.renderTopButtons()}</Box>
            <Box sx={styles.mainContainerPosition} id="scrollableContainer">
              <Paper sx={styles.mainScrollContainer} elevation={1} square>
                <Grid container>{this.renderAvailabilityList(this)}</Grid>
              </Paper>
            </Box>
            <OptionalAvailabilityPromptContainer madeUpdates={this.hasUpdatedSessionData()} />
          </React.Fragment>
        )
      }
    }

    return (
      <React.Fragment>
        <HeaderTitle title="My Voluntary Availability" />
        {ViewableCalendar}
        {ViewableContent}
        {ViewableSaveDialog}
        {ViewableCancelDialog}
        {ViewableDifferentWeekDialog}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPersistedData,
      setExpand,
      setLoading,
      setSessionData,
      loadGetRequest,
      loadPostRequest,
      setPostResponse,
      setSelectedDate,
      setSaveDialogOpen,
      setCancelDialogOpen,
      setExitDialogOpen,
      setDifferentWeekDialogOpen,
      setUpdatedSessionData,
      setNextLocation,
      setNavigateToNextLocation,
      showNotificationSuccess,
      showNotificationError,
      setPostError,
    },
    dispatch,
  )

const mapStateToProps = (state) => {
  return {
    loading: state.tmOptionalAvailability.loading,
    selectedDate: state.tmOptionalAvailability.selectedDate,
    expandPanel: state.tmOptionalAvailability.expandPanel,
    persistedData: state.tmOptionalAvailability.persistedData,
    sessionData: state.tmOptionalAvailability.sessionData,
    updatedSessionData: state.tmOptionalAvailability.updatedSessionData,
    postResponse: state.tmOptionalAvailability.postResponse,
    saveDialogOpen: state.tmOptionalAvailability.saveDialogOpen,
    cancelDialogOpen: state.tmOptionalAvailability.cancelDialogOpen,
    exitDialogOpen: state.tmOptionalAvailability.exitDialogOpen,
    differentWeekDialogOpen: state.tmOptionalAvailability.differentWeekDialogOpen,
    nextLocation: state.tmOptionalAvailability.nextLocation,
    navigateToNextLocation: state.tmOptionalAvailability.navigateToNextLocation,
    getError: state.tmOptionalAvailability.getError,
    postError: state.tmOptionalAvailability.postError,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withAuth()(TMOptionalAvailabilityPage))
