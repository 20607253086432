import { Divider } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../../../../auth/hooks/useUser'
import { CALL_OFF_ABSENT, CATEGORY_FEATURE_MAP } from '../../../../../constants/RequestConstants'
import { setControlPanelSettings } from '../../../../../store/leader/actionCreator'
import TotalTimeOffRequestsByStatusButton from '../../TotalTimeOffRequestsByStatusButton'

const CallOffAtAGlance = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { configMap } = useUser().leaderConfig

  const handleCallOffRequestsButtonClick = () => {
    dispatch(
      setControlPanelSettings(
        CALL_OFF_ABSENT,
        configMap[CALL_OFF_ABSENT].defaultStartDate,
        configMap[CALL_OFF_ABSENT].defaultEndDate,
        {},
        configMap[CALL_OFF_ABSENT].searchText,
        configMap[CALL_OFF_ABSENT].selectedSortByIndex,
        configMap[CALL_OFF_ABSENT].sortOrder,
      ),
    )
    navigate('/leader/myteam')
  }

  return (
    <>
      <Divider aria-hidden="true" />
      <TotalTimeOffRequestsByStatusButton
        status={''}
        startDate={configMap[CALL_OFF_ABSENT].defaultStartDate}
        endDate={configMap[CALL_OFF_ABSENT].defaultEndDate}
        handleButtonClick={handleCallOffRequestsButtonClick}
        buttonMessage={'Call Off Requests'}
        selectedCategory={CATEGORY_FEATURE_MAP.get(CALL_OFF_ABSENT)}
        categoryName={CALL_OFF_ABSENT}
      />
    </>
  )
}
export default CallOffAtAGlance
