import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Slide,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '../../auth/hooks/useUser'
import RequestStatusIcon from '../../components/Requests/RequestStatusIcon'
import { targetGrey, targetRed } from '../../config/themeConfig'
import { CALL_IN_OFF_PREFIX, FAILED, REQUEST_STATUS_TYPES, TIME_OFF_TYPES } from '../../constants/RequestConstants'
import { STATUS_MAP_BY_REQUEST_TYPE_GROUP } from '../../constants/leaderConstants'
import {
  closeTimeOffRequestDialog,
  setTimeOffRequestDataSaving,
  updateLeaderTimeOffDecisions,
} from '../../store/leaderViewPTORequestsAutomation/actionCreator'
import { padEmpIdWithZeros } from '../../utils/EmployeeId'
import { COMMENT, COMMENT_MAX_LENGTH } from '../Requests/PaidTimeOff/RequestPaidTimeOff'
import RequestCommentForm from '../Requests/RequestCommentForm'
import RequestDetailsTable from '../Requests/RequestDetailsTable'

const ADD_A_COMMENT_TITLE = 'Add Comment:'
const COMMENTS_TITLE = 'Comment History:'
const STATUS_TITLE = 'Current status:'
const STATUSES_TITLE = 'Current status:'
const LAST_UPDATED_TITLE = 'Last updated by:'
const UPDATE_STATUS_TITLE = 'Update Status:'

const UPDATE_STATUS_FAILURE_MSG_SUMMARY =
  'One or more request segments has failed to process completely and/or requires manual action.'
const UPDATE_STATUS_FAILURE_MSG_ACTION =
  "Please take the necessary action on the team member's time card or schedule and then update the status(s) below accordingly."
const STATUS_NOT_UPDATABLE_MSG = 'This request has reached its final state and can no longer be updated.'
const STATUS_UPDATABLE_IN_FUTURE_MSG =
  'This request will be able to be actioned upon once the requested time has passed.'
const startEndDateFormats = 'dddd, MMMM D'
const updatedDateTimeFormat = 'lll'

const UPDATE_STATUS_GROUPS = {
  UPDATE_NONE: 'updateNone',
  UPDATE_REQUEST: 'updateRequest',
  UPDATE_INDIVIDUAL_REQUEST_SEGMENT: 'updateFailedRequestDetail',
}

function Transition(props) {
  return <Slide direction="up" {...props} />
}

export const doesAllowPartialAction = (status, type, endTime, timeZone) => {
  if (status === REQUEST_STATUS_TYPES.FAILED) return true

  let isRequestDetailInPast = moment.tz(endTime, timeZone).isBefore(moment.tz(timeZone))

  return !!(status === REQUEST_STATUS_TYPES.SCHEDULED && type === TIME_OFF_TYPES.PTO_OTHER && isRequestDetailInPast)
}

const willHavePartialAction = (status, type, endTime, timeZone) => {
  let isRequestDetailInFuture = moment.tz(endTime, timeZone).isAfter(moment.tz(timeZone))
  return !!(status === REQUEST_STATUS_TYPES.SCHEDULED && type === TIME_OFF_TYPES.PTO_OTHER && isRequestDetailInFuture)
}

const getStyles = (theme) => ({
  failureMessage: {
    color: targetRed.main,
    textAlign: 'center',
  },
  infoMessage: {
    color: targetGrey.contrastText,
    textAlign: 'center',
    paddingTop: '24px',
  },
  textSection: {
    marginTop: '20px',
  },
  loader: {
    padding: '0px 32px 12px 0px',
  },
  root: {
    flexGrow: 1,
    marginLeft: '27px',
    marginRight: '10px',
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '11px 16px',
    padding: '3px',
  },
  commentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '11px 16px',
    border: `1px solid ${theme.palette.tertiary.dark}`,
    padding: '3px',
    borderRadius: '5px',
  },
  updateStatusContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '11px 16px',
    padding: '3px',
  },
  commentRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  commentColumn: {
    display: 'flex',
  },
  dash: {
    marginLeft: '5px',
    marginRight: '5px',
    color: theme.infoMessages.color,
    alignSelf: 'center',
  },
  smallDetail: {
    fontSize: 'small',
    color: theme.infoMessages.color,
  },
  statusListItem: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  statusTextField: {
    marginLeft: '5px',
  },
  comment: {
    fontSize: 'medium',
    color: theme.infoMessages.color,
    marginLeft: '2px',
    width: '100%',
    overflowWrap: 'anywhere',
  },
  commentHistoryTitle: {
    display: 'flex',
    fontWeight: 'bold',
    marginTop: '3px',
    marginBottom: '3px',
  },
  detail: {
    color: theme.infoMessages.color,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3px',
  },
  requestDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  dividerPadding: {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  requestStatusDropDown: {
    width: '180px',
  },
})

const TimeOffRequestLeaderDialog = () => {
  const dispatch = useDispatch()
  const { session } = useAuth()
  const theme = useTheme()
  const styles = getStyles(theme)

  const user = useUser()

  // local state
  const [leaderComment, setLeaderComment] = useState('')
  const [updateStatusGroup, setUpdateStatusGroup] = useState(null)
  const [selectableStatuses, setSelectableStatuses] = useState([])
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [statusChangeMap, setStatusChangeMap] = useState(new Map())
  const [statusChange, setStatusChange] = useState('')
  const [updateNoneMessage, setUpdateNoneMessage] = useState(STATUS_NOT_UPDATABLE_MSG)

  // redux state (selectors)
  const selectedTimeOffRequest = useSelector((state) => state.leaderViewPTORequestsAutomation.selectedTimeOffRequest)
  const { request_details, worker_details } = selectedTimeOffRequest
  const timeOffRequestSaveDialogOpen = useSelector(
    (state) => state.leaderViewPTORequestsAutomation.timeOffRequestSaveDialogOpen,
  )
  const timeOffRequestDataSaving = useSelector(
    (state) => state.leaderViewPTORequestsAutomation.timeOffRequestDataSaving,
  )

  useEffect(() => {
    if (selectedTimeOffRequest && !updateStatusGroup) {
      const hasCallInOffInDetail = (detail) => detail.type.includes(CALL_IN_OFF_PREFIX)
      const hasCallInOff = request_details.some(hasCallInOffInDetail)

      let statusArray = []
      let allowsPartialAction = false
      let requestWillHaveMorePartialAction = false

      selectedTimeOffRequest.request_details.forEach((detail) => {
        statusArray.push(detail.status)
        if (doesAllowPartialAction(detail.status, detail.type, detail.end_time, user.locationData.iso_time_zone_code)) {
          allowsPartialAction = true
        }
        if (willHavePartialAction(detail.status, detail.type, detail.end_time, user.locationData.iso_time_zone_code)) {
          requestWillHaveMorePartialAction = true
        }
      })

      let status = statusArray[0] // use when all request segments are the same

      if (hasCallInOff) {
        if (Object.prototype.hasOwnProperty.call(STATUS_MAP_BY_REQUEST_TYPE_GROUP.CALL_IN_OFF, status)) {
          if (status === FAILED) {
            setUpdateStatusGroup(UPDATE_STATUS_GROUPS.UPDATE_INDIVIDUAL_REQUEST_SEGMENT)
          } else {
            setUpdateStatusGroup(UPDATE_STATUS_GROUPS.UPDATE_REQUEST)
          }
          setSelectableStatuses(STATUS_MAP_BY_REQUEST_TYPE_GROUP.CALL_IN_OFF[status])
        } else {
          setUpdateStatusGroup(UPDATE_STATUS_GROUPS.UPDATE_NONE)
        }
      } else {
        let statusSet = new Set(statusArray)
        if (allowsPartialAction) {
          setUpdateStatusGroup(UPDATE_STATUS_GROUPS.UPDATE_INDIVIDUAL_REQUEST_SEGMENT)
          setSelectableStatuses(STATUS_MAP_BY_REQUEST_TYPE_GROUP.PTO_VACATION_AUTOMATION[REQUEST_STATUS_TYPES.FAILED])
        } else if (
          statusSet.size === 1 &&
          Object.prototype.hasOwnProperty.call(STATUS_MAP_BY_REQUEST_TYPE_GROUP.PTO_VACATION_AUTOMATION, status)
        ) {
          setUpdateStatusGroup(UPDATE_STATUS_GROUPS.UPDATE_REQUEST)
          setSelectableStatuses(STATUS_MAP_BY_REQUEST_TYPE_GROUP.PTO_VACATION_AUTOMATION[status])
        } else {
          if (requestWillHaveMorePartialAction) {
            // if a PTO other detail is in scheduled status and is in the future
            setUpdateNoneMessage(STATUS_UPDATABLE_IN_FUTURE_MSG)
          }
          setUpdateStatusGroup(UPDATE_STATUS_GROUPS.UPDATE_NONE)
        }
      }
    }
  }, [
    selectedTimeOffRequest,
    setUpdateStatusGroup,
    request_details,
    updateStatusGroup,
    user.locationData.iso_time_zone_code,
  ])

  const buildRequestBody = () => {
    let detailArray = []

    if (statusChangeMap.size > 0) {
      for (let [index, status] of statusChangeMap.entries()) {
        detailArray.push({ request_detail_id: request_details[index].time_off_request_detail_id, status: status })
      }
    } else {
      for (let detail of request_details) {
        detailArray.push({ request_detail_id: detail.time_off_request_detail_id, status: statusChange })
      }
    }

    let requestBody = {
      comment: leaderComment,
      request_id: selectedTimeOffRequest.request_id,
      request_details: detailArray,
      updated_by: padEmpIdWithZeros(session?.userInfo?.empid, 10),
      feature: 'legacy_leader',
    }

    if (!/\S/.test(leaderComment)) {
      // comment is empty or only contains whitespace
      delete requestBody.comment
    }

    return requestBody
  }

  const handleUpdateStatusSubmit = () => {
    let requestBody = buildRequestBody()
    if (
      user.is('punch_correction', 'leader', 'read', true) ||
      user.is('availability', 'leader', 'read', true) ||
      user.is('time_off', 'leader', 'read', true)
    ) {
      dispatch(setTimeOffRequestDataSaving(false))
    } else {
      dispatch(setTimeOffRequestDataSaving(true))
    }
    dispatch(updateLeaderTimeOffDecisions(requestBody))
    setLeaderComment('')
  }

  const handleClosingSaveDialog = () => {
    dispatch(closeTimeOffRequestDialog())
    setLeaderComment('')
  }

  const handleStatusChangePerDetail = (status, index) => {
    setStatusChangeMap(new Map(statusChangeMap.set(index, status)))
    setSubmitDisabled(false)
  }

  const handleStatusChange = (status) => {
    setStatusChange(status)
    if (
      user.is('punch_correction', 'leader', 'read', true) ||
      user.is('availability', 'leader', 'read', true) ||
      user.is('time_off', 'leader', 'read', true)
    ) {
      setSubmitDisabled(true)
    } else {
      setSubmitDisabled(false)
    }
  }

  const getDialogTitle = () => {
    return `Update ${worker_details.full_name}'s Call In / Paid Time Off Request Status`
  }

  const renderDialogContent = () => {
    return (
      <React.Fragment>
        <DialogContent>
          {renderRequestSummary()}
          {renderDivider()}
          {renderCommentHistoryList()}
          {renderAddACommentSection()}
          {renderUpdateStatusRequestSection()}
        </DialogContent>
      </React.Fragment>
    )
  }

  const renderDivider = () => {
    return (
      <div style={styles.dividerPadding}>
        <Divider />
      </div>
    )
  }

  const renderSavingSpinner = () => {
    return (
      <div style={styles.loader}>
        <CircularProgress size={32} />
      </div>
    )
  }

  const renderTypes = () => {
    let typeArray = request_details.map((detail) => detail.type)
    let typeSet = new Set(typeArray)
    let typeTitle = typeSet.size > 1 ? 'Types:' : 'Type:'
    let types = [...typeSet].join(', ')

    return (
      <div style={styles.requestDetailsContainer}>
        {renderLabel(typeTitle)}
        <Typography sx={styles.detail}>{types}</Typography>
      </div>
    )
  }

  const renderCurrentStatus = () => {
    let statusArray = request_details.map((detail) => detail.status)
    let statusSet = new Set(statusArray)
    let statusTitle = statusSet.size > 1 ? STATUSES_TITLE : STATUS_TITLE
    let statuses = [...statusSet].join(', ')

    return (
      <div style={styles.requestDetailsContainer}>
        {renderLabel(statusTitle)}
        <Typography sx={styles.detail}>{statuses}</Typography>
      </div>
    )
  }

  const renderLastUpdatedBy = () => {
    return (
      <div style={styles.requestDetailsContainer}>
        {renderLabel(LAST_UPDATED_TITLE)}
        <Typography sx={styles.detail}>{renderCreatedBy(selectedTimeOffRequest.updated_by)}</Typography>
        <Typography sx={styles.dash}>-</Typography>
        <Typography sx={styles.detail}>
          {moment(selectedTimeOffRequest.updated_timestamp)?.format(updatedDateTimeFormat)}
        </Typography>
      </div>
    )
  }

  const renderRequestSummary = () => {
    return (
      <div style={styles.summaryContainer}>
        {renderSelectedTimeOffDates()}
        {renderCurrentStatus()}
        {renderLastUpdatedBy()}
        {renderTypes()}
      </div>
    )
  }

  const renderSelectedTimeOffDates = () => {
    let requestDates = moment(selectedTimeOffRequest.start_date)?.format(startEndDateFormats)
    let title = 'Request date:'

    if (selectedTimeOffRequest.start_date !== selectedTimeOffRequest.end_date) {
      requestDates = `${moment(selectedTimeOffRequest.start_date)?.format(startEndDateFormats)} - ${moment(
        selectedTimeOffRequest.end_date,
      )?.format(startEndDateFormats)}`
      title = 'Request dates:'
    }

    return (
      <div style={styles.requestDetailsContainer}>
        {renderLabel(title)}
        <Typography sx={styles.detail}>{requestDates}</Typography>
      </div>
    )
  }

  const renderLabel = (title) => {
    return <Typography variant={'subtitle2'}>{title}</Typography>
  }

  const renderTitle = (title) => {
    return <Typography variant={'subtitle1'}>{title}</Typography>
  }

  const renderCommentHistoryList = () => {
    if (selectedTimeOffRequest.comments) {
      return (
        <>
          {renderTitle(COMMENTS_TITLE)}
          {selectedTimeOffRequest.comments.map((comment, key) => (
            <div key={key} style={styles.commentsContainer}>
              {renderCommentHistoryItem(comment)}
            </div>
          ))}
        </>
      )
    }
  }

  const renderCreatedBy = (createdByWorkerId) => {
    if (!createdByWorkerId) return
    if (user.userData.worker_id === createdByWorkerId) return 'You'
    return createdByWorkerId
  }

  const renderCommentHistoryItem = (comment, key) => {
    return (
      <React.Fragment key={key}>
        <div style={styles.commentRow}>
          <div style={styles.commentColumn}>
            <Typography sx={styles.smallDetail}>
              {renderCreatedBy(padEmpIdWithZeros(comment.created_by_worker_id, 10))}
            </Typography>
          </div>
          <div style={styles.commentColumn}>
            <Typography sx={styles.dash}>-</Typography>
          </div>
          <div style={styles.commentColumn}>
            <Typography sx={styles.smallDetail}>
              {moment
                .tz(comment.created_timestamp, user.locationData.iso_time_zone_code)
                ?.format(updatedDateTimeFormat)}
            </Typography>
          </div>
        </div>
        <div>
          <Typography sx={styles.comment}>{comment.comment}</Typography>
        </div>
      </React.Fragment>
    )
  }

  const renderAddACommentSection = () => {
    if (updateStatusGroup !== UPDATE_STATUS_GROUPS.UPDATE_NONE) {
      return (
        <React.Fragment>
          {renderTitle(ADD_A_COMMENT_TITLE)}
          <RequestCommentForm
            value={leaderComment}
            disabled={timeOffRequestDataSaving}
            setComment={setLeaderComment}
            label={COMMENT}
            maxLength={COMMENT_MAX_LENGTH}
          />
        </React.Fragment>
      )
    }
  }

  const renderUpdateStatusRequestSection = () => {
    if (updateStatusGroup === UPDATE_STATUS_GROUPS.UPDATE_REQUEST) {
      return renderUpdateEntireRequestSection()
    } else if (updateStatusGroup === UPDATE_STATUS_GROUPS.UPDATE_INDIVIDUAL_REQUEST_SEGMENT) {
      return renderUpdateIndividualDetailSection()
    } else {
      return renderUpdateNoneSection()
    }
  }

  const renderUpdateEntireRequestSection = () => {
    return (
      <React.Fragment>
        {renderTitle(UPDATE_STATUS_TITLE)}
        <div style={styles.updateStatusContainer}>{renderRequestStatusDropDown()}</div>
      </React.Fragment>
    )
  }

  const renderMenuStatusItem = (status) => {
    return (
      <MenuItem key={status} value={status}>
        <div style={styles.statusListItem}>
          <RequestStatusIcon status={status} isTimeOffTeamMember={false} />
          <div style={styles.statusTextField}>{status}</div>
        </div>
      </MenuItem>
    )
  }

  const renderRequestStatusDropDown = () => {
    return (
      <TextField
        sx={styles.requestStatusDropDown}
        label="Status"
        id={`updateRequestStatus`}
        data-cy={`updateRequestStatus`}
        select
        disabled={timeOffRequestDataSaving}
        value={statusChange == null ? '' : statusChange}
        onChange={(e) => handleStatusChange(e.target.value)}
        SelectProps={{
          MenuProps: {
            sx: styles.menu,
          },
        }}
      >
        {selectableStatuses.map((status) => {
          return renderMenuStatusItem(status)
        })}
      </TextField>
    )
  }

  const renderUpdateIndividualDetailSection = () => {
    return (
      <React.Fragment>
        {renderTitle(UPDATE_STATUS_TITLE)}
        <div style={styles.updateStatusContainer}>
          <Typography sx={styles.failureMessage} variant={'body1'} paragraph gutterBottom>
            {UPDATE_STATUS_FAILURE_MSG_SUMMARY}
          </Typography>
          <Typography sx={styles.failureMessage} variant={'body2'} paragraph gutterBottom>
            {UPDATE_STATUS_FAILURE_MSG_ACTION}
          </Typography>
          <RequestDetailsTable
            requestDetails={selectedTimeOffRequest.request_details}
            selectableUpdateStatuses={selectableStatuses}
            statusChangeMap={statusChangeMap}
            disableStatusUpdates={timeOffRequestDataSaving}
            handleStatusChange={(requestDetailPerDateIndex, rowDetail) =>
              handleStatusChangePerDetail(requestDetailPerDateIndex, rowDetail)
            }
            isTimeOffTeamMember={false}
          />
        </div>
      </React.Fragment>
    )
  }

  const renderUpdateNoneSection = () => {
    return (
      <div style={styles.updateStatusContainer}>
        <Typography sx={styles.infoMessage} variant={'body1'} paragraph gutterBottom>
          {updateNoneMessage}
        </Typography>
      </div>
    )
  }

  const renderDialogActionContent = () => {
    return (
      <React.Fragment>
        {timeOffRequestDataSaving ? renderSavingSpinner() : null}
        <Button onClick={handleClosingSaveDialog} color="primary" disabled={timeOffRequestDataSaving}>
          Cancel
        </Button>
        <Button
          onClick={() => handleUpdateStatusSubmit()}
          color="primary"
          variant="contained"
          disabled={timeOffRequestDataSaving || submitDisabled}
        >
          Submit
        </Button>
      </React.Fragment>
    )
  }

  const renderDialogActionAndContent = () => {
    return (
      <React.Fragment>
        {renderDialogContent()}
        <DialogActions>{renderDialogActionContent()}</DialogActions>
      </React.Fragment>
    )
  }
  const renderDialog = () => {
    if (selectedTimeOffRequest) {
      return (
        <Dialog
          open={timeOffRequestSaveDialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClosingSaveDialog}
          fullWidth={true}
          maxWidth={'lg'}
          disableBackdropClick={timeOffRequestDataSaving}
        >
          <DialogTitle id="save-dialog-slide-title">{getDialogTitle()}</DialogTitle>
          {renderDialogActionAndContent()}
        </Dialog>
      )
    }
  }

  return <React.Fragment>{renderDialog()}</React.Fragment>
}
export default TimeOffRequestLeaderDialog
