import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import React, { useState } from 'react'
import { useCoverShift } from '../../api/availableShifts/useCoverShift'
import { useUser } from '../../auth/hooks/useUser'
import { MultiClickButton as CoverShiftButton } from '../../utils/MultiClickButton'
import { truncateString } from '../../utils/strings'
import ConfirmCoverShiftModal from './ConfirmCoverShiftModal'
import DisplayAvailableShiftSegment from './DisplayAvailableShiftSegment'

const styles = {
  dateHeading: {
    display: 'flex',
    fontWeight: 'bold',
    fontSize: 'medium',
  },
  displaySegmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5em',
  },
  coverShiftBtnGrid: {
    display: 'flex',
    flexFlow: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  mobileButton: {
    maxWidth: 120,
  },
  shiftLabelContainer: {
    marginTop: '1px',
    marginLeft: '12px',
    display: 'inline-Block',
  },
  shiftLabelContainerInModal: {
    marginTop: 1,
    display: 'inline-Block',
  },
  additionalShiftLabels: {
    margin: '2px 6px',
  },
  shiftLabel: {
    border: '1px solid #333',
    color: '#333',
    padding: '1px 5px',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    marginLeft: '5px',
  },
}

const DisplayAvailableShift = (props) => {
  const { shiftAvailableDate, shift, getLocationIds, hasMissedPunch } = props
  const [open, setOpen] = useState(false)
  const { locationData } = useUser()
  const { mutate, isLoading } = useCoverShift(getLocationIds())

  // submit cover shift
  const handleSubmit = () => {
    handleClose()
    mutate(shift)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <ConfirmCoverShiftModal
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        selectedShift={shift}
        shiftAvailableDate={shiftAvailableDate}
        isHomeLocation={parseInt(locationData.location_id) === parseInt(shift.org_structure.location_id)}
      />
      <Grid item xs={12} sm={12} sx={styles.displaySegmentContainer}>
        <Typography component="h2" color="textPrimary" sx={styles.dateHeading}>
          {`${shift.shift_hours} Hour shift`}
          {shift.generated_by === 'team_member' && shift.shift_label && (
            <span style={styles.shiftLabel}>{`${truncateString(shift.shift_label, 3)}`}</span>
          )}
        </Typography>
      </Grid>

      <Grid container>
        <Grid item xs={8}>
          <DisplayAvailableShiftSegment
            shift={shift}
            renderedOn="availableShifts"
            isHomeLocation={parseInt(locationData.location_id) === parseInt(shift.org_structure.location_id)}
          />
        </Grid>
        <Grid item xs={4} sx={styles.coverShiftBtnGrid}>
          <CoverShiftButton
            hasMissedPunch={hasMissedPunch}
            label="Cover Shift"
            handleClick={handleOpen}
            handleDoubleClick={handleOpen}
            styles={styles}
            processIndicator={isLoading}
            pageName="coverShift"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default DisplayAvailableShift
