import { useAuth } from '@praxis/component-auth'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useUser } from '../../../auth/hooks/useUser'
import { postDeviceRegistration } from '../../../store/pushNotification/actionCreator'

export const useDeviceRegistration = () => {
  const { session } = useAuth()
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams()
  const deviceRegistered = useSelector((state: any) => state.layout.deviceRegistered)

  const user = useUser()
  const dispatch = useDispatch()

  useEffect(() => {
    /**
     * After the screen access api has returned a response, register device info for push notifications if the user
     * has the schedule feature access, has device details, and hasn't registered a device during this web session.
     * @param prevProps
     */
    if (user.is('schedule') && !deviceRegistered) {
      let deviceToken = searchParams.get('device_token')
      let deviceId = searchParams.get('device_uuid')
      let deviceType = searchParams.get('device_type')
      let deviceVersion = searchParams.get('device_version')
      let deviceOsVersion = searchParams.get('device_os_version')
      let deviceStatus = searchParams.get('device_status')

      if (deviceToken && deviceId && deviceType && deviceVersion && deviceOsVersion && deviceStatus) {
        const deviceDetails = {
          deviceToken: deviceToken,
          deviceId: deviceId,
          deviceType: deviceType,
          deviceVersion: deviceVersion,
          deviceOsVersion: deviceOsVersion,
          deviceStatus: deviceStatus,
        }
        dispatch(postDeviceRegistration(deviceDetails, user.userData.worker_id, user.locationData.location_id))
      }
    }
  }, [searchParams, dispatch, session, user, deviceRegistered])
}
