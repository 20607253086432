import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { format } from 'date-fns'
import { useUser } from '../../auth/hooks/useUser'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'

const getFeedback = async (
  workerId: string,
  startDate: string,
  endDate: string,
  module: string,
  personas: Array<String>,
) => {
  let start = format(new Date(startDate), 'yyyy-MM-dd')
  let end = format(new Date(endDate), 'yyyy-MM-dd')

  let apiUrl =
    `${apiConfig.feedback.url}` +
    '/data' +
    '?worker_id=' +
    workerId +
    '&start_date=' +
    start +
    '&end_date=' +
    end +
    '&module=' +
    module +
    '&personas=' +
    personas +
    '&key=' +
    `${apiConfig.feedback.key}`

  return axios.get(apiUrl, BASIC_REQUEST_CONFIG)
}

export function useUserFeedback(
  startDate: string,
  endDate: string,
  module: string,
  personas: Array<String>,
  isFlipped: boolean = true,
) {
  const { isAuthenticated, login } = useAuth()
  const authenticated = isAuthenticated()
  const user = useUser()

  if (!authenticated) {
    login({ redirect: window.location.href })
  }

  return useQuery({
    queryKey: ['data', user.userData.worker_id, startDate, endDate, module, personas],
    queryFn: () => getFeedback(user.userData.worker_id, startDate, endDate, module, personas),
    enabled: authenticated && isFlipped,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 15000),
    retry: 1,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}
