export const pnTypes = [
  {
    title: 'time-off-call-in',
    targetAudience: ['call_in'],
  },
  {
    title: 'time-off-call-off',
    targetAudience: ['call_off'],
  },
  {
    title: 'shift-swap',
    targetAudience: ['shift_swap'],
  },
  {
    title: 'schedule-edits',
    targetAudience: ['schedule'],
  },
]
