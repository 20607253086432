import { useAuth } from '@praxis/component-auth'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { format } from 'date-fns'
import { useUser } from '../../auth/hooks/useUser'
import apiConfig from '../../config/apiConfig'
import { BASIC_REQUEST_CONFIG } from '../../constants/apiConstants'
import { getStartEndDatesOfWeek, isDateInCurrentWeek } from '../../utils/DateUtil'

const getTimecardWeekly = async (tmNumber, locationId, selectedDate) => {
  let { startDate, endDate } = getStartEndDatesOfWeek(selectedDate)

  if (isDateInCurrentWeek(selectedDate)) {
    endDate = format(selectedDate, 'yyyy-MM-dd')
  }

  let apiUrl =
    `${apiConfig.timecards.url}` +
    '/timecard_weekly_view' +
    '?end_date=' +
    endDate +
    '&location_id=' +
    locationId +
    '&start_date=' +
    startDate +
    '&worker_id=' +
    tmNumber +
    '&key=' +
    `${apiConfig.timecards.key}`

  const { data } = await axios.get(apiUrl, BASIC_REQUEST_CONFIG)

  return data
}

export function useTimecardWeekly(selectedDate) {
  const { isAuthenticated, login } = useAuth()
  const user = useUser()

  if (!isAuthenticated()) {
    login({ redirect: window.location.href })
  }

  return useQuery({
    queryKey: ['timecardWeekly', { selectedDate }],
    queryFn: () => getTimecardWeekly(user.userData.worker_id, user.locationData.location_id, selectedDate),
    staleTime: 1000 * 30,
    enabled: !!selectedDate && isAuthenticated(),
  })
}
