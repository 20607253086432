import { SET_COMMUNICATION_OPEN, SET_CONFIRM, SET_HEADER_TITLE, SHOW_AUTH_POPUP } from './actionType'

export const initialState = {
  openPopup: 0,
  popupType: '',
  headerTitle: '',
  confirm: {
    prompt: '',
    isOpen: false,
    proceed: null,
    cancel: null,
  },
  isCommunicationOpen: false,
}

export default function layoutReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_AUTH_POPUP: {
      const { payload } = action
      return {
        ...state,
        openPopup: state.openPopup + 1,
        popupType: payload,
      }
    }
    case SET_HEADER_TITLE: {
      const { payload } = action
      return {
        ...state,
        headerTitle: payload,
      }
    }
    case SET_CONFIRM: {
      const { payload } = action
      return {
        ...state,
        confirm: {
          ...state.confirm,
          ...payload,
        },
      }
    }
    case SET_COMMUNICATION_OPEN: {
      const { payload } = action
      return {
        ...state,
        isCommunicationOpen: payload,
      }
    }
    default:
      return state
  }
}
