// useCommunicationData.js
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useCommunication } from '../api/userProfiles/useCommunication'
import { setCommunicationOpen } from '../store/layout/actionCreator'
import { removeOldestElement } from '../utils/lists'

const MAX_COM_LIST_SIZE = 25

export default function useCommunicationData(isForPopup) {
  const [activeStep, setActiveStep] = useState(0)
  const dispatch = useDispatch()
  const [viewedCommunications, setViewedCommunications] = useState(() => {
    const savedViewedCommunicationsString = localStorage.getItem('viewed_communications')
    const savedViewedCommunications = JSON.parse(savedViewedCommunicationsString)

    return savedViewedCommunications || []
  })

  const { data } = useCommunication(isForPopup, 1, 25)
  const communications = data?.data

  useEffect(() => {
    if (communications?.total_communications > 0) {
      dispatch(setCommunicationOpen(true))
      setActiveStep(0)
    }
  }, [communications?.total_communications, dispatch])

  const handleClose = () => {
    addCommunication(communications?.communications[activeStep])
    localStorage.setItem('viewed_communications', JSON.stringify(viewedCommunications))
    dispatch(setCommunicationOpen(false))
  }

  const addCommunication = (communication) => {
    const doesExist = viewedCommunications?.some((element) => {
      return element?.id === communication?.id && element?.updated_timestamp === communication?.updated_timestamp
    })

    if (!doesExist) {
      setViewedCommunications((prevCommunications) => {
        prevCommunications.push({ id: communication.id, updated_timestamp: communication.updated_timestamp })
        if (prevCommunications.length > MAX_COM_LIST_SIZE) {
          prevCommunications = removeOldestElement(prevCommunications)
        }
        return prevCommunications
      })
    }
  }

  const handleNext = () => {
    addCommunication(communications?.communications[activeStep])
    setActiveStep((prevActiveStep) => {
      // TODO: track event with praxis firefly, how to not send same one twice? local storage check.
      return prevActiveStep + 1
    })
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return {
    activeStep,
    communications,
    handleClose,
    handleNext,
    handleBack,
  }
}
