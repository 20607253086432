import { Info } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '../../auth/hooks/useUser'
import {
  handleWorkerRedemptionCodeGetData,
  setIsIssueCodeDialogOpen,
  setLoading,
} from '../../store/workerRedemptionCode/actionCreator'

const DIALOG_TITLE = 'Are you sure you want to be issued a new code?'
const ISSUE_CODE_EXPLANATION_1a =
  'After you have been issued a new code, the next step will be to redeem the code in the '

const ISSUE_CODE_EXPLANATION_1b = 'Redeem Code'
const ISSUE_CODE_EXPLANATION_1c = ' section, which will then allow you to download the app.'
// const ISSUE_CODE_EXPLANATION_2 =
//   'A new code will be issued to a team member no more than 3 times. The only time ' +
//   'you would need to be issued another code is if you want the app on a device that is linked ' +
//   'to a different Apple Id than the previous issued code was redeemed with.'
const ISSUE_CODE_EXPLANATION_2a =
  'Once redeemed, your code should be permanently linked to your Apple Id, so you should only ever need to be issued a code once. However, Target will issue '
const ISSUE_CODE_EXPLANATION_2b = 'up to 3 codes'
const ISSUE_CODE_EXPLANATION_2c = ' in case your Apple Id changes.'

const NO_BTN_LABEL = 'No'
const YES_BTN_LABEL = 'Yes'

const styles = {
  infoIcon: {
    marginRight: '4px',
    fontSize: '12px',
  },
}

const IssueCodeDialog = () => {
  const dispatch = useDispatch()
  const isIssueCodeDialogOpen = useSelector((state) => state.workerRedemptionCode.isIssueCodeDialogOpen)
  const user = useUser()

  const handleCancelButtonClick = () => {
    dispatch(setIsIssueCodeDialogOpen(false))
  }

  const handleIssueNewCodeClick = () => {
    dispatch(setIsIssueCodeDialogOpen(false))
    dispatch(setLoading(true))
    dispatch(handleWorkerRedemptionCodeGetData(user.userData.worker_id, true))
  }

  const handleClosingIssueNewCodeDialog = () => {
    dispatch(setIsIssueCodeDialogOpen(false))
  }

  const renderDialogActions = () => {
    return (
      <React.Fragment>
        <Button onClick={handleCancelButtonClick} color="primary" data-cy="IssueCodeDialogNoButton">
          {NO_BTN_LABEL}
        </Button>
        <Button
          onClick={handleIssueNewCodeClick}
          color="primary"
          variant="contained"
          data-cy="IssueCodeDialogYesButton"
        >
          {YES_BTN_LABEL}
        </Button>
      </React.Fragment>
    )
  }

  const renderDialogContent = () => {
    return (
      <React.Fragment>
        <Typography component="div" gutterBottom>
          {ISSUE_CODE_EXPLANATION_1a}
          <strong>{ISSUE_CODE_EXPLANATION_1b}</strong>
          {ISSUE_CODE_EXPLANATION_1c}
        </Typography>
        <Typography variant={'caption'}>
          <Info sx={styles.infoIcon} />
          {ISSUE_CODE_EXPLANATION_2a}
          <strong>{ISSUE_CODE_EXPLANATION_2b}</strong>
          {ISSUE_CODE_EXPLANATION_2c}
        </Typography>
      </React.Fragment>
    )
  }

  const renderDialogActionAndContent = () => {
    return (
      <React.Fragment>
        <DialogContent>{renderDialogContent()}</DialogContent>
        <DialogActions>{renderDialogActions()}</DialogActions>
      </React.Fragment>
    )
  }

  const renderDialog = () => {
    return (
      <Dialog
        open={isIssueCodeDialogOpen}
        // TransitionComponent={Transition}
        onClose={handleClosingIssueNewCodeDialog}
        aria-labelledby="issue-code-dialog-label"
        aria-describedby="issue-code-dialog-description"
        data-cy="IssueCodeDialog"
      >
        <DialogTitle id="save-dialog-slide-title">{DIALOG_TITLE}</DialogTitle>
        {renderDialogActionAndContent()}
      </Dialog>
    )
  }

  return renderDialog()
}

export default IssueCodeDialog
