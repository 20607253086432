import { Grid, Skeleton } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import { useSchedules } from '../../api/schedule/useSchedules'
import { useUser } from '../../auth/hooks/useUser'
import { getNowDateTimeInTimezone } from '../../utils/DateUtil'
import ShiftCard from '../common/cards/ShiftCard'

function SelectShift({ handleSelectSchedule }) {
  const { locationData } = useUser()
  var today = getNowDateTimeInTimezone(locationData.iso_time_zone_code)
  var startTime = new Date(today)
  var endTime = new Date(today)

  startTime.setHours(startTime.getHours() - 2)
  endTime.setHours(endTime.getHours() + 24)

  const { data, error } = useSchedules(startTime, endTime)
  const [selectedScheduleIndexes, setSelectedScheduleIndexes] = useState('')

  const handleChange = (event) => {
    setSelectedScheduleIndexes(event.target.value)
    var scheduleIndexes = event.target.value.split('_')
    var schedule = data?.data?.schedules[scheduleIndexes[0]]
    handleSelectSchedule(schedule, scheduleIndexes[1])
  }

  const getSchedules = () => {
    let totalDisplaySegments = data.data.schedules.reduce((acc, schedule) => acc + schedule.total_display_segments, 0)

    if (totalDisplaySegments === 0) {
      return <Grid sx={{ padding: '5px' }}>No shifts available to call in/off at this time</Grid>
    }

    return data?.data?.schedules?.map((schedule, dayIndex) => {
      return schedule?.display_segments?.map((segment, index) => {
        return (
          <MenuItem key={`${dayIndex}_${index}`} value={`${dayIndex}_${index}`} divider>
            <ShiftCard scheduleDate={schedule?.schedule_date} displaySegment={segment} />
          </MenuItem>
        )
      })
    })
  }

  const renderOptions = () => {
    if (data?.data) return getSchedules()
    if (error?.response?.data) {
      return (
        <MenuItem>
          <div>Error loading your schedules</div>
        </MenuItem>
      )
    }

    return (
      <MenuItem>
        <Skeleton sx={{ width: '100%' }} />
      </MenuItem>
    )
  }

  return (
    <TextField
      data-cy={`select_shift_dialog_dropdown`}
      id="schedule"
      select
      label="Select Schedule"
      value={selectedScheduleIndexes}
      onChange={handleChange} // ${dayIndex}_${index}
      margin="normal"
      fullWidth
    >
      {renderOptions()}
    </TextField>
  )
}

export default SelectShift
