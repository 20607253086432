import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useUser } from '../../../auth/hooks/useUser'
import { LEADER_DASHBOARD_TITLE } from '../../../constants/leaderConstants.js'
import { setHeaderTitle } from '../../../store/layout/actionCreator'
import { MainContentDiv } from '../../common/layout/Layouts'
import InputLocationSelector from '../InputLocationSelector.js'
import LocationSelector from '../LocationSelector'
import AtAGlanceCard from './AtAGlanceCard.js'
import DailyActionsCard from './DailyActionsCard'

const getStyles = (theme) => ({
  gridItem: {
    padding: theme.spacing(1),
  },
  gridItemInput: {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
})

const DashboardPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setHeaderTitle(LEADER_DASHBOARD_TITLE))
  }, [dispatch])

  return (
    <MainContentDiv>
      <DashboardLocationSelector />
      <DashboardCards />
    </MainContentDiv>
  )
}
export default DashboardPage

const DashboardLocationSelector = () => {
  const theme = useTheme()
  const styles = getStyles(theme)
  const user = useUser()

  return (
    <Grid container item xs={12} md={12} sx={styles.gridItem} minHeight={'60px'} spacing={1}>
      <Grid item xs={3} md={3} sx={styles.gridItemInput}>
        {user.is('availability', 'leader', 'read', true) ? (
          <>
            <InputLocationSelector />
          </>
        ) : (
          <>
            <LocationSelector />
          </>
        )}
      </Grid>
    </Grid>
  )
}

const DashboardCards = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  return (
    <>
      <Grid item xs={12} md={12} sx={styles.gridItem}>
        <AtAGlanceCard />
      </Grid>
      <Grid item xs={12} md={12} sx={styles.gridItem}>
        <DailyActionsCard />
      </Grid>
    </>
  )
}
