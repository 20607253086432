import { LOCATION_TYPE_DC, LOCATION_TYPE_OFFICE, LOCATION_TYPE_RGD, LOCATION_TYPE_STORE } from './locationConstants'

export const REQUEST_TYPE_TIME_OFF = 'time_off'
export const REQUEST_TYPE_CALL_IN = 'call_in'
export const REQUEST_TYPE_CALL_OFF = 'call_off'
export const REQUEST_TYPE_PUNCH_CORRECTION = 'punch_correction'
export const REQUEST_TYPE_AVAILABILITY = 'availability'
export const REQUEST_TYPE_MASS_VACATION = 'mass_vacation'

export const REQUEST_TYPES = {
  [REQUEST_TYPE_AVAILABILITY]: { type: 'Availability', access: [REQUEST_TYPE_AVAILABILITY] },
  [REQUEST_TYPE_MASS_VACATION]: { type: 'Mass Vacation', access: [REQUEST_TYPE_MASS_VACATION] },
  [REQUEST_TYPE_PUNCH_CORRECTION]: { type: 'Punch Correction', access: [REQUEST_TYPE_PUNCH_CORRECTION] },
  [REQUEST_TYPE_TIME_OFF]: {
    type: 'Time Off',
    access: [REQUEST_TYPE_TIME_OFF, REQUEST_TYPE_CALL_IN, REQUEST_TYPE_CALL_OFF],
  },
}

export const PENDING = 'Pending'
export const DENIED = 'Denied'
export const WAITLISTED = 'Waitlisted'
export const SCHEDULED = 'Scheduled'
export const ACKNOWLEDGED = 'Acknowledged'
export const CANCELLED = 'Cancelled'
export const FAILED = 'Failed'
export const FAILED_CANCELLED = 'Failed Cancelled'
export const PROCESSED = 'Processed'
export const ALL = 'All'
export const APPROVED = 'Approved'

// UI Only
export const PROCESSING = 'Processing'

export const REQUEST_STATUS_TYPES = {
  ALL: ALL,
  PENDING: PENDING,
  SCHEDULED: SCHEDULED,
  PROCESSED: PROCESSED,
  WAITLISTED: WAITLISTED,
  ACKNOWLEDGED: ACKNOWLEDGED,
  DENIED: DENIED,
  FAILED: FAILED,
  CANCELLED: CANCELLED,
  APPROVED: APPROVED,
}

export const REQUEST_STATUS_TYPES_STORE_AVAILABILITY = {
  ALL: ALL,
}

export const REQUEST_STATUS_TYPES_STORE_TIME_OFF = {
  ALL: ALL,
  PENDING: PENDING,
  SCHEDULED: SCHEDULED,
  PROCESSED: PROCESSED,
  ACKNOWLEDGED: ACKNOWLEDGED,
  DENIED: DENIED,
  FAILED: FAILED,
  CANCELLED: CANCELLED,
}

export const REQUEST_STATUS_TYPES_DC_TIME_OFF = {
  ALL: ALL,
  PENDING: PENDING,
  SCHEDULED: SCHEDULED,
  PROCESSED: PROCESSED,
  WAITLISTED: WAITLISTED,
  ACKNOWLEDGED: ACKNOWLEDGED,
  DENIED: DENIED,
  FAILED: FAILED,
  CANCELLED: CANCELLED,
}

export const REQUEST_STATUS_TYPES_OFFICE_RGD_TIME_OFF = {
  ALL: ALL,
  PENDING: PENDING,
  SCHEDULED: SCHEDULED,
  PROCESSED: PROCESSED,
  DENIED: DENIED,
  FAILED: FAILED,
  CANCELLED: CANCELLED,
}

export const REQUEST_STATUS_TYPES_DC_PUNCH_CORRECTION = {
  ALL: ALL,
  PENDING: PENDING,
  PROCESSED: PROCESSED,
  FAILED: FAILED,
  APPROVED: APPROVED,
}

export const REQUEST_STATUS_TYPES_OFFICE_PUNCH_CORRECTION = {
  ALL: ALL,
  PENDING: PENDING,
  PROCESSED: PROCESSED,
  FAILED: FAILED,
  APPROVED: APPROVED,
}

export const REQUEST_STATUS_TYPES_STORES_PUNCH_CORRECTION = {
  ALL: ALL,
  PENDING: PENDING,
  PROCESSED: PROCESSED,
  DENIED: DENIED,
  CANCELLED: CANCELLED,
  APPROVED: APPROVED,
}

export const TIME_OFF_TYPES = {
  CALL_IN_LATE: 'Call In - Late',
  PTO_VACATION: 'PTO - Vacation',
  PTO_OTHER: 'PTO - Other',
  PTO_SICK: 'PTO - Sick',
  UNPAID: 'Unpaid',
}

export const TIME_OFF_GROUPS = {
  CALL_IN: 'Call In - Late',
  CALL_OFF: 'Call Off - Absent',
  TIME_OFF: 'Time Off',
}

// category type display names
export const TIME_OFF = 'Time Off'
export const CALL_IN_LATE = 'Call In Late'
export const CALL_OFF_ABSENT = 'Call Off Absent'
export const AVAILABILITY = 'Availability'
export const PUNCH_CORRECTION = 'Punch Correction'

export const TIME_OFF_CONFIGURATION_FUNCTION_NAMES = {
  PAID_TIME_OFF: 'paid_time_off',
  CALL_IN_CALL_OFF: 'call_in_call_off',
  TIME_OFF: 'time_off',
}

export const CATEGORY_FEATURE_MAP = new Map([
  ['time_off', TIME_OFF],
  ['call_in', CALL_IN_LATE],
  ['call_off', CALL_OFF_ABSENT],
  ['availability', AVAILABILITY],
  ['punch_correction', PUNCH_CORRECTION],
  [TIME_OFF, 'time_off'],
  [CALL_IN_LATE, 'call_in'],
  [CALL_OFF_ABSENT, 'call_off'],
  [AVAILABILITY, 'availability'],
  [PUNCH_CORRECTION, 'punch_correction'],
])

export const CALL_OFF_ILL = 'Call Off - Ill (Self or Dependent)'
export const CALL_OFF_TRANSPORTATION = 'Call Off - Transportation Issue'
export const CALL_OFF_CHILDCARE = 'Call Off - Childcare'
export const CALL_OFF_OTHER = 'Call Off - Other'
export const CALL_OFF_PREFIX = 'Call Off'
export const CALL_IN_OFF_PREFIX = 'Call'
export const PTO_PREFIX = 'PTO'
export const AVAILABILITY_SUFFIX = 'AVAILABILITY'

export const CALL_OFF_TYPE_CONFIG = {
  STR: {
    [CALL_OFF_ILL]: {
      primary_value: CALL_OFF_ILL,
      comment: { visible: false, required: false },
    },
    [CALL_OFF_OTHER]: {
      primary_value: CALL_OFF_OTHER,
      comment: { visible: false, required: false },
    },
  },
  DC: {
    [CALL_OFF_ILL]: {
      primary_value: CALL_OFF_ILL,
      comment: { visible: true, required: false },
    },
    [CALL_OFF_TRANSPORTATION]: {
      primary_value: CALL_OFF_TRANSPORTATION,
      comment: { visible: false, required: false },
    },
    [CALL_OFF_CHILDCARE]: {
      primary_value: CALL_OFF_CHILDCARE,
      comment: { visible: false, required: false },
    },
    [CALL_OFF_OTHER]: {
      primary_value: CALL_OFF_OTHER,
      comment: { visible: true, required: true },
    },
  },
}

export const CALL_OFF_TYPE_DROPDOWN = {
  STR: [CALL_OFF_TYPE_CONFIG.STR[CALL_OFF_ILL], CALL_OFF_TYPE_CONFIG.STR[CALL_OFF_OTHER]],
  DC: [
    CALL_OFF_TYPE_CONFIG.DC[CALL_OFF_ILL],
    CALL_OFF_TYPE_CONFIG.DC[CALL_OFF_TRANSPORTATION],
    CALL_OFF_TYPE_CONFIG.DC[CALL_OFF_CHILDCARE],
    CALL_OFF_TYPE_CONFIG.DC[CALL_OFF_OTHER],
  ],
}

export const SYSTEM_NAME = 'System'

export const OVERNIGHT_DAY_IN_MIN_OFFSET = 60 * 24

export const SAVE_DIALOG_TITLE = 'Are you sure you want to save?'

export const SAVE_DIALOG_TEXT = 'I confirm that what I am submitting is accurate.'
export const CANCEL_DIALOG_TITLE = 'Are you sure you want to cancel?'
export const CANCEL_DIALOG_TEXT = 'You have unsaved changes to this page. If you cancel, you will lose these changes.'

export const REQUEST_SCREEN_TITLES = {
  NOTIFY_CALL_IN: 'Notify Call In - Late',
  NOTIFY_CALL_OFF: 'Notify Call Off - Absent',
  REQUEST_PAID_TIME_OFF: 'Request Paid Time Off',
  REQUEST_TIME_OFF: 'Request Time Off',
}

export const TIME_OFF_VALID_DATE_RANGE_START_MINUS_WEEKS = 2
export const FEEDBACK_DATE_RANGE = 6

export const requestRowVariants = {
  TIME_OFF_PTO: 'TIME_OFF_PTO',
  TIME_OFF_UNPAID: 'TIME_OFF_UNPAID',
  CALL_IN: 'CALL_IN',
  CALL_OFF: 'CALL_OFF',
}

export const ADD_ROW = 'Add row'
export const REMOVE_ROW = 'Remove row'
export const MAX_ROWS_PER_DAY_STORES = 2
export const MAX_ROWS_PER_DAY_DC = 3
export const MIN_ROWS_PER_DAY = 1

export const requestStatusTypes = {
  [LOCATION_TYPE_STORE]: {
    [REQUEST_TYPE_TIME_OFF]: {
      statuses: REQUEST_STATUS_TYPES_STORE_TIME_OFF,
    },
    [REQUEST_TYPE_AVAILABILITY]: {
      statuses: REQUEST_STATUS_TYPES_STORE_AVAILABILITY,
    },
    [REQUEST_TYPE_PUNCH_CORRECTION]: {
      statuses: REQUEST_STATUS_TYPES_STORES_PUNCH_CORRECTION,
    },
  },
  [LOCATION_TYPE_DC]: {
    [REQUEST_TYPE_TIME_OFF]: {
      statuses: REQUEST_STATUS_TYPES_DC_TIME_OFF,
    },
    [REQUEST_TYPE_MASS_VACATION]: {
      statuses: REQUEST_STATUS_TYPES_OFFICE_RGD_TIME_OFF,
    },
    [REQUEST_TYPE_PUNCH_CORRECTION]: {
      statuses: REQUEST_STATUS_TYPES_DC_PUNCH_CORRECTION,
    },
  },
  [LOCATION_TYPE_OFFICE]: {
    [REQUEST_TYPE_TIME_OFF]: {
      statuses: REQUEST_STATUS_TYPES_OFFICE_RGD_TIME_OFF,
    },
    [REQUEST_TYPE_PUNCH_CORRECTION]: {
      statuses: REQUEST_STATUS_TYPES_OFFICE_PUNCH_CORRECTION,
    },
  },
  [LOCATION_TYPE_RGD]: {
    [REQUEST_TYPE_TIME_OFF]: {
      statuses: REQUEST_STATUS_TYPES_OFFICE_RGD_TIME_OFF,
    },
    [REQUEST_TYPE_PUNCH_CORRECTION]: {
      statuses: REQUEST_STATUS_TYPES_OFFICE_PUNCH_CORRECTION,
    },
  },
}

export const UPDATE_TO_CANCELLED_CONFIG = {
  [CALL_IN_LATE]: {
    [PENDING]: {},
  },
  [CALL_OFF_ABSENT]: {
    [PENDING]: {},
  },
  [TIME_OFF]: {
    [PENDING]: {},
    [WAITLISTED]: { hoursBeforeShift: { locationTypes: [LOCATION_TYPE_STORE, LOCATION_TYPE_DC], value: 24 } },
    [SCHEDULED]: { hoursBeforeShift: { locationTypes: [LOCATION_TYPE_STORE, LOCATION_TYPE_DC], value: 24 } },
    [PROCESSED]: {
      hoursBeforeShift: { locationTypes: [LOCATION_TYPE_STORE, LOCATION_TYPE_DC], value: 24 },
      allowedTypes: [TIME_OFF_TYPES.UNPAID],
    },
  },
}

// Request errors
export const CROSS_MIDNIGHT = 'Cross midnight'
export const DETAIL_OVERLAP = 'Time ranges for this date must not overlap'
export const FORM_HAS_ERRORS_MESSAGE = 'Please correct errors before submitting request'
export const DC_UNPAID_MAX = 'Unpaid day is more than 6 months in the future'
export const DC_UNPAID_NOTE = 'Note: Request types "Unpaid" can only be requested up to 6 months in the future'
