import { Grid } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '../../../../auth/hooks/useUser'
import { setDate, setSearchNameText } from '../../../../store/leader/actionCreator'
import DateRangePicker from '../../../common/calendar/DateRangePicker'
import SearchName from '../../../common/DelayedSearch'
import InputLocationSelector from '../../InputLocationSelector'
import LocationSelector from '../../LocationSelector'
import ExpandCollapsePannelButton from './ExpandCollapsePanelButton'
import FilterBy from './FilterBy'
import FilterChips from './FilterChips'
import SelectCategory from './SelectCategory'
import SortBy from './SortBy'

const ExpandedControlPanel = () => {
  const dispatch = useDispatch()
  const selectedCategory = useSelector((state) => state.leader.selectedCategory)
  const minDate = useSelector((state) => state.leader[selectedCategory]?.minDate)
  const maxDate = useSelector((state) => state.leader[selectedCategory]?.maxDate)
  const defaultStartDate = useSelector((state) => state.leader[selectedCategory]?.defaultStartDate)
  const defaultEndDate = useSelector((state) => state.leader[selectedCategory]?.defaultEndDate)
  const startDate = useSelector((state) => state.leader[selectedCategory]?.startDate)
  const endDate = useSelector((state) => state.leader[selectedCategory]?.endDate)
  const maxDaysInRange = useSelector((state) => state.leader[selectedCategory]?.maxDaysInRange)
  const searchText = useSelector((state) => state.leader[selectedCategory]?.searchText)

  const user = useUser()

  useEffect(() => {
    if (selectedCategory) {
      if (typeof startDate === 'undefined') dispatch(setDate('startDate', defaultStartDate))
      if (typeof endDate === 'undefined') dispatch(setDate('endDate', defaultEndDate))
    }
  }, [selectedCategory, defaultStartDate, defaultEndDate, startDate, endDate, dispatch])

  const handleOnDateChange = (name, date) => {
    dispatch(setDate(name, date))
  }

  const handleOnSearch = useCallback(
    (text) => {
      dispatch(setSearchNameText(text))
    },
    [dispatch],
  )

  return (
    <Grid container rowGap={1}>
      <Grid container item columnGap={1} xs alignContent={'center'}>
        <Grid item>
          {user.is('availability', 'leader', 'read', true) ? (
            <>
              <InputLocationSelector />
            </>
          ) : (
            <>
              <LocationSelector />
            </>
          )}
        </Grid>
        <Grid item>
          <SelectCategory />
        </Grid>
      </Grid>
      <Grid>
        <ExpandCollapsePannelButton />
      </Grid>
      {selectedCategory ? (
        <DateRangePicker
          key={selectedCategory}
          minDate={minDate}
          maxDate={maxDate}
          defaultStartDate={defaultStartDate}
          defaultEndDate={endDate ? endDate : defaultEndDate}
          maxDaysInRange={maxDaysInRange}
          handleOnChange={handleOnDateChange}
          onBlurMessage={true}
          containerSx={{ justifyContent: 'left', gap: '8px' }}
        />
      ) : (
        <></>
      )}
      <Grid container alignItems={'center'} rowGap={0.1}>
        <Grid item>
          <SortBy />
        </Grid>
        <Grid item>
          <FilterBy />
        </Grid>
        <Grid item>
          {selectedCategory ? (
            <SearchName
              tooltipTitle="Enter name to search"
              id="search-name"
              label="Search by Name"
              ariaLabel="Search by name"
              dataCy="searchName"
              defaultValue={searchText}
              onSearch={handleOnSearch}
            />
          ) : (
            <></>
          )}
        </Grid>
        <FilterChips />
      </Grid>
    </Grid>
  )
}

export default ExpandedControlPanel
