import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { MobileStepper } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import * as DOMPurify from 'dompurify'
import PropTypes from 'prop-types'
import { CustomDialog } from '../styled/mui'

CommunicationDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

function CommunicationDialogTitle(props) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="close"
          sx={{
            // display: 'flex',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function CommunicationDialog({
  activeStep,
  communications,
  handleClose,
  handleNext,
  handleBack,
  isOpen,
}) {
  let clean = DOMPurify.sanitize(communications?.communications?.[activeStep]?.text)
  return (
    <CustomDialog
      onClose={(event, reason) => {
        return reason === 'backdropClick' ? null : handleClose
      }}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <CommunicationDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {communications?.communications?.[activeStep]?.title}
      </CommunicationDialogTitle>
      <DialogContent dividers>
        <div dangerouslySetInnerHTML={{ __html: clean }} />
      </DialogContent>
      <DialogActions>
        <MobileStepper
          sx={{ width: '100%' }}
          variant="text"
          steps={communications?.total_communications || 1}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              variant="contained"
              sx={{ alignItems: 'flex-end', height: '32px' }}
              size="small"
              onClick={activeStep === communications?.total_communications - 1 ? handleClose : handleNext}
            >
              {activeStep === communications?.total_communications - 1 ? 'Close' : 'Next'}
              {activeStep === communications?.total_communications - 1 ? <></> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button
              sx={{ alignItems: 'flex-end' }}
              variant="outlined"
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </DialogActions>
    </CustomDialog>
  )
}
