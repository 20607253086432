import { Grid, Icon, Link, Paper, Typography, useTheme } from '@mui/material'
import { useEffect } from 'react'
import HeaderTitle from '../Header/HeaderTitle'
// import InnerLoader from '../Loader/InnerLoader'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { setBackToComponent } from '../../store/header/actionCreator'
import { clearUserPreferencesResponses } from '../../store/settings/actionCreator'

const PAGE_TITLE = 'My Settings'
const SETTINGS_PAGE_PATH = '/team-member/settings'

const getStyles = (theme) => ({
  mainScrollContainer: {
    ...theme.mainScrollContainer,
    overflowY: 'auto',
    height: '100%',
  },
  mainContainerPosition: theme.mainContainerPosition,
  loaderStyles: {
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
    padding: '32px',
  },
  settingsListContainer: {
    margin: '10px',
    position: 'relative',
  },
  settingsListItem: {
    borderBottom: '1px solid #333',
    padding: '20px 0 20px 0',
    color: '#333',
  },
  settingsListItemIcon: {
    paddingTop: '3px',
    paddingBottom: '10px',
    display: 'contents',
  },
  settingsListText: {
    paddingLeft: '10px',
    alignContent: 'center',
  },
  settingsListItemLink: {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
  },
})

const SettingsPage = () => {
  const theme = useTheme()
  const styles = getStyles(theme)
  // const { classes } = props
  // const [showLoader, setLoader] = useState(false)
  const backToComponent = useSelector((state) => state.header.backToComponent)

  const dispatch = useDispatch()
  let navigate = useNavigate()

  useEffect(() => {
    if (backToComponent && backToComponent === SETTINGS_PAGE_PATH) {
      dispatch(setBackToComponent(null))
      dispatch(clearUserPreferencesResponses())
    }
  })

  const renderSettingsList = () => {
    return (
      // <div className={classes.settingsListContainer} data-cy={`settings-item-${index}`} key={index}></div>
      <div style={styles.settingsListContainer}>
        <SettingsListItem />
      </div>
    )
  }

  const goToPushNotificationPage = () => {
    dispatch(setBackToComponent(SETTINGS_PAGE_PATH))
    navigate('/team-member/settings/push-notifications')
  }

  const SettingsListItem = () => {
    return (
      <div style={styles.settingsListItem}>
        <Link
          to={'/team-member/settings/push-notifications'}
          role="link"
          tabIndex="0"
          aria-label={`My Push Notifications list item`}
          style={styles.settingsListItemLink}
          onClick={goToPushNotificationPage}
        >
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item xs={1} sx={styles.settingsListItemIcon}>
              <Icon>
                <NotificationsIcon />
              </Icon>
            </Grid>
            <Grid item xs={10} sx={styles.settingsListText} data-cy="mysettings-list-pn">
              <Typography>My Push Notifications</Typography>
            </Grid>
            <Grid item xs={1} sx={styles.settingsListItemIcon}>
              <Icon>
                <NavigateNextIcon />
              </Icon>
            </Grid>
          </Grid>
        </Link>
      </div>
    )
  }

  const renderComponent = () => {
    return (
      <div style={styles.mainContainerPosition}>
        <Paper sx={styles.mainScrollContainer}>{renderSettingsList()}</Paper>
      </div>
    )
  }

  // const renderLoader = () => {
  //   return (
  //     <>
  //       <span className={classes.loaderStyles}>
  //         <InnerLoader size={36} />
  //       </span>
  //     </>
  //   )
  // }

  return (
    <>
      <HeaderTitle title={PAGE_TITLE} />
      {renderComponent()}
    </>
  )
}

export default SettingsPage
