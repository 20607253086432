import { Divider } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../../../../auth/hooks/useUser'
import { AVAILABILITY, CATEGORY_FEATURE_MAP, FAILED, PENDING } from '../../../../../constants/RequestConstants'
import { setControlPanelSettings } from '../../../../../store/leader/actionCreator'
import TotalTimeOffRequestsByStatusButton from '../../TotalTimeOffRequestsByStatusButton'

const AvailabilityDailyActions = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { configMap } = useUser().leaderConfig

  const handleAvailabilityRequestsButtonClick = (statuses) => {
    let filterOverrides = { statuses: { selectedValues: statuses } }

    dispatch(
      setControlPanelSettings(
        AVAILABILITY,
        configMap[AVAILABILITY].defaultStartDate,
        configMap[AVAILABILITY].defaultEndDate,
        filterOverrides,
        configMap[AVAILABILITY].searchText,
        configMap[AVAILABILITY].selectedSortByIndex,
        configMap[AVAILABILITY].sortOrder,
      ),
    )
    navigate('/leader/myteam')
  }

  return (
    <>
      <Divider aria-hidden="true" />
      <TotalTimeOffRequestsByStatusButton
        status={PENDING}
        startDate={configMap[AVAILABILITY].defaultStartDate}
        endDate={configMap[AVAILABILITY].defaultEndDate}
        handleButtonClick={() => handleAvailabilityRequestsButtonClick(['Pending'])}
        buttonMessage={'Pending Availability Requests'}
        selectedCategory={CATEGORY_FEATURE_MAP.get(AVAILABILITY)}
        categoryName={AVAILABILITY}
      />
      <Divider aria-hidden="true" />
      <TotalTimeOffRequestsByStatusButton
        status={FAILED}
        startDate={configMap[AVAILABILITY].defaultStartDate}
        endDate={configMap[AVAILABILITY].defaultEndDate}
        handleButtonClick={() => handleAvailabilityRequestsButtonClick(['Failed'])}
        buttonMessage={'Failed Availability Requests'}
        selectedCategory={CATEGORY_FEATURE_MAP.get(AVAILABILITY)}
        categoryName={AVAILABILITY}
      />
    </>
  )
}

export default AvailabilityDailyActions
