import { Autocomplete, TextField } from '@mui/material'
import { useAvailablePersonas } from '../../../../api/userProfiles/useAvailablePersonas'

export default function PersonasDropdown({
  handleFormValueChange,
  handleInputValueChange,
  formValues,
  selectInputValues,
}) {
  const { data: personas, isFetching, isLoading } = useAvailablePersonas()

  return (
    <Autocomplete
      name="audience_roles"
      fullWidth
      multiple
      disableCloseOnSelect
      id="audience_roles-select"
      size="small"
      loading={isFetching || isLoading}
      options={personas?.data || []}
      getOptionLabel={(option) => option}
      value={formValues.audience_roles}
      onChange={(event, newValue) => {
        handleFormValueChange('audience_roles', newValue)
      }}
      inputValue={selectInputValues.audience_roles}
      onInputChange={(event, newInputValue) => {
        handleInputValueChange('audience_roles', newInputValue)
      }}
      limitTags={3}
      renderInput={(params) => {
        const { key, ...otherParams } = params
        return (
          <TextField
            {...otherParams}
            key={key}
            variant="standard"
            label="Audience Personas"
            placeholder="Leave blank for all personas"
          />
        )
      }}
    />
  )
}
