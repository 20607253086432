import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import HomeIcon from '@mui/icons-material/Home'
import ScheduleIcon from '@mui/icons-material/Today'
import TimecardIcon from '../Icons/TimecardIcon'

import { SvgIconComponent } from '@mui/icons-material'
import CustomAvailableShiftsIcon from '../Icons/CustomAvailableShiftsIcon'

import { PersonAdd, QrCodeScanner } from '@mui/icons-material'
import DownloadIcon from '@mui/icons-material/CloudDownload'
import AvailabilityIcon from '@mui/icons-material/EventAvailable'
import RateReviewIcon from '@mui/icons-material/RateReview'
import SettingsIcon from '@mui/icons-material/Settings'
import UpdateIcon from '@mui/icons-material/Update'
import LeaderViewIcon from '@mui/icons-material/Web'

import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import { AuthorizedAccess, FeatureName, PermissionName, User, ViewName } from '../../auth/User'
import '../../styles/index.css'

interface Feature {
  to: string
  label: string
  Icon: SvgIconComponent | React.FC<any>
  allowed: FeatureName[]
  view: ViewName
  permissions: PermissionName[]
}

const features: Feature[] = [
  {
    to: '/admin',
    label: 'Admins',
    Icon: AdminPanelSettingsIcon,
    allowed: ['communication'],
    view: 'admin',
    permissions: ['write'],
  },
  {
    to: '/',
    label: 'Home',
    Icon: HomeIcon,
    allowed: ['schedule', 'timecard'],
    view: 'team_member',
    permissions: ['read', 'write'],
  },
  {
    to: '/team-member/schedule',
    label: 'Schedule',
    Icon: ScheduleIcon,
    allowed: ['schedule'],
    view: 'team_member',
    permissions: ['read', 'write'],
  },
  {
    to: '/team-member/availableShifts',
    label: 'Available Shifts',
    Icon: CustomAvailableShiftsIcon,
    allowed: ['open_shifts', 'request_to_cover'],
    view: 'team_member',
    permissions: ['read', 'write'],
  },
  {
    to: '/team-member/timecardWeekly',
    label: 'Timecard',
    Icon: TimecardIcon,
    allowed: ['timecard'],
    view: 'team_member',
    permissions: ['read', 'write'],
  },
  {
    to: '/team-member/requests',
    label: 'My Requests',
    Icon: PersonAdd,
    allowed: ['punch_correction', 'time_off', 'call_in', 'call_off'],
    view: 'team_member',
    permissions: ['read', 'write'],
  },
  {
    to: '/team-member/availability',
    label: 'Availability',
    Icon: EventAvailableIcon,
    allowed: ['availability'],
    view: 'team_member',
    permissions: ['read', 'write'],
  },
  {
    to: '/team-member/tmOptionalAvailability',
    label: 'Voluntary Availability',
    Icon: AvailabilityIcon,
    allowed: ['voluntary_availability'],
    view: 'team_member',
    permissions: ['read', 'write'],
  },
  {
    to: '/leaderView',
    label: 'Leader View',
    Icon: LeaderViewIcon,
    allowed: ['dashboard'],
    view: 'leader',
    permissions: ['read', 'write'],
  },
  {
    to: '/leader',
    label: 'Leader',
    Icon: LeaderViewIcon,
    allowed: ['call_in', 'call_off', 'time_off', 'availability', 'punch_correction'],
    view: 'leader',
    permissions: ['read', 'write'],
  },
  {
    to: '/team-member/desiredHours',
    label: 'Desired Hours',
    Icon: UpdateIcon,
    allowed: ['desired_hours'],
    view: 'team_member',
    permissions: ['read', 'write'],
  },
  {
    to: '/team-member/barcode',
    label: 'My Barcode',
    Icon: QrCodeScanner,
    allowed: ['barcode'],
    view: 'team_member',
    permissions: ['read'],
  },
  {
    to: '/team-member/settings',
    label: 'My Settings',
    Icon: SettingsIcon,
    allowed: ['opt_in_opt_out'],
    view: 'team_member',
    permissions: ['read'],
  },
  {
    to: '/team-member/appleIosDownload',
    label: 'Apple - iOS Download',
    Icon: DownloadIcon,
    allowed: ['redemption_code'],
    view: 'team_member',
    permissions: ['read', 'write'],
  },
  {
    to: '/team-member/feedback',
    label: 'Feedback',
    Icon: RateReviewIcon,
    allowed: ['feedback'],
    view: 'team_member',
    permissions: ['read', 'write'],
  },
]

export const featureConfigManager = (user: User): Feature[] => {
  return features.filter((feature) => {
    let authAccess: AuthorizedAccess = user.hasAccess(feature.allowed, feature.view, feature.permissions, false)

    if (authAccess.hasAccess) {
      return true
    }

    return false
  })
}
