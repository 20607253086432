import { styled } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import update from 'immutability-helper'
import {
  DEFAULT_PAGE_NUM,
  DEFAULT_PER_PAGE,
  DEFAULT_PTO_REQUESTS_DATA,
  DEFAULT_PTO_REQUESTS_DATA_SORT,
  DEFAULT_PTO_REQUESTS_DATA_TOTAL_RECORDS,
  DEFAULT_PTO_REQUESTS_SORT_BY,
  PTO_REQUESTS_COLUMNS_ENUM,
  PTO_REQUESTS_COLUMNS_ORDER,
} from '../../components/LeaderViewAutomation/LeaderViewAutomation'
import RequestStatusIcon from '../../components/Requests/RequestStatusIcon'
import { Comment } from '../../components/common/Comment'
import { REQUEST_STATUS_TYPES } from '../../constants/RequestConstants'
import { PERSONAS } from '../../constants/userConstants'
import { getFormattedTimestamp, massVacationManager } from '../../utils/DateUtil'
import {
  CLOSE_TIME_OFF_DIALOG,
  GET_PTO_REQUESTS_FILTERS_REQUEST_ERROR,
  GET_PTO_REQUESTS_FILTERS_REQUEST_SUCCESS,
  GET_PTO_REQUESTS_REQUEST_ERROR,
  GET_PTO_REQUESTS_REQUEST_SUCCESS,
  LEADER_TIME_OFF_DECISION_ERROR,
  LEADER_TIME_OFF_DECISION_SUCCESS,
  OPEN_TIME_OFF_DIALOG,
  SET_LEADER_TIME_OFF_DATA_ERROR,
  SET_LEADER_TIME_OFF_DATA_RESPONSE,
  SET_LEADER_TIME_OFF_DATA_SAVING,
  SET_PTO_REQUESTS_DATA_COLUMN_VIEW,
  SET_PTO_REQUESTS_DATA_LOADING,
  SET_PTO_REQUESTS_DATA_PAGE,
  SET_PTO_REQUESTS_DATA_PER_PAGE,
  SET_PTO_REQUESTS_DATA_SEARCH,
  SET_PTO_REQUESTS_DATA_SELECTED_FILTERS,
  SET_PTO_REQUESTS_DATA_SORT,
  SET_PTO_REQUESTS_DATA_TABLE_COLUMNS_TO_DEFAULT,
  SET_PTO_REQUESTS_DATA_TABLE_FILTERS_TO_DEFAULT,
  SET_PTO_REQUESTS_DATA_TABLE_STATE_TO_DEFAULT,
} from './actionType'

const WhiteTextTypography = styled(Typography)({
  color: '#FFFFFF',
})

const ListItemCustom = styled(ListItem)({
  paddingTop: 6,
  paddingBottom: 6,
})

export const formatPTORequestData = (data = {}) => {
  return data.map((element) => {
    return {
      name: element.worker_details.full_name,
      request_id: element.request_id,
      worker_id: element.worker_details.worker_id,
      scheduling_key: element.worker_details.scheduling_key,
      department: element.worker_details.department,
      job_classification: element.worker_details.job_classification,
      type: getRequestedTypes(element.request_details),
      created_timestamp: getFormattedTimestampHandler(element.created_timestamp, 'created_timestamp'),
      start_time: getFormattedTimestampHandler(element.request_details, 'start_time'),
      end_time: getFormattedTimestampHandler(element.request_details, 'end_time'),
      hours: getCalculatedHours(element.request_details),
      priority: element.priority,
      request_type: element.request_type,
      comments: element.comments,
      status: getStatuses(element.request_details),
      updated_timestamp: getUpdatedTimestamps(element.request_details),
      updated_by: getUpdatedByWorkerIds(element.request_details),
    }
  })
}

/**
 * getCalculatedHours(data) - a function that returns the number of hours scheduled based
 * on the start and end dates of a request
 * param data - object containing date/time information
 * returns {*} - array with calculated hours per row in a request
 */
function getCalculatedHours(data) {
  return data.map((requestDetail) => {
    return ((new Date(requestDetail.end_time) - new Date(requestDetail.start_time)) / (1000 * 60 * 60)).toFixed(2)
  })
}

export const getRequestedTypes = (data) => {
  return data.map((value) => {
    return value.type
  })
}

export const getStatuses = (data) => {
  return data.map((value) => {
    return value.status
  })
}

export const getUpdatedByWorkerIds = (data) => {
  return data.map((value) => {
    return value.updated_by
  })
}

export const getUpdatedTimestamps = (data) => {
  return data.map((value) => {
    return getFormattedTimestampHandler(value.updated_timestamp, 'updated_timestamp')
  })
}

const getFormattedTimestampHandler = (data, type) => {
  if (type === 'start_time' || type === 'end_time') {
    return data.map((element) => {
      return getFormattedTimestamp(element[type].substring(0, 16))
    })
  }
  if (type === 'created_timestamp' || type === 'updated_timestamp') {
    return getFormattedTimestamp(data.substring(0, 16))
  }
}

const renderCommentCell = (data) => {
  let commentSnippet = ' '
  let comments = [...data].reverse()
  let commentPreview = [...Array(2)].map((_, index) => {
    return <Comment comment={comments[index]} whiteText={true} />
  })

  if (comments[0]) {
    commentSnippet = checkIfCommentIsOverLimit(comments[0].comment)
  }

  return (
    <Tooltip title={commentPreview} arrow={true}>
      <Typography>{commentSnippet}</Typography>
    </Tooltip>
  )
}

const renderStatusCell = (status) => {
  return (
    <Tooltip value={status} title={<WhiteTextTypography>{status}</WhiteTextTypography>}>
      <RequestStatusIcon status={status} isTimeOffTeamMember={false} />
    </Tooltip>
  )
}

export const checkIfCommentIsOverLimit = (comment) => {
  let commentSnippet
  if (comment.length > 15) {
    commentSnippet = `${comment.substring(0, 15)}...`
  } else {
    commentSnippet = comment
  }
  return commentSnippet
}

const buildPaginationDetailsFromHeaderResponse = (headers = {}) => {
  return headers['x-total-count']
}

/**
 * Build the array of arrays for how many columns are defined in the enum.
 * @param userGroup - the persona of the user (DC_MANAGER = OM, DC_NON_EXEMPT_MANAGER = HR)
 * @returns {*[]} - An array of arrays where the status array (column/index 10)
 * is changed dependent on persona
 */
const buildDefaultSelectedFilters = (persona) => {
  let columns = []
  PTO_REQUESTS_COLUMNS_ORDER.forEach((columnName) => {
    let filtersForSingleColumn = []
    if (
      columnName === PTO_REQUESTS_COLUMNS_ENUM.status &&
      [PERSONAS.DC_HR_NE].some((partial) => persona.includes(partial))
    ) {
      filtersForSingleColumn.push(REQUEST_STATUS_TYPES.SCHEDULED, REQUEST_STATUS_TYPES.FAILED)
    } else if (
      [PERSONAS.DC_LEADER_EX].some((partial) => persona.includes(partial)) &&
      columnName === PTO_REQUESTS_COLUMNS_ENUM.status
    ) {
      filtersForSingleColumn.push(REQUEST_STATUS_TYPES.PENDING)
    }

    columns.push(filtersForSingleColumn)
  })

  return columns
}

const updateColumnView = (state, column, action) => {
  let isViewable
  if (action === 'add') {
    isViewable = true
  }
  if (action === 'remove') {
    isViewable = false
  }

  return update(state, {
    ptoRequestsColumns: {
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(column)]: {
        options: {
          display: { $set: isViewable },
        },
      },
    },
  })
}

/**
 * Sort columns using created_timestamp value
 */
const updateSortColumns = (state, sort, column) => {
  return update(state, {
    ptoRequestsColumns: {
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.created_timestamp)]: {
        options: {
          sortOrder: { $set: column === PTO_REQUESTS_COLUMNS_ENUM.created_timestamp ? sort.toLowerCase() : 'none' },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.name)]: {
        options: {
          sortOrder: { $set: column === PTO_REQUESTS_COLUMNS_ENUM.name ? sort.toLowerCase() : 'none' },
        },
      },
    },
    ptoRequestsDataSort: { $set: sort },
    ptoRequestsColumnSortBy: { $set: column },
  })
}

/**
 * Update checkbox checked/unchecked display for filter options
 */
export const updateSelectedFilters = (state, payload) => {
  return update(state, {
    ptoRequestsColumns: {
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.scheduling_key)]: {
        options: {
          filterList: { $set: payload[PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.scheduling_key)] },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.department)]: {
        options: {
          filterList: { $set: payload[PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.department)] },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.job_classification)]: {
        options: {
          filterList: {
            $set: payload[PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.job_classification)],
          },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.type)]: {
        options: {
          filterList: {
            $set: payload[PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.type)],
          },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.status)]: {
        options: {
          filterList: {
            $set: payload[PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.status)],
          },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.updated_by)]: {
        options: {
          filterList: {
            $set: payload[PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.updated_by)],
          },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.priority)]: {
        options: {
          filterList: {
            $set: payload[PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.priority)],
          },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.request_type)]: {
        options: {
          filterList: {
            $set: payload[PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.request_type)],
          },
        },
      },
    },
    ptoRequestsDataSelectedFilters: { $set: payload },
  })
}

/**
 * Values for the filter dropdowns for the filterable attributes
 */
const updatePTORequestsColumns = (state, record) => {
  return update(state, {
    ptoRequestsColumns: {
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.scheduling_key)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.job_keys },
          },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.department)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.departments },
          },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.job_classification)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.jobs },
          },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.type)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.types },
          },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.status)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.statuses },
          },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.updated_by)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.updated_by },
          },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.priority)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.priorities },
          },
        },
      },
      [PTO_REQUESTS_COLUMNS_ORDER.indexOf(PTO_REQUESTS_COLUMNS_ENUM.request_type)]: {
        options: {
          filterOptions: {
            names: { $set: record.data.request_types },
          },
        },
      },
    },
    ptoRequestFiltersGetError: { $set: null },
  })
}

const renderUpdatedBy = (workerId) => {
  if (!workerId) return ''
  return workerId
}

export const initialState = {
  timeOffRequestPostError: null,
  timeOffRequestPostResponse: null,
  timeOffRequestDataSaving: false,
  timeOffRequestSaveDialogOpen: false,
  ptoRequestsDataSort: DEFAULT_PTO_REQUESTS_DATA_SORT,
  ptoRequestsColumnSortBy: DEFAULT_PTO_REQUESTS_SORT_BY,
  ptoRequestFiltersGetError: null,
  ptoRequestsGetError: null,
  ptoRequestsData: DEFAULT_PTO_REQUESTS_DATA,
  workerTimeOffRequests: null,
  is_automation_location: null,
  ptoRequestsDataSelectedFilters: null,
  ptoRequestsPrevRequestedSelectedFilters: null,
  ptoRequestsSessionData: DEFAULT_PTO_REQUESTS_DATA,
  ptoRequestsDataLoading: false,
  ptoRequestsDataTotalRecords: DEFAULT_PTO_REQUESTS_DATA_TOTAL_RECORDS,
  ptoRequestsDataPage: DEFAULT_PAGE_NUM,
  ptoRequestsDataPerPage: DEFAULT_PER_PAGE,
  selectedTimeOffRequest: null,
  ptoRequestsDialogOpen: false,
  ptoRequestsDataSearch: null,
  ptoRequestsPrevRequestedSearch: null,
  ptoRequestsColumns: [
    /**
     * Column order matters here. Using PTO_REQUESTS_COLUMNS_ORDER indexes to maintain order. If add/removing or changing
     * order of a column(s) here, also update PTO_REQUESTS_COLUMNS_ORDER
     */
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[0],
      label: 'Name',
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[1],
      label: 'Team Member ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[2],
      label: 'Key',
      options: {
        filter: true,
        filterList: [],
        filterType: 'multiselect',
        filterOptions: {
          names: [],
        },
        sort: false,
        searchable: false,
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[3],
      label: 'Department',
      options: {
        filter: true,
        filterList: [],
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[4],
      label: 'Job Classification',
      options: {
        filter: true,
        filterList: [],
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[5],
      label: 'Time Off Type',
      options: {
        sort: false,
        searchable: false,
        filter: true,
        filterType: 'multiselect',
        filterList: [],
        filterOptions: {
          names: [],
        },

        customBodyRender: (value) => {
          return value ? (
            <List disablePadding>
              {value.map((request, index) => (
                <ListItem style={{ width: 'max-content' }} disableGutters dense key={index}>
                  <ListItemText primary={request} />
                </ListItem>
              ))}
            </List>
          ) : (
            ' '
          )
        },
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[6],
      label: 'Start Time',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) => {
          return value ? (
            <List disablePadding>
              {value.map((request, index) => (
                <ListItem style={{ width: 'max-content' }} disableGutters dense key={index}>
                  <ListItemText primary={request} />
                </ListItem>
              ))}
            </List>
          ) : (
            ' '
          )
        },
        searchable: false,
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[7],
      label: 'End Time',
      options: {
        customBodyRender: (value) => {
          return value ? (
            <List disablePadding>
              {value.map((request, index) => (
                <ListItem style={{ width: 'max-content' }} disableGutters dense key={index}>
                  <ListItemText primary={request} />
                </ListItem>
              ))}
            </List>
          ) : (
            ' '
          )
        },
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[8],
      label: 'Hours',
      options: {
        customBodyRender: (value) => {
          return value ? (
            <List disablePadding>
              {value.map((request, index) => (
                <ListItem style={{ width: 'max-content' }} disableGutters dense key={index}>
                  <ListItemText primary={request} />
                </ListItem>
              ))}
            </List>
          ) : (
            ' '
          )
        },
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[9],
      label: 'Priority',
      options: {
        filter: true,
        filterList: [],
        sort: false,
        display: massVacationManager().status === 'decisions',
        filterOptions: {
          names: [],
        },
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[10],
      label: 'Request Type',
      options: {
        filter: true,
        filterType: 'multiselect',
        filterList: [],
        sort: false,
        filterOptions: {
          names: [],
        },
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[11],
      label: 'Comments',
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => {
          if (value) {
            return renderCommentCell(value)
          }
        },
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[12],
      label: 'Status',
      options: {
        customBodyRender: (value) => {
          return value ? (
            <List disablePadding>
              {value.map((request, index) => (
                <ListItemCustom disableGutters key={index}>
                  {renderStatusCell(request)}
                </ListItemCustom>
              ))}
            </List>
          ) : (
            ' '
          )
        },
        filterList: [],
        filterOptions: {
          names: [],
        },
        filterType: 'multiselect',
        sort: false,
        searchable: false,
        filter: true,
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[13],
      label: 'Updated By',
      options: {
        filter: true,
        filterType: 'multiselect',
        filterList: [],
        filterOptions: {
          names: [],
        },
        sort: false,
        customBodyRender: (value) => {
          return value ? (
            <List disablePadding>
              {value.map((request, index) => (
                <ListItem style={{ width: 'max-content' }} disableGutters dense key={index}>
                  <ListItemText primary={renderUpdatedBy(request)} />
                </ListItem>
              ))}
            </List>
          ) : (
            ' '
          )
        },
        searchable: false,
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[14],
      label: 'Updated Timestamp',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value ? (
            <List disablePadding>
              {value.map((request, index) => (
                <ListItem style={{ width: 'max-content' }} disableGutters dense key={index}>
                  <ListItemText primary={request} />
                </ListItem>
              ))}
            </List>
          ) : (
            ' '
          )
        },
        searchable: false,
        display: false,
      },
    },
    {
      name: PTO_REQUESTS_COLUMNS_ORDER[15],
      label: 'Created Timestamp',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <List disablePadding>
            <ListItem style={{ width: 'max-content' }} disableGutters dense key={value}>
              <ListItemText primary={value} />
            </ListItem>
          </List>
        ),
        searchable: false,
        sortOrder: 'asc',
      },
    },
  ],
}

export default function leaderViewPTORequests(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PTO_REQUESTS_DATA_TABLE_COLUMNS_TO_DEFAULT: {
      return {
        ...state,
        ptoRequestsColumns: initialState.ptoRequestsColumns,
      }
    }
    case SET_PTO_REQUESTS_DATA_TABLE_STATE_TO_DEFAULT: {
      return {
        ...state,
        ptoRequestsData: DEFAULT_PTO_REQUESTS_DATA,
        ptoRequestsSessionData: DEFAULT_PTO_REQUESTS_DATA,
        ptoRequestsDataTotalRecords: DEFAULT_PTO_REQUESTS_DATA_TOTAL_RECORDS,
        ptoRequestsGetError: null,
      }
    }
    case SET_PTO_REQUESTS_DATA_LOADING: {
      const { payload, search, selectedFilters } = action

      return {
        ...state,
        ptoRequestsDataLoading: payload,
        ptoRequestsPrevRequestedSearch: search,
        ptoRequestsPrevRequestedSelectedFilters: selectedFilters,
      }
    }
    case SET_PTO_REQUESTS_DATA_TABLE_FILTERS_TO_DEFAULT: {
      const { persona } = action
      return {
        ...state,
        ...updateSelectedFilters(state, buildDefaultSelectedFilters(persona)),
      }
    }

    case SET_PTO_REQUESTS_DATA_PER_PAGE: {
      const { payload } = action
      return {
        ...state,
        ptoRequestsDataPerPage: payload,
      }
    }
    case SET_PTO_REQUESTS_DATA_PAGE: {
      const { payload } = action
      return {
        ...state,
        ptoRequestsDataPage: payload,
      }
    }
    case SET_PTO_REQUESTS_DATA_SEARCH: {
      const { payload } = action
      return {
        ...state,
        ptoRequestsDataSearch: payload,
      }
    }
    case GET_PTO_REQUESTS_REQUEST_SUCCESS: {
      const { record } = action
      let formattedData = formatPTORequestData(record.data.worker_time_off_requests)
      return {
        ...state,
        ptoRequestsDataTotalRecords: buildPaginationDetailsFromHeaderResponse(record.headers),
        ptoRequestsData: formattedData,
        is_automation_location: record.data.is_automation_location,
        ptoRequestsGetError: null,
        timeOffRequestPostError: null,
        ptoRequestsDataLoading: false,
        workerTimeOffRequests: record.data.worker_time_off_requests,
      }
    }
    case GET_PTO_REQUESTS_REQUEST_ERROR: {
      const { record } = action
      return {
        ...state,
        ptoRequestsGetError: record.response ? record.response.data : record,
        timeOffRequestPostError: null,
        ptoRequestsDataLoading: false,
      }
    }
    case OPEN_TIME_OFF_DIALOG: {
      const { record } = action
      return {
        ...state,
        selectedTimeOffRequest: record,
        timeOffRequestSaveDialogOpen: true,
      }
    }
    case CLOSE_TIME_OFF_DIALOG: {
      return {
        ...state,
        selectedTimeOffRequest: null,
        timeOffRequestSaveDialogOpen: false,
      }
    }
    case SET_PTO_REQUESTS_DATA_SELECTED_FILTERS: {
      const { payload } = action
      return updateSelectedFilters(state, payload)
    }
    case GET_PTO_REQUESTS_FILTERS_REQUEST_SUCCESS: {
      const { record } = action
      return updatePTORequestsColumns(state, record)
    }
    case GET_PTO_REQUESTS_FILTERS_REQUEST_ERROR: {
      const { record } = action
      return {
        ...state,
        ptoRequestFiltersGetError: record.response ? record.response.data : record,
      }
    }
    case LEADER_TIME_OFF_DECISION_SUCCESS: {
      const { record } = action
      return {
        ...state,
        timeOffRequestPostResponse: record.response ? record.response.data : record,
        timeOffRequestPostError: null,
        timeOffRequestDataSaving: false,
      }
    }
    case LEADER_TIME_OFF_DECISION_ERROR: {
      const { record } = action
      return {
        ...state,
        timeOffRequestPostError: record.response ? record.response.data : record,
        timeOffRequestPostResponse: null,
        timeOffRequestDataSaving: false,
      }
    }
    case SET_LEADER_TIME_OFF_DATA_SAVING: {
      const { isSaving } = action

      return {
        ...state,
        timeOffRequestDataSaving: isSaving,
      }
    }
    case SET_LEADER_TIME_OFF_DATA_ERROR: {
      const { error } = action
      return {
        ...state,
        timeOffRequestPostError: error,
      }
    }
    case SET_LEADER_TIME_OFF_DATA_RESPONSE: {
      const { response } = action

      return {
        ...state,
        timeOffRequestPostResponse: response,
      }
    }
    case SET_PTO_REQUESTS_DATA_SORT: {
      const { payload, column } = action
      return updateSortColumns(state, payload, column)
    }
    case SET_PTO_REQUESTS_DATA_COLUMN_VIEW: {
      const { column, columnAction } = action
      return updateColumnView(state, column, columnAction)
    }
    default:
      return state
  }
}
