// UserContext.tsx
import { AuthProvider, useAuth } from '@praxis/component-auth'
import { AxiosError } from 'axios'
import { ReactNode } from 'react'
import { IError } from '../api/globalResponses'
import { useLocationDetails } from '../api/location/useLocationDetails'
import { useUserAccess } from '../api/userAccess/useUserAccess'
import InnerLoader from '../components/Loader/InnerLoader'
import PrivacyPolicyPage from '../components/PrivacyPolicy/PrivacyPolicyPage'
import PreRouterError from '../components/common/fallback/PreRouterError'
import apiConfig from '../config/apiConfig'
import LogIn from '../pages/LogIn'
import { User } from './User'
import UserContext from './UserContext'
import useFireflyAdoption from './hooks/useFireflyAdoption'

export type ControlledState = {
  client_id: string
  worker_id: string
  location_id: string
  fap: string
  is_exempt: string
  location_type: string
}

interface MyTimeAuthProviderProps {
  children?: ReactNode
}

const MyTimeAuthProvider = ({ children }: MyTimeAuthProviderProps) => {
  return (
    <AuthProvider
      clientId={`${apiConfig.auth.clientId}`}
      authorizationUrl={`${apiConfig.auth.authorizationUrl}`}
      logoutUrl={`${apiConfig.auth.logoutHost}`}
    >
      {children}
    </AuthProvider>
  )
}

interface MyTimeUserProviderProps {
  children?: ReactNode
}

export const MyTimeUserProvider = ({ children }: MyTimeUserProviderProps) => {
  const { data: userData, error } = useUserAccess()
  const { data: locationData, error: locationError } = useLocationDetails()

  const { isAuthenticated } = useAuth()

  useFireflyAdoption()

  const user = userData && locationData ? new User(userData, locationData) : null

  if (window.location.pathname === '/privacypolicy') {
    return <PrivacyPolicyPage />
  }

  if (!isAuthenticated()) {
    return <LogIn />
  }

  if (user) {
    return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  }

  if (error) {
    const userAccessError = error as AxiosError<IError>
    return <PreRouterError error={userAccessError.response?.data} />
  }

  if (locationError) {
    const locationApiError = locationError as AxiosError<IError>
    return <PreRouterError error={locationApiError?.response?.data} />
  }

  return <InnerLoader />
}

export default MyTimeAuthProvider
