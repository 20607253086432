import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { addWeeks, endOfWeek, startOfWeek } from 'date-fns'
import { values } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Element, scroller } from 'react-scroll/modules'
import { useAvailableShifts } from '../../api/availableShifts/useAvailableShifts'
import { useUser } from '../../auth/hooks/useUser'
import MyProtectedElement from '../../auth/MyProtectedElement'
import {
  ERROR_CODES_SHOW_MESSAGE,
  ERROR_CODE_CANNOT_CONNECT_TO_SERVER,
  LOAD_AVAILABLE_SHIFTS_ERROR_MESSAGE,
  MISSED_PUNCH_MESSAGE,
  NO_AVAILABLE_SHIFTS_MESSAGE,
  NO_AVAILABLE_SHIFTS_MESSAGE_MULTI_LOCATION,
  NO_LOCATION_SELECTED_MESSAGE,
} from '../../constants/errorConstants'
import { getMultiLocationGroup } from '../../constants/multiLocationPilot'
import { showNotificationError } from '../../store/notification/actionCreator'
import { getDateOfTodayWithNoTimestamp, getStartEndDatesOfWeek } from '../../utils/DateUtil'
import { formatErrorCode } from '../../utils/ErrorHandling'
import WeeklyCalendar from '../Calendar/WeeklyCalendar'
import HeaderTitle from '../Header/HeaderTitle'
import InnerLoader from '../Loader/InnerLoader'
import AvailableShiftsAccordion from './AvailableShiftsAccordion'

const PAGE_TITLE = 'My Available Shifts'
const outputDateFormat = 'dddd, MMMM D'

const getStyles = (theme) => ({
  mainScrollContainer: {
    ...theme.mainScrollContainer,
    overflowY: 'auto',
    height: '100%',
  },
  mainContainerPosition: theme.mainContainerPosition,
  infoMessage: theme.infoMessages,
  errorCodeMessage: theme.errorCodeMessages,
  errorMessage: theme.errorMessages,
  highlights: {
    fontWeight: 'bold',
    fontSize: '14px',
    paddingTop: '2px',
  },
  dividerBox: {
    flexGrow: '1',
  },
  dividerStyle: {
    border: `1px solid ${theme.palette.tertiary.dark}`,
  },
  arrowBtn: {
    marginRight: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
  },
  accordionElement: {
    paddingBottom: '1px',
  },
  expandCollapseButtonsContainer: {
    margin: '0 auto',
    maxWidth: '640px',
    width: '100%',
    padding: '2px 7px 10px 7px',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
    justifyContent: 'space-between',
  },
  optionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  locationsForm: {
    display: 'flex',
    flex: 10,
    justifyContent: 'center',
    height: '56px',
  },
  label: {
    marginLeft: '15px',
  },
  secondaryLoader: {
    flex: 1,
  },
  lastUpdated: {
    color: theme.palette.tertiary.dark,
  },
  optionsContainer: {
    display: 'flex',
    flex: 10,
    flexDirection: 'column',
    marginLeft: '10px',
    justifyContent: 'center',
    minWidth: 0,
  },
  itemText: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
})

const AvailableShifts = () => {
  const theme = useTheme()
  const styles = getStyles(theme)

  const dispatch = useDispatch()
  // eslint-disable-next-line
  const [searchParams] = useSearchParams()
  const user = useUser()

  const multiLocationDetails = useMemo(
    () => getMultiLocationGroup(user.locationData.location_id),
    [user.locationData.location_id],
  )

  const [expandedList, setExpandedList] = useState(() => {
    let array = [false, false, false, false, false, false, false]
    if (searchParams.get('expand') === 'all') array.fill(true)
    if (searchParams.get('expand') === 'first') array[0] = true
    return array
  })

  const [missedPunch, setMissedPunch] = useState(false)
  const [availableShiftsSelectedDate, setAvailableShiftsSelectedDate] = useState(getDateOfTodayWithNoTimestamp())
  const { startDate, endDate } = getStartEndDatesOfWeek(availableShiftsSelectedDate)
  const [selectedLocationIds, setSelectedLocationIds] = useState([])
  const [eligibleLocations, setEligibleLocations] = useState([])

  const minDate = startOfWeek(getDateOfTodayWithNoTimestamp())
  const maxDate = endOfWeek(addWeeks(getDateOfTodayWithNoTimestamp(), 2))

  const { data, error, isFetching } = useAvailableShifts('AVAILABLE_SHIFTS', startDate, endDate, selectedLocationIds)

  useEffect(() => {
    if (data && data.total_available_shifts > 0) {
      if (data.has_missed_punch) {
        setMissedPunch(data.has_missed_punch)
        dispatch(showNotificationError(true, MISSED_PUNCH_MESSAGE))
      }
    }
  }, [data, dispatch])

  useEffect(() => {
    setSelectedLocationIds([user.locationData.location_id])
    if (user.is('multi_location')) {
      setEligibleLocations(multiLocationDetails)
    } else {
      setEligibleLocations([user.locationData.location_id])
    }
  }, [user, user.locationData.location_id, multiLocationDetails])

  useEffect(() => {
    let formattedSelectedDate = moment(availableShiftsSelectedDate)?.format(outputDateFormat)

    if (formattedSelectedDate !== null) {
      // running into odd problem where this was throwing scroll-to error, slight delay fixed it
      setTimeout(() => {
        autoScrollToPanel(formattedSelectedDate)
      }, 100)
    }
  }, [availableShiftsSelectedDate])

  const getLocationIds = useCallback(() => {
    return selectedLocationIds
  }, [selectedLocationIds])

  const autoScrollToPanel = (panelNum) => {
    scroller.scrollTo(panelNum, {
      duration: 400,
      delay: 0,
      smooth: 'easeInOutQuart',
      containerId: 'scrollableContainer',
      offset: 0,
    })
  }

  const handleDateChange = (date) => {
    setAvailableShiftsSelectedDate(date)
  }

  const displayErrors = (errorObj) => {
    if (errorObj.message && errorObj.code && ERROR_CODES_SHOW_MESSAGE.includes(errorObj.code)) {
      return (
        <div style={styles.mainContainerPosition}>
          <p style={styles.errorMessage}>{errorObj.message}</p>
        </div>
      )
    } else {
      return (
        <div style={styles.mainContainerPosition}>
          <p style={styles.errorMessage}>
            {LOAD_AVAILABLE_SHIFTS_ERROR_MESSAGE}
            <span style={styles.errorCodeMessage}>
              {formatErrorCode(errorObj, ERROR_CODE_CANNOT_CONNECT_TO_SERVER)}
            </span>
          </p>
        </div>
      )
    }
  }

  const displayInfoMessage = (message) => {
    return (
      <div style={styles.mainContainerPosition}>
        <p style={styles.infoMessage}>{message}</p>
      </div>
    )
  }

  const handleLocationChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedLocationIds(
      // On autofill, we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  const renderOptionsHeader = () => {
    return (
      <Paper elevation={1} square sx={styles.expandCollapseButtonsContainer}>
        <Grid container justifyContent={'center'} flexDirection={'column'}>
          {renderGeneralOptions()}
          <MyProtectedElement allowed={['multi_location']} renderUnauthorized={() => <></>}>
            {renderMultiLocationSelect()}
          </MyProtectedElement>
        </Grid>
      </Paper>
    )
  }

  const renderListItemText = (locationObject) => {
    return `${locationObject.store_name} T${locationObject.location}`
  }

  const handleExpandCollapse = () => {
    setExpandedList((prevList) => {
      return [...prevList.fill(!expandedList.includes(true))]
    })
  }

  const renderExpandCollapseAllButtons = () => {
    return (
      <Grid container item xs={2} justifyContent={'right'} sx={{ paddingY: '4px' }}>
        <Tooltip id="tooltip-expand-all" title={expandedList?.includes(true) ? 'Collapse All' : 'Expand All'}>
          <Button
            sx={{ padding: '6px', minWidth: '50px' }}
            variant="contained"
            color="tertiary"
            onClick={handleExpandCollapse}
            size={'medium'}
          >
            {expandedList?.includes(true) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        </Tooltip>
      </Grid>
    )
  }

  const renderMultiLocationSelect = () => {
    return (
      <Grid container item xs={12}>
        <Grid container item xs={11}>
          <FormControl size="small" fullWidth>
            <InputLabel id="multiple-checkbox" htmlFor="multiple-checkbox">
              Locations
            </InputLabel>
            <Select
              label="Locations"
              id="multiple-checkbox"
              multiple
              select
              value={selectedLocationIds}
              onChange={handleLocationChange}
              renderValue={(selected) => selected.join(', ')}
              variant="outlined"
              fullWidth
            >
              {eligibleLocations.map((locationObject, i) => (
                <MenuItem aria-label={renderListItemText(locationObject)} key={i} value={locationObject.location}>
                  <Checkbox
                    aria-checked={selectedLocationIds.indexOf(locationObject.location) > -1}
                    checked={selectedLocationIds.indexOf(locationObject.location) > -1}
                  />
                  <ListItemText sx={styles.itemText} primary={renderListItemText(locationObject)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid container item xs={1} justifyContent={'center'}>
          {isFetching ? <InnerLoader size={24} /> : <></>}
        </Grid>
      </Grid>
    )
  }

  const renderAvailableShiftsAccordians = () => {
    const outputDateFormat = 'dddd, MMMM D'

    return (
      <>
        {data?.total_available_shifts === 0 ? (
          displayInfoMessage(
            user.is('multi_location') ? NO_AVAILABLE_SHIFTS_MESSAGE_MULTI_LOCATION : NO_AVAILABLE_SHIFTS_MESSAGE,
          )
        ) : (
          <Paper sx={styles.mainScrollContainer} elevation={1} square id="scrollableContainer">
            {values(data.available_shifts_by_date).map((item, index) => {
              let scheduleDate = moment(Object.keys(data.available_shifts_by_date)[index])?.format(outputDateFormat)
              let scheduleDateFormatted = scheduleDate.split(', ')[1]
              return (
                <Element
                  id={scheduleDateFormatted}
                  name={scheduleDate.toString()}
                  sx={styles.accordionElement}
                  key={index}
                >
                  <AvailableShiftsAccordion
                    hasMissedPunch={missedPunch}
                    shifts={item}
                    scheduleDate={scheduleDate}
                    currentWeeklyHrs={data.weekly_scheduled_hours}
                    panelId={index}
                    expandedList={expandedList}
                    setExpandedList={setExpandedList}
                    getLocationIds={getLocationIds}
                  />
                </Element>
              )
            })}
          </Paper>
        )}
      </>
    )
  }

  const renderComponent = () => {
    if (data && selectedLocationIds.length > 0 && expandedList !== null) {
      return renderAvailableShiftsAccordians()
    }
    if (selectedLocationIds.length === 0) {
      return user.is('multi_location')
        ? displayInfoMessage(NO_LOCATION_SELECTED_MESSAGE)
        : displayInfoMessage(LOAD_AVAILABLE_SHIFTS_ERROR_MESSAGE)
    }
    if (error) return displayErrors(error?.response?.data)
    return <InnerLoader size={48} />
  }

  const viewCalendar = () => {
    return (
      <WeeklyCalendar
        id="AvailableShiftsPage"
        selectedDate={new Date(availableShiftsSelectedDate)}
        onChange={(date) => handleDateChange(date)}
        minDate={minDate}
        maxDate={maxDate}
      />
    )
  }

  const renderGeneralOptions = () => {
    return (
      <Grid container item xs={12}>
        <Grid container item xs={10} alignItems="center">
          <Typography variant="body2" component="h1" sx={styles.highlights}>
            {`Hours Worked + Future Schedules: ${data ? data.weekly_scheduled_hours : 'Error'}`}
          </Typography>
        </Grid>
        {renderExpandCollapseAllButtons()}
      </Grid>
    )
  }

  return (
    <>
      <HeaderTitle title={PAGE_TITLE} />
      {viewCalendar()}
      {renderOptionsHeader()}
      {renderComponent()}
    </>
  )
}

export default AvailableShifts
