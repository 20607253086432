import _ from 'lodash'
import {
  RESET_FILTERS,
  SET_CONTROL_PANEL_SETTINGS,
  SET_FILTERS,
  SET_LEADER_CONFIG,
  SET_LEADER_DATE,
  SET_LEADER_FILTER_CONFIG,
  SET_PAGE,
  SET_ROWS_PER_PAGE,
  SET_SEARCH_NAME_TEXT,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_LOCATION,
  SET_SORT_BY,
  SET_SORT_ORDER,
  SET_TOTAL_COUNT,
  TOGGLE_PANEL_EXPAND_COLLAPSE,
} from './actionType'

export const initialState = {
  selectedLocation: '',
  selectedCategory: '',
  isPanelExpanded: true,
}

export default function leaderReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CONTROL_PANEL_SETTINGS: {
      const { category, startDate, endDate, filterOverride, searchText, sortBy, sortOrder } = action
      let newFilters = {}

      // set only the filter overrides
      if (state[category]?.filters) {
        newFilters = Object.keys(state[category].filters).reduce((current, key) => {
          if (filterOverride[key]) {
            return {
              ...current,
              [key]: {
                ...state[category].filters[key],
                selectedValues: filterOverride[key].selectedValues,
              },
            }
          }
          return { ...current, [key]: { ...state[category].filters[key], selectedValues: [] } }
        }, {})
      } else {
        newFilters = _.merge({}, state[category].filters, filterOverride)
      }

      return {
        ...state,
        selectedCategory: category,
        [category]: {
          ...state[category],
          startDate: startDate,
          endDate: endDate,
          filters: newFilters,
          searchText: searchText,
          selectedSortByIndex: sortBy,
          sortOrder: sortOrder,
          page: 1,
        },
      }
    }
    case SET_SELECTED_LOCATION: {
      const { payload } = action

      if (state.selectedCategory) {
        return {
          ...state,
          selectedLocation: payload,
          [state.selectedCategory]: {
            ...state[state.selectedCategory],
            page: 1,
          },
        }
      } else {
        return {
          ...state,
          selectedLocation: payload,
        }
      }
    }
    case SET_SELECTED_CATEGORY: {
      const { payload } = action
      return {
        ...state,
        selectedCategory: payload,
        [payload]: {
          ...state[payload],
          page: 1,
        },
      }
    }
    case SET_SORT_ORDER: {
      const { payload } = action
      return {
        ...state,
        [state.selectedCategory]: {
          ...state[state.selectedCategory],
          sortOrder: payload,
        },
      }
    }
    case SET_SORT_BY: {
      const { payload } = action
      return {
        ...state,
        [state.selectedCategory]: {
          ...state[state.selectedCategory],
          selectedSortByIndex: payload,
        },
      }
    }
    case SET_LEADER_CONFIG: {
      const { payload } = action
      let defaultSelected = payload.selectableCategories?.length === 1 ? payload.selectableCategories[0] : ''
      return _.merge({}, state, {
        ...payload.configMap,
        selectableCategories: payload.selectableCategories,
        selectedCategory: defaultSelected,
      })
    }
    case SET_LEADER_DATE: {
      const { name, date } = action
      return {
        ...state,
        [state.selectedCategory]: {
          ...state[state.selectedCategory],
          [name]: date,
          page: 1,
        },
      }
    }
    case TOGGLE_PANEL_EXPAND_COLLAPSE: {
      return {
        ...state,
        isPanelExpanded: !state.isPanelExpanded,
      }
    }
    case SET_FILTERS: {
      const { filterKey, value } = action
      return {
        ...state,
        [state.selectedCategory]: {
          ...state[state.selectedCategory],
          filters: {
            ...state[state.selectedCategory].filters,
            [filterKey]: {
              ...state[state.selectedCategory].filters[filterKey],
              selectedValues: value,
            },
          },
          page: 1,
        },
      }
    }
    case RESET_FILTERS: {
      let newFilters = {}
      if (Object.keys(state[state.selectedCategory].filters) !== undefined) {
        newFilters = Object.keys(state[state.selectedCategory].filters).reduce((current, key) => {
          return { ...current, [key]: { ...state[state.selectedCategory].filters[key], selectedValues: [] } }
        }, {})
      }

      return {
        ...state,
        [state.selectedCategory]: {
          ...state[state.selectedCategory],
          filters: newFilters,
          page: 1,
        },
      }
    }
    case SET_LEADER_FILTER_CONFIG: {
      const { payload, selectedCategory } = action
      let category = selectedCategory ? selectedCategory : state.selectedCategory
      return {
        ...state,
        [category]: {
          ...state[category],
          filters: _.merge({}, state[category].filters, payload),
        },
      }
    }
    case SET_SEARCH_NAME_TEXT: {
      const { searchText } = action
      return {
        ...state,
        [state.selectedCategory]: {
          ...state[state.selectedCategory],
          searchText: searchText,
          page: 1,
        },
      }
    }
    case SET_PAGE: {
      const { payload } = action
      return {
        ...state,
        [state.selectedCategory]: {
          ...state[state.selectedCategory],
          page: payload,
        },
      }
    }
    case SET_ROWS_PER_PAGE: {
      const { payload } = action
      return {
        ...state,
        [state.selectedCategory]: {
          ...state[state.selectedCategory],
          rowsPerPage: payload,
          page: 1,
        },
      }
    }
    case SET_TOTAL_COUNT: {
      const { payload } = action
      return {
        ...state,
        [state.selectedCategory]: {
          ...state[state.selectedCategory],
          totalCount: payload,
        },
      }
    }
    default:
      return state
  }
}
