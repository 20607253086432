import 'material-design-icons/iconfont/material-icons.css'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import 'typeface-roboto'
import Layout from './Layout/Layout'
import LeaderViewPageAutomation from './LeaderViewAutomation/LeaderViewAutomation'
import PrivacyPolicyPage from './PrivacyPolicy/PrivacyPolicyPage'
import DailySchedulePage from './Schedule/DailySchedulePage'

import { ProtectedElement } from '@praxis/component-auth'
import { ErrorBoundary } from '@praxis/component-logging'
import AppleIosDownloadPage from './AppleIosDownload/AppleIosDownloadPage'
import AvailableShiftsPage from './AvailableShifts/AvailableShifts'
import TMOptionalAvailabilityPage from './OptionalAvailability/TMOptionalAvailabilityPage'
import Requests from './Requests/Requests'
import WeeklySchedulePage from './Schedule/WeeklySchedulePage'
import ReviewSwapRequests from './SwapShift/ReviewSwapRequests'
import SearchSwapShifts from './SwapShift/SearchSwapShifts'
import TimecardDaily from './Timecard/TimecardDaily'
import TimecardWeekly from './Timecard/TimecardWeekly'

import DesiredHours from './DesiredHours/DesiredHours'
import HomePage from './HomePage/HomePage'
import NotFoundPage from './NotFoundPage/NotFoundPage'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Provider } from 'react-redux'
import muiTheme from '../config/themeConfig'

//Firefly imports
import AvailabilityHome from './Availability/AvailabilityHome'
import ViewAvailability from './Availability/ViewAvailability'
import CategoryDetailPage from './Leader/CategoryDetail/CategoryDetailPage'
import DashboardPage from './Leader/Dashboard/DashboardPage'
import MyTeamPage from './Leader/MyTeam/MyTeamPage'
import Loader from './Loader/Loader'
import RequestAvailability from './Requests/Availability/RequestAvailability'
import RequestAvailabilityDetail from './Requests/Availability/RequestAvailabilityDetail'
import RequestPunchCorrection from './Requests/PunchCorrection/RequestPunchCorrection'
import PushNotifications from './Settings/PushNotifications/PushNotifications'
import Error from './common/fallback/Error'

import TeamMemberBarcode from '../pages/Barcode'

import MyProtectedElement from '../auth/MyProtectedElement'
import SettingsPage from './Settings/Settings'

import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import axios from 'axios'
import { queryClient } from '../api/queryClient'
import MyTimeAuthProvider, { MyTimeUserProvider } from '../auth/MyTimeAccessProviders'
import Communication from '../pages/Communication'
import Feedback from '../pages/Feedback'
import FeedbackAnalysis from '../pages/FeedbackAnalysis'
import HomeRedirect from '../pages/HomeRedirect'
import RequestTimeOff from '../pages/RequestTimeOff'
import configureStore from '../store/configureStore'
import { useBarcodeAccess } from './Layout/hooks/useBarcodeAccess'
import LeaderNotPunchedIn from './Leader/LeaderNotPunchedIn'
import LeaderRedirect from './Leader/LeaderRedirect'
import RequestCallIn from './Requests/CallIn/RequestCallIn'
import RequestCallOff from './Requests/CallOff/RequestCallOff'
import RequestMassVacation from './Requests/MassVacation/RequestMassVacation'
import DailySchduleRedirectTemp from './Schedule/DailySchduleRedirectTemp'
import NotAuthorized from './common/fallback/NotAuthorized'
import { AdminLayout, LeaderLayout, TeamMemberLayout } from './common/layout/Layouts'
import { QueryBoundaries } from './common/wrappers/QueryBoundaries'

axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

/* eslint-env browser */
const store = configureStore()

declare global {
  interface Window {
    store: any // can have typed redux when upgrade.
  }
}

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store
}

const createRouteErrorObject = (pageName: string) => {
  const errorId = Math.floor(Math.random() * 9999) + 1
  return {
    message: `Router errorElement caught unknown error in ${pageName} component`,
    code: `mytime-ui-${pageName}-${errorId}`,
  }
}

const USER_FRIENDLY_MESSAGE = 'myTime For Target UI'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={
          <ProtectedElement>
            <Layout />
          </ProtectedElement>
        }
      >
        <Route index element={<HomeRedirect />} />
        <Route path="team-member" element={<TeamMemberLayout />}>
          <Route
            path="availableShifts"
            element={
              <MyProtectedElement allowed={['open_shifts', 'request_to_cover']} permissions={['read', 'write']}>
                <AvailableShiftsPage />
              </MyProtectedElement>
            }
            errorElement={
              <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('availableShifts')} />
            }
          />
          <Route
            index
            path="dailySchedulePage/schedule"
            element={
              <MyProtectedElement allowed={['schedule']} permissions={['read']}>
                <DailySchedulePage />
              </MyProtectedElement>
            }
            errorElement={
              <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('dailySchedulePage')} />
            }
          />
          <Route
            path="feedback"
            element={
              <QueryBoundaries>
                <MyProtectedElement
                  allowed={['feedback']}
                  permissions={['read', 'write']}
                  blockWhileLoading={true}
                  fullScreenLoading={false}
                >
                  <Feedback />
                </MyProtectedElement>
              </QueryBoundaries>
            }
          />
          <Route
            path={'home'}
            element={<HomePage />}
            errorElement={<Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('home')} />}
          />
          <Route
            path="schedule"
            element={
              <MyProtectedElement allowed={['schedule']} permissions={['read']}>
                <WeeklySchedulePage />
              </MyProtectedElement>
            }
            errorElement={
              <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('schedule')} />
            }
          />
          <Route
            path="tmOptionalAvailability"
            element={
              <MyProtectedElement allowed={['voluntary_availability']} permissions={['read']}>
                <TMOptionalAvailabilityPage />
              </MyProtectedElement>
            }
            errorElement={
              <Error
                userFriendlyMessage={USER_FRIENDLY_MESSAGE}
                error={createRouteErrorObject('tmOptionalAvailability')}
              />
            }
          />
          <Route
            path="requests"
            errorElement={
              <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('requests')} />
            }
          >
            <Route
              index
              element={
                <MyProtectedElement
                  allowed={['availability', 'time_off', 'call_in', 'call_off', 'mass_vacation', 'punch_correction']}
                  permissions={['read', 'write']}
                  checkCompensable={false}
                >
                  <Requests />
                </MyProtectedElement>
              }
              errorElement={
                <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('requests')} />
              }
            />
            <Route
              path=":requestType"
              element={
                <MyProtectedElement
                  allowed={['availability', 'time_off', 'call_in', 'call_off', 'mass_vacation', 'punch_correction']}
                  permissions={['read', 'write']}
                  checkCompensable={false}
                >
                  <Requests />
                </MyProtectedElement>
              }
              errorElement={
                <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('requests')} />
              }
            />
            <Route
              path="time_off/timeOff"
              element={
                <MyProtectedElement allowed={['time_off']}>
                  <RequestTimeOff />
                </MyProtectedElement>
              }
              errorElement={
                <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('paidTimeOff')} />
              }
            />
            <Route
              path="time_off/callIn"
              element={
                <MyProtectedElement allowed={['call_in']}>
                  <RequestCallIn />
                </MyProtectedElement>
              }
              errorElement={
                <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('callIn')} />
              }
            />
            <Route
              path="time_off/callOff"
              element={
                <MyProtectedElement allowed={['call_off']}>
                  <RequestCallOff />
                </MyProtectedElement>
              }
              errorElement={
                <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('callOff')} />
              }
            />
            <Route
              path="mass_vacation/create-mass-vacation"
              errorElement={
                <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('massVacation')} />
              }
            >
              <Route
                index
                element={
                  <MyProtectedElement allowed={['mass_vacation']}>
                    <RequestMassVacation />
                  </MyProtectedElement>
                }
                errorElement={
                  <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('massVacation')} />
                }
              />
              <Route
                path=":requestId"
                element={
                  <MyProtectedElement allowed={['mass_vacation']}>
                    <RequestMassVacation />
                  </MyProtectedElement>
                }
                errorElement={
                  <Error
                    userFriendlyMessage={USER_FRIENDLY_MESSAGE}
                    error={createRouteErrorObject('massVacationEdit')}
                  />
                }
              />
            </Route>
          </Route>
          <Route
            path="timecardWeekly"
            element={
              <MyProtectedElement allowed={['timecard']}>
                <TimecardWeekly />
              </MyProtectedElement>
            }
            errorElement={
              <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('timecardWeekly')} />
            }
          />
          <Route
            path="timecardDaily"
            element={
              <MyProtectedElement allowed={['timecard']}>
                <TimecardDaily />
              </MyProtectedElement>
            }
            errorElement={
              <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('timecardDaily')} />
            }
          />
          <Route
            path="timecardDaily/fixapunch"
            element={
              <MyProtectedElement allowed={['punch_correction']} permissions={['write']}>
                <RequestPunchCorrection />
              </MyProtectedElement>
            }
          />
          <Route
            path="swapsearchresults"
            element={
              <MyProtectedElement allowed={['shift_swap']}>
                <SearchSwapShifts />
              </MyProtectedElement>
            }
            errorElement={
              <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('swapsearchresults')} />
            }
          />
          <Route
            path="reviewswaprequests"
            element={
              <MyProtectedElement allowed={['shift_swap']}>
                <ReviewSwapRequests />
              </MyProtectedElement>
            }
            errorElement={
              <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('reviewswaprequests')} />
            }
          />
          <Route
            path="desiredHours"
            element={
              <MyProtectedElement allowed={['desired_hours']} permissions={['read', 'write']}>
                <DesiredHours />
              </MyProtectedElement>
            }
            errorElement={
              <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('desiredHours')} />
            }
          />
          <Route
            path="availability"
            element={
              <MyProtectedElement allowed={['availability']}>
                <AvailabilityHome />
              </MyProtectedElement>
            }
          />
          <Route
            path="availability/view-all"
            element={
              <MyProtectedElement allowed={['availability']}>
                <ViewAvailability />
              </MyProtectedElement>
            }
          />
          <Route
            path="availability/create-new-availability"
            element={
              <MyProtectedElement allowed={['availability']} permissions={['write']}>
                <RequestAvailability />
              </MyProtectedElement>
            }
          />
          <Route
            path="availability/display-availability"
            element={
              <MyProtectedElement allowed={['availability']} permissions={['read']}>
                <RequestAvailabilityDetail />
              </MyProtectedElement>
            }
          />

          {/* <Route path="survey" element={<Survey />} /> */}
          <Route
            path="barcode"
            element={
              <MyProtectedElement allowed={['barcode']} permissions={['read']}>
                <TeamMemberBarcode />
              </MyProtectedElement>
            }
          />
          <Route
            path="appleIosDownload"
            element={
              <MyProtectedElement allowed={['redemption_code']} permissions={['read']}>
                <AppleIosDownloadPage />
              </MyProtectedElement>
            }
            errorElement={
              <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('appleIosDownload')} />
            }
          />

          <Route path="settings" element={<SettingsPage />} />
          <Route
            path="settings/push-notifications"
            element={
              <MyProtectedElement allowed={['opt_in_opt_out']} permissions={['read']}>
                <PushNotifications />
              </MyProtectedElement>
            }
          />
        </Route>
        <Route
          path="admin"
          element={
            <MyProtectedElement allowed={['communication']} view={'admin'} permissions={['read', 'write']}>
              <AdminLayout />
            </MyProtectedElement>
          }
          errorElement={<Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('admin')} />}
        >
          <Route path="communication" element={<Communication />} />
          <Route path="feedback-analysis" element={<FeedbackAnalysis />} />
        </Route>

        <Route
          path="leader"
          element={<LeaderLayout />}
          errorElement={<Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('leader')} />}
        >
          <Route
            index
            element={<LeaderRedirect />}
            errorElement={
              <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('leader')} />
            }
          />
          <Route
            path="dashboard"
            element={
              <MyProtectedElement
                allowed={['call_in', 'call_off', 'time_off', 'punch_correction']}
                view={'leader'}
                permissions={['read', 'write', 'partial']}
                renderUnauthorized={LeaderNotPunchedIn}
              >
                <DashboardPage />
              </MyProtectedElement>
            }
            errorElement={
              <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('dashboard')} />
            }
          />
          <Route
            path="myteam"
            errorElement={
              <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('myteam')} />
            }
          >
            <Route
              index
              element={
                <MyProtectedElement
                  allowed={['call_in', 'call_off', 'time_off', 'punch_correction']}
                  view={'leader'}
                  permissions={['read', 'write', 'partial']}
                  renderUnauthorized={LeaderNotPunchedIn}
                >
                  <MyTeamPage />
                </MyProtectedElement>
              }
              errorElement={
                <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('myteam')} />
              }
            />
            <Route
              path=":category"
              element={
                <MyProtectedElement
                  allowed={['call_in', 'call_off', 'time_off', 'punch_correction']}
                  view={'leader'}
                  permissions={['read', 'write', 'partial']}
                  renderUnauthorized={LeaderNotPunchedIn}
                >
                  <MyTeamPage />
                </MyProtectedElement>
              }
              errorElement={
                <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('myTeam/category')} />
              }
            />
            <Route
              path=":category/:id"
              element={
                <MyProtectedElement
                  allowed={['call_in', 'call_off', 'time_off', 'availability', 'punch_correction']}
                  view={'leader'}
                  permissions={['read', 'write', 'partial']}
                  renderUnauthorized={LeaderNotPunchedIn}
                >
                  <CategoryDetailPage />{' '}
                </MyProtectedElement>
              }
              errorElement={
                <Error
                  userFriendlyMessage={USER_FRIENDLY_MESSAGE}
                  error={createRouteErrorObject('myTeam/category/request')}
                />
              }
            />
          </Route>
        </Route>
        <Route
          path="leaderView/*"
          element={
            <MyProtectedElement allowed={['dashboard']} view={'leader'} permissions={['read', 'write']}>
              <LeaderViewPageAutomation />
            </MyProtectedElement>
          }
          errorElement={
            <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('leaderView')} />
          }
        />
        <Route
          path="leaderViewAutomation"
          element={
            <MyProtectedElement allowed={['dashboard']} view={'leader'} permissions={['read', 'write']}>
              <LeaderViewPageAutomation />
            </MyProtectedElement>
          }
          errorElement={
            <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('leaderViewAutomation')} />
          }
        />
        <Route
          index
          path="dailySchedulePage/schedule"
          element={
            <MyProtectedElement allowed={['schedule']} permissions={['read']}>
              <DailySchduleRedirectTemp />
            </MyProtectedElement>
          }
          errorElement={
            <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('dailySchedulePage')} />
          }
        />

        <Route path="unauthorized" element={<NotAuthorized />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
    </>,
  ),
)

const FallbackError = () => {
  return <Error userFriendlyMessage={USER_FRIENDLY_MESSAGE} error={createRouteErrorObject('app')} />
}

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <MyTimeAuthProvider>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <ThemeProvider theme={muiTheme}>
              <MyTimeUserProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <ErrorBoundary FallbackComponent={FallbackError}>
                    <MainRouter />
                  </ErrorBoundary>
                </LocalizationProvider>
              </MyTimeUserProvider>
            </ThemeProvider>
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </MyTimeAuthProvider>
    </StyledEngineProvider>
  )
}

const MainRouter = () => {
  useBarcodeAccess()
  return <RouterProvider router={router} fallbackElement={<Loader />} />
}

export default App
