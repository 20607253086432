import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import usePutMassVacation from '../../../api/requests/usePutMassVacation'
import { useUser } from '../../../auth/hooks/useUser'
import useConfirm from '../../../hooks/useConfirm'
import { setBackToComponent } from '../../../store/header/actionCreator'
import { formatMinutesToDisplay, massVacationManager } from '../../../utils/DateUtil'
import { getOrdinalNumber, pluralize } from '../../../utils/strings'
import RequestStatusIcon from '../RequestStatusIcon'

const getDateTitle = (startDate, endDate, zone) => {
  const outputDateFormat = 'MMM D, YYYY'

  if (!startDate || !endDate) {
    return 'No title'
  }

  const start = moment(startDate).tz(zone).format(outputDateFormat) || null
  const end = moment(endDate).tz(zone).format(outputDateFormat) || null

  if (startDate === endDate) {
    return start
  } else {
    return `${start} - ${end}`
  }
}

export default function MassVacationRequestAccordion(props) {
  const { request, shiftLengthMinutes } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { mutate } = usePutMassVacation()
  const { locationData } = useUser()
  let zone = locationData?.iso_time_zone_code
  const { isConfirmed } = useConfirm()
  const mvManager = massVacationManager()

  const handleEdit = (e) => {
    dispatch(setBackToComponent('/team-member/requests/mass_vacation'))
    navigate(`/team-member/requests/mass_vacation/create-mass-vacation/${request.request_id}`, {
      state: { data: { request, shiftLengthMinutes: shiftLengthMinutes } },
    })
  }

  const handleConfirmDelete = async () => {
    return await isConfirmed('Deleting will permanently delete this Mass Vacation. This can not be undone.')
  }

  const handleDelete = async (e) => {
    if (await handleConfirmDelete()) {
      mutate({ massVacation: { requestId: request.request_id }, action: 'DELETE' })
    }
  }

  return (
    <Accordion square disableGutters>
      <Summary request={request} zone={zone} />
      <AccordionDetails sx={{ padding: 1 }}>
        <MassVacationDetails requestDetails={request.request_details} />
      </AccordionDetails>
      <AccordionActions>
        <Grid container sx={{ paddingX: 1 }}>
          {locationData ? (
            <Typography sx={{ fontSize: 'small' }} color={'tertiary'}>{`Last updated ${moment(request.updated_timestamp)
              .tz(zone)
              .format('lll')}`}</Typography>
          ) : (
            <></>
          )}
        </Grid>
        {!mvManager.submitDisabled ? (
          <>
            <Button onClick={handleEdit} color="primary" variant="contained" size="small" data-cy="editButton">
              Edit
            </Button>
            <Button onClick={handleDelete} variant="outlined" size="small" data-cy="deleteButton">
              Delete
            </Button>
          </>
        ) : (
          <></>
        )}
      </AccordionActions>
    </Accordion>
  )
}

function Summary({ request, zone }) {
  const getPriority = () => {
    let noPriority = request.priority === 0
    let priorityString = noPriority ? 'No Priority' : `${getOrdinalNumber(request.priority)} priority`
    return (
      <Typography sx={{ fontSize: 'small' }} color={noPriority ? 'primary' : null}>
        {priorityString}
      </Typography>
    )
  }

  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon color={'primary'} />}
      aria-controls="panel1c-content"
      id="panel1c-header"
      sx={{ paddingX: 1 }}
    >
      <Grid container item flexShrink={11} alignContent={'center'}>
        <RequestStatusIcon requestType={'time_off'} status={request.status} isTimeOffTeamMember={true} />
      </Grid>
      <Grid container flexDirection={'column'}>
        <Grid item>
          <Typography
            component="h2"
            sx={{
              fontSize: 'large',
              fontWeight: 'bold',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {request.title ? request.title : getDateTitle(request.start_time, request.end_time, zone)}
          </Typography>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>{getPriority()}</Grid>
          <Grid item>
            <Typography sx={{ fontSize: 'small' }}>|</Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: 'small' }}>{pluralize(request.total_request_details, 'day')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </AccordionSummary>
  )
}

function MassVacationDetails({ requestDetails }) {
  const getDuration = (start, end) => moment(end).diff(moment(start), 'minutes')
  return (
    <Grid container paddingX={1}>
      <Grid item container justifyContent={'space-between'}>
        <Typography fontWeight={'bold'}>Date</Typography>
        <Typography fontWeight={'bold'}>Hours</Typography>
      </Grid>
      <Grid container item>
        {requestDetails.map((detail, index) => {
          return (
            <Grid key={`${detail.request_detail_id}`} item container justifyContent={'space-between'}>
              <Grid item>
                <Typography>{moment(detail.start_time).format('ll')}</Typography>
              </Grid>
              <Grid item>
                <Typography>{formatMinutesToDisplay(getDuration(detail.start_time, detail.end_time))}</Typography>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}
